{
  "day": {
    "name": "Day",
    "backgroundColor": "#a9ccdb"
  },
  "twilight": {
    "name": "Twilight",
    "backgroundGradient": [
      ["#5583be", 0],
      ["#eebaa5", 0.4],
      ["#de7d8e", 0.8]
    ],
    "cloudOpacity": 0.85
  },
  "sunset": {
    "name": "Sunset",
    "enabled": false,
    "backgroundGradient": [
      ["#020b1a"],
      ["#3e5879"],
      ["#9ba5ae"],
      ["#dcb697"],
      ["#fc7001"],
      ["#dd723c"],
      ["#ad4a28"],
      ["#040308"]
    ],
    "cloudOpacity": 0.65
  },
  "dusk": {
    "name": "Dusk",
    "backgroundGradient": [
      ["#020b1a"],
      ["#3e5879"],
      ["#ec6127", 0.5],
      ["#ffbb65", 0.7]
    ],
    "cloudOpacity": 0.5,
    "invertUITextColor": true
  },
  "sunrise": {
    "_comment": "Renamed to 'Dawn'",
    "enabled": false,
    "name": "Sunrise",
    "backgroundGradient": [
      "#a9ccdb",
      "#b7d6fd",
      "#fbce96",
      ["#dd771e", 0.9],
      "#58151a"
    ]
  },
  "dawn": {
    "name": "Dawn",
    "backgroundGradient": [
      "#a9ccdb",
      "#b7d6fd",
      "#fbce96",
      ["#dd771e", 0.9],
      "#58151a"
    ]
  },
  "night": {
    "name": "Night",
    "iconImage": "environs--icon-night",
    "backgroundImage": "sky--stars",
    "_commentBackgroundColor": "Fall back to solid color in case image is broken",
    "backgroundGradient": [
      ["#01051600", 0],
      ["#01081777", 0.33],
      ["#031641", 0.66],
      ["#061c45", 1]
    ],
    "backgroundColor": "#000928",
    "backgroundObjects": [
      {
        "image": "sky--moon",
        "width": 116,
        "height": 116,
        "top": 0.2,
        "left": 0.8
      }
    ],
    "cloudOpacity": 0.2,
    "invertUITextColor": true
  },
  "supermoon": {
    "name": "Night",
    "enabled": false,
    "iconImage": "environs--icon-night",
    "backgroundImage": "sky--stars",
    "backgroundGradient": [
      ["#01051600", 0],
      ["#01081777", 0.33],
      ["#031641", 0.66],
      ["#061c45", 1]
    ],
    "backgroundColor": "#000928",
    "backgroundObjects": [
      {
        "image": "sky--super-blood-wolf-moon",
        "width": 116,
        "height": 116,
        "top": 0.2,
        "left": 0.8
      }
    ],
    "cloudOpacity": 0.2,
    "invertUITextColor": true
  },
  "karl": {
    "name": "Fog",
    "backgroundColor": "#e2f0f2",
    "foregroundGradient": [
      ["rgba(255,255,255,0.85)", 0],
      ["rgba(192,192,192,0.5)", 0.85],
      ["transparent", 1]
    ]
  },
  "holodeck": {
    "name": "Holodeck",
    "iconImage": "environs--icon-holodeck",
    "backgroundColor": "#141414",
    "backgroundImage": "sky--holodeck-grid",
    "cloudOpacity": 0,
    "invertUITextColor": true
  }
}
