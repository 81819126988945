"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * A fluent interface for autocomplete searches in Pelias
 */
const Constants = __importStar(require("../constants"));
const fetch_1 = require("../util/fetch/fetch");
const parameterSet = __importStar(require("../util/parameter-set/parameter-set"));
// TODO: take a formed search object so the use doesn't have to use the fluent setters
class Autocomplete {
    constructor(config) {
        this.setSearchTerm = parameterSet.setSearchTerm.bind(this, '_autocompleteObject');
        this.setFocusPoint = parameterSet.setFocusPoint.bind(this, '_autocompleteObject');
        this.setBoundaryCountry = parameterSet.setBoundaryCountry.bind(this, '_autocompleteObject');
        this.setBoundaryRectangle = parameterSet.setBoundaryRectangle.bind(this, '_autocompleteObject');
        this.setDataSources = parameterSet.setDataSources.bind(this, '_autocompleteObject');
        this.setLayers = parameterSet.setLayers.bind(this, '_autocompleteObject');
        this.execute = () => {
            const query = buildAutocompleteQueryString(this._autocompleteObject);
            return fetch_1.autocomplete(this._baseUrl, query).then((response) => {
                return (response);
            });
        };
        this._autocompleteObject = {
            searchTerm: undefined,
            apiKey: config.apiKey || undefined
        };
        if (!('peliasUrl' in config)) {
            throw new Error("peliasUrl must be specified in the constructor");
        }
        this._baseUrl = config.peliasUrl;
    }
}
// Search takes a GET request with a variety of query string params
const buildAutocompleteQueryString = (autocompleteObject) => {
    const paramsArray = [];
    if (autocompleteObject.searchTerm) {
        paramsArray.push([Constants.QS_TEXT, autocompleteObject.searchTerm]);
    }
    if (autocompleteObject.apiKey) {
        paramsArray.push([Constants.QS_API_KEY, autocompleteObject.apiKey]);
    }
    if (autocompleteObject.focusPoint) {
        paramsArray.push([Constants.QS_FOCUS_LAT, autocompleteObject.focusPoint.lat]);
        paramsArray.push([Constants.QS_FOCUS_LONG, autocompleteObject.focusPoint.lon]);
    }
    if (autocompleteObject.boundaryCountry) {
        paramsArray.push([Constants.QS_BOUNDARY_COUNTRY, autocompleteObject.boundaryCountry]);
    }
    if (autocompleteObject.boundaryRectangle) {
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MIN_LAT, autocompleteObject.boundaryRectangle.min_lat]);
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MAX_LAT, autocompleteObject.boundaryRectangle.max_lat]);
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MIN_LON, autocompleteObject.boundaryRectangle.min_lon]);
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MAX_LON, autocompleteObject.boundaryRectangle.max_lon]);
    }
    if (autocompleteObject.dataSources) {
        paramsArray.push([Constants.QS_DATA_SOURCES, autocompleteObject.dataSources.join(",")]);
    }
    if (autocompleteObject.layers) {
        paramsArray.push([Constants.QS_LAYERS, autocompleteObject.layers.join(",")]);
    }
    const autocompleteParams = paramsArray.map((keyValPair) => {
        return `${encodeURIComponent(keyValPair[0])}=${encodeURIComponent(keyValPair[1])}`;
    }).join('&');
    return autocompleteParams;
};
exports.default = Autocomplete;
