{
  "bike-lane:green->divider:planter-box": 1,
  "bike-lane:green|outbound->sidewalk-lamp:left|modern": 1,
  "bike-lane:green|outbound|sidewalk->sidewalk-lamp:left|modern": 1,
  "bike-lane:green|sidewalk->sidewalk-lamp:left|modern": 1,
  "bike-lane:inbound->sidewalk-lamp:left|modern": 3,
  "bike-lane:inbound|colored->bike-lane:inbound|colored": 33,
  "bike-lane:inbound|colored->bike-lane:inbound|regular": 19,
  "bike-lane:inbound|colored->bike-lane:outbound|colored": 324,
  "bike-lane:inbound|colored->bike-lane:outbound|regular": 49,
  "bike-lane:inbound|colored->bus-lane:inbound|colored": 21,
  "bike-lane:inbound|colored->bus-lane:inbound|regular": 100,
  "bike-lane:inbound|colored->bus-lane:outbound|regular": 3,
  "bike-lane:inbound|colored->divider:big-tree": 64,
  "bike-lane:inbound|colored->divider:bollard": 103,
  "bike-lane:inbound|colored->divider:bush": 47,
  "bike-lane:inbound|colored->divider:dome": 33,
  "bike-lane:inbound|colored->divider:flowers": 117,
  "bike-lane:inbound|colored->divider:median": 86,
  "bike-lane:inbound|colored->divider:palm-tree": 31,
  "bike-lane:inbound|colored->divider:planting-strip": 36,
  "bike-lane:inbound|colored->divider:striped-buffer": 104,
  "bike-lane:inbound|colored->drive-lane:inbound|car": 329,
  "bike-lane:inbound|colored->drive-lane:inbound|sharrow": 3,
  "bike-lane:inbound|colored->drive-lane:inbound|truck": 27,
  "bike-lane:inbound|colored->drive-lane:outbound|car": 12,
  "bike-lane:inbound|colored->drive-lane:outbound|sharrow": 1,
  "bike-lane:inbound|colored->drive-lane:outbound|truck": 9,
  "bike-lane:inbound|colored->light-rail:inbound|colored": 13,
  "bike-lane:inbound|colored->light-rail:inbound|regular": 26,
  "bike-lane:inbound|colored->light-rail:outbound|colored": 1,
  "bike-lane:inbound|colored->light-rail:outbound|regular": 3,
  "bike-lane:inbound|colored->parking-lane:inbound|left": 40,
  "bike-lane:inbound|colored->parking-lane:inbound|right": 31,
  "bike-lane:inbound|colored->parking-lane:outbound|left": 1,
  "bike-lane:inbound|colored->parking-lane:outbound|right": 41,
  "bike-lane:inbound|colored->parking-lane:sideways|right": 1,
  "bike-lane:inbound|colored->parklet:left": 9,
  "bike-lane:inbound|colored->parklet:right": 26,
  "bike-lane:inbound|colored->sidewalk-bench:center": 1,
  "bike-lane:inbound|colored->sidewalk-bench:left": 6,
  "bike-lane:inbound|colored->sidewalk-bench:right": 2,
  "bike-lane:inbound|colored->sidewalk-bike-rack:left|road": 4,
  "bike-lane:inbound|colored->sidewalk-bike-rack:left|sidewalk": 19,
  "bike-lane:inbound|colored->sidewalk-bike-rack:left|sidewalk-parallel": 12,
  "bike-lane:inbound|colored->sidewalk-bike-rack:right|road": 1,
  "bike-lane:inbound|colored->sidewalk-bike-rack:right|sidewalk": 4,
  "bike-lane:inbound|colored->sidewalk-bike-rack:right|sidewalk-parallel": 7,
  "bike-lane:inbound|colored->sidewalk-lamp:both|modern": 43,
  "bike-lane:inbound|colored->sidewalk-lamp:both|traditional": 57,
  "bike-lane:inbound|colored->sidewalk-lamp:left|modern": 134,
  "bike-lane:inbound|colored->sidewalk-lamp:left|traditional": 37,
  "bike-lane:inbound|colored->sidewalk-lamp:right|modern": 39,
  "bike-lane:inbound|colored->sidewalk-lamp:right|traditional": 22,
  "bike-lane:inbound|colored->sidewalk-tree:big": 255,
  "bike-lane:inbound|colored->sidewalk-tree:palm-tree": 18,
  "bike-lane:inbound|colored->sidewalk-wayfinding:large": 3,
  "bike-lane:inbound|colored->sidewalk-wayfinding:medium": 13,
  "bike-lane:inbound|colored->sidewalk-wayfinding:small": 5,
  "bike-lane:inbound|colored->sidewalk:dense": 53,
  "bike-lane:inbound|colored->sidewalk:normal": 22,
  "bike-lane:inbound|colored->sidewalk:sparse": 5,
  "bike-lane:inbound|colored->streetcar:inbound|colored": 14,
  "bike-lane:inbound|colored->streetcar:inbound|regular": 40,
  "bike-lane:inbound|colored->streetcar:outbound|regular": 2,
  "bike-lane:inbound|colored->transit-shelter:left|light-rail": 19,
  "bike-lane:inbound|colored->transit-shelter:left|street-level": 102,
  "bike-lane:inbound|colored->transit-shelter:right|light-rail": 1,
  "bike-lane:inbound|colored->transit-shelter:right|street-level": 16,
  "bike-lane:inbound|colored->turn-lane:inbound|left": 5,
  "bike-lane:inbound|colored->turn-lane:inbound|left-straight": 17,
  "bike-lane:inbound|colored->turn-lane:inbound|right": 10,
  "bike-lane:inbound|colored->turn-lane:inbound|right-straight": 36,
  "bike-lane:inbound|colored->turn-lane:inbound|shared": 1,
  "bike-lane:inbound|colored->turn-lane:inbound|straight": 23,
  "bike-lane:inbound|colored->turn-lane:outbound|left": 4,
  "bike-lane:inbound|colored->turn-lane:outbound|left-straight": 1,
  "bike-lane:inbound|colored->turn-lane:outbound|straight": 2,
  "bike-lane:inbound|green->sidewalk-lamp:left|modern": 1,
  "bike-lane:inbound|green|road->bike-lane:inbound|green|road": 5,
  "bike-lane:inbound|green|road->bike-lane:outbound|green|road": 105,
  "bike-lane:inbound|green|road->bike-lane:outbound|red|road": 8,
  "bike-lane:inbound|green|road->bike-lane:outbound|regular|road": 9,
  "bike-lane:inbound|green|road->bikeshare:left|road": 1,
  "bike-lane:inbound|green|road->bikeshare:right|sidewalk": 1,
  "bike-lane:inbound|green|road->bus-lane:inbound|colored|typical": 15,
  "bike-lane:inbound|green|road->bus-lane:inbound|regular|typical": 12,
  "bike-lane:inbound|green|road->bus-lane:inbound|shared|typical": 3,
  "bike-lane:inbound|green|road->divider:big-tree": 20,
  "bike-lane:inbound|green|road->divider:bollard": 35,
  "bike-lane:inbound|green|road->divider:bush": 15,
  "bike-lane:inbound|green|road->divider:dome": 20,
  "bike-lane:inbound|green|road->divider:flowers": 77,
  "bike-lane:inbound|green|road->divider:median": 11,
  "bike-lane:inbound|green|road->divider:palm-tree": 2,
  "bike-lane:inbound|green|road->divider:planter-box": 26,
  "bike-lane:inbound|green|road->divider:planting-strip": 9,
  "bike-lane:inbound|green|road->divider:striped-buffer": 22,
  "bike-lane:inbound|green|road->drive-lane:inbound": 1,
  "bike-lane:inbound|green|road->drive-lane:inbound|car": 84,
  "bike-lane:inbound|green|road->drive-lane:inbound|truck": 3,
  "bike-lane:inbound|green|road->drive-lane:outbound|car": 15,
  "bike-lane:inbound|green|road->flex-zone-curb:sparse|left": 3,
  "bike-lane:inbound|green|road->flex-zone-curb:sparse|right": 2,
  "bike-lane:inbound|green|road->flex-zone:taxi|inbound|left": 2,
  "bike-lane:inbound|green|road->flex-zone:taxi|inbound|right": 1,
  "bike-lane:inbound|green|road->food-truck:left": 1,
  "bike-lane:inbound|green|road->food-truck:right": 1,
  "bike-lane:inbound|green|road->light-rail:inbound|grass": 1,
  "bike-lane:inbound|green|road->light-rail:inbound|regular": 1,
  "bike-lane:inbound|green|road->parking-lane:angled-front-right|right": 2,
  "bike-lane:inbound|green|road->parking-lane:inbound|left": 15,
  "bike-lane:inbound|green|road->parking-lane:inbound|right": 7,
  "bike-lane:inbound|green|road->parking-lane:outbound|right": 1,
  "bike-lane:inbound|green|road->scooter:inbound|regular": 1,
  "bike-lane:inbound|green|road->scooter:outbound|green": 4,
  "bike-lane:inbound|green|road->sidewalk-bench:center": 1,
  "bike-lane:inbound|green|road->sidewalk-bench:right": 1,
  "bike-lane:inbound|green|road->sidewalk-bike-rack:left|sidewalk": 7,
  "bike-lane:inbound|green|road->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "bike-lane:inbound|green|road->sidewalk-lamp:both|modern": 9,
  "bike-lane:inbound|green|road->sidewalk-lamp:both|traditional": 12,
  "bike-lane:inbound|green|road->sidewalk-lamp:left|modern": 32,
  "bike-lane:inbound|green|road->sidewalk-lamp:left|pride": 2,
  "bike-lane:inbound|green|road->sidewalk-lamp:left|traditional": 11,
  "bike-lane:inbound|green|road->sidewalk-lamp:modern|both": 1,
  "bike-lane:inbound|green|road->sidewalk-lamp:right|modern": 4,
  "bike-lane:inbound|green|road->sidewalk-lamp:right|traditional": 4,
  "bike-lane:inbound|green|road->sidewalk-tree:big": 73,
  "bike-lane:inbound|green|road->sidewalk-tree:palm-tree": 3,
  "bike-lane:inbound|green|road->sidewalk:dense": 32,
  "bike-lane:inbound|green|road->sidewalk:normal": 5,
  "bike-lane:inbound|green|road->streetcar:inbound|colored": 2,
  "bike-lane:inbound|green|road->streetcar:inbound|grass": 1,
  "bike-lane:inbound|green|road->streetcar:inbound|regular": 4,
  "bike-lane:inbound|green|road->transit-shelter:left|street-level": 14,
  "bike-lane:inbound|green|road->transit-shelter:right|street-level": 1,
  "bike-lane:inbound|green|road->turn-lane:inbound|left": 5,
  "bike-lane:inbound|green|road->turn-lane:inbound|left-right-straight": 3,
  "bike-lane:inbound|green|road->turn-lane:inbound|left-straight": 4,
  "bike-lane:inbound|green|road->turn-lane:inbound|straight": 7,
  "bike-lane:inbound|green|road->turn-lane:outbound|left-straight": 1,
  "bike-lane:inbound|green|sidewalk->bike-lane:outbound|green|sidewalk": 55,
  "bike-lane:inbound|green|sidewalk->bike-lane:outbound|regular|sidewalk": 1,
  "bike-lane:inbound|green|sidewalk->bikeshare:right|sidewalk": 1,
  "bike-lane:inbound|green|sidewalk->bus-lane:inbound|colored|typical": 2,
  "bike-lane:inbound|green|sidewalk->bus-lane:inbound|regular|typical": 3,
  "bike-lane:inbound|green|sidewalk->divider:big-tree": 15,
  "bike-lane:inbound|green|sidewalk->divider:bollard": 9,
  "bike-lane:inbound|green|sidewalk->divider:bush": 2,
  "bike-lane:inbound|green|sidewalk->divider:dome": 1,
  "bike-lane:inbound|green|sidewalk->divider:flowers": 23,
  "bike-lane:inbound|green|sidewalk->divider:median": 19,
  "bike-lane:inbound|green|sidewalk->divider:palm-tree": 1,
  "bike-lane:inbound|green|sidewalk->divider:planter-box": 3,
  "bike-lane:inbound|green|sidewalk->divider:planting-strip": 8,
  "bike-lane:inbound|green|sidewalk->divider:striped-buffer": 3,
  "bike-lane:inbound|green|sidewalk->drive-lane:inbound|car": 22,
  "bike-lane:inbound|green|sidewalk->drive-lane:inbound|truck": 1,
  "bike-lane:inbound|green|sidewalk->drive-lane:outbound|car": 2,
  "bike-lane:inbound|green|sidewalk->flex-zone-curb:empty|right": 1,
  "bike-lane:inbound|green|sidewalk->light-rail:inbound|grass": 1,
  "bike-lane:inbound|green|sidewalk->light-rail:inbound|regular": 1,
  "bike-lane:inbound|green|sidewalk->parking-lane:inbound|left": 4,
  "bike-lane:inbound|green|sidewalk->parking-lane:inbound|right": 1,
  "bike-lane:inbound|green|sidewalk->scooter-drop-zone:right|sidewalk|empty": 1,
  "bike-lane:inbound|green|sidewalk->sidewalk-bike-rack:left|sidewalk": 3,
  "bike-lane:inbound|green|sidewalk->sidewalk-bike-rack:right|sidewalk": 1,
  "bike-lane:inbound|green|sidewalk->sidewalk-lamp:both|modern": 5,
  "bike-lane:inbound|green|sidewalk->sidewalk-lamp:both|traditional": 9,
  "bike-lane:inbound|green|sidewalk->sidewalk-lamp:left|modern": 3,
  "bike-lane:inbound|green|sidewalk->sidewalk-lamp:left|traditional": 6,
  "bike-lane:inbound|green|sidewalk->sidewalk-lamp:right|modern": 4,
  "bike-lane:inbound|green|sidewalk->sidewalk-lamp:right|traditional": 4,
  "bike-lane:inbound|green|sidewalk->sidewalk-tree:big": 41,
  "bike-lane:inbound|green|sidewalk->sidewalk-tree:palm-tree": 1,
  "bike-lane:inbound|green|sidewalk->sidewalk:dense": 11,
  "bike-lane:inbound|green|sidewalk->sidewalk:empty": 1,
  "bike-lane:inbound|green|sidewalk->sidewalk:normal": 3,
  "bike-lane:inbound|green|sidewalk->sidewalk:sparse": 1,
  "bike-lane:inbound|green|sidewalk->streetcar:inbound|regular": 1,
  "bike-lane:inbound|green|sidewalk->transit-shelter:left|light-rail": 1,
  "bike-lane:inbound|green|sidewalk->transit-shelter:left|street-level": 7,
  "bike-lane:inbound|green|sidewalk->transit-shelter:right|street-level": 1,
  "bike-lane:inbound|green|sidewalk->turn-lane:inbound|left": 1,
  "bike-lane:inbound|green|sidewalk->turn-lane:inbound|left-straight": 1,
  "bike-lane:inbound|green|sidewalk->turn-lane:inbound|right": 1,
  "bike-lane:inbound|red|road->bike-lane:inbound|red|road": 4,
  "bike-lane:inbound|red|road->bike-lane:outbound|green|road": 5,
  "bike-lane:inbound|red|road->bike-lane:outbound|red|road": 34,
  "bike-lane:inbound|red|road->bus-lane:inbound|regular|typical": 5,
  "bike-lane:inbound|red|road->bus-lane:outbound|regular|typical": 2,
  "bike-lane:inbound|red|road->divider:big-tree": 8,
  "bike-lane:inbound|red|road->divider:bollard": 16,
  "bike-lane:inbound|red|road->divider:bush": 6,
  "bike-lane:inbound|red|road->divider:dome": 6,
  "bike-lane:inbound|red|road->divider:flowers": 16,
  "bike-lane:inbound|red|road->divider:median": 6,
  "bike-lane:inbound|red|road->divider:palm-tree": 1,
  "bike-lane:inbound|red|road->divider:planter-box": 8,
  "bike-lane:inbound|red|road->divider:planting-strip": 2,
  "bike-lane:inbound|red|road->divider:striped-buffer": 8,
  "bike-lane:inbound|red|road->drive-lane:inbound|car": 31,
  "bike-lane:inbound|red|road->drive-lane:inbound|truck": 1,
  "bike-lane:inbound|red|road->drive-lane:outbound|car": 1,
  "bike-lane:inbound|red|road->flex-zone-curb:sparse|right": 2,
  "bike-lane:inbound|red|road->light-rail:inbound|grass": 1,
  "bike-lane:inbound|red|road->parking-lane:angled-front-right|right": 1,
  "bike-lane:inbound|red|road->parking-lane:inbound|left": 6,
  "bike-lane:inbound|red|road->parking-lane:inbound|right": 1,
  "bike-lane:inbound|red|road->parking-lane:sideways|right": 1,
  "bike-lane:inbound|red|road->parklet:left": 1,
  "bike-lane:inbound|red|road->scooter:inbound|red": 2,
  "bike-lane:inbound|red|road->scooter:inbound|regular": 1,
  "bike-lane:inbound|red|road->scooter:outbound|red": 1,
  "bike-lane:inbound|red|road->sidewalk-bike-rack:left|sidewalk": 1,
  "bike-lane:inbound|red|road->sidewalk-lamp:both|modern": 17,
  "bike-lane:inbound|red|road->sidewalk-lamp:both|traditional": 3,
  "bike-lane:inbound|red|road->sidewalk-lamp:left|modern": 7,
  "bike-lane:inbound|red|road->sidewalk-lamp:right|modern": 2,
  "bike-lane:inbound|red|road->sidewalk-tree:big": 37,
  "bike-lane:inbound|red|road->sidewalk-tree:palm-tree": 3,
  "bike-lane:inbound|red|road->sidewalk:dense": 7,
  "bike-lane:inbound|red|road->sidewalk:normal": 4,
  "bike-lane:inbound|red|road->streetcar:inbound|grass": 1,
  "bike-lane:inbound|red|road->streetcar:inbound|regular": 2,
  "bike-lane:inbound|red|road->transit-shelter:left|light-rail": 1,
  "bike-lane:inbound|red|road->transit-shelter:left|street-level": 9,
  "bike-lane:inbound|red|road->turn-lane:inbound|left-straight": 3,
  "bike-lane:inbound|red|sidewalk->bike-lane:inbound|red|sidewalk": 2,
  "bike-lane:inbound|red|sidewalk->bike-lane:outbound|red|sidewalk": 26,
  "bike-lane:inbound|red|sidewalk->bikeshare:right|sidewalk": 1,
  "bike-lane:inbound|red|sidewalk->bus-lane:inbound|regular|typical": 6,
  "bike-lane:inbound|red|sidewalk->divider:big-tree": 11,
  "bike-lane:inbound|red|sidewalk->divider:bollard": 3,
  "bike-lane:inbound|red|sidewalk->divider:bush": 4,
  "bike-lane:inbound|red|sidewalk->divider:flowers": 5,
  "bike-lane:inbound|red|sidewalk->divider:median": 5,
  "bike-lane:inbound|red|sidewalk->divider:palm-tree": 1,
  "bike-lane:inbound|red|sidewalk->divider:planter-box": 1,
  "bike-lane:inbound|red|sidewalk->divider:planting-strip": 3,
  "bike-lane:inbound|red|sidewalk->divider:striped-buffer": 2,
  "bike-lane:inbound|red|sidewalk->drive-lane:inbound|car": 7,
  "bike-lane:inbound|red|sidewalk->drive-lane:outbound|car": 1,
  "bike-lane:inbound|red|sidewalk->drive-lane:outbound|truck": 1,
  "bike-lane:inbound|red|sidewalk->flex-zone-curb:empty|right": 1,
  "bike-lane:inbound|red|sidewalk->light-rail:inbound|grass": 1,
  "bike-lane:inbound|red|sidewalk->parking-lane:inbound|left": 2,
  "bike-lane:inbound|red|sidewalk->sidewalk-lamp:both|modern": 4,
  "bike-lane:inbound|red|sidewalk->sidewalk-lamp:both|traditional": 3,
  "bike-lane:inbound|red|sidewalk->sidewalk-lamp:left|modern": 2,
  "bike-lane:inbound|red|sidewalk->sidewalk-lamp:right|modern": 1,
  "bike-lane:inbound|red|sidewalk->sidewalk-tree:big": 21,
  "bike-lane:inbound|red|sidewalk->sidewalk-tree:palm-tree": 3,
  "bike-lane:inbound|red|sidewalk->sidewalk:dense": 5,
  "bike-lane:inbound|red|sidewalk->sidewalk:sparse": 1,
  "bike-lane:inbound|red|sidewalk->transit-shelter:left|light-rail": 3,
  "bike-lane:inbound|red|sidewalk->transit-shelter:left|street-level": 2,
  "bike-lane:inbound|red|sidewalk->transit-shelter:right|light-rail": 1,
  "bike-lane:inbound|red|sidewalk->transit-shelter:right|street-level": 1,
  "bike-lane:inbound|red|sidewalk->turn-lane:inbound|left-straight": 1,
  "bike-lane:inbound|red|sidewalk->turn-lane:outbound|straight": 1,
  "bike-lane:inbound|regular->bike-lane:inbound|colored": 44,
  "bike-lane:inbound|regular->bike-lane:inbound|regular": 149,
  "bike-lane:inbound|regular->bike-lane:outbound|colored": 410,
  "bike-lane:inbound|regular->bike-lane:outbound|red": 1,
  "bike-lane:inbound|regular->bike-lane:outbound|regular": 198,
  "bike-lane:inbound|regular->bus-lane:inbound|colored": 4,
  "bike-lane:inbound|regular->bus-lane:inbound|regular": 219,
  "bike-lane:inbound|regular->bus-lane:outbound|colored": 1,
  "bike-lane:inbound|regular->bus-lane:outbound|regular": 4,
  "bike-lane:inbound|regular->divider:big-tree": 62,
  "bike-lane:inbound|regular->divider:bollard": 56,
  "bike-lane:inbound|regular->divider:bush": 49,
  "bike-lane:inbound|regular->divider:dome": 27,
  "bike-lane:inbound|regular->divider:flowers": 113,
  "bike-lane:inbound|regular->divider:median": 145,
  "bike-lane:inbound|regular->divider:palm-tree": 23,
  "bike-lane:inbound|regular->divider:planting-strip": 11,
  "bike-lane:inbound|regular->divider:striped-buffer": 63,
  "bike-lane:inbound|regular->drive-lane:inbound|car": 499,
  "bike-lane:inbound|regular->drive-lane:inbound|sharrow": 1,
  "bike-lane:inbound|regular->drive-lane:inbound|truck": 23,
  "bike-lane:inbound|regular->drive-lane:outbound|car": 13,
  "bike-lane:inbound|regular->drive-lane:outbound|truck": 7,
  "bike-lane:inbound|regular->light-rail:inbound|colored": 4,
  "bike-lane:inbound|regular->light-rail:inbound|regular": 62,
  "bike-lane:inbound|regular->light-rail:outbound|regular": 4,
  "bike-lane:inbound|regular->parking-lane:inbound|left": 58,
  "bike-lane:inbound|regular->parking-lane:inbound|right": 36,
  "bike-lane:inbound|regular->parking-lane:outbound|left": 5,
  "bike-lane:inbound|regular->parking-lane:outbound|right": 11,
  "bike-lane:inbound|regular->parking-lane:sideways|right": 2,
  "bike-lane:inbound|regular->parklet:left": 27,
  "bike-lane:inbound|regular->parklet:right": 28,
  "bike-lane:inbound|regular->sidewalk-bench:left": 9,
  "bike-lane:inbound|regular->sidewalk-bench:right": 8,
  "bike-lane:inbound|regular->sidewalk-bike-rack:left|road": 3,
  "bike-lane:inbound|regular->sidewalk-bike-rack:left|sidewalk": 10,
  "bike-lane:inbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 38,
  "bike-lane:inbound|regular->sidewalk-bike-rack:right|road": 2,
  "bike-lane:inbound|regular->sidewalk-bike-rack:right|sidewalk": 2,
  "bike-lane:inbound|regular->sidewalk-bike-rack:right|sidewalk-parallel": 13,
  "bike-lane:inbound|regular->sidewalk-lamp:both|modern": 22,
  "bike-lane:inbound|regular->sidewalk-lamp:both|traditional": 69,
  "bike-lane:inbound|regular->sidewalk-lamp:left|modern": 93,
  "bike-lane:inbound|regular->sidewalk-lamp:left|traditional": 28,
  "bike-lane:inbound|regular->sidewalk-lamp:right|modern": 47,
  "bike-lane:inbound|regular->sidewalk-lamp:right|traditional": 9,
  "bike-lane:inbound|regular->sidewalk-tree:big": 476,
  "bike-lane:inbound|regular->sidewalk-tree:palm-tree": 15,
  "bike-lane:inbound|regular->sidewalk-wayfinding:large": 15,
  "bike-lane:inbound|regular->sidewalk-wayfinding:medium": 4,
  "bike-lane:inbound|regular->sidewalk-wayfinding:small": 3,
  "bike-lane:inbound|regular->sidewalk:dense": 94,
  "bike-lane:inbound|regular->sidewalk:normal": 33,
  "bike-lane:inbound|regular->sidewalk:sparse": 4,
  "bike-lane:inbound|regular->streetcar:inbound|colored": 1,
  "bike-lane:inbound|regular->streetcar:inbound|regular": 47,
  "bike-lane:inbound|regular->streetcar:outbound|colored": 1,
  "bike-lane:inbound|regular->streetcar:outbound|regular": 3,
  "bike-lane:inbound|regular->transit-shelter:left|light-rail": 12,
  "bike-lane:inbound|regular->transit-shelter:left|street-level": 92,
  "bike-lane:inbound|regular->transit-shelter:right|light-rail": 1,
  "bike-lane:inbound|regular->transit-shelter:right|street-level": 10,
  "bike-lane:inbound|regular->turn-lane:inbound|both": 1,
  "bike-lane:inbound|regular->turn-lane:inbound|left": 19,
  "bike-lane:inbound|regular->turn-lane:inbound|left-straight": 7,
  "bike-lane:inbound|regular->turn-lane:inbound|right": 9,
  "bike-lane:inbound|regular->turn-lane:inbound|right-straight": 42,
  "bike-lane:inbound|regular->turn-lane:inbound|straight": 24,
  "bike-lane:inbound|regular->turn-lane:outbound|left": 1,
  "bike-lane:inbound|regular->turn-lane:outbound|left-straight": 1,
  "bike-lane:inbound|regular->turn-lane:outbound|shared": 1,
  "bike-lane:inbound|regular->turn-lane:outbound|straight": 2,
  "bike-lane:inbound|regular|road->bike-lane:inbound|regular|road": 78,
  "bike-lane:inbound|regular|road->bike-lane:outbound": 1,
  "bike-lane:inbound|regular|road->bike-lane:outbound|green|road": 45,
  "bike-lane:inbound|regular|road->bike-lane:outbound|red|road": 1,
  "bike-lane:inbound|regular|road->bike-lane:outbound|regular|road": 209,
  "bike-lane:inbound|regular|road->bike-lane:outbound|regular|sidewalk": 1,
  "bike-lane:inbound|regular|road->bikeshare:left|road": 14,
  "bike-lane:inbound|regular|road->bikeshare:right|road": 2,
  "bike-lane:inbound|regular|road->bus-lane:inbound|colored|typical": 1,
  "bike-lane:inbound|regular|road->bus-lane:inbound|regular|typical": 56,
  "bike-lane:inbound|regular|road->bus-lane:inbound|shared|typical": 10,
  "bike-lane:inbound|regular|road->bus-lane:outbound|regular|typical": 2,
  "bike-lane:inbound|regular|road->bus-lane:outbound|shared|typical": 1,
  "bike-lane:inbound|regular|road->divider:big-tree": 52,
  "bike-lane:inbound|regular|road->divider:bollard": 59,
  "bike-lane:inbound|regular|road->divider:bush": 47,
  "bike-lane:inbound|regular|road->divider:dome": 30,
  "bike-lane:inbound|regular|road->divider:flowers": 233,
  "bike-lane:inbound|regular|road->divider:median": 29,
  "bike-lane:inbound|regular|road->divider:palm-tree": 11,
  "bike-lane:inbound|regular|road->divider:planter-box": 50,
  "bike-lane:inbound|regular|road->divider:planting-strip": 34,
  "bike-lane:inbound|regular|road->divider:striped-buffer": 73,
  "bike-lane:inbound|regular|road->drive-lane:inbound|car": 409,
  "bike-lane:inbound|regular|road->drive-lane:inbound|sharrow": 1,
  "bike-lane:inbound|regular|road->drive-lane:inbound|truck": 7,
  "bike-lane:inbound|regular|road->drive-lane:outbound|car": 32,
  "bike-lane:inbound|regular|road->drive-lane:outbound|truck": 1,
  "bike-lane:inbound|regular|road->flex-zone-curb:empty|left": 1,
  "bike-lane:inbound|regular|road->flex-zone-curb:empty|right": 1,
  "bike-lane:inbound|regular|road->flex-zone-curb:sparse|left": 16,
  "bike-lane:inbound|regular|road->flex-zone-curb:sparse|right": 4,
  "bike-lane:inbound|regular|road->flex-zone:rideshare|inbound|left": 1,
  "bike-lane:inbound|regular|road->flex-zone:taxi|inbound|left": 9,
  "bike-lane:inbound|regular|road->flex-zone:taxi|outbound|right": 1,
  "bike-lane:inbound|regular|road->food-truck:left": 6,
  "bike-lane:inbound|regular|road->food-truck:right": 2,
  "bike-lane:inbound|regular|road->light-rail:inbound|regular": 3,
  "bike-lane:inbound|regular|road->light-rail:outbound|regular": 2,
  "bike-lane:inbound|regular|road->parking-lane:inbound|left": 41,
  "bike-lane:inbound|regular|road->parking-lane:inbound|right": 38,
  "bike-lane:inbound|regular|road->parking-lane:outbound|left": 2,
  "bike-lane:inbound|regular|road->parking-lane:outbound|right": 11,
  "bike-lane:inbound|regular|road->parklet:left": 3,
  "bike-lane:inbound|regular|road->parklet:right": 7,
  "bike-lane:inbound|regular|road->scooter-drop-zone:right|sidewalk|empty": 7,
  "bike-lane:inbound|regular|road->scooter:inbound|regular": 35,
  "bike-lane:inbound|regular|road->scooter:outbound|regular": 6,
  "bike-lane:inbound|regular|road->sidewalk-bench:center": 1,
  "bike-lane:inbound|regular|road->sidewalk-bench:left": 9,
  "bike-lane:inbound|regular|road->sidewalk-bike-rack:left|sidewalk": 15,
  "bike-lane:inbound|regular|road->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "bike-lane:inbound|regular|road->sidewalk-bike-rack:right|sidewalk": 4,
  "bike-lane:inbound|regular|road->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "bike-lane:inbound|regular|road->sidewalk-lamp:both|modern": 23,
  "bike-lane:inbound|regular|road->sidewalk-lamp:both|pride": 2,
  "bike-lane:inbound|regular|road->sidewalk-lamp:both|traditional": 25,
  "bike-lane:inbound|regular|road->sidewalk-lamp:left|modern": 119,
  "bike-lane:inbound|regular|road->sidewalk-lamp:left|pride": 1,
  "bike-lane:inbound|regular|road->sidewalk-lamp:left|traditional": 46,
  "bike-lane:inbound|regular|road->sidewalk-lamp:right|modern": 44,
  "bike-lane:inbound|regular|road->sidewalk-lamp:right|pride": 1,
  "bike-lane:inbound|regular|road->sidewalk-lamp:right|traditional": 16,
  "bike-lane:inbound|regular|road->sidewalk-tree:big": 326,
  "bike-lane:inbound|regular|road->sidewalk-tree:palm-tree": 12,
  "bike-lane:inbound|regular|road->sidewalk-wayfinding:large": 1,
  "bike-lane:inbound|regular|road->sidewalk-wayfinding:small": 2,
  "bike-lane:inbound|regular|road->sidewalk:dense": 189,
  "bike-lane:inbound|regular|road->sidewalk:empty": 3,
  "bike-lane:inbound|regular|road->sidewalk:normal": 69,
  "bike-lane:inbound|regular|road->sidewalk:sparse": 14,
  "bike-lane:inbound|regular|road->streetcar:inbound|regular": 17,
  "bike-lane:inbound|regular|road->temporary:jersey-barrier-concrete": 1,
  "bike-lane:inbound|regular|road->transit-shelter:left|light-rail": 6,
  "bike-lane:inbound|regular|road->transit-shelter:left|street-level": 33,
  "bike-lane:inbound|regular|road->transit-shelter:right|street-level": 18,
  "bike-lane:inbound|regular|road->turn-lane:inbound|both": 1,
  "bike-lane:inbound|regular|road->turn-lane:inbound|left": 13,
  "bike-lane:inbound|regular|road->turn-lane:inbound|left-right-straight": 2,
  "bike-lane:inbound|regular|road->turn-lane:inbound|left-straight": 12,
  "bike-lane:inbound|regular|road->turn-lane:inbound|right": 1,
  "bike-lane:inbound|regular|road->turn-lane:inbound|right-straight": 4,
  "bike-lane:inbound|regular|road->turn-lane:inbound|straight": 12,
  "bike-lane:inbound|regular|road->turn-lane:outbound|left": 1,
  "bike-lane:inbound|regular|road->turn-lane:outbound|left-straight": 17,
  "bike-lane:inbound|regular|road->turn-lane:outbound|right": 3,
  "bike-lane:inbound|regular|road->turn-lane:outbound|right-straight": 2,
  "bike-lane:inbound|regular|road->turn-lane:outbound|straight": 6,
  "bike-lane:inbound|regular|sidewalk->bike-lane:inbound|regular|road": 1,
  "bike-lane:inbound|regular|sidewalk->bike-lane:inbound|regular|sidewalk": 3,
  "bike-lane:inbound|regular|sidewalk->bike-lane:outbound|green|sidewalk": 3,
  "bike-lane:inbound|regular|sidewalk->bike-lane:outbound|regular|sidewalk": 70,
  "bike-lane:inbound|regular|sidewalk->bikeshare:left|sidewalk": 1,
  "bike-lane:inbound|regular|sidewalk->bus-lane:inbound|regular|typical": 4,
  "bike-lane:inbound|regular|sidewalk->divider:big-tree": 7,
  "bike-lane:inbound|regular|sidewalk->divider:bollard": 2,
  "bike-lane:inbound|regular|sidewalk->divider:bush": 1,
  "bike-lane:inbound|regular|sidewalk->divider:flowers": 18,
  "bike-lane:inbound|regular|sidewalk->divider:median": 7,
  "bike-lane:inbound|regular|sidewalk->divider:planting-strip": 11,
  "bike-lane:inbound|regular|sidewalk->divider:striped-buffer": 1,
  "bike-lane:inbound|regular|sidewalk->drive-lane:inbound|car": 24,
  "bike-lane:inbound|regular|sidewalk->drive-lane:outbound|car": 1,
  "bike-lane:inbound|regular|sidewalk->drive-lane:outbound|truck": 1,
  "bike-lane:inbound|regular|sidewalk->parking-lane:inbound|left": 2,
  "bike-lane:inbound|regular|sidewalk->parking-lane:inbound|right": 1,
  "bike-lane:inbound|regular|sidewalk->scooter:inbound|regular": 1,
  "bike-lane:inbound|regular|sidewalk->scooter:outbound|regular": 2,
  "bike-lane:inbound|regular|sidewalk->sidewalk-bench:left": 2,
  "bike-lane:inbound|regular|sidewalk->sidewalk-bike-rack:left|sidewalk": 2,
  "bike-lane:inbound|regular|sidewalk->sidewalk-bike-rack:left|sidewalk-parallel": 3,
  "bike-lane:inbound|regular|sidewalk->sidewalk-lamp:both|modern": 2,
  "bike-lane:inbound|regular|sidewalk->sidewalk-lamp:both|traditional": 3,
  "bike-lane:inbound|regular|sidewalk->sidewalk-lamp:left|modern": 4,
  "bike-lane:inbound|regular|sidewalk->sidewalk-lamp:left|traditional": 2,
  "bike-lane:inbound|regular|sidewalk->sidewalk-lamp:right|modern": 1,
  "bike-lane:inbound|regular|sidewalk->sidewalk-lamp:right|traditional": 8,
  "bike-lane:inbound|regular|sidewalk->sidewalk-tree:big": 28,
  "bike-lane:inbound|regular|sidewalk->sidewalk:dense": 12,
  "bike-lane:inbound|regular|sidewalk->sidewalk:normal": 5,
  "bike-lane:inbound|regular|sidewalk->sidewalk:sparse": 3,
  "bike-lane:inbound|regular|sidewalk->streetcar:inbound|grass": 1,
  "bike-lane:inbound|regular|sidewalk->transit-shelter:left|light-rail": 3,
  "bike-lane:inbound|regular|sidewalk->transit-shelter:left|street-level": 3,
  "bike-lane:inbound|road|green->sidewalk-lamp:left|modern": 1,
  "bike-lane:outbound->sidewalk-tree:big": 2,
  "bike-lane:outbound->sidewalk:dense": 2,
  "bike-lane:outbound|colored->bike-lane:inbound|colored": 108,
  "bike-lane:outbound|colored->bike-lane:inbound|regular": 33,
  "bike-lane:outbound|colored->bike-lane:outbound|colored": 60,
  "bike-lane:outbound|colored->bike-lane:outbound|regular": 38,
  "bike-lane:outbound|colored->bus-lane:inbound|regular": 9,
  "bike-lane:outbound|colored->bus-lane:outbound|colored": 14,
  "bike-lane:outbound|colored->bus-lane:outbound|regular": 61,
  "bike-lane:outbound|colored->divider:big-tree": 45,
  "bike-lane:outbound|colored->divider:bollard": 45,
  "bike-lane:outbound|colored->divider:bush": 62,
  "bike-lane:outbound|colored->divider:dome": 28,
  "bike-lane:outbound|colored->divider:flowers": 99,
  "bike-lane:outbound|colored->divider:median": 79,
  "bike-lane:outbound|colored->divider:palm-tree": 18,
  "bike-lane:outbound|colored->divider:planting-strip": 11,
  "bike-lane:outbound|colored->divider:striped-buffer": 30,
  "bike-lane:outbound|colored->drive-lane:inbound|car": 6,
  "bike-lane:outbound|colored->drive-lane:inbound|truck": 1,
  "bike-lane:outbound|colored->drive-lane:outbound|car": 81,
  "bike-lane:outbound|colored->drive-lane:outbound|sharrow": 1,
  "bike-lane:outbound|colored->drive-lane:outbound|truck": 488,
  "bike-lane:outbound|colored->light-rail:inbound|colored": 3,
  "bike-lane:outbound|colored->light-rail:inbound|regular": 14,
  "bike-lane:outbound|colored->light-rail:outbound|colored": 10,
  "bike-lane:outbound|colored->light-rail:outbound|regular": 74,
  "bike-lane:outbound|colored->parking-lane:inbound|left": 6,
  "bike-lane:outbound|colored->parking-lane:inbound|right": 51,
  "bike-lane:outbound|colored->parking-lane:outbound|left": 49,
  "bike-lane:outbound|colored->parking-lane:outbound|right": 12727,
  "bike-lane:outbound|colored->parking-lane:sideways|right": 1,
  "bike-lane:outbound|colored->parklet:left": 4,
  "bike-lane:outbound|colored->parklet:right": 85,
  "bike-lane:outbound|colored->sidewalk-bench:center": 8,
  "bike-lane:outbound|colored->sidewalk-bench:left": 14,
  "bike-lane:outbound|colored->sidewalk-bench:right": 25,
  "bike-lane:outbound|colored->sidewalk-bike-rack:left|road": 7,
  "bike-lane:outbound|colored->sidewalk-bike-rack:left|sidewalk": 62,
  "bike-lane:outbound|colored->sidewalk-bike-rack:left|sidewalk-parallel": 86,
  "bike-lane:outbound|colored->sidewalk-bike-rack:right|road": 6,
  "bike-lane:outbound|colored->sidewalk-bike-rack:right|sidewalk": 19,
  "bike-lane:outbound|colored->sidewalk-bike-rack:right|sidewalk-parallel": 32,
  "bike-lane:outbound|colored->sidewalk-lamp:both|modern": 166,
  "bike-lane:outbound|colored->sidewalk-lamp:both|traditional": 258,
  "bike-lane:outbound|colored->sidewalk-lamp:left|modern": 5439,
  "bike-lane:outbound|colored->sidewalk-lamp:left|traditional": 398,
  "bike-lane:outbound|colored->sidewalk-lamp:right|modern": 21,
  "bike-lane:outbound|colored->sidewalk-lamp:right|traditional": 34,
  "bike-lane:outbound|colored->sidewalk-tree:big": 873,
  "bike-lane:outbound|colored->sidewalk-tree:palm-tree": 53,
  "bike-lane:outbound|colored->sidewalk-wayfinding:large": 13,
  "bike-lane:outbound|colored->sidewalk-wayfinding:medium": 136,
  "bike-lane:outbound|colored->sidewalk-wayfinding:small": 20,
  "bike-lane:outbound|colored->sidewalk:dense": 149,
  "bike-lane:outbound|colored->sidewalk:empty": 2,
  "bike-lane:outbound|colored->sidewalk:normal": 256,
  "bike-lane:outbound|colored->sidewalk:sparse": 8,
  "bike-lane:outbound|colored->streetcar:inbound|colored": 2,
  "bike-lane:outbound|colored->streetcar:inbound|regular": 2,
  "bike-lane:outbound|colored->streetcar:outbound|colored": 8,
  "bike-lane:outbound|colored->streetcar:outbound|regular": 45,
  "bike-lane:outbound|colored->transit-shelter:left|light-rail": 7,
  "bike-lane:outbound|colored->transit-shelter:left|street-level": 46,
  "bike-lane:outbound|colored->transit-shelter:right|street-level": 30,
  "bike-lane:outbound|colored->turn-lane:inbound|right-straight": 1,
  "bike-lane:outbound|colored->turn-lane:outbound|left": 50,
  "bike-lane:outbound|colored->turn-lane:outbound|left-straight": 2,
  "bike-lane:outbound|colored->turn-lane:outbound|right": 30,
  "bike-lane:outbound|colored->turn-lane:outbound|right-straight": 2,
  "bike-lane:outbound|colored->turn-lane:outbound|shared": 2,
  "bike-lane:outbound|colored->turn-lane:outbound|straight": 5,
  "bike-lane:outbound|green->bike-lane:inbound|green": 1,
  "bike-lane:outbound|green->drive-lane:inbound|car": 1,
  "bike-lane:outbound|green->parking-lane:outbound|right": 7,
  "bike-lane:outbound|green->sidewalk-lamp:left|modern": 3,
  "bike-lane:outbound|green|road->bike-lane:inbound|green|road": 25,
  "bike-lane:outbound|green|road->bike-lane:inbound|red|road": 4,
  "bike-lane:outbound|green|road->bike-lane:inbound|regular|road": 46,
  "bike-lane:outbound|green|road->bike-lane:outbound|green|road": 7,
  "bike-lane:outbound|green|road->bikeshare:left|road": 20,
  "bike-lane:outbound|green|road->bikeshare:left|sidewalk": 2,
  "bike-lane:outbound|green|road->bikeshare:right|road": 2,
  "bike-lane:outbound|green|road->bikeshare:right|sidewalk": 1,
  "bike-lane:outbound|green|road->bus-lane:inbound|regular|typical": 6,
  "bike-lane:outbound|green|road->bus-lane:inbound|shared|typical": 15,
  "bike-lane:outbound|green|road->bus-lane:outbound|colored|typical": 2,
  "bike-lane:outbound|green|road->bus-lane:outbound|regular|typical": 6,
  "bike-lane:outbound|green|road->bus-lane:outbound|shared|typical": 1,
  "bike-lane:outbound|green|road->divider:big-tree": 26,
  "bike-lane:outbound|green|road->divider:bollard": 28,
  "bike-lane:outbound|green|road->divider:bush": 43,
  "bike-lane:outbound|green|road->divider:dome": 7,
  "bike-lane:outbound|green|road->divider:flowers": 82,
  "bike-lane:outbound|green|road->divider:median": 25,
  "bike-lane:outbound|green|road->divider:palm-tree": 6,
  "bike-lane:outbound|green|road->divider:planter-box": 1007,
  "bike-lane:outbound|green|road->divider:planting-strip": 14,
  "bike-lane:outbound|green|road->divider:striped-buffer": 27,
  "bike-lane:outbound|green|road->drive-lane:inbound|car": 105,
  "bike-lane:outbound|green|road->drive-lane:inbound|truck": 6,
  "bike-lane:outbound|green|road->drive-lane:outbound|car": 29,
  "bike-lane:outbound|green|road->flex-zone-curb:empty|left": 1,
  "bike-lane:outbound|green|road->flex-zone-curb:sparse|left": 6,
  "bike-lane:outbound|green|road->flex-zone:taxi|inbound|left": 2,
  "bike-lane:outbound|green|road->flex-zone:taxi|outbound|left": 2,
  "bike-lane:outbound|green|road->food-truck:left": 2,
  "bike-lane:outbound|green|road->food-truck:right": 3,
  "bike-lane:outbound|green|road->light-rail:colored|inbound": 1,
  "bike-lane:outbound|green|road->light-rail:inbound|regular": 3,
  "bike-lane:outbound|green|road->light-rail:outbound|regular": 5,
  "bike-lane:outbound|green|road->parking-lane:angled-front-left|right": 1,
  "bike-lane:outbound|green|road->parking-lane:inbound|left": 8,
  "bike-lane:outbound|green|road->parking-lane:inbound|right": 10,
  "bike-lane:outbound|green|road->parking-lane:outbound|left": 45,
  "bike-lane:outbound|green|road->parking-lane:outbound|right": 35,
  "bike-lane:outbound|green|road->parklet:left": 2,
  "bike-lane:outbound|green|road->parklet:right": 12,
  "bike-lane:outbound|green|road->scooter-drop-zone:right|sidewalk|empty": 13,
  "bike-lane:outbound|green|road->scooter:inbound|green": 4,
  "bike-lane:outbound|green|road->scooter:inbound|red": 1,
  "bike-lane:outbound|green|road->scooter:inbound|regular": 33,
  "bike-lane:outbound|green|road->scooter:outbound|green": 4,
  "bike-lane:outbound|green|road->scooter:outbound|red": 3,
  "bike-lane:outbound|green|road->scooter:outbound|regular": 6,
  "bike-lane:outbound|green|road->sidewalk-bench:left": 4,
  "bike-lane:outbound|green|road->sidewalk-bench:right": 5,
  "bike-lane:outbound|green|road->sidewalk-bike-rack:left|road": 2,
  "bike-lane:outbound|green|road->sidewalk-bike-rack:left|sidewalk": 31,
  "bike-lane:outbound|green|road->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "bike-lane:outbound|green|road->sidewalk-bike-rack:right|road": 1,
  "bike-lane:outbound|green|road->sidewalk-bike-rack:right|sidewalk": 1,
  "bike-lane:outbound|green|road->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "bike-lane:outbound|green|road->sidewalk-lamp:both|modern": 43,
  "bike-lane:outbound|green|road->sidewalk-lamp:both|pride": 5,
  "bike-lane:outbound|green|road->sidewalk-lamp:both|traditional": 38,
  "bike-lane:outbound|green|road->sidewalk-lamp:left|modern": 14077,
  "bike-lane:outbound|green|road->sidewalk-lamp:left|pride": 50,
  "bike-lane:outbound|green|road->sidewalk-lamp:left|traditional": 105,
  "bike-lane:outbound|green|road->sidewalk-lamp:modern": 1,
  "bike-lane:outbound|green|road->sidewalk-lamp:modern|left": 1,
  "bike-lane:outbound|green|road->sidewalk-lamp:pride": 1,
  "bike-lane:outbound|green|road->sidewalk-lamp:right|modern": 14,
  "bike-lane:outbound|green|road->sidewalk-lamp:right|traditional": 4,
  "bike-lane:outbound|green|road->sidewalk-tree:big": 283,
  "bike-lane:outbound|green|road->sidewalk-tree:palm-tree": 10,
  "bike-lane:outbound|green|road->sidewalk-wayfinding:large": 1,
  "bike-lane:outbound|green|road->sidewalk:dense": 98,
  "bike-lane:outbound|green|road->sidewalk:empty": 2,
  "bike-lane:outbound|green|road->sidewalk:normal": 165,
  "bike-lane:outbound|green|road->sidewalk:sparse": 4,
  "bike-lane:outbound|green|road->streetcar:inbound|regular": 2,
  "bike-lane:outbound|green|road->streetcar:outbound|regular": 1,
  "bike-lane:outbound|green|road->transit-shelter:left|street-level": 12,
  "bike-lane:outbound|green|road->transit-shelter:right|light-rail": 1,
  "bike-lane:outbound|green|road->transit-shelter:right|street-level": 14,
  "bike-lane:outbound|green|road->turn-lane:inbound|left": 2,
  "bike-lane:outbound|green|road->turn-lane:inbound|right": 1,
  "bike-lane:outbound|green|road->turn-lane:inbound|straight": 3,
  "bike-lane:outbound|green|road->turn-lane:left": 1,
  "bike-lane:outbound|green|road->turn-lane:outbound|left": 1,
  "bike-lane:outbound|green|road->turn-lane:outbound|left-right-straight": 4,
  "bike-lane:outbound|green|road->turn-lane:outbound|left-straight": 24,
  "bike-lane:outbound|green|road->turn-lane:outbound|right": 3,
  "bike-lane:outbound|green|road->turn-lane:outbound|right-straight": 15,
  "bike-lane:outbound|green|road->turn-lane:outbound|straight": 8,
  "bike-lane:outbound|green|road|inbound->bike-lane:outbound|regular|road": 1,
  "bike-lane:outbound|green|road|inbound->bus-lane:inbound|regular|typical": 1,
  "bike-lane:outbound|green|road|inbound->divider:planter-box": 2,
  "bike-lane:outbound|green|road|inbound->sidewalk-bike-rack:left|sidewalk": 1,
  "bike-lane:outbound|green|road|inbound->sidewalk-lamp:left|modern": 18,
  "bike-lane:outbound|green|road|inbound->sidewalk-lamp:left|pride": 1,
  "bike-lane:outbound|green|road|inbound->sidewalk-tree:big": 1,
  "bike-lane:outbound|green|road|inbound->sidewalk:normal": 3,
  "bike-lane:outbound|green|sidewalk->bike-lane:inbound|green|sidewalk": 18,
  "bike-lane:outbound|green|sidewalk->bike-lane:inbound|regular|sidewalk": 2,
  "bike-lane:outbound|green|sidewalk->bike-lane:outbound|green|sidewalk": 1,
  "bike-lane:outbound|green|sidewalk->bus-lane:outbound|regular|typical": 2,
  "bike-lane:outbound|green|sidewalk->divider:big-tree": 21,
  "bike-lane:outbound|green|sidewalk->divider:bollard": 7,
  "bike-lane:outbound|green|sidewalk->divider:bush": 3,
  "bike-lane:outbound|green|sidewalk->divider:dome": 2,
  "bike-lane:outbound|green|sidewalk->divider:flowers": 14,
  "bike-lane:outbound|green|sidewalk->divider:median": 4,
  "bike-lane:outbound|green|sidewalk->divider:planter-box": 3,
  "bike-lane:outbound|green|sidewalk->divider:planting-strip": 4,
  "bike-lane:outbound|green|sidewalk->divider:striped-buffer": 2,
  "bike-lane:outbound|green|sidewalk->drive-lane:inbound|car": 3,
  "bike-lane:outbound|green|sidewalk->drive-lane:outbound|car": 6,
  "bike-lane:outbound|green|sidewalk->drive-lane:outbound|truck": 1,
  "bike-lane:outbound|green|sidewalk->food-truck:right": 1,
  "bike-lane:outbound|green|sidewalk->light-rail:outbound|regular": 3,
  "bike-lane:outbound|green|sidewalk->parking-lane:inbound|left": 3,
  "bike-lane:outbound|green|sidewalk->parking-lane:inbound|right": 1,
  "bike-lane:outbound|green|sidewalk->parking-lane:outbound|left": 1,
  "bike-lane:outbound|green|sidewalk->parking-lane:outbound|right": 2,
  "bike-lane:outbound|green|sidewalk->sidewalk-bench:center": 1,
  "bike-lane:outbound|green|sidewalk->sidewalk-bike-rack:left|sidewalk": 3,
  "bike-lane:outbound|green|sidewalk->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "bike-lane:outbound|green|sidewalk->sidewalk-bike-rack:right|sidewalk-parallel": 2,
  "bike-lane:outbound|green|sidewalk->sidewalk-lamp:both|modern": 5,
  "bike-lane:outbound|green|sidewalk->sidewalk-lamp:both|traditional": 13,
  "bike-lane:outbound|green|sidewalk->sidewalk-lamp:left|modern": 35,
  "bike-lane:outbound|green|sidewalk->sidewalk-lamp:left|pride": 2,
  "bike-lane:outbound|green|sidewalk->sidewalk-lamp:left|traditional": 3,
  "bike-lane:outbound|green|sidewalk->sidewalk-tree:big": 64,
  "bike-lane:outbound|green|sidewalk->sidewalk-wayfinding:large": 3,
  "bike-lane:outbound|green|sidewalk->sidewalk:dense": 31,
  "bike-lane:outbound|green|sidewalk->sidewalk:normal": 15,
  "bike-lane:outbound|green|sidewalk->sidewalk:sparse": 1,
  "bike-lane:outbound|green|sidewalk->transit-shelter:left|light-rail": 1,
  "bike-lane:outbound|green|sidewalk->transit-shelter:right|street-level": 2,
  "bike-lane:outbound|green|sidewalk->turn-lane:outbound|left-straight": 2,
  "bike-lane:outbound|green|sidewalk->turn-lane:outbound|straight": 2,
  "bike-lane:outbound|green|sidewalk|inbound->divider:planter-box": 1,
  "bike-lane:outbound|green|sidewalk|inbound->sidewalk-lamp:left|modern": 2,
  "bike-lane:outbound|red->sidewalk-lamp:left|modern": 1,
  "bike-lane:outbound|red|road->bike-lane:inbound|green|road": 2,
  "bike-lane:outbound|red|road->bike-lane:inbound|red|road": 9,
  "bike-lane:outbound|red|road->bike-lane:outbound|red|road": 3,
  "bike-lane:outbound|red|road->bikeshare:right|sidewalk": 1,
  "bike-lane:outbound|red|road->bus-lane:outbound|regular|typical": 1,
  "bike-lane:outbound|red|road->divider:big-tree": 5,
  "bike-lane:outbound|red|road->divider:bollard": 5,
  "bike-lane:outbound|red|road->divider:bush": 7,
  "bike-lane:outbound|red|road->divider:dome": 1,
  "bike-lane:outbound|red|road->divider:flowers": 14,
  "bike-lane:outbound|red|road->divider:median": 4,
  "bike-lane:outbound|red|road->divider:palm-tree": 3,
  "bike-lane:outbound|red|road->divider:planter-box": 3,
  "bike-lane:outbound|red|road->divider:planting-strip": 4,
  "bike-lane:outbound|red|road->divider:striped-buffer": 5,
  "bike-lane:outbound|red|road->drive-lane:outbound|car": 15,
  "bike-lane:outbound|red|road->flex-zone-curb:sparse|left": 2,
  "bike-lane:outbound|red|road->food-truck:right": 2,
  "bike-lane:outbound|red|road->light-rail:inbound|grass": 2,
  "bike-lane:outbound|red|road->parking-lane:inbound|left": 1,
  "bike-lane:outbound|red|road->parking-lane:inbound|right": 1,
  "bike-lane:outbound|red|road->parking-lane:outbound|left": 2,
  "bike-lane:outbound|red|road->parking-lane:outbound|right": 3,
  "bike-lane:outbound|red|road->parklet:right": 1,
  "bike-lane:outbound|red|road->scooter:inbound|green": 1,
  "bike-lane:outbound|red|road->scooter:inbound|red": 1,
  "bike-lane:outbound|red|road->scooter:outbound|green": 1,
  "bike-lane:outbound|red|road->scooter:outbound|regular": 1,
  "bike-lane:outbound|red|road->sidewalk-bench:center": 1,
  "bike-lane:outbound|red|road->sidewalk-bench:left": 2,
  "bike-lane:outbound|red|road->sidewalk-bike-rack:left|sidewalk": 4,
  "bike-lane:outbound|red|road->sidewalk-bike-rack:right|sidewalk": 1,
  "bike-lane:outbound|red|road->sidewalk-lamp:both|modern": 3,
  "bike-lane:outbound|red|road->sidewalk-lamp:both|traditional": 4,
  "bike-lane:outbound|red|road->sidewalk-lamp:left|modern": 41,
  "bike-lane:outbound|red|road->sidewalk-lamp:left|traditional": 5,
  "bike-lane:outbound|red|road->sidewalk-lamp:right": 1,
  "bike-lane:outbound|red|road->sidewalk-tree:big": 47,
  "bike-lane:outbound|red|road->sidewalk-tree:palm-tree": 2,
  "bike-lane:outbound|red|road->sidewalk-wayfinding:small": 1,
  "bike-lane:outbound|red|road->sidewalk:dense": 26,
  "bike-lane:outbound|red|road->sidewalk:empty": 3,
  "bike-lane:outbound|red|road->sidewalk:normal": 19,
  "bike-lane:outbound|red|road->sidewalk:sparse": 2,
  "bike-lane:outbound|red|road->streetcar:outbound|grass": 1,
  "bike-lane:outbound|red|road->transit-shelter:left|street-level": 3,
  "bike-lane:outbound|red|road->transit-shelter:right|street-level": 2,
  "bike-lane:outbound|red|road->turn-lane:outbound|right-straight": 1,
  "bike-lane:outbound|red|road|inbound->sidewalk-lamp:left|modern": 3,
  "bike-lane:outbound|red|road|inbound->sidewalk-lamp:left|traditional": 1,
  "bike-lane:outbound|red|road|inbound->sidewalk-tree:big": 1,
  "bike-lane:outbound|red|road|inbound->turn-lane:outbound|right": 1,
  "bike-lane:outbound|red|sidewalk->bike-lane:inbound|red|sidewalk": 6,
  "bike-lane:outbound|red|sidewalk->bike-lane:inbound|regular|sidewalk": 1,
  "bike-lane:outbound|red|sidewalk->bus-lane:inbound|regular|typical": 1,
  "bike-lane:outbound|red|sidewalk->divider:big-tree": 8,
  "bike-lane:outbound|red|sidewalk->divider:bush": 1,
  "bike-lane:outbound|red|sidewalk->divider:flowers": 6,
  "bike-lane:outbound|red|sidewalk->divider:median": 3,
  "bike-lane:outbound|red|sidewalk->divider:planting-strip": 3,
  "bike-lane:outbound|red|sidewalk->drive-lane:inbound|car": 2,
  "bike-lane:outbound|red|sidewalk->drive-lane:outbound|car": 3,
  "bike-lane:outbound|red|sidewalk->parklet:left": 1,
  "bike-lane:outbound|red|sidewalk->parklet:right": 1,
  "bike-lane:outbound|red|sidewalk->scooter:outbound|green": 1,
  "bike-lane:outbound|red|sidewalk->scooter:outbound|red": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk-bench:left": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk-bench:right": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk-lamp:both|modern": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk-lamp:both|traditional": 3,
  "bike-lane:outbound|red|sidewalk->sidewalk-lamp:left|modern": 13,
  "bike-lane:outbound|red|sidewalk->sidewalk-lamp:left|traditional": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk-tree:big": 14,
  "bike-lane:outbound|red|sidewalk->sidewalk-tree:palm-tree": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk:dense": 26,
  "bike-lane:outbound|red|sidewalk->sidewalk:empty": 1,
  "bike-lane:outbound|red|sidewalk->sidewalk:normal": 15,
  "bike-lane:outbound|red|sidewalk->sidewalk:sparse": 3,
  "bike-lane:outbound|red|sidewalk->transit-shelter:left|light-rail": 2,
  "bike-lane:outbound|red|sidewalk->turn-lane:outbound|straight": 1,
  "bike-lane:outbound|red|sidewalk|inbound->divider:planter-box": 2,
  "bike-lane:outbound|red|sidewalk|inbound->sidewalk-lamp:both|modern": 1,
  "bike-lane:outbound|red|sidewalk|inbound->sidewalk-lamp:both|traditional": 1,
  "bike-lane:outbound|red|sidewalk|inbound->sidewalk-lamp:left|modern": 4,
  "bike-lane:outbound|regular->bike-lane:inbound|colored": 10,
  "bike-lane:outbound|regular->bike-lane:inbound|regular": 65,
  "bike-lane:outbound|regular->bike-lane:outbound|colored": 60,
  "bike-lane:outbound|regular->bike-lane:outbound|regular": 76,
  "bike-lane:outbound|regular->bus-lane:inbound|regular": 1,
  "bike-lane:outbound|regular->bus-lane:outbound|colored": 3,
  "bike-lane:outbound|regular->bus-lane:outbound|regular": 15,
  "bike-lane:outbound|regular->divider:big-tree": 31,
  "bike-lane:outbound|regular->divider:bollard": 20,
  "bike-lane:outbound|regular->divider:bush": 16,
  "bike-lane:outbound|regular->divider:dome": 14,
  "bike-lane:outbound|regular->divider:flowers": 37,
  "bike-lane:outbound|regular->divider:median": 33,
  "bike-lane:outbound|regular->divider:palm-tree": 12,
  "bike-lane:outbound|regular->divider:planting-strip": 11,
  "bike-lane:outbound|regular->divider:striped-buffer": 12,
  "bike-lane:outbound|regular->drive-lane:inbound|car": 7,
  "bike-lane:outbound|regular->drive-lane:outbound|car": 46,
  "bike-lane:outbound|regular->drive-lane:outbound|sharrow": 4,
  "bike-lane:outbound|regular->drive-lane:outbound|truck": 14,
  "bike-lane:outbound|regular->light-rail:inbound|regular": 6,
  "bike-lane:outbound|regular->light-rail:outbound|colored": 1,
  "bike-lane:outbound|regular->light-rail:outbound|regular": 19,
  "bike-lane:outbound|regular->parking-lane:inbound|left": 5,
  "bike-lane:outbound|regular->parking-lane:inbound|right": 22,
  "bike-lane:outbound|regular->parking-lane:outbound|left": 5,
  "bike-lane:outbound|regular->parking-lane:outbound|right": 80,
  "bike-lane:outbound|regular->parking-lane:sideways|left": 1,
  "bike-lane:outbound|regular->parking-lane:sideways|right": 4,
  "bike-lane:outbound|regular->parklet:left": 8,
  "bike-lane:outbound|regular->parklet:right": 21,
  "bike-lane:outbound|regular->sidewalk-bench:left": 6,
  "bike-lane:outbound|regular->sidewalk-bench:right": 4,
  "bike-lane:outbound|regular->sidewalk-bike-rack:left|road": 2,
  "bike-lane:outbound|regular->sidewalk-bike-rack:left|sidewalk": 12,
  "bike-lane:outbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 31,
  "bike-lane:outbound|regular->sidewalk-bike-rack:right|road": 8,
  "bike-lane:outbound|regular->sidewalk-bike-rack:right|sidewalk": 4,
  "bike-lane:outbound|regular->sidewalk-bike-rack:right|sidewalk-parallel": 6,
  "bike-lane:outbound|regular->sidewalk-lamp:both|modern": 28,
  "bike-lane:outbound|regular->sidewalk-lamp:both|traditional": 43,
  "bike-lane:outbound|regular->sidewalk-lamp:left|modern": 198,
  "bike-lane:outbound|regular->sidewalk-lamp:left|traditional": 70,
  "bike-lane:outbound|regular->sidewalk-lamp:right|modern": 7,
  "bike-lane:outbound|regular->sidewalk-lamp:right|traditional": 7,
  "bike-lane:outbound|regular->sidewalk-tree:big": 312,
  "bike-lane:outbound|regular->sidewalk-tree:palm-tree": 10,
  "bike-lane:outbound|regular->sidewalk-wayfinding:large": 4,
  "bike-lane:outbound|regular->sidewalk-wayfinding:medium": 13,
  "bike-lane:outbound|regular->sidewalk-wayfinding:small": 2,
  "bike-lane:outbound|regular->sidewalk:dense": 106,
  "bike-lane:outbound|regular->sidewalk:normal": 49,
  "bike-lane:outbound|regular->sidewalk:sparse": 8,
  "bike-lane:outbound|regular->streetcar:outbound|colored": 1,
  "bike-lane:outbound|regular->streetcar:outbound|regular": 10,
  "bike-lane:outbound|regular->transit-shelter:left|light-rail": 6,
  "bike-lane:outbound|regular->transit-shelter:left|street-level": 16,
  "bike-lane:outbound|regular->transit-shelter:right|street-level": 4,
  "bike-lane:outbound|regular->turn-lane:inbound|both": 1,
  "bike-lane:outbound|regular->turn-lane:inbound|right-straight": 1,
  "bike-lane:outbound|regular->turn-lane:outbound|left": 1,
  "bike-lane:outbound|regular->turn-lane:outbound|left-straight": 3,
  "bike-lane:outbound|regular->turn-lane:outbound|right": 5,
  "bike-lane:outbound|regular->turn-lane:outbound|right-straight": 3,
  "bike-lane:outbound|regular->turn-lane:outbound|straight": 4,
  "bike-lane:outbound|regular|road->bike-lane:inbound|green|road": 4,
  "bike-lane:outbound|regular|road->bike-lane:inbound|red|road": 1,
  "bike-lane:outbound|regular|road->bike-lane:inbound|regular|road": 61,
  "bike-lane:outbound|regular|road->bike-lane:outbound|green|road": 4,
  "bike-lane:outbound|regular|road->bike-lane:outbound|regular|road": 23,
  "bike-lane:outbound|regular|road->bikeshare:left|road": 10,
  "bike-lane:outbound|regular|road->bikeshare:right|road": 1,
  "bike-lane:outbound|regular|road->bus-lane:inbound|regular|typical": 4,
  "bike-lane:outbound|regular|road->bus-lane:outbound|colored|typical": 1,
  "bike-lane:outbound|regular|road->bus-lane:outbound|regular|typical": 5,
  "bike-lane:outbound|regular|road->divider:big-tree": 24,
  "bike-lane:outbound|regular|road->divider:bollard": 9,
  "bike-lane:outbound|regular|road->divider:bush": 14,
  "bike-lane:outbound|regular|road->divider:dome": 7,
  "bike-lane:outbound|regular|road->divider:flowers": 62,
  "bike-lane:outbound|regular|road->divider:median": 21,
  "bike-lane:outbound|regular|road->divider:planter-box": 6,
  "bike-lane:outbound|regular|road->divider:planting-strip": 16,
  "bike-lane:outbound|regular|road->divider:striped-buffer": 21,
  "bike-lane:outbound|regular|road->drive-lane:inbound|car": 12,
  "bike-lane:outbound|regular|road->drive-lane:inbound|sharrow": 1,
  "bike-lane:outbound|regular|road->drive-lane:outbound|car": 19,
  "bike-lane:outbound|regular|road->drive-lane:outbound|truck": 3,
  "bike-lane:outbound|regular|road->flex-zone-curb:empty|left": 1,
  "bike-lane:outbound|regular|road->flex-zone-curb:sparse|left": 10,
  "bike-lane:outbound|regular|road->flex-zone:taxi|inbound|left": 1,
  "bike-lane:outbound|regular|road->food-truck:right": 1,
  "bike-lane:outbound|regular|road->light-rail:outbound|regular": 2,
  "bike-lane:outbound|regular|road->parking-lane:inbound|left": 1,
  "bike-lane:outbound|regular|road->parking-lane:inbound|right": 15,
  "bike-lane:outbound|regular|road->parking-lane:outbound|left": 5,
  "bike-lane:outbound|regular|road->parking-lane:outbound|right": 26,
  "bike-lane:outbound|regular|road->parking-lane:sideways|right": 1,
  "bike-lane:outbound|regular|road->parklet:left": 2,
  "bike-lane:outbound|regular|road->parklet:right": 3,
  "bike-lane:outbound|regular|road->scooter:inbound|regular": 5,
  "bike-lane:outbound|regular|road->scooter:outbound|regular": 3,
  "bike-lane:outbound|regular|road->sidewalk-bench:left": 2,
  "bike-lane:outbound|regular|road->sidewalk-bench:right": 4,
  "bike-lane:outbound|regular|road->sidewalk-bike-rack:left|sidewalk": 19,
  "bike-lane:outbound|regular|road->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "bike-lane:outbound|regular|road->sidewalk-bike-rack:right|sidewalk": 2,
  "bike-lane:outbound|regular|road->sidewalk-lamp:both|modern": 12,
  "bike-lane:outbound|regular|road->sidewalk-lamp:both|pride": 3,
  "bike-lane:outbound|regular|road->sidewalk-lamp:both|traditional": 17,
  "bike-lane:outbound|regular|road->sidewalk-lamp:left|modern": 104,
  "bike-lane:outbound|regular|road->sidewalk-lamp:left|pride": 6,
  "bike-lane:outbound|regular|road->sidewalk-lamp:left|traditional": 24,
  "bike-lane:outbound|regular|road->sidewalk-lamp:right|modern": 3,
  "bike-lane:outbound|regular|road->sidewalk-lamp:right|traditional": 6,
  "bike-lane:outbound|regular|road->sidewalk-tree:big": 150,
  "bike-lane:outbound|regular|road->sidewalk-tree:palm-tree": 4,
  "bike-lane:outbound|regular|road->sidewalk:dense": 153,
  "bike-lane:outbound|regular|road->sidewalk:empty": 6,
  "bike-lane:outbound|regular|road->sidewalk:normal": 81,
  "bike-lane:outbound|regular|road->sidewalk:sparse": 16,
  "bike-lane:outbound|regular|road->streetcar:outbound|grass": 1,
  "bike-lane:outbound|regular|road->streetcar:outbound|regular": 1,
  "bike-lane:outbound|regular|road->transit-shelter:left|street-level": 3,
  "bike-lane:outbound|regular|road->transit-shelter:right|light-rail": 1,
  "bike-lane:outbound|regular|road->transit-shelter:right|street-level": 8,
  "bike-lane:outbound|regular|road->turn-lane:inbound|left": 1,
  "bike-lane:outbound|regular|road->turn-lane:inbound|right": 1,
  "bike-lane:outbound|regular|road->turn-lane:left-straight": 1,
  "bike-lane:outbound|regular|road->turn-lane:outbound|left": 1,
  "bike-lane:outbound|regular|road->turn-lane:outbound|left-straight": 4,
  "bike-lane:outbound|regular|road->turn-lane:outbound|right": 2,
  "bike-lane:outbound|regular|road->turn-lane:outbound|right-straight": 1,
  "bike-lane:outbound|regular|road->turn-lane:outbound|straight": 3,
  "bike-lane:outbound|regular|road|inbound->sidewalk-lamp:left|modern": 9,
  "bike-lane:outbound|regular|road|inbound->sidewalk-lamp:left|traditional": 1,
  "bike-lane:outbound|regular|sidewalk->bike-lane:inbound|regular|sidewalk": 8,
  "bike-lane:outbound|regular|sidewalk->bikeshare:left|sidewalk": 1,
  "bike-lane:outbound|regular|sidewalk->divider:big-tree": 8,
  "bike-lane:outbound|regular|sidewalk->divider:bollard": 2,
  "bike-lane:outbound|regular|sidewalk->divider:bush": 3,
  "bike-lane:outbound|regular|sidewalk->divider:flowers": 15,
  "bike-lane:outbound|regular|sidewalk->divider:median": 34,
  "bike-lane:outbound|regular|sidewalk->divider:planter-box": 2,
  "bike-lane:outbound|regular|sidewalk->divider:planting-strip": 3,
  "bike-lane:outbound|regular|sidewalk->drive-lane:inbound|car": 2,
  "bike-lane:outbound|regular|sidewalk->drive-lane:outbound|car": 3,
  "bike-lane:outbound|regular|sidewalk->drive-lane:outbound|truck": 1,
  "bike-lane:outbound|regular|sidewalk->parking-lane:outbound|left": 1,
  "bike-lane:outbound|regular|sidewalk->parklet:right": 1,
  "bike-lane:outbound|regular|sidewalk->scooter:inbound|regular": 1,
  "bike-lane:outbound|regular|sidewalk->sidewalk-bench:left": 1,
  "bike-lane:outbound|regular|sidewalk->sidewalk-bike-rack:left|sidewalk": 2,
  "bike-lane:outbound|regular|sidewalk->sidewalk-lamp:both|modern": 1,
  "bike-lane:outbound|regular|sidewalk->sidewalk-lamp:both|traditional": 3,
  "bike-lane:outbound|regular|sidewalk->sidewalk-lamp:left|modern": 9,
  "bike-lane:outbound|regular|sidewalk->sidewalk-lamp:left|traditional": 4,
  "bike-lane:outbound|regular|sidewalk->sidewalk-tree:big": 22,
  "bike-lane:outbound|regular|sidewalk->sidewalk:dense": 41,
  "bike-lane:outbound|regular|sidewalk->sidewalk:normal": 22,
  "bike-lane:outbound|regular|sidewalk->sidewalk:sparse": 1,
  "bike-lane:outbound|regular|sidewalk->transit-shelter:left|street-level": 2,
  "bike-lane:outbound|regular|sidewalk->turn-lane:inbound|left": 1,
  "bike-lane:outbound|regular|sidewalk|inbound->divider:bush": 1,
  "bike-lane:outbound|regular|sidewalk|inbound->sidewalk-lamp:left|modern": 7,
  "bike-lane:outbound||->sidewalk-tree:big": 1,
  "bike-lane:red->sidewalk-tree:big": 1,
  "bike-lane:red|inbound|sidewalk->sidewalk-lamp:left|modern": 1,
  "bike-lane:red|road|outbound->parking-lane:outbound|left": 1,
  "bike-lane:red||sidewalk->sidewalk-tree:big": 1,
  "bike-lane:regular|inbound|sidewalk->divider:flowers": 1,
  "bike-lane:regular|road->drive-lane:inbound|car": 1,
  "bike-lane:road|inbound->divider:dome": 2,
  "bike-lane:road|outbound|regular->sidewalk:dense": 1,
  "bike-lane:road|red|outbound->sidewalk-lamp:left|modern": 1,
  "bike-lane:sidewalk->drive-lane:inbound|car": 1,
  "bike-lane:sidewalk|green|inbound->sidewalk-lamp:left|modern": 1,
  "bikeshare:left|road->bike-lane:inbound|green|road": 8,
  "bikeshare:left|road->bike-lane:inbound|green|sidewalk": 2,
  "bikeshare:left|road->bike-lane:inbound|red|road": 1,
  "bikeshare:left|road->bike-lane:inbound|regular|road": 18,
  "bikeshare:left|road->bike-lane:inbound|regular|sidewalk": 1,
  "bikeshare:left|road->bike-lane:outbound|green|road": 7,
  "bikeshare:left|road->bike-lane:outbound|red|road": 1,
  "bikeshare:left|road->bike-lane:outbound|regular|road": 1,
  "bikeshare:left|road->bikeshare:left|road": 2,
  "bikeshare:left|road->bus-lane:inbound|colored|typical": 1,
  "bikeshare:left|road->bus-lane:inbound|regular|typical": 2,
  "bikeshare:left|road->bus-lane:inbound|shared|typical": 2,
  "bikeshare:left|road->bus-lane:outbound|shared|typical": 1,
  "bikeshare:left|road->divider:bollard": 2,
  "bikeshare:left|road->divider:bush": 4,
  "bikeshare:left|road->divider:flowers": 2,
  "bikeshare:left|road->divider:median": 1,
  "bikeshare:left|road->divider:palm-tree": 1,
  "bikeshare:left|road->divider:planter-box": 3,
  "bikeshare:left|road->divider:planting-strip": 1,
  "bikeshare:left|road->drive-lane:inbound|car": 4,
  "bikeshare:left|road->drive-lane:inbound|sharrow": 1,
  "bikeshare:left|road->flex-zone-curb:sparse|left": 4,
  "bikeshare:left|road->flex-zone:taxi|inbound|left": 3,
  "bikeshare:left|road->flex-zone:taxi|inbound|right": 1,
  "bikeshare:left|road->food-truck:left": 5,
  "bikeshare:left|road->light-rail:outbound|regular": 1,
  "bikeshare:left|road->parking-lane:inbound|left": 2,
  "bikeshare:left|road->parking-lane:outbound|right": 1,
  "bikeshare:left|road->parklet:left": 3,
  "bikeshare:left|road->scooter-drop-zone:left|road|empty": 2,
  "bikeshare:left|road->scooter-drop-zone:left|sidewalk|empty": 6,
  "bikeshare:left|road->scooter-drop-zone:right|sidewalk|empty": 6,
  "bikeshare:left|road->scooter:inbound|green": 1,
  "bikeshare:left|road->scooter:inbound|regular": 4,
  "bikeshare:left|road->sidewalk-bench:left": 5,
  "bikeshare:left|road->sidewalk-bike-rack:left|road": 2,
  "bikeshare:left|road->sidewalk-bike-rack:left|sidewalk": 2,
  "bikeshare:left|road->sidewalk-bike-rack:right|sidewalk": 4,
  "bikeshare:left|road->sidewalk-lamp:both|pride": 1,
  "bikeshare:left|road->sidewalk-lamp:both|traditional": 6,
  "bikeshare:left|road->sidewalk-lamp:left|modern": 6,
  "bikeshare:left|road->sidewalk-lamp:left|pride": 1,
  "bikeshare:left|road->sidewalk-lamp:left|traditional": 7,
  "bikeshare:left|road->sidewalk-lamp:right|modern": 5,
  "bikeshare:left|road->sidewalk-lamp:right|traditional": 3,
  "bikeshare:left|road->sidewalk-tree:big": 28,
  "bikeshare:left|road->sidewalk-tree:palm-tree": 5,
  "bikeshare:left|road->sidewalk-wayfinding:large": 4,
  "bikeshare:left|road->sidewalk-wayfinding:small": 2,
  "bikeshare:left|road->sidewalk:dense": 13,
  "bikeshare:left|road->sidewalk:normal": 19,
  "bikeshare:left|road->sidewalk:sparse": 1,
  "bikeshare:left|road->streetcar:inbound|regular": 2,
  "bikeshare:left|road->transit-shelter:left|street-level": 1,
  "bikeshare:left|road->transit-shelter:right|street-level": 2,
  "bikeshare:left|road->turn-lane:inbound|left": 2,
  "bikeshare:left|road->turn-lane:inbound|left-straight": 2,
  "bikeshare:left|road->turn-lane:outbound|left-straight": 1,
  "bikeshare:left|road->turn-lane:shared": 1,
  "bikeshare:left|sidewalk->bike-lane:inbound|green|road": 1,
  "bikeshare:left|sidewalk->bike-lane:inbound|green|sidewalk": 2,
  "bikeshare:left|sidewalk->bike-lane:inbound|regular|road": 1,
  "bikeshare:left|sidewalk->bike-lane:outbound|regular|road": 3,
  "bikeshare:left|sidewalk->divider:big-tree": 2,
  "bikeshare:left|sidewalk->flex-zone-curb:sparse|left": 2,
  "bikeshare:left|sidewalk->flex-zone:rideshare|inbound|left": 1,
  "bikeshare:left|sidewalk->scooter-drop-zone:right|sidewalk|empty": 1,
  "bikeshare:left|sidewalk->scooter:inbound|regular": 1,
  "bikeshare:left|sidewalk->sidewalk-bike-rack:left|sidewalk": 1,
  "bikeshare:left|sidewalk->sidewalk-lamp:both|modern": 1,
  "bikeshare:left|sidewalk->sidewalk-lamp:both|traditional": 1,
  "bikeshare:left|sidewalk->sidewalk-lamp:right|traditional": 2,
  "bikeshare:left|sidewalk->sidewalk-tree:big": 4,
  "bikeshare:left|sidewalk->sidewalk:dense": 5,
  "bikeshare:left|sidewalk->sidewalk:normal": 3,
  "bikeshare:right|road->divider:flowers": 1,
  "bikeshare:right|road->divider:median": 1,
  "bikeshare:right|road->divider:planting-strip": 1,
  "bikeshare:right|road->sidewalk-bench:left": 1,
  "bikeshare:right|road->sidewalk-bench:right": 1,
  "bikeshare:right|road->sidewalk-lamp:both|modern": 1,
  "bikeshare:right|road->sidewalk-lamp:left|modern": 1,
  "bikeshare:right|road->sidewalk-tree:big": 4,
  "bikeshare:right|road->sidewalk:dense": 1,
  "bikeshare:right|road->sidewalk:normal": 2,
  "bikeshare:right|road->turn-lane:inbound|left-right-straight": 1,
  "bikeshare:right|sidewalk->divider:big-tree": 1,
  "bikeshare:right|sidewalk->drive-lane:inbound|car": 2,
  "bikeshare:right|sidewalk->parklet:left": 1,
  "bikeshare:right|sidewalk->sidewalk-bench:center": 2,
  "bikeshare:right|sidewalk->sidewalk-tree:big": 2,
  "bikeshare:right|sidewalk->sidewalk-tree:palm-tree": 1,
  "bikeshare:right|sidewalk->sidewalk:dense": 2,
  "bikeshare:sidewalk->sidewalk-bike-rack:right|sidewalk": 1,
  "brt-lane:inbound|red->brt-station:center": 1,
  "brt-lane:inbound|red->streetcar:inbound|grass": 1,
  "brt-lane:inbound|regular->divider:bush": 1,
  "brt-lane:outbound|regular->transit-shelter:right|street-level": 1,
  "brt-station:center->brt-lane:inbound|red": 1,
  "brt-station:center->light-rail:inbound|regular": 1,
  "brt-station:center->sidewalk-lamp:right|modern": 1,
  "brt-station:left->divider:planting-strip": 1,
  "brt-station:left->sidewalk-bench:left": 1,
  "brt-station:right->sidewalk-lamp:left|modern": 1,
  "bus-lane:colored->bus-lane:inbound|regular|typical": 1,
  "bus-lane:colored->drive-lane:car|outbound": 1,
  "bus-lane:colored->drive-lane:inbound|car": 1,
  "bus-lane:colored|inbound->drive-lane:inbound|car": 1,
  "bus-lane:colored|inbound|typical->drive-lane:inbound|car": 1,
  "bus-lane:colored||typical->bus-lane:inbound|regular|typical": 1,
  "bus-lane:inbound->divider:big-tree": 2,
  "bus-lane:inbound->turn-lane:outbound": 1,
  "bus-lane:inbound|colored->bike-lane:inbound|colored": 31,
  "bus-lane:inbound|colored->bike-lane:inbound|regular": 6,
  "bus-lane:inbound|colored->bike-lane:outbound|colored": 2,
  "bus-lane:inbound|colored->bus-lane:inbound|colored": 15,
  "bus-lane:inbound|colored->bus-lane:inbound|regular": 1,
  "bus-lane:inbound|colored->bus-lane:outbound|colored": 29,
  "bus-lane:inbound|colored->bus-lane:outbound|regular": 3,
  "bus-lane:inbound|colored->divider:big-tree": 14,
  "bus-lane:inbound|colored->divider:bollard": 16,
  "bus-lane:inbound|colored->divider:bush": 1,
  "bus-lane:inbound|colored->divider:dome": 4,
  "bus-lane:inbound|colored->divider:flowers": 46,
  "bus-lane:inbound|colored->divider:median": 17,
  "bus-lane:inbound|colored->divider:palm-tree": 3,
  "bus-lane:inbound|colored->divider:planting-strip": 1,
  "bus-lane:inbound|colored->divider:striped-buffer": 6,
  "bus-lane:inbound|colored->drive-lane:inbound|car": 206,
  "bus-lane:inbound|colored->drive-lane:inbound|sharrow": 13,
  "bus-lane:inbound|colored->drive-lane:inbound|truck": 15,
  "bus-lane:inbound|colored->drive-lane:outbound|car": 7,
  "bus-lane:inbound|colored->drive-lane:outbound|truck": 1,
  "bus-lane:inbound|colored->light-rail:inbound|colored": 1,
  "bus-lane:inbound|colored->light-rail:inbound|regular": 6,
  "bus-lane:inbound|colored->parking-lane:inbound|left": 1,
  "bus-lane:inbound|colored->parking-lane:inbound|right": 2,
  "bus-lane:inbound|colored->parking-lane:outbound|right": 2,
  "bus-lane:inbound|colored->parklet:left": 3,
  "bus-lane:inbound|colored->parklet:right": 2,
  "bus-lane:inbound|colored->sidewalk-bench:right": 1,
  "bus-lane:inbound|colored->sidewalk-lamp:both|modern": 9,
  "bus-lane:inbound|colored->sidewalk-lamp:both|traditional": 2,
  "bus-lane:inbound|colored->sidewalk-lamp:left|modern": 8,
  "bus-lane:inbound|colored->sidewalk-lamp:left|traditional": 1,
  "bus-lane:inbound|colored->sidewalk-tree:big": 9,
  "bus-lane:inbound|colored->sidewalk-tree:palm-tree": 1,
  "bus-lane:inbound|colored->sidewalk-wayfinding:large": 1,
  "bus-lane:inbound|colored->sidewalk-wayfinding:small": 1,
  "bus-lane:inbound|colored->sidewalk:dense": 6,
  "bus-lane:inbound|colored->sidewalk:normal": 3,
  "bus-lane:inbound|colored->sidewalk:sparse": 3,
  "bus-lane:inbound|colored->streetcar:inbound|colored": 2,
  "bus-lane:inbound|colored->streetcar:inbound|regular": 5,
  "bus-lane:inbound|colored->streetcar:outbound|colored": 4,
  "bus-lane:inbound|colored->transit-shelter:left|light-rail": 11,
  "bus-lane:inbound|colored->transit-shelter:left|street-level": 5,
  "bus-lane:inbound|colored->transit-shelter:right|light-rail": 6,
  "bus-lane:inbound|colored->transit-shelter:right|street-level": 35,
  "bus-lane:inbound|colored->turn-lane:inbound|both": 7,
  "bus-lane:inbound|colored->turn-lane:inbound|left": 3,
  "bus-lane:inbound|colored->turn-lane:inbound|left-straight": 8,
  "bus-lane:inbound|colored->turn-lane:inbound|right": 10,
  "bus-lane:inbound|colored->turn-lane:inbound|right-straight": 118,
  "bus-lane:inbound|colored->turn-lane:inbound|shared": 2,
  "bus-lane:inbound|colored->turn-lane:inbound|straight": 18,
  "bus-lane:inbound|colored->turn-lane:outbound|right": 2,
  "bus-lane:inbound|colored->turn-lane:outbound|right-straight": 3,
  "bus-lane:inbound|colored->turn-lane:outbound|straight": 3,
  "bus-lane:inbound|colored|typical->bike-lane:inbound|green|road": 3,
  "bus-lane:inbound|colored|typical->bike-lane:inbound|regular|sidewalk": 1,
  "bus-lane:inbound|colored|typical->bus-lane:inbound|colored|typical": 3,
  "bus-lane:inbound|colored|typical->bus-lane:inbound|regular|typical": 1,
  "bus-lane:inbound|colored|typical->bus-lane:outbound|colored|typical": 22,
  "bus-lane:inbound|colored|typical->bus-lane:outbound|regular|typical": 1,
  "bus-lane:inbound|colored|typical->divider:big-tree": 6,
  "bus-lane:inbound|colored|typical->divider:bollard": 1,
  "bus-lane:inbound|colored|typical->divider:bush": 14,
  "bus-lane:inbound|colored|typical->divider:dome": 6,
  "bus-lane:inbound|colored|typical->divider:flowers": 7,
  "bus-lane:inbound|colored|typical->divider:median": 5,
  "bus-lane:inbound|colored|typical->divider:palm-tree": 1,
  "bus-lane:inbound|colored|typical->divider:planter-box": 1,
  "bus-lane:inbound|colored|typical->divider:planting-strip": 2,
  "bus-lane:inbound|colored|typical->divider:striped-buffer": 1,
  "bus-lane:inbound|colored|typical->drive-lane:inbound|car": 163,
  "bus-lane:inbound|colored|typical->drive-lane:inbound|sharrow": 2,
  "bus-lane:inbound|colored|typical->drive-lane:inbound|truck": 1,
  "bus-lane:inbound|colored|typical->drive-lane:outbound|car": 6,
  "bus-lane:inbound|colored|typical->drive-lane:outbound|truck": 1,
  "bus-lane:inbound|colored|typical->flex-zone:rideshare|outbound|right": 1,
  "bus-lane:inbound|colored|typical->light-rail:inbound|colored": 7,
  "bus-lane:inbound|colored|typical->parking-lane:inbound|right": 1,
  "bus-lane:inbound|colored|typical->scooter:outbound|green": 1,
  "bus-lane:inbound|colored|typical->sidewalk-lamp:both|modern": 3,
  "bus-lane:inbound|colored|typical->sidewalk-lamp:left|modern": 5,
  "bus-lane:inbound|colored|typical->sidewalk-lamp:right|modern": 1,
  "bus-lane:inbound|colored|typical->sidewalk-lamp:right|traditional": 1,
  "bus-lane:inbound|colored|typical->sidewalk-tree:big": 1,
  "bus-lane:inbound|colored|typical->sidewalk-tree:palm-tree": 1,
  "bus-lane:inbound|colored|typical->sidewalk:dense": 4,
  "bus-lane:inbound|colored|typical->sidewalk:sparse": 1,
  "bus-lane:inbound|colored|typical->streetcar:inbound|regular": 1,
  "bus-lane:inbound|colored|typical->transit-shelter:left|light-rail": 1,
  "bus-lane:inbound|colored|typical->transit-shelter:left|street-level": 3,
  "bus-lane:inbound|colored|typical->transit-shelter:right|light-rail": 2,
  "bus-lane:inbound|colored|typical->transit-shelter:right|street-level": 7,
  "bus-lane:inbound|colored|typical->turn-lane:inbound|left-straight": 2,
  "bus-lane:inbound|colored|typical->turn-lane:inbound|right-straight": 1,
  "bus-lane:inbound|colored|typical->turn-lane:inbound|straight": 1,
  "bus-lane:inbound|colored|typical->turn-lane:outbound|left": 1,
  "bus-lane:inbound|colored|typical->turn-lane:outbound|left-straight": 4,
  "bus-lane:inbound|colored|typical->turn-lane:outbound|straight": 1,
  "bus-lane:inbound|colored|typical->turn-lane:straight|outbound": 1,
  "bus-lane:inbound|regular->bike-lane:inbound|colored": 75,
  "bus-lane:inbound|regular->bike-lane:inbound|regular": 64,
  "bus-lane:inbound|regular->bike-lane:outbound|colored": 42,
  "bus-lane:inbound|regular->bus-lane:inbound|colored": 1,
  "bus-lane:inbound|regular->bus-lane:inbound|regular": 49,
  "bus-lane:inbound|regular->bus-lane:outbound|colored": 3,
  "bus-lane:inbound|regular->bus-lane:outbound|regular": 55,
  "bus-lane:inbound|regular->divider:big-tree": 54,
  "bus-lane:inbound|regular->divider:bollard": 18,
  "bus-lane:inbound|regular->divider:bush": 30,
  "bus-lane:inbound|regular->divider:dome": 18,
  "bus-lane:inbound|regular->divider:flowers": 922,
  "bus-lane:inbound|regular->divider:median": 65,
  "bus-lane:inbound|regular->divider:palm-tree": 30,
  "bus-lane:inbound|regular->divider:planting-strip": 9,
  "bus-lane:inbound|regular->divider:striped-buffer": 18,
  "bus-lane:inbound|regular->drive-lane:inbound|car": 2930,
  "bus-lane:inbound|regular->drive-lane:inbound|sharrow": 12,
  "bus-lane:inbound|regular->drive-lane:inbound|truck": 116,
  "bus-lane:inbound|regular->drive-lane:outbound|car": 82,
  "bus-lane:inbound|regular->drive-lane:outbound|truck": 101,
  "bus-lane:inbound|regular->light-rail:inbound|colored": 5,
  "bus-lane:inbound|regular->light-rail:inbound|regular": 59,
  "bus-lane:inbound|regular->light-rail:outbound|colored": 2,
  "bus-lane:inbound|regular->light-rail:outbound|regular": 7,
  "bus-lane:inbound|regular->parking-lane:inbound|left": 8,
  "bus-lane:inbound|regular->parking-lane:inbound|right": 15,
  "bus-lane:inbound|regular->parking-lane:outbound|left": 1,
  "bus-lane:inbound|regular->parking-lane:outbound|right": 26,
  "bus-lane:inbound|regular->parklet:left": 5,
  "bus-lane:inbound|regular->parklet:right": 36,
  "bus-lane:inbound|regular->sidewalk-bench:center": 1,
  "bus-lane:inbound|regular->sidewalk-bench:left": 5,
  "bus-lane:inbound|regular->sidewalk-bench:right": 6,
  "bus-lane:inbound|regular->sidewalk-bike-rack:left|sidewalk": 3,
  "bus-lane:inbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 13,
  "bus-lane:inbound|regular->sidewalk-lamp:both|modern": 44,
  "bus-lane:inbound|regular->sidewalk-lamp:both|traditional": 7,
  "bus-lane:inbound|regular->sidewalk-lamp:left|modern": 416,
  "bus-lane:inbound|regular->sidewalk-lamp:left|traditional": 8,
  "bus-lane:inbound|regular->sidewalk-lamp:right|modern": 46,
  "bus-lane:inbound|regular->sidewalk-lamp:right|traditional": 1,
  "bus-lane:inbound|regular->sidewalk-tree:big": 113,
  "bus-lane:inbound|regular->sidewalk-tree:palm-tree": 8,
  "bus-lane:inbound|regular->sidewalk-wayfinding:large": 15,
  "bus-lane:inbound|regular->sidewalk-wayfinding:medium": 8,
  "bus-lane:inbound|regular->sidewalk:dense": 66,
  "bus-lane:inbound|regular->sidewalk:empty": 1,
  "bus-lane:inbound|regular->sidewalk:normal": 13,
  "bus-lane:inbound|regular->sidewalk:sparse": 2,
  "bus-lane:inbound|regular->streetcar:inbound|colored": 5,
  "bus-lane:inbound|regular->streetcar:inbound|regular": 63,
  "bus-lane:inbound|regular->streetcar:outbound|colored": 3,
  "bus-lane:inbound|regular->streetcar:outbound|regular": 8,
  "bus-lane:inbound|regular->transit-shelter:left|light-rail": 6,
  "bus-lane:inbound|regular->transit-shelter:left|street-level": 48,
  "bus-lane:inbound|regular->transit-shelter:right|light-rail": 16,
  "bus-lane:inbound|regular->transit-shelter:right|street-level": 136,
  "bus-lane:inbound|regular->turn-lane:inbound|both": 75,
  "bus-lane:inbound|regular->turn-lane:inbound|left": 52,
  "bus-lane:inbound|regular->turn-lane:inbound|left-straight": 35,
  "bus-lane:inbound|regular->turn-lane:inbound|right": 225,
  "bus-lane:inbound|regular->turn-lane:inbound|right-straight": 12465,
  "bus-lane:inbound|regular->turn-lane:inbound|shared": 30,
  "bus-lane:inbound|regular->turn-lane:inbound|straight": 101,
  "bus-lane:inbound|regular->turn-lane:outbound|both": 2,
  "bus-lane:inbound|regular->turn-lane:outbound|left": 43,
  "bus-lane:inbound|regular->turn-lane:outbound|left-straight": 5,
  "bus-lane:inbound|regular->turn-lane:outbound|right": 6,
  "bus-lane:inbound|regular->turn-lane:outbound|right-straight": 6,
  "bus-lane:inbound|regular->turn-lane:outbound|shared": 3,
  "bus-lane:inbound|regular->turn-lane:outbound|straight": 9,
  "bus-lane:inbound|regular|typical->bike-lane:inbound|green|road": 7,
  "bus-lane:inbound|regular|typical->bike-lane:inbound|green|sidewalk": 2,
  "bus-lane:inbound|regular|typical->bike-lane:inbound|red|sidewalk": 1,
  "bus-lane:inbound|regular|typical->bike-lane:inbound|regular|road": 20,
  "bus-lane:inbound|regular|typical->bike-lane:inbound|regular|sidewalk": 1,
  "bus-lane:inbound|regular|typical->bike-lane:outbound|green|road": 8,
  "bus-lane:inbound|regular|typical->bike-lane:outbound|green|sidewalk": 2,
  "bus-lane:inbound|regular|typical->bike-lane:outbound|red|road": 1,
  "bus-lane:inbound|regular|typical->bike-lane:outbound|regular|road": 3,
  "bus-lane:inbound|regular|typical->bike-lane:outbound|regular|sidewalk": 3,
  "bus-lane:inbound|regular|typical->bus-lane:inbound|colored|typical": 2,
  "bus-lane:inbound|regular|typical->bus-lane:inbound|regular|typical": 39,
  "bus-lane:inbound|regular|typical->bus-lane:outbound|regular|typical": 73,
  "bus-lane:inbound|regular|typical->bus-lane:outbound|shared|typical": 2,
  "bus-lane:inbound|regular|typical->divider:big-tree": 25,
  "bus-lane:inbound|regular|typical->divider:bollard": 6,
  "bus-lane:inbound|regular|typical->divider:bush": 30,
  "bus-lane:inbound|regular|typical->divider:dome": 5,
  "bus-lane:inbound|regular|typical->divider:flowers": 45,
  "bus-lane:inbound|regular|typical->divider:median": 20,
  "bus-lane:inbound|regular|typical->divider:palm-tree": 5,
  "bus-lane:inbound|regular|typical->divider:planter-box": 15,
  "bus-lane:inbound|regular|typical->divider:planting-strip": 6,
  "bus-lane:inbound|regular|typical->divider:striped-buffer": 18,
  "bus-lane:inbound|regular|typical->drive-lane:inbound|car": 716,
  "bus-lane:inbound|regular|typical->drive-lane:inbound|sharrow": 4,
  "bus-lane:inbound|regular|typical->drive-lane:inbound|truck": 14,
  "bus-lane:inbound|regular|typical->drive-lane:outbound|car": 34,
  "bus-lane:inbound|regular|typical->drive-lane:outbound|truck": 1,
  "bus-lane:inbound|regular|typical->flex-zone-curb:empty|left": 1,
  "bus-lane:inbound|regular|typical->flex-zone-curb:empty|right": 1,
  "bus-lane:inbound|regular|typical->flex-zone-curb:sparse|left": 13,
  "bus-lane:inbound|regular|typical->flex-zone:taxi|inbound|left": 4,
  "bus-lane:inbound|regular|typical->flex-zone:taxi|outbound|left": 1,
  "bus-lane:inbound|regular|typical->flex-zone:taxi|outbound|right": 2,
  "bus-lane:inbound|regular|typical->food-truck:right": 1,
  "bus-lane:inbound|regular|typical->light-rail:inbound|grass": 2,
  "bus-lane:inbound|regular|typical->light-rail:inbound|regular": 7,
  "bus-lane:inbound|regular|typical->parking-lane:angled-front-right|left": 1,
  "bus-lane:inbound|regular|typical->parking-lane:inbound|left": 7,
  "bus-lane:inbound|regular|typical->parking-lane:inbound|right": 13,
  "bus-lane:inbound|regular|typical->parking-lane:outbound|right": 4,
  "bus-lane:inbound|regular|typical->parking-lane:sideways|right": 2,
  "bus-lane:inbound|regular|typical->parklet:left": 2,
  "bus-lane:inbound|regular|typical->parklet:right": 2,
  "bus-lane:inbound|regular|typical->scooter-drop-zone:right|sidewalk|empty": 1,
  "bus-lane:inbound|regular|typical->scooter:inbound|regular": 1,
  "bus-lane:inbound|regular|typical->scooter:outbound|regular": 1,
  "bus-lane:inbound|regular|typical->sidewalk-bench:left": 1,
  "bus-lane:inbound|regular|typical->sidewalk-bench:right": 3,
  "bus-lane:inbound|regular|typical->sidewalk-bike-rack:left|sidewalk": 1,
  "bus-lane:inbound|regular|typical->sidewalk-lamp:both|modern": 11,
  "bus-lane:inbound|regular|typical->sidewalk-lamp:both|traditional": 8,
  "bus-lane:inbound|regular|typical->sidewalk-lamp:left|modern": 39,
  "bus-lane:inbound|regular|typical->sidewalk-lamp:left|traditional": 8,
  "bus-lane:inbound|regular|typical->sidewalk-lamp:right|modern": 8,
  "bus-lane:inbound|regular|typical->sidewalk-lamp:right|pride": 1,
  "bus-lane:inbound|regular|typical->sidewalk-lamp:right|traditional": 4,
  "bus-lane:inbound|regular|typical->sidewalk-tree:big": 32,
  "bus-lane:inbound|regular|typical->sidewalk-tree:palm-tree": 5,
  "bus-lane:inbound|regular|typical->sidewalk-wayfinding:large": 3,
  "bus-lane:inbound|regular|typical->sidewalk:dense": 16,
  "bus-lane:inbound|regular|typical->sidewalk:empty": 2,
  "bus-lane:inbound|regular|typical->sidewalk:normal": 3,
  "bus-lane:inbound|regular|typical->sidewalk:sparse": 2,
  "bus-lane:inbound|regular|typical->streetcar:inbound|regular": 7,
  "bus-lane:inbound|regular|typical->streetcar:outbound|regular": 2,
  "bus-lane:inbound|regular|typical->temporary:jersey-barrier-concrete": 1,
  "bus-lane:inbound|regular|typical->temporary:traffic-cone": 1,
  "bus-lane:inbound|regular|typical->transit-shelter:left|street-level": 11,
  "bus-lane:inbound|regular|typical->transit-shelter:right|light-rail": 3,
  "bus-lane:inbound|regular|typical->transit-shelter:right|street-level": 67,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|both": 1,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|left": 5,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|left-right-straight": 2,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|left-straight": 3,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|right": 9,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|right-straight": 7,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|shared": 1,
  "bus-lane:inbound|regular|typical->turn-lane:inbound|straight": 11,
  "bus-lane:inbound|regular|typical->turn-lane:outbound|left-right-straight": 1,
  "bus-lane:inbound|regular|typical->turn-lane:outbound|left-straight": 5,
  "bus-lane:inbound|regular|typical->turn-lane:outbound|right": 1,
  "bus-lane:inbound|regular|typical->turn-lane:outbound|shared": 1,
  "bus-lane:inbound|regular|typical->turn-lane:outbound|straight": 18,
  "bus-lane:inbound|regular|typical->turn-lane:right|right-straight": 1,
  "bus-lane:inbound|shared|typical->bike-lane:inbound|regular|road": 8,
  "bus-lane:inbound|shared|typical->bike-lane:outbound|green|road": 6,
  "bus-lane:inbound|shared|typical->bike-lane:outbound|regular|road": 2,
  "bus-lane:inbound|shared|typical->bikeshare:left|road": 1,
  "bus-lane:inbound|shared|typical->bus-lane:inbound|regular|typical": 9,
  "bus-lane:inbound|shared|typical->bus-lane:outbound|regular|typical": 3,
  "bus-lane:inbound|shared|typical->bus-lane:outbound|shared|typical": 15,
  "bus-lane:inbound|shared|typical->divider:big-tree": 7,
  "bus-lane:inbound|shared|typical->divider:bollard": 7,
  "bus-lane:inbound|shared|typical->divider:bush": 258,
  "bus-lane:inbound|shared|typical->divider:dome": 15,
  "bus-lane:inbound|shared|typical->divider:flowers": 16,
  "bus-lane:inbound|shared|typical->divider:median": 2,
  "bus-lane:inbound|shared|typical->divider:palm-tree": 3,
  "bus-lane:inbound|shared|typical->divider:planter-box": 24,
  "bus-lane:inbound|shared|typical->divider:planting-strip": 2,
  "bus-lane:inbound|shared|typical->divider:striped-buffer": 29,
  "bus-lane:inbound|shared|typical->drive-lane:car": 1,
  "bus-lane:inbound|shared|typical->drive-lane:inbound|car": 12854,
  "bus-lane:inbound|shared|typical->drive-lane:inbound|pedestrian": 1,
  "bus-lane:inbound|shared|typical->drive-lane:inbound|truck": 61,
  "bus-lane:inbound|shared|typical->drive-lane:outbound|car": 37,
  "bus-lane:inbound|shared|typical->drive-lane:outbound|truck": 2,
  "bus-lane:inbound|shared|typical->drive-lane:truck": 2,
  "bus-lane:inbound|shared|typical->drive-lane:truck|outbound": 1,
  "bus-lane:inbound|shared|typical->drive-lane:truck|truck": 1,
  "bus-lane:inbound|shared|typical->flex-zone-curb:sparse|left": 3,
  "bus-lane:inbound|shared|typical->flex-zone:taxi|inbound|left": 2,
  "bus-lane:inbound|shared|typical->flex-zone:taxi|inbound|right": 9,
  "bus-lane:inbound|shared|typical->food-truck:left": 5,
  "bus-lane:inbound|shared|typical->light-rail:inbound|regular": 1,
  "bus-lane:inbound|shared|typical->light-rail:outbound|regular": 1,
  "bus-lane:inbound|shared|typical->parking-lane:inbound|left": 2,
  "bus-lane:inbound|shared|typical->parking-lane:inbound|right": 17,
  "bus-lane:inbound|shared|typical->parking-lane:outbound|left": 1,
  "bus-lane:inbound|shared|typical->parking-lane:outbound|right": 25,
  "bus-lane:inbound|shared|typical->parklet:right": 3,
  "bus-lane:inbound|shared|typical->scooter-drop-zone:right|sidewalk|empty": 2,
  "bus-lane:inbound|shared|typical->scooter:inbound|regular": 1,
  "bus-lane:inbound|shared|typical->sidewalk-bike-rack:left|sidewalk": 1,
  "bus-lane:inbound|shared|typical->sidewalk-lamp:both|modern": 5,
  "bus-lane:inbound|shared|typical->sidewalk-lamp:both|pride": 3,
  "bus-lane:inbound|shared|typical->sidewalk-lamp:both|traditional": 5,
  "bus-lane:inbound|shared|typical->sidewalk-lamp:left|modern": 958,
  "bus-lane:inbound|shared|typical->sidewalk-lamp:left|pride": 1,
  "bus-lane:inbound|shared|typical->sidewalk-lamp:left|traditional": 3,
  "bus-lane:inbound|shared|typical->sidewalk-lamp:right|modern": 21,
  "bus-lane:inbound|shared|typical->sidewalk-tree:big": 35,
  "bus-lane:inbound|shared|typical->sidewalk-tree:palm-tree": 2,
  "bus-lane:inbound|shared|typical->sidewalk-wayfinding:large": 1,
  "bus-lane:inbound|shared|typical->sidewalk:dense": 16,
  "bus-lane:inbound|shared|typical->sidewalk:normal": 7,
  "bus-lane:inbound|shared|typical->streetcar:inbound|regular": 2,
  "bus-lane:inbound|shared|typical->transit-shelter:left|street-level": 8,
  "bus-lane:inbound|shared|typical->transit-shelter:right|light-rail": 1,
  "bus-lane:inbound|shared|typical->transit-shelter:right|street-level": 23,
  "bus-lane:inbound|shared|typical->turn-lane:inbound|left": 3,
  "bus-lane:inbound|shared|typical->turn-lane:inbound|left-right-straight": 4,
  "bus-lane:inbound|shared|typical->turn-lane:inbound|left-straight": 3,
  "bus-lane:inbound|shared|typical->turn-lane:inbound|right": 3,
  "bus-lane:inbound|shared|typical->turn-lane:inbound|straight": 1,
  "bus-lane:inbound|shared|typical->turn-lane:outbound": 1,
  "bus-lane:inbound|shared|typical->turn-lane:outbound|both": 1,
  "bus-lane:inbound|shared|typical->turn-lane:outbound|left-right-straight": 4,
  "bus-lane:inbound|shared|typical->turn-lane:outbound|left-straight": 73,
  "bus-lane:inbound|shared|typical->turn-lane:outbound|right-straight": 3,
  "bus-lane:inbound|shared|typical->turn-lane:outbound|straight": 1,
  "bus-lane:inbound|typical->drive-lane:inbound|car": 1,
  "bus-lane:inbound||->divider:big-tree": 1,
  "bus-lane:outbound->drive-lane:inbound|car": 1,
  "bus-lane:outbound->sidewalk-lamp:left|modern": 1,
  "bus-lane:outbound|colored->bike-lane:inbound|colored": 1,
  "bus-lane:outbound|colored->bike-lane:inbound|regular": 3,
  "bus-lane:outbound|colored->bike-lane:outbound|colored": 26,
  "bus-lane:outbound|colored->bike-lane:outbound|regular": 3,
  "bus-lane:outbound|colored->bus-lane:inbound|colored": 6,
  "bus-lane:outbound|colored->bus-lane:inbound|regular": 2,
  "bus-lane:outbound|colored->bus-lane:outbound|colored": 7,
  "bus-lane:outbound|colored->bus-lane:outbound|regular": 1,
  "bus-lane:outbound|colored->divider:big-tree": 5,
  "bus-lane:outbound|colored->divider:bollard": 5,
  "bus-lane:outbound|colored->divider:bush": 3,
  "bus-lane:outbound|colored->divider:dome": 4,
  "bus-lane:outbound|colored->divider:flowers": 11,
  "bus-lane:outbound|colored->divider:median": 11,
  "bus-lane:outbound|colored->divider:striped-buffer": 3,
  "bus-lane:outbound|colored->drive-lane:inbound|car": 7,
  "bus-lane:outbound|colored->drive-lane:outbound|car": 58,
  "bus-lane:outbound|colored->drive-lane:outbound|sharrow": 2,
  "bus-lane:outbound|colored->drive-lane:outbound|truck": 6,
  "bus-lane:outbound|colored->light-rail:outbound|regular": 2,
  "bus-lane:outbound|colored->parking-lane:inbound|right": 2,
  "bus-lane:outbound|colored->parking-lane:outbound|right": 8,
  "bus-lane:outbound|colored->sidewalk-bench:right": 1,
  "bus-lane:outbound|colored->sidewalk-bike-rack:left|sidewalk": 1,
  "bus-lane:outbound|colored->sidewalk-lamp:both|modern": 9,
  "bus-lane:outbound|colored->sidewalk-lamp:both|traditional": 8,
  "bus-lane:outbound|colored->sidewalk-lamp:left|modern": 31,
  "bus-lane:outbound|colored->sidewalk-lamp:left|traditional": 8,
  "bus-lane:outbound|colored->sidewalk-tree:big": 13,
  "bus-lane:outbound|colored->sidewalk:dense": 6,
  "bus-lane:outbound|colored->sidewalk:normal": 1,
  "bus-lane:outbound|colored->streetcar:inbound|regular": 1,
  "bus-lane:outbound|colored->streetcar:outbound|colored": 1,
  "bus-lane:outbound|colored->transit-shelter:left|street-level": 1,
  "bus-lane:outbound|colored->transit-shelter:right|light-rail": 4,
  "bus-lane:outbound|colored->transit-shelter:right|street-level": 53,
  "bus-lane:outbound|colored->turn-lane:inbound|both": 4,
  "bus-lane:outbound|colored->turn-lane:inbound|right": 1,
  "bus-lane:outbound|colored->turn-lane:inbound|right-straight": 5,
  "bus-lane:outbound|colored->turn-lane:outbound|both": 2,
  "bus-lane:outbound|colored->turn-lane:outbound|left-straight": 2,
  "bus-lane:outbound|colored->turn-lane:outbound|right": 2,
  "bus-lane:outbound|colored->turn-lane:outbound|right-straight": 2,
  "bus-lane:outbound|colored->turn-lane:outbound|straight": 6,
  "bus-lane:outbound|colored|typical->bike-lane:outbound|green|road": 8,
  "bus-lane:outbound|colored|typical->bike-lane:outbound|green|sidewalk": 1,
  "bus-lane:outbound|colored|typical->bus-lane:inbound|colored|typical": 4,
  "bus-lane:outbound|colored|typical->bus-lane:outbound|colored|typical": 2,
  "bus-lane:outbound|colored|typical->bus-lane:outbound|regular|typical": 1,
  "bus-lane:outbound|colored|typical->divider:big-tree": 6,
  "bus-lane:outbound|colored|typical->divider:bollard": 2,
  "bus-lane:outbound|colored|typical->divider:bush": 3,
  "bus-lane:outbound|colored|typical->divider:dome": 8,
  "bus-lane:outbound|colored|typical->divider:flowers": 9,
  "bus-lane:outbound|colored|typical->divider:median": 8,
  "bus-lane:outbound|colored|typical->divider:planter-box": 3,
  "bus-lane:outbound|colored|typical->divider:planting-strip": 1,
  "bus-lane:outbound|colored|typical->divider:striped-buffer": 6,
  "bus-lane:outbound|colored|typical->drive-lane:inbound|car": 14,
  "bus-lane:outbound|colored|typical->drive-lane:outbound|car": 28,
  "bus-lane:outbound|colored|typical->flex-zone-curb:sparse|left": 4,
  "bus-lane:outbound|colored|typical->flex-zone:taxi|outbound|left": 1,
  "bus-lane:outbound|colored|typical->light-rail:outbound|colored": 2,
  "bus-lane:outbound|colored|typical->parking-lane:inbound|right": 1,
  "bus-lane:outbound|colored|typical->parking-lane:outbound|right": 5,
  "bus-lane:outbound|colored|typical->parklet:right": 1,
  "bus-lane:outbound|colored|typical->sidewalk-bike-rack:right|road": 1,
  "bus-lane:outbound|colored|typical->sidewalk-lamp:both|modern": 4,
  "bus-lane:outbound|colored|typical->sidewalk-lamp:both|pride": 2,
  "bus-lane:outbound|colored|typical->sidewalk-lamp:both|traditional": 1,
  "bus-lane:outbound|colored|typical->sidewalk-lamp:left|modern": 3,
  "bus-lane:outbound|colored|typical->sidewalk-lamp:left|pride": 3,
  "bus-lane:outbound|colored|typical->sidewalk-tree:big": 12,
  "bus-lane:outbound|colored|typical->sidewalk:dense": 7,
  "bus-lane:outbound|colored|typical->sidewalk:normal": 1,
  "bus-lane:outbound|colored|typical->transit-shelter:left|light-rail": 1,
  "bus-lane:outbound|colored|typical->transit-shelter:right|light-rail": 3,
  "bus-lane:outbound|colored|typical->transit-shelter:right|street-level": 29,
  "bus-lane:outbound|colored|typical->turn-lane:outbound|right-straight": 2,
  "bus-lane:outbound|colored|typical->turn-lane:outbound|straight": 1,
  "bus-lane:outbound|regular->bike-lane:inbound|colored": 3,
  "bus-lane:outbound|regular->bike-lane:inbound|regular": 1,
  "bus-lane:outbound|regular->bike-lane:outbound|colored": 402,
  "bus-lane:outbound|regular->bike-lane:outbound|regular": 43,
  "bus-lane:outbound|regular->bus-lane:inbound|regular": 12,
  "bus-lane:outbound|regular->bus-lane:outbound|colored": 2,
  "bus-lane:outbound|regular->bus-lane:outbound|regular": 18,
  "bus-lane:outbound|regular->divider:big-tree": 11,
  "bus-lane:outbound|regular->divider:bollard": 5,
  "bus-lane:outbound|regular->divider:bush": 4,
  "bus-lane:outbound|regular->divider:dome": 9,
  "bus-lane:outbound|regular->divider:flowers": 47,
  "bus-lane:outbound|regular->divider:median": 28,
  "bus-lane:outbound|regular->divider:palm-tree": 5,
  "bus-lane:outbound|regular->divider:planting-strip": 6,
  "bus-lane:outbound|regular->divider:striped-buffer": 10,
  "bus-lane:outbound|regular->drive-lane:inbound|car": 31,
  "bus-lane:outbound|regular->drive-lane:inbound|truck": 4,
  "bus-lane:outbound|regular->drive-lane:outbound|car": 115,
  "bus-lane:outbound|regular->drive-lane:outbound|sharrow": 2,
  "bus-lane:outbound|regular->drive-lane:outbound|truck": 86,
  "bus-lane:outbound|regular->light-rail:inbound|regular": 3,
  "bus-lane:outbound|regular->light-rail:outbound|colored": 2,
  "bus-lane:outbound|regular->light-rail:outbound|regular": 13,
  "bus-lane:outbound|regular->parking-lane:inbound|left": 2,
  "bus-lane:outbound|regular->parking-lane:inbound|right": 11,
  "bus-lane:outbound|regular->parking-lane:outbound|left": 5,
  "bus-lane:outbound|regular->parking-lane:outbound|right": 48,
  "bus-lane:outbound|regular->parking-lane:sideways|right": 1,
  "bus-lane:outbound|regular->parklet:left": 1,
  "bus-lane:outbound|regular->parklet:right": 4,
  "bus-lane:outbound|regular->sidewalk-bench:left": 1,
  "bus-lane:outbound|regular->sidewalk-bench:right": 3,
  "bus-lane:outbound|regular->sidewalk-bike-rack:left|sidewalk": 1,
  "bus-lane:outbound|regular->sidewalk-lamp:both|modern": 17,
  "bus-lane:outbound|regular->sidewalk-lamp:both|traditional": 8,
  "bus-lane:outbound|regular->sidewalk-lamp:left|modern": 118,
  "bus-lane:outbound|regular->sidewalk-lamp:left|traditional": 10,
  "bus-lane:outbound|regular->sidewalk-lamp:right|modern": 5,
  "bus-lane:outbound|regular->sidewalk-lamp:right|traditional": 6,
  "bus-lane:outbound|regular->sidewalk-tree:big": 55,
  "bus-lane:outbound|regular->sidewalk-tree:palm-tree": 3,
  "bus-lane:outbound|regular->sidewalk-wayfinding:large": 4,
  "bus-lane:outbound|regular->sidewalk-wayfinding:medium": 5,
  "bus-lane:outbound|regular->sidewalk-wayfinding:small": 1,
  "bus-lane:outbound|regular->sidewalk:dense": 21,
  "bus-lane:outbound|regular->sidewalk:normal": 13,
  "bus-lane:outbound|regular->sidewalk:sparse": 2,
  "bus-lane:outbound|regular->streetcar:inbound|regular": 1,
  "bus-lane:outbound|regular->streetcar:outbound|colored": 7,
  "bus-lane:outbound|regular->streetcar:outbound|regular": 4,
  "bus-lane:outbound|regular->transit-shelter:left|street-level": 7,
  "bus-lane:outbound|regular->transit-shelter:right|light-rail": 5,
  "bus-lane:outbound|regular->transit-shelter:right|street-level": 128,
  "bus-lane:outbound|regular->turn-lane:inbound|both": 3,
  "bus-lane:outbound|regular->turn-lane:inbound|left-straight": 4,
  "bus-lane:outbound|regular->turn-lane:inbound|right": 4,
  "bus-lane:outbound|regular->turn-lane:inbound|right-straight": 18,
  "bus-lane:outbound|regular->turn-lane:inbound|shared": 1,
  "bus-lane:outbound|regular->turn-lane:inbound|straight": 3,
  "bus-lane:outbound|regular->turn-lane:outbound|both": 1,
  "bus-lane:outbound|regular->turn-lane:outbound|left": 33,
  "bus-lane:outbound|regular->turn-lane:outbound|left-straight": 5,
  "bus-lane:outbound|regular->turn-lane:outbound|right": 8,
  "bus-lane:outbound|regular->turn-lane:outbound|right-straight": 16,
  "bus-lane:outbound|regular->turn-lane:outbound|straight": 4,
  "bus-lane:outbound|regular|typical->bike-lane:inbound|red|road": 1,
  "bus-lane:outbound|regular|typical->bike-lane:inbound|regular|road": 6,
  "bus-lane:outbound|regular|typical->bike-lane:outbound|green|road": 19,
  "bus-lane:outbound|regular|typical->bike-lane:outbound|green|sidewalk": 6,
  "bus-lane:outbound|regular|typical->bike-lane:outbound|red|road": 4,
  "bus-lane:outbound|regular|typical->bike-lane:outbound|red|sidewalk": 3,
  "bus-lane:outbound|regular|typical->bike-lane:outbound|regular|road": 23,
  "bus-lane:outbound|regular|typical->bike-lane:outbound|regular|sidewalk": 1,
  "bus-lane:outbound|regular|typical->bus-lane:inbound|regular|typical": 21,
  "bus-lane:outbound|regular|typical->bus-lane:outbound|regular|typical": 25,
  "bus-lane:outbound|regular|typical->bus-lane:outbound|shared|typical": 3,
  "bus-lane:outbound|regular|typical->divider:big-tree": 20,
  "bus-lane:outbound|regular|typical->divider:bollard": 20,
  "bus-lane:outbound|regular|typical->divider:bush": 17,
  "bus-lane:outbound|regular|typical->divider:dome": 12,
  "bus-lane:outbound|regular|typical->divider:flowers": 30,
  "bus-lane:outbound|regular|typical->divider:median": 23,
  "bus-lane:outbound|regular|typical->divider:palm-tree": 3,
  "bus-lane:outbound|regular|typical->divider:planter-box": 14,
  "bus-lane:outbound|regular|typical->divider:planting-strip": 5,
  "bus-lane:outbound|regular|typical->divider:striped-buffer": 15,
  "bus-lane:outbound|regular|typical->drive-lane:inbound|car": 26,
  "bus-lane:outbound|regular|typical->drive-lane:inbound|truck": 3,
  "bus-lane:outbound|regular|typical->drive-lane:outbound|car": 112,
  "bus-lane:outbound|regular|typical->drive-lane:outbound|sharrow": 1,
  "bus-lane:outbound|regular|typical->drive-lane:outbound|truck": 4,
  "bus-lane:outbound|regular|typical->flex-zone-curb:sparse|left": 16,
  "bus-lane:outbound|regular|typical->flex-zone:rideshare|outbound|right": 1,
  "bus-lane:outbound|regular|typical->flex-zone:taxi|outbound|right": 2,
  "bus-lane:outbound|regular|typical->light-rail:inbound|regular": 1,
  "bus-lane:outbound|regular|typical->parking-lane:angled-front-left|left": 1,
  "bus-lane:outbound|regular|typical->parking-lane:inbound|left": 4,
  "bus-lane:outbound|regular|typical->parking-lane:inbound|right": 7,
  "bus-lane:outbound|regular|typical->parking-lane:outbound|left": 6,
  "bus-lane:outbound|regular|typical->parking-lane:outbound|right": 41,
  "bus-lane:outbound|regular|typical->parklet:left": 1,
  "bus-lane:outbound|regular|typical->parklet:right": 1,
  "bus-lane:outbound|regular|typical->scooter:outbound|regular": 1,
  "bus-lane:outbound|regular|typical->sidewalk-lamp:both|modern": 14,
  "bus-lane:outbound|regular|typical->sidewalk-lamp:both|traditional": 5,
  "bus-lane:outbound|regular|typical->sidewalk-lamp:left|modern": 105,
  "bus-lane:outbound|regular|typical->sidewalk-lamp:left|traditional": 9,
  "bus-lane:outbound|regular|typical->sidewalk-lamp:right|modern": 2,
  "bus-lane:outbound|regular|typical->sidewalk-lamp:right|traditional": 3,
  "bus-lane:outbound|regular|typical->sidewalk-tree:big": 57,
  "bus-lane:outbound|regular|typical->sidewalk-tree:palm-tree": 5,
  "bus-lane:outbound|regular|typical->sidewalk-wayfinding:small": 2,
  "bus-lane:outbound|regular|typical->sidewalk:dense": 19,
  "bus-lane:outbound|regular|typical->sidewalk:normal": 12,
  "bus-lane:outbound|regular|typical->sidewalk:sparse": 5,
  "bus-lane:outbound|regular|typical->streetcar:outbound|regular": 2,
  "bus-lane:outbound|regular|typical->temporary:jersey-barrier-concrete": 1,
  "bus-lane:outbound|regular|typical->transit-shelter:left|light-rail": 1,
  "bus-lane:outbound|regular|typical->transit-shelter:left|street-level": 3,
  "bus-lane:outbound|regular|typical->transit-shelter:right|light-rail": 4,
  "bus-lane:outbound|regular|typical->transit-shelter:right|street-level": 94,
  "bus-lane:outbound|regular|typical->turn-lane:inbound|left": 1,
  "bus-lane:outbound|regular|typical->turn-lane:inbound|right": 1,
  "bus-lane:outbound|regular|typical->turn-lane:inbound|straight": 2,
  "bus-lane:outbound|regular|typical->turn-lane:outbound|left": 4,
  "bus-lane:outbound|regular|typical->turn-lane:outbound|left-right-straight": 1,
  "bus-lane:outbound|regular|typical->turn-lane:outbound|left-straight": 7,
  "bus-lane:outbound|regular|typical->turn-lane:outbound|right": 8,
  "bus-lane:outbound|regular|typical->turn-lane:outbound|right-straight": 7,
  "bus-lane:outbound|regular|typical->turn-lane:outbound|straight": 8,
  "bus-lane:outbound|shared|typical->bike-lane:outbound|green|sidewalk": 1,
  "bus-lane:outbound|shared|typical->bikeshare:right|road": 1,
  "bus-lane:outbound|shared|typical->bikeshare:right|sidewalk": 1,
  "bus-lane:outbound|shared|typical->bus-lane:inbound|shared|typical": 1,
  "bus-lane:outbound|shared|typical->divider:bollard": 2,
  "bus-lane:outbound|shared|typical->divider:bush": 1,
  "bus-lane:outbound|shared|typical->divider:dome": 3,
  "bus-lane:outbound|shared|typical->divider:flowers": 2,
  "bus-lane:outbound|shared|typical->divider:median": 1,
  "bus-lane:outbound|shared|typical->divider:planter-box": 5,
  "bus-lane:outbound|shared|typical->divider:planting-strip": 1,
  "bus-lane:outbound|shared|typical->divider:striped-buffer": 1,
  "bus-lane:outbound|shared|typical->drive-lane:inbound|car": 42,
  "bus-lane:outbound|shared|typical->drive-lane:inbound|truck": 13,
  "bus-lane:outbound|shared|typical->drive-lane:outbound|car": 31,
  "bus-lane:outbound|shared|typical->drive-lane:outbound|truck": 1,
  "bus-lane:outbound|shared|typical->flex-zone-curb:sparse|left": 3,
  "bus-lane:outbound|shared|typical->parking-lane:inbound|right": 3,
  "bus-lane:outbound|shared|typical->parking-lane:outbound|right": 4,
  "bus-lane:outbound|shared|typical->scooter:outbound|regular": 1,
  "bus-lane:outbound|shared|typical->sidewalk-bench:right": 3,
  "bus-lane:outbound|shared|typical->sidewalk-bike-rack:left|sidewalk": 1,
  "bus-lane:outbound|shared|typical->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "bus-lane:outbound|shared|typical->sidewalk-lamp:both|modern": 2,
  "bus-lane:outbound|shared|typical->sidewalk-lamp:both|pride": 2,
  "bus-lane:outbound|shared|typical->sidewalk-lamp:left|modern": 35,
  "bus-lane:outbound|shared|typical->sidewalk-lamp:left|pride": 1,
  "bus-lane:outbound|shared|typical->sidewalk-lamp:left|traditional": 5,
  "bus-lane:outbound|shared|typical->sidewalk-lamp:right|modern": 2,
  "bus-lane:outbound|shared|typical->sidewalk-lamp:right|traditional": 1,
  "bus-lane:outbound|shared|typical->sidewalk-tree:big": 8,
  "bus-lane:outbound|shared|typical->sidewalk-tree:palm-tree": 1,
  "bus-lane:outbound|shared|typical->sidewalk:dense": 7,
  "bus-lane:outbound|shared|typical->sidewalk:normal": 7,
  "bus-lane:outbound|shared|typical->sidewalk:sparse": 2,
  "bus-lane:outbound|shared|typical->streetcar:inbound|grass": 1,
  "bus-lane:outbound|shared|typical->transit-shelter:left|street-level": 1,
  "bus-lane:outbound|shared|typical->transit-shelter:right|street-level": 9,
  "bus-lane:outbound|shared|typical->turn-lane:inbound|right-straight": 1,
  "bus-lane:outbound|shared|typical->turn-lane:outbound|left-right-straight": 1,
  "bus-lane:outbound|shared|typical->turn-lane:outbound|left-straight": 3,
  "bus-lane:outbound|shared|typical->turn-lane:outbound|right-straight": 2,
  "bus-lane:regular->divider:bush": 1,
  "bus-lane:regular->drive-lane:inbound|car": 1,
  "bus-lane:regular|typical->drive-lane:inbound|car": 1,
  "bus-lane:shared|inbound->drive-lane:inbound|car": 2,
  "bus-lane:shared|typical|inbound->drive-lane:truck": 1,
  "divider:big-tree->bike-lane:inbound|colored": 51,
  "divider:big-tree->bike-lane:inbound|green|road": 38,
  "divider:big-tree->bike-lane:inbound|green|sidewalk": 24,
  "divider:big-tree->bike-lane:inbound|red|road": 9,
  "divider:big-tree->bike-lane:inbound|red|sidewalk": 7,
  "divider:big-tree->bike-lane:inbound|regular": 47,
  "divider:big-tree->bike-lane:inbound|regular|road": 61,
  "divider:big-tree->bike-lane:inbound|regular|sidewalk": 15,
  "divider:big-tree->bike-lane:outbound|colored": 117,
  "divider:big-tree->bike-lane:outbound|green|road": 111,
  "divider:big-tree->bike-lane:outbound|green|road|inbound": 1,
  "divider:big-tree->bike-lane:outbound|green|sidewalk": 12,
  "divider:big-tree->bike-lane:outbound|red|road": 7,
  "divider:big-tree->bike-lane:outbound|red|sidewalk": 13,
  "divider:big-tree->bike-lane:outbound|regular": 43,
  "divider:big-tree->bike-lane:outbound|regular|road": 26,
  "divider:big-tree->bike-lane:outbound|regular|sidewalk": 8,
  "divider:big-tree->bike-lane:outbound|regular|sidewalk|inbound": 1,
  "divider:big-tree->bikeshare:left|road": 1,
  "divider:big-tree->bus-lane:inbound|colored": 7,
  "divider:big-tree->bus-lane:inbound|colored|typical": 10,
  "divider:big-tree->bus-lane:inbound|regular": 17,
  "divider:big-tree->bus-lane:inbound|regular|typical": 14,
  "divider:big-tree->bus-lane:inbound|shared|typical": 5,
  "divider:big-tree->bus-lane:outbound|colored": 9,
  "divider:big-tree->bus-lane:outbound|colored|typical": 6,
  "divider:big-tree->bus-lane:outbound|regular": 24,
  "divider:big-tree->bus-lane:outbound|regular|typical": 19,
  "divider:big-tree->bus-lane:outbound|shared|typical": 2,
  "divider:big-tree->divider:big-tree": 57,
  "divider:big-tree->divider:bollard": 11,
  "divider:big-tree->divider:bush": 29,
  "divider:big-tree->divider:dome": 1,
  "divider:big-tree->divider:flowers": 95,
  "divider:big-tree->divider:median": 48,
  "divider:big-tree->divider:palm-tree": 1,
  "divider:big-tree->divider:planter-box": 5,
  "divider:big-tree->divider:planting-strip": 36,
  "divider:big-tree->divider:striped-buffer": 12,
  "divider:big-tree->drive-lane:inbound|": 1,
  "divider:big-tree->drive-lane:inbound|car": 286,
  "divider:big-tree->drive-lane:inbound|sharrow": 5,
  "divider:big-tree->drive-lane:inbound|truck": 15,
  "divider:big-tree->drive-lane:outbound|": 1,
  "divider:big-tree->drive-lane:outbound|car": 412,
  "divider:big-tree->drive-lane:outbound|sharrow": 9,
  "divider:big-tree->drive-lane:outbound|truck": 187,
  "divider:big-tree->flex-zone-curb:empty|left": 1,
  "divider:big-tree->flex-zone-curb:empty|right": 1,
  "divider:big-tree->flex-zone-curb:sparse|left": 2,
  "divider:big-tree->flex-zone-curb:sparse|right": 2,
  "divider:big-tree->flex-zone:rideshare|inbound|left": 2,
  "divider:big-tree->flex-zone:taxi|inbound|left": 1,
  "divider:big-tree->food-truck:left": 1,
  "divider:big-tree->light-rail:inbound|colored": 17,
  "divider:big-tree->light-rail:inbound|grass": 5,
  "divider:big-tree->light-rail:inbound|regular": 25,
  "divider:big-tree->light-rail:outbound|colored": 5,
  "divider:big-tree->light-rail:outbound|grass": 4,
  "divider:big-tree->light-rail:outbound|regular": 36,
  "divider:big-tree->outdoor-dining:occupied|sidewalk": 2,
  "divider:big-tree->parking-lane:angled-front-left|left": 2,
  "divider:big-tree->parking-lane:inbound|left": 68,
  "divider:big-tree->parking-lane:inbound|right": 8,
  "divider:big-tree->parking-lane:outbound|left": 22,
  "divider:big-tree->parking-lane:outbound|right": 23,
  "divider:big-tree->parking-lane:sideways|left": 5,
  "divider:big-tree->parklet:left": 5,
  "divider:big-tree->parklet:right": 8,
  "divider:big-tree->scooter-drop-zone:right|sidewalk|empty": 1,
  "divider:big-tree->scooter:inbound|green": 2,
  "divider:big-tree->scooter:inbound|regular": 2,
  "divider:big-tree->scooter:outbound|green": 1,
  "divider:big-tree->scooter:outbound|red": 1,
  "divider:big-tree->scooter:outbound|regular": 2,
  "divider:big-tree->sidewalk-bench:center": 10,
  "divider:big-tree->sidewalk-bench:left": 42,
  "divider:big-tree->sidewalk-bench:right": 8,
  "divider:big-tree->sidewalk-bike-rack:left|sidewalk": 15,
  "divider:big-tree->sidewalk-bike-rack:left|sidewalk-parallel": 7,
  "divider:big-tree->sidewalk-bike-rack:right|sidewalk": 2,
  "divider:big-tree->sidewalk-bike-rack:right|sidewalk-parallel": 5,
  "divider:big-tree->sidewalk-lamp:both|modern": 36,
  "divider:big-tree->sidewalk-lamp:both|pride": 1,
  "divider:big-tree->sidewalk-lamp:both|traditional": 56,
  "divider:big-tree->sidewalk-lamp:left|modern": 26,
  "divider:big-tree->sidewalk-lamp:left|traditional": 4,
  "divider:big-tree->sidewalk-lamp:right|modern": 54,
  "divider:big-tree->sidewalk-lamp:right|traditional": 63,
  "divider:big-tree->sidewalk-tree:big": 16,
  "divider:big-tree->sidewalk-wayfinding:large": 1,
  "divider:big-tree->sidewalk-wayfinding:medium": 1,
  "divider:big-tree->sidewalk-wayfinding:small": 5,
  "divider:big-tree->sidewalk:dense": 245,
  "divider:big-tree->sidewalk:empty": 6,
  "divider:big-tree->sidewalk:normal": 112,
  "divider:big-tree->sidewalk:sparse": 33,
  "divider:big-tree->streetcar:inbound|colored": 4,
  "divider:big-tree->streetcar:inbound|grass": 2,
  "divider:big-tree->streetcar:inbound|regular": 16,
  "divider:big-tree->streetcar:outbound|colored": 14,
  "divider:big-tree->streetcar:outbound|grass": 1,
  "divider:big-tree->streetcar:outbound|regular": 18,
  "divider:big-tree->temporary:traffic-cone": 1,
  "divider:big-tree->transit-shelter:left|light-rail": 11,
  "divider:big-tree->transit-shelter:left|street-level": 45,
  "divider:big-tree->transit-shelter:right|light-rail": 4,
  "divider:big-tree->transit-shelter:right|street-level": 6,
  "divider:big-tree->turn-lane:inbound|left": 11,
  "divider:big-tree->turn-lane:inbound|left-straight": 11,
  "divider:big-tree->turn-lane:inbound|right": 3,
  "divider:big-tree->turn-lane:inbound|right-straight": 2,
  "divider:big-tree->turn-lane:inbound|straight": 3,
  "divider:big-tree->turn-lane:outbound|both": 3,
  "divider:big-tree->turn-lane:outbound|left": 149,
  "divider:big-tree->turn-lane:outbound|left-right-straight": 10,
  "divider:big-tree->turn-lane:outbound|left-straight": 172,
  "divider:big-tree->turn-lane:outbound|left|straight": 1,
  "divider:big-tree->turn-lane:outbound|right": 3,
  "divider:big-tree->turn-lane:outbound|right-straight": 17,
  "divider:big-tree->turn-lane:outbound|straight": 80,
  "divider:big-tree->utilities:left": 2,
  "divider:bollard->bike-lane:inbound|colored": 53,
  "divider:bollard->bike-lane:inbound|green|road": 15,
  "divider:bollard->bike-lane:inbound|green|sidewalk": 4,
  "divider:bollard->bike-lane:inbound|red|road": 9,
  "divider:bollard->bike-lane:inbound|red|sidewalk": 2,
  "divider:bollard->bike-lane:inbound|regular": 30,
  "divider:bollard->bike-lane:inbound|regular|road": 32,
  "divider:bollard->bike-lane:outbound|colored": 124,
  "divider:bollard->bike-lane:outbound|green": 1,
  "divider:bollard->bike-lane:outbound|green|road": 106,
  "divider:bollard->bike-lane:outbound|green|sidewalk": 6,
  "divider:bollard->bike-lane:outbound|red|road": 6,
  "divider:bollard->bike-lane:outbound|regular": 39,
  "divider:bollard->bike-lane:outbound|regular|road": 40,
  "divider:bollard->bike-lane:outbound|regular|road|inbound": 1,
  "divider:bollard->bike-lane:outbound|regular|sidewalk": 2,
  "divider:bollard->bikeshare:left|road": 9,
  "divider:bollard->bus-lane:inbound|colored": 4,
  "divider:bollard->bus-lane:inbound|colored|typical": 2,
  "divider:bollard->bus-lane:inbound|regular": 16,
  "divider:bollard->bus-lane:inbound|regular|typical": 17,
  "divider:bollard->bus-lane:inbound|shared|typical": 3,
  "divider:bollard->bus-lane:outbound|colored": 14,
  "divider:bollard->bus-lane:outbound|regular": 10,
  "divider:bollard->bus-lane:outbound|regular|typical": 7,
  "divider:bollard->bus-lane:outbound|shared|typical": 3,
  "divider:bollard->divider:big-tree": 10,
  "divider:bollard->divider:bollard": 16,
  "divider:bollard->divider:bush": 7,
  "divider:bollard->divider:flowers": 8,
  "divider:bollard->divider:median": 9,
  "divider:bollard->divider:planting-strip": 4,
  "divider:bollard->divider:striped-buffer": 10,
  "divider:bollard->drive-lane:inbound|car": 237,
  "divider:bollard->drive-lane:inbound|sharrow": 1,
  "divider:bollard->drive-lane:inbound|truck": 15,
  "divider:bollard->drive-lane:outbound|car": 147,
  "divider:bollard->drive-lane:outbound|sharrow": 5,
  "divider:bollard->drive-lane:outbound|truck": 62,
  "divider:bollard->flex-zone:taxi|outbound|left": 3,
  "divider:bollard->food-truck:left": 1,
  "divider:bollard->food-truck:right": 1,
  "divider:bollard->light-rail:inbound|colored": 10,
  "divider:bollard->light-rail:inbound|grass": 2,
  "divider:bollard->light-rail:inbound|regular": 35,
  "divider:bollard->light-rail:outbound|colored": 11,
  "divider:bollard->light-rail:outbound|grass": 2,
  "divider:bollard->light-rail:outbound|regular": 28,
  "divider:bollard->parking-lane:angled-front-left|left": 3,
  "divider:bollard->parking-lane:inbound|left": 54,
  "divider:bollard->parking-lane:inbound|right": 11,
  "divider:bollard->parking-lane:outbound|left": 13,
  "divider:bollard->parking-lane:outbound|right": 9,
  "divider:bollard->parking-lane:sideways|left": 1,
  "divider:bollard->parking-lane:sideways|right": 1,
  "divider:bollard->parklet:left": 2,
  "divider:bollard->parklet:right": 1,
  "divider:bollard->scooter:inbound|green": 1,
  "divider:bollard->scooter:outbound|green": 2,
  "divider:bollard->sidewalk-bench:left": 3,
  "divider:bollard->sidewalk-bike-rack:left|sidewalk": 1,
  "divider:bollard->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "divider:bollard->sidewalk-bike-rack:right|road": 1,
  "divider:bollard->sidewalk-bike-rack:right|sidewalk": 1,
  "divider:bollard->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "divider:bollard->sidewalk-lamp:both|modern": 11,
  "divider:bollard->sidewalk-lamp:both|traditional": 3,
  "divider:bollard->sidewalk-lamp:left|modern": 30,
  "divider:bollard->sidewalk-lamp:left|traditional": 3,
  "divider:bollard->sidewalk-lamp:right|modern": 4,
  "divider:bollard->sidewalk-lamp:right|traditional": 7,
  "divider:bollard->sidewalk-tree:big": 20,
  "divider:bollard->sidewalk-tree:palm-tree": 3,
  "divider:bollard->sidewalk-wayfinding:large": 1,
  "divider:bollard->sidewalk-wayfinding:small": 2,
  "divider:bollard->sidewalk:dense": 29,
  "divider:bollard->sidewalk:empty": 1,
  "divider:bollard->sidewalk:normal": 5,
  "divider:bollard->sidewalk:sparse": 1,
  "divider:bollard->streetcar:inbound|regular": 8,
  "divider:bollard->streetcar:outbound|colored": 6,
  "divider:bollard->streetcar:outbound|regular": 17,
  "divider:bollard->transit-shelter:left|street-level": 3,
  "divider:bollard->transit-shelter:right|light-rail": 1,
  "divider:bollard->transit-shelter:right|street-level": 1,
  "divider:bollard->turn-lane:inbound|both": 1,
  "divider:bollard->turn-lane:inbound|left": 9,
  "divider:bollard->turn-lane:inbound|left-right-straight": 2,
  "divider:bollard->turn-lane:inbound|left-straight": 3,
  "divider:bollard->turn-lane:inbound|right": 3,
  "divider:bollard->turn-lane:inbound|right-straight": 3,
  "divider:bollard->turn-lane:inbound|shared": 1,
  "divider:bollard->turn-lane:inbound|straight": 7,
  "divider:bollard->turn-lane:outbound": 1,
  "divider:bollard->turn-lane:outbound|both": 1,
  "divider:bollard->turn-lane:outbound|left": 37,
  "divider:bollard->turn-lane:outbound|left-right-straight": 2,
  "divider:bollard->turn-lane:outbound|left-straight": 57,
  "divider:bollard->turn-lane:outbound|right": 3,
  "divider:bollard->turn-lane:outbound|right-straight": 3,
  "divider:bollard->turn-lane:outbound|shared": 1,
  "divider:bollard->turn-lane:outbound|straight": 24,
  "divider:bush->bike-lane:green|outbound": 1,
  "divider:bush->bike-lane:inbound": 1,
  "divider:bush->bike-lane:inbound|colored": 49,
  "divider:bush->bike-lane:inbound|green|road": 19,
  "divider:bush->bike-lane:inbound|green|sidewalk": 2,
  "divider:bush->bike-lane:inbound|red|road": 7,
  "divider:bush->bike-lane:inbound|red|sidewalk": 2,
  "divider:bush->bike-lane:inbound|regular": 25,
  "divider:bush->bike-lane:inbound|regular|road": 40,
  "divider:bush->bike-lane:inbound|regular|sidewalk": 3,
  "divider:bush->bike-lane:outbound|colored": 126,
  "divider:bush->bike-lane:outbound|green|road": 112,
  "divider:bush->bike-lane:outbound|green|road|inbound": 1,
  "divider:bush->bike-lane:outbound|green|sidewalk": 5,
  "divider:bush->bike-lane:outbound|red|road": 14,
  "divider:bush->bike-lane:outbound|red|sidewalk": 5,
  "divider:bush->bike-lane:outbound|regular": 30,
  "divider:bush->bike-lane:outbound|regular|road": 19,
  "divider:bush->bike-lane:outbound|regular|sidewalk": 1,
  "divider:bush->bike-lane:road|inbound": 2,
  "divider:bush->bikeshare:left|road": 2,
  "divider:bush->bus-lane:inbound|colored": 3,
  "divider:bush->bus-lane:inbound|colored|typical": 3,
  "divider:bush->bus-lane:inbound|regular": 12,
  "divider:bush->bus-lane:inbound|regular|typical": 24,
  "divider:bush->bus-lane:inbound|shared|typical": 49,
  "divider:bush->bus-lane:outbound|colored|typical": 1,
  "divider:bush->bus-lane:outbound|regular": 15,
  "divider:bush->bus-lane:outbound|regular|typical": 16,
  "divider:bush->bus-lane:outbound|shared|typical": 3,
  "divider:bush->divider:big-tree": 35,
  "divider:bush->divider:bollard": 3,
  "divider:bush->divider:bush": 18,
  "divider:bush->divider:dome": 1,
  "divider:bush->divider:flowers": 62,
  "divider:bush->divider:median": 18,
  "divider:bush->divider:palm-tree": 14,
  "divider:bush->divider:planter-box": 171,
  "divider:bush->divider:planting-strip": 19,
  "divider:bush->divider:striped-buffer": 18,
  "divider:bush->drive-lane:inbound": 1,
  "divider:bush->drive-lane:inbound|car": 1261,
  "divider:bush->drive-lane:inbound|sharrow": 3,
  "divider:bush->drive-lane:inbound|truck": 18,
  "divider:bush->drive-lane:outbound|car": 302,
  "divider:bush->drive-lane:outbound|sharrow": 6,
  "divider:bush->drive-lane:outbound|truck": 76,
  "divider:bush->flex-zone-curb:empty|right": 1,
  "divider:bush->flex-zone-curb:sparse|left": 2,
  "divider:bush->flex-zone:rideshare|inbound|left": 2,
  "divider:bush->flex-zone:taxi|inbound|left": 3,
  "divider:bush->flex-zone:taxi|outbound|left": 2,
  "divider:bush->food-truck:left": 3,
  "divider:bush->food-truck:right": 1,
  "divider:bush->light-rail:inbound|colored": 7,
  "divider:bush->light-rail:inbound|grass": 3,
  "divider:bush->light-rail:inbound|regular": 27,
  "divider:bush->light-rail:outbound|colored": 25,
  "divider:bush->light-rail:outbound|grass": 3,
  "divider:bush->light-rail:outbound|regular": 94,
  "divider:bush->parking-lane:angled-front-right": 1,
  "divider:bush->parking-lane:angled-front-right|left": 1,
  "divider:bush->parking-lane:inbound|left": 32,
  "divider:bush->parking-lane:inbound|right": 18,
  "divider:bush->parking-lane:outbound|left": 10,
  "divider:bush->parking-lane:outbound|right": 324,
  "divider:bush->parking-lane:sideways|right": 1,
  "divider:bush->parklet:left": 10,
  "divider:bush->parklet:right": 2,
  "divider:bush->scooter-drop-zone:left|sidewalk|empty": 1,
  "divider:bush->scooter-drop-zone:right|sidewalk|empty": 5,
  "divider:bush->scooter:inbound|green": 1,
  "divider:bush->scooter:inbound|regular": 6,
  "divider:bush->scooter:outbound|regular": 7,
  "divider:bush->sidewalk-bench:center": 4,
  "divider:bush->sidewalk-bench:left": 27,
  "divider:bush->sidewalk-bench:right": 6,
  "divider:bush->sidewalk-bike-rack:left|road": 2,
  "divider:bush->sidewalk-bike-rack:left|sidewalk": 9,
  "divider:bush->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "divider:bush->sidewalk-bike-rack:right|sidewalk": 2,
  "divider:bush->sidewalk-lamp:both|modern": 20,
  "divider:bush->sidewalk-lamp:both|pride": 2,
  "divider:bush->sidewalk-lamp:both|traditional": 39,
  "divider:bush->sidewalk-lamp:left|modern": 69,
  "divider:bush->sidewalk-lamp:left|pride": 4,
  "divider:bush->sidewalk-lamp:left|traditional": 21,
  "divider:bush->sidewalk-lamp:right|modern": 35,
  "divider:bush->sidewalk-lamp:right|traditional": 25,
  "divider:bush->sidewalk-tree:big": 263,
  "divider:bush->sidewalk-tree:palm-tree": 20,
  "divider:bush->sidewalk-wayfinding:large": 4,
  "divider:bush->sidewalk-wayfinding:small": 3,
  "divider:bush->sidewalk:dense": 115,
  "divider:bush->sidewalk:empty": 1,
  "divider:bush->sidewalk:normal": 76,
  "divider:bush->sidewalk:sparse": 16,
  "divider:bush->streetcar:inbound|colored": 7,
  "divider:bush->streetcar:inbound|grass": 1,
  "divider:bush->streetcar:inbound|regular": 17,
  "divider:bush->streetcar:outbound": 1,
  "divider:bush->streetcar:outbound|colored": 2,
  "divider:bush->streetcar:outbound|regular": 29,
  "divider:bush->transit-shelter:left|light-rail": 33,
  "divider:bush->transit-shelter:left|street-level": 16,
  "divider:bush->transit-shelter:right|light-rail": 8,
  "divider:bush->transit-shelter:right|street-level": 8,
  "divider:bush->turn-lane:inbound": 1,
  "divider:bush->turn-lane:inbound|both": 6,
  "divider:bush->turn-lane:inbound|left": 17,
  "divider:bush->turn-lane:inbound|left-right-straight": 3,
  "divider:bush->turn-lane:inbound|left-straight": 22,
  "divider:bush->turn-lane:inbound|right": 3,
  "divider:bush->turn-lane:inbound|right-straight": 1,
  "divider:bush->turn-lane:inbound|shared": 3,
  "divider:bush->turn-lane:inbound|straight": 18,
  "divider:bush->turn-lane:left-straight|outbound": 1,
  "divider:bush->turn-lane:left-straight|shared": 1,
  "divider:bush->turn-lane:left|outbound": 2,
  "divider:bush->turn-lane:outbound": 2,
  "divider:bush->turn-lane:outbound|both": 34,
  "divider:bush->turn-lane:outbound|left": 60,
  "divider:bush->turn-lane:outbound|left-right-straight": 33,
  "divider:bush->turn-lane:outbound|left-straight": 12835,
  "divider:bush->turn-lane:outbound|right": 27,
  "divider:bush->turn-lane:outbound|right-straight": 22,
  "divider:bush->turn-lane:outbound|shared": 5,
  "divider:bush->turn-lane:outbound|straight": 202,
  "divider:bush->turn-lane:right-straight|outbound": 1,
  "divider:bush->turn-lane:shared": 2,
  "divider:bush->turn-lane:shared|outbound": 1,
  "divider:bush->utilities:left": 1,
  "divider:dome->bike-lane:inbound|colored": 27,
  "divider:dome->bike-lane:inbound|green|road": 4,
  "divider:dome->bike-lane:inbound|red|road": 4,
  "divider:dome->bike-lane:inbound|regular": 19,
  "divider:dome->bike-lane:inbound|regular|road": 17,
  "divider:dome->bike-lane:inbound|regular|sidewalk": 2,
  "divider:dome->bike-lane:outbound": 1,
  "divider:dome->bike-lane:outbound|colored": 57,
  "divider:dome->bike-lane:outbound|green|road": 62,
  "divider:dome->bike-lane:outbound|green|sidewalk": 8,
  "divider:dome->bike-lane:outbound|red|road": 12,
  "divider:dome->bike-lane:outbound|regular": 23,
  "divider:dome->bike-lane:outbound|regular|road": 12,
  "divider:dome->bike-lane:outbound|regular|road|inbound": 2,
  "divider:dome->bike-lane:outbound||": 1,
  "divider:dome->bus-lane:inbound|colored": 5,
  "divider:dome->bus-lane:inbound|colored|typical": 7,
  "divider:dome->bus-lane:inbound|regular": 12,
  "divider:dome->bus-lane:inbound|regular|typical": 11,
  "divider:dome->bus-lane:inbound|shared|typical": 2,
  "divider:dome->bus-lane:outbound|colored": 5,
  "divider:dome->bus-lane:outbound|colored|typical": 4,
  "divider:dome->bus-lane:outbound|regular": 6,
  "divider:dome->bus-lane:outbound|regular|typical": 3,
  "divider:dome->bus-lane:outbound|shared|typical": 10,
  "divider:dome->divider:big-tree": 1,
  "divider:dome->divider:bollard": 2,
  "divider:dome->divider:bush": 2,
  "divider:dome->divider:flowers": 2,
  "divider:dome->divider:median": 1,
  "divider:dome->divider:palm-tree": 1,
  "divider:dome->divider:planting-strip": 1,
  "divider:dome->divider:striped-buffer": 1,
  "divider:dome->drive-lane:inbound|car": 134,
  "divider:dome->drive-lane:inbound|sharrow": 1,
  "divider:dome->drive-lane:inbound|truck": 8,
  "divider:dome->drive-lane:outbound|car": 85,
  "divider:dome->drive-lane:outbound|sharrow": 3,
  "divider:dome->drive-lane:outbound|truck": 44,
  "divider:dome->light-rail:inbound|colored": 5,
  "divider:dome->light-rail:inbound|regular": 15,
  "divider:dome->light-rail:outbound|colored": 2,
  "divider:dome->light-rail:outbound|regular": 8,
  "divider:dome->parking-lane:inbound|left": 19,
  "divider:dome->parking-lane:inbound|right": 6,
  "divider:dome->parking-lane:outbound|left": 5,
  "divider:dome->parking-lane:outbound|right": 8,
  "divider:dome->parking-lane:sideways|right": 1,
  "divider:dome->parklet:left": 1,
  "divider:dome->parklet:right": 2,
  "divider:dome->scooter-drop-zone:right|sidewalk|empty": 1,
  "divider:dome->scooter:inbound|green": 1,
  "divider:dome->scooter:inbound|regular": 1,
  "divider:dome->scooter:outbound|red": 1,
  "divider:dome->scooter:outbound|regular": 1,
  "divider:dome->sidewalk-bike-rack:right|road": 1,
  "divider:dome->sidewalk-lamp:both|modern": 2,
  "divider:dome->sidewalk-lamp:both|traditional": 3,
  "divider:dome->sidewalk-lamp:left|modern": 6,
  "divider:dome->sidewalk-lamp:right|modern": 2,
  "divider:dome->sidewalk-lamp:right|traditional": 2,
  "divider:dome->sidewalk-tree:big": 19,
  "divider:dome->sidewalk-tree:palm-tree": 2,
  "divider:dome->sidewalk-wayfinding:medium": 1,
  "divider:dome->sidewalk:dense": 7,
  "divider:dome->sidewalk:normal": 6,
  "divider:dome->sidewalk:sparse": 1,
  "divider:dome->streetcar:inbound|colored": 3,
  "divider:dome->streetcar:inbound|grass": 2,
  "divider:dome->streetcar:inbound|regular": 6,
  "divider:dome->streetcar:outbound|colored": 1,
  "divider:dome->streetcar:outbound|regular": 3,
  "divider:dome->transit-shelter:left|street-level": 3,
  "divider:dome->turn-lane:inbound|left": 1,
  "divider:dome->turn-lane:inbound|left-straight": 5,
  "divider:dome->turn-lane:inbound|right": 4,
  "divider:dome->turn-lane:inbound|right-straight": 2,
  "divider:dome->turn-lane:inbound|straight": 8,
  "divider:dome->turn-lane:outbound|both": 1,
  "divider:dome->turn-lane:outbound|left": 51,
  "divider:dome->turn-lane:outbound|left-right-straight": 2,
  "divider:dome->turn-lane:outbound|left-straight": 24,
  "divider:dome->turn-lane:outbound|right": 4,
  "divider:dome->turn-lane:outbound|right-straight": 6,
  "divider:dome->turn-lane:outbound|straight": 14,
  "divider:flowers->bike-lane:inbound|colored": 99,
  "divider:flowers->bike-lane:inbound|green|road": 44,
  "divider:flowers->bike-lane:inbound|green|sidewalk": 15,
  "divider:flowers->bike-lane:inbound|red|road": 14,
  "divider:flowers->bike-lane:inbound|red|sidewalk": 7,
  "divider:flowers->bike-lane:inbound|regular": 365,
  "divider:flowers->bike-lane:inbound|regular|road": 194,
  "divider:flowers->bike-lane:inbound|regular|sidewalk": 21,
  "divider:flowers->bike-lane:outbound|colored": 1044,
  "divider:flowers->bike-lane:outbound|green|road": 131,
  "divider:flowers->bike-lane:outbound|green|road|inbound": 2,
  "divider:flowers->bike-lane:outbound|green|sidewalk": 25,
  "divider:flowers->bike-lane:outbound|red|road": 8,
  "divider:flowers->bike-lane:outbound|red|sidewalk": 9,
  "divider:flowers->bike-lane:outbound|regular": 76,
  "divider:flowers->bike-lane:outbound|regular|road": 99,
  "divider:flowers->bike-lane:outbound|regular|sidewalk": 26,
  "divider:flowers->bikeshare:left|road": 10,
  "divider:flowers->bikeshare:left|sidewalk": 5,
  "divider:flowers->bus-lane:inbound|colored": 7,
  "divider:flowers->bus-lane:inbound|colored|typical": 8,
  "divider:flowers->bus-lane:inbound|regular": 49,
  "divider:flowers->bus-lane:inbound|regular|typical": 41,
  "divider:flowers->bus-lane:inbound|shared|typical": 19,
  "divider:flowers->bus-lane:outbound|colored": 9,
  "divider:flowers->bus-lane:outbound|colored|typical": 7,
  "divider:flowers->bus-lane:outbound|regular": 115,
  "divider:flowers->bus-lane:outbound|regular|typical": 64,
  "divider:flowers->bus-lane:outbound|shared|typical": 4,
  "divider:flowers->divider:big-tree": 94,
  "divider:flowers->divider:bollard": 5,
  "divider:flowers->divider:bush": 35,
  "divider:flowers->divider:dome": 2,
  "divider:flowers->divider:flowers": 194,
  "divider:flowers->divider:median": 44,
  "divider:flowers->divider:palm-tree": 13,
  "divider:flowers->divider:planter-box": 9,
  "divider:flowers->divider:planting-strip": 17,
  "divider:flowers->divider:striped-buffer": 20,
  "divider:flowers->drive-lane:inbound|car": 569,
  "divider:flowers->drive-lane:inbound|sharrow": 7,
  "divider:flowers->drive-lane:inbound|truck": 52,
  "divider:flowers->drive-lane:outbound|car": 511,
  "divider:flowers->drive-lane:outbound|sharrow": 36,
  "divider:flowers->drive-lane:outbound|truck": 13087,
  "divider:flowers->flex-zone-curb:empty|right": 1,
  "divider:flowers->flex-zone-curb:sparse|left": 5,
  "divider:flowers->flex-zone-curb:sparse|right": 3,
  "divider:flowers->flex-zone:rideshare|inbound|left": 1,
  "divider:flowers->flex-zone:taxi|inbound|left": 11,
  "divider:flowers->flex-zone:taxi|outbound|left": 1,
  "divider:flowers->food-truck:left": 4,
  "divider:flowers->food-truck:right": 2,
  "divider:flowers->light-rail:inbound|colored": 14,
  "divider:flowers->light-rail:inbound|grass": 3,
  "divider:flowers->light-rail:inbound|regular": 68,
  "divider:flowers->light-rail:outbound|colored": 18,
  "divider:flowers->light-rail:outbound|grass": 6,
  "divider:flowers->light-rail:outbound|regular": 179,
  "divider:flowers->parking-lane:angled-front-left|left": 3,
  "divider:flowers->parking-lane:angled-front-left|right": 1,
  "divider:flowers->parking-lane:inbound|left": 91,
  "divider:flowers->parking-lane:inbound|right": 22,
  "divider:flowers->parking-lane:outbound|left": 58,
  "divider:flowers->parking-lane:outbound|right": 150,
  "divider:flowers->parking-lane:sideways|left": 1,
  "divider:flowers->parking-lane:sideways|right": 2,
  "divider:flowers->parklet:left": 49,
  "divider:flowers->parklet:right": 13,
  "divider:flowers->scooter-drop-zone:left|sidewalk|sparse": 2,
  "divider:flowers->scooter-drop-zone:right|sidewalk|empty": 5,
  "divider:flowers->scooter-drop-zone:right|sidewalk|sparse": 1,
  "divider:flowers->scooter:inbound|green": 1,
  "divider:flowers->scooter:inbound|red": 2,
  "divider:flowers->scooter:inbound|regular": 17,
  "divider:flowers->scooter:outbound|green": 2,
  "divider:flowers->scooter:outbound|regular": 4,
  "divider:flowers->sidewalk-bench:center": 8,
  "divider:flowers->sidewalk-bench:left": 67,
  "divider:flowers->sidewalk-bench:right": 26,
  "divider:flowers->sidewalk-bike-rack:left|road": 1,
  "divider:flowers->sidewalk-bike-rack:left|sidewalk": 27,
  "divider:flowers->sidewalk-bike-rack:left|sidewalk-parallel": 13,
  "divider:flowers->sidewalk-bike-rack:right|sidewalk": 6,
  "divider:flowers->sidewalk-bike-rack:right|sidewalk-parallel": 3,
  "divider:flowers->sidewalk-lamp:both|modern": 83,
  "divider:flowers->sidewalk-lamp:both|pride": 1,
  "divider:flowers->sidewalk-lamp:both|traditional": 97,
  "divider:flowers->sidewalk-lamp:left|modern": 241,
  "divider:flowers->sidewalk-lamp:left|pride": 2,
  "divider:flowers->sidewalk-lamp:left|traditional": 41,
  "divider:flowers->sidewalk-lamp:right|modern": 123,
  "divider:flowers->sidewalk-lamp:right|traditional": 64,
  "divider:flowers->sidewalk-tree:big": 656,
  "divider:flowers->sidewalk-tree:palm-tree": 26,
  "divider:flowers->sidewalk-wayfinding:large": 22,
  "divider:flowers->sidewalk-wayfinding:medium": 19,
  "divider:flowers->sidewalk-wayfinding:small": 4,
  "divider:flowers->sidewalk:dense": 478,
  "divider:flowers->sidewalk:empty": 5,
  "divider:flowers->sidewalk:normal": 190,
  "divider:flowers->sidewalk:sparse": 34,
  "divider:flowers->street-vendor:tarp|concrete": 1,
  "divider:flowers->streetcar:inbound|colored": 5,
  "divider:flowers->streetcar:inbound|grass": 5,
  "divider:flowers->streetcar:inbound|regular": 61,
  "divider:flowers->streetcar:outbound|colored": 16,
  "divider:flowers->streetcar:outbound|grass": 6,
  "divider:flowers->streetcar:outbound|regular": 166,
  "divider:flowers->train:": 1,
  "divider:flowers->transit-shelter:left|light-rail": 19,
  "divider:flowers->transit-shelter:left|street-level": 68,
  "divider:flowers->transit-shelter:right|light-rail": 8,
  "divider:flowers->transit-shelter:right|street-level": 37,
  "divider:flowers->turn-lane:inbound|both": 8,
  "divider:flowers->turn-lane:inbound|left": 40,
  "divider:flowers->turn-lane:inbound|left-straight": 328,
  "divider:flowers->turn-lane:inbound|left|left": 2,
  "divider:flowers->turn-lane:inbound|right": 27,
  "divider:flowers->turn-lane:inbound|right-straight": 32,
  "divider:flowers->turn-lane:inbound|shared": 1,
  "divider:flowers->turn-lane:inbound|straight": 27,
  "divider:flowers->turn-lane:outbound|both": 40,
  "divider:flowers->turn-lane:outbound|left": 1976,
  "divider:flowers->turn-lane:outbound|left-right-straight": 7,
  "divider:flowers->turn-lane:outbound|left-straight": 200,
  "divider:flowers->turn-lane:outbound|left|right": 1,
  "divider:flowers->turn-lane:outbound|left|straight": 1,
  "divider:flowers->turn-lane:outbound|right": 38,
  "divider:flowers->turn-lane:outbound|right-straight": 40,
  "divider:flowers->turn-lane:outbound|shared": 17,
  "divider:flowers->turn-lane:outbound|straight": 150,
  "divider:median->bike-lane:inbound|colored": 53,
  "divider:median->bike-lane:inbound|green|road": 15,
  "divider:median->bike-lane:inbound|green|sidewalk": 7,
  "divider:median->bike-lane:inbound|red|road": 3,
  "divider:median->bike-lane:inbound|red|sidewalk": 5,
  "divider:median->bike-lane:inbound|regular": 125,
  "divider:median->bike-lane:inbound|regular|road": 38,
  "divider:median->bike-lane:inbound|regular|sidewalk": 10,
  "divider:median->bike-lane:outbound|colored": 195,
  "divider:median->bike-lane:outbound|green|road": 45,
  "divider:median->bike-lane:outbound|green|sidewalk": 17,
  "divider:median->bike-lane:outbound|red|road": 6,
  "divider:median->bike-lane:outbound|red|sidewalk": 4,
  "divider:median->bike-lane:outbound|regular": 62,
  "divider:median->bike-lane:outbound|regular|road": 19,
  "divider:median->bike-lane:outbound|regular|sidewalk": 9,
  "divider:median->brt-station:center": 1,
  "divider:median->bus-lane:inbound|colored": 11,
  "divider:median->bus-lane:inbound|colored|typical": 8,
  "divider:median->bus-lane:inbound|regular": 52,
  "divider:median->bus-lane:inbound|regular|typical": 27,
  "divider:median->bus-lane:outbound|colored": 7,
  "divider:median->bus-lane:outbound|colored|typical": 4,
  "divider:median->bus-lane:outbound|regular": 22,
  "divider:median->bus-lane:outbound|regular|typical": 17,
  "divider:median->divider:big-tree": 47,
  "divider:median->divider:bollard": 6,
  "divider:median->divider:bush": 10,
  "divider:median->divider:dome": 1,
  "divider:median->divider:flowers": 59,
  "divider:median->divider:median": 61,
  "divider:median->divider:palm-tree": 10,
  "divider:median->divider:planting-strip": 18,
  "divider:median->divider:striped-buffer": 16,
  "divider:median->drive-lane:inbound|car": 376,
  "divider:median->drive-lane:inbound|sharrow": 2,
  "divider:median->drive-lane:inbound|truck": 13,
  "divider:median->drive-lane:outbound|car": 274,
  "divider:median->drive-lane:outbound|sharrow": 7,
  "divider:median->drive-lane:outbound|truck": 103,
  "divider:median->drive-lane:straight|car": 1,
  "divider:median->flex-zone-curb:sparse|left": 6,
  "divider:median->flex-zone-curb:sparse|right": 1,
  "divider:median->flex-zone:rideshare|inbound|left": 1,
  "divider:median->flex-zone:taxi|inbound|left": 14,
  "divider:median->light-rail:inbound|colored": 6,
  "divider:median->light-rail:inbound|grass": 6,
  "divider:median->light-rail:inbound|regular": 71,
  "divider:median->light-rail:outbound|colored": 8,
  "divider:median->light-rail:outbound|grass": 3,
  "divider:median->light-rail:outbound|regular": 43,
  "divider:median->parking-lane:inbound|left": 83,
  "divider:median->parking-lane:inbound|right": 29,
  "divider:median->parking-lane:outbound|left": 19,
  "divider:median->parking-lane:outbound|right": 18,
  "divider:median->parking-lane:sideways|left": 3,
  "divider:median->parking-lane:sideways|right": 1,
  "divider:median->parklet:left": 18,
  "divider:median->parklet:right": 8,
  "divider:median->sidewalk-bench:center": 4,
  "divider:median->sidewalk-bench:left": 4,
  "divider:median->sidewalk-bench:right": 4,
  "divider:median->sidewalk-bike-rack:left|sidewalk": 5,
  "divider:median->sidewalk-bike-rack:left|sidewalk-parallel": 5,
  "divider:median->sidewalk-bike-rack:right|sidewalk": 1,
  "divider:median->sidewalk-lamp:both|modern": 18,
  "divider:median->sidewalk-lamp:both|pride": 1,
  "divider:median->sidewalk-lamp:both|traditional": 9,
  "divider:median->sidewalk-lamp:left|modern": 47,
  "divider:median->sidewalk-lamp:left|traditional": 73,
  "divider:median->sidewalk-lamp:right|modern": 18,
  "divider:median->sidewalk-lamp:right|traditional": 12,
  "divider:median->sidewalk-tree:big": 102,
  "divider:median->sidewalk-tree:palm-tree": 3,
  "divider:median->sidewalk-wayfinding:large": 8,
  "divider:median->sidewalk-wayfinding:medium": 6,
  "divider:median->sidewalk-wayfinding:small": 3,
  "divider:median->sidewalk:dense": 80,
  "divider:median->sidewalk:empty": 6,
  "divider:median->sidewalk:normal": 12,
  "divider:median->sidewalk:sparse": 7,
  "divider:median->streetcar:inbound|colored": 14,
  "divider:median->streetcar:inbound|grass": 9,
  "divider:median->streetcar:inbound|regular": 49,
  "divider:median->streetcar:outbound|colored": 3,
  "divider:median->streetcar:outbound|regular": 32,
  "divider:median->transit-shelter:left|light-rail": 3,
  "divider:median->transit-shelter:left|street-level": 14,
  "divider:median->transit-shelter:right|light-rail": 3,
  "divider:median->transit-shelter:right|street-level": 12,
  "divider:median->turn-lane:inbound|left": 13,
  "divider:median->turn-lane:inbound|left-right-straight": 1,
  "divider:median->turn-lane:inbound|left-straight": 13,
  "divider:median->turn-lane:inbound|right": 8,
  "divider:median->turn-lane:inbound|right-straight": 14,
  "divider:median->turn-lane:inbound|shared": 1,
  "divider:median->turn-lane:inbound|straight": 6,
  "divider:median->turn-lane:outbound|both": 3,
  "divider:median->turn-lane:outbound|left": 96,
  "divider:median->turn-lane:outbound|left-right-straight": 3,
  "divider:median->turn-lane:outbound|left-straight": 52,
  "divider:median->turn-lane:outbound|right": 8,
  "divider:median->turn-lane:outbound|right-straight": 6,
  "divider:median->turn-lane:outbound|shared": 4,
  "divider:median->turn-lane:outbound|straight": 29,
  "divider:median->turn-lane:straight|left": 1,
  "divider:palm-tree->bike-lane:inbound|colored": 15,
  "divider:palm-tree->bike-lane:inbound|green|road": 2,
  "divider:palm-tree->bike-lane:inbound|green|sidewalk": 1,
  "divider:palm-tree->bike-lane:inbound|red|road": 3,
  "divider:palm-tree->bike-lane:inbound|regular": 21,
  "divider:palm-tree->bike-lane:inbound|regular|road": 5,
  "divider:palm-tree->bike-lane:outbound|colored": 65,
  "divider:palm-tree->bike-lane:outbound|green|road": 47,
  "divider:palm-tree->bike-lane:outbound|green|road|inbound": 1,
  "divider:palm-tree->bike-lane:outbound|green|sidewalk": 1,
  "divider:palm-tree->bike-lane:outbound|red|road": 1,
  "divider:palm-tree->bike-lane:outbound|regular": 11,
  "divider:palm-tree->bike-lane:outbound|regular|road": 4,
  "divider:palm-tree->bike-lane:outbound|regular|sidewalk": 1,
  "divider:palm-tree->bikeshare:left|road": 2,
  "divider:palm-tree->bus-lane:inbound|regular": 3,
  "divider:palm-tree->bus-lane:inbound|regular|typical": 3,
  "divider:palm-tree->bus-lane:outbound|colored": 1,
  "divider:palm-tree->bus-lane:outbound|regular": 11,
  "divider:palm-tree->bus-lane:outbound|regular|typical": 3,
  "divider:palm-tree->bus-lane:outbound|shared|typical": 1,
  "divider:palm-tree->divider:big-tree": 1,
  "divider:palm-tree->divider:bollard": 2,
  "divider:palm-tree->divider:bush": 8,
  "divider:palm-tree->divider:dome": 1,
  "divider:palm-tree->divider:flowers": 17,
  "divider:palm-tree->divider:median": 10,
  "divider:palm-tree->divider:palm-tree": 17,
  "divider:palm-tree->divider:planter-box": 1,
  "divider:palm-tree->divider:planting-strip": 6,
  "divider:palm-tree->divider:striped-buffer": 1,
  "divider:palm-tree->drive-lane:inbound|car": 50,
  "divider:palm-tree->drive-lane:inbound|sharrow": 1,
  "divider:palm-tree->drive-lane:inbound|truck": 7,
  "divider:palm-tree->drive-lane:outbound|car": 112,
  "divider:palm-tree->drive-lane:outbound|sharrow": 8,
  "divider:palm-tree->drive-lane:outbound|truck": 160,
  "divider:palm-tree->flex-zone-curb:sparse|right": 1,
  "divider:palm-tree->food-truck:right": 1,
  "divider:palm-tree->light-rail:inbound|colored": 5,
  "divider:palm-tree->light-rail:inbound|regular": 11,
  "divider:palm-tree->light-rail:outbound|colored": 6,
  "divider:palm-tree->light-rail:outbound|grass": 1,
  "divider:palm-tree->light-rail:outbound|regular": 21,
  "divider:palm-tree->parking-lane:inbound|left": 8,
  "divider:palm-tree->parking-lane:inbound|right": 2,
  "divider:palm-tree->parking-lane:outbound|left": 8,
  "divider:palm-tree->parking-lane:outbound|right": 8,
  "divider:palm-tree->parking-lane:sideways|left": 1,
  "divider:palm-tree->parklet:left": 3,
  "divider:palm-tree->parklet:right": 2,
  "divider:palm-tree->scooter-drop-zone:right|sidewalk|empty": 1,
  "divider:palm-tree->scooter:inbound|regular": 3,
  "divider:palm-tree->scooter:outbound|green": 1,
  "divider:palm-tree->sidewalk-bench:center": 3,
  "divider:palm-tree->sidewalk-bench:left": 16,
  "divider:palm-tree->sidewalk-bench:right": 1,
  "divider:palm-tree->sidewalk-bike-rack:left|sidewalk": 5,
  "divider:palm-tree->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "divider:palm-tree->sidewalk-lamp:both|modern": 8,
  "divider:palm-tree->sidewalk-lamp:both|traditional": 14,
  "divider:palm-tree->sidewalk-lamp:left|modern": 16,
  "divider:palm-tree->sidewalk-lamp:left|traditional": 2,
  "divider:palm-tree->sidewalk-lamp:right|modern": 21,
  "divider:palm-tree->sidewalk-lamp:right|pride": 2,
  "divider:palm-tree->sidewalk-lamp:right|traditional": 12,
  "divider:palm-tree->sidewalk-tree:big": 12,
  "divider:palm-tree->sidewalk-tree:palm-tree": 7,
  "divider:palm-tree->sidewalk-wayfinding:large": 3,
  "divider:palm-tree->sidewalk-wayfinding:medium": 7,
  "divider:palm-tree->sidewalk-wayfinding:small": 3,
  "divider:palm-tree->sidewalk:dense": 26,
  "divider:palm-tree->sidewalk:empty": 1,
  "divider:palm-tree->sidewalk:normal": 8,
  "divider:palm-tree->streetcar:inbound|grass": 1,
  "divider:palm-tree->streetcar:inbound|regular": 12,
  "divider:palm-tree->streetcar:outbound|colored": 1,
  "divider:palm-tree->streetcar:outbound|grass": 2,
  "divider:palm-tree->streetcar:outbound|regular": 12,
  "divider:palm-tree->transit-shelter:left|light-rail": 9,
  "divider:palm-tree->transit-shelter:left|street-level": 6,
  "divider:palm-tree->turn-lane:inbound|both": 1,
  "divider:palm-tree->turn-lane:inbound|left": 1,
  "divider:palm-tree->turn-lane:inbound|left-straight": 9,
  "divider:palm-tree->turn-lane:inbound|right": 2,
  "divider:palm-tree->turn-lane:inbound|right-straight": 1,
  "divider:palm-tree->turn-lane:inbound|straight": 1,
  "divider:palm-tree->turn-lane:outbound|both": 9,
  "divider:palm-tree->turn-lane:outbound|left": 134,
  "divider:palm-tree->turn-lane:outbound|left-right-straight": 1,
  "divider:palm-tree->turn-lane:outbound|left-straight": 132,
  "divider:palm-tree->turn-lane:outbound|right": 1,
  "divider:palm-tree->turn-lane:outbound|right-straight": 7,
  "divider:palm-tree->turn-lane:outbound|shared": 1,
  "divider:palm-tree->turn-lane:outbound|straight": 25,
  "divider:planter-box->bike-lane:green|outbound|sidewalk": 1,
  "divider:planter-box->bike-lane:green|sidewalk": 1,
  "divider:planter-box->bike-lane:inbound": 2,
  "divider:planter-box->bike-lane:inbound|green|road": 31,
  "divider:planter-box->bike-lane:inbound|green|sidewalk": 5,
  "divider:planter-box->bike-lane:inbound|red|road": 3,
  "divider:planter-box->bike-lane:inbound|regular|road": 47,
  "divider:planter-box->bike-lane:inbound|regular|sidewalk": 3,
  "divider:planter-box->bike-lane:inbound|road|green": 1,
  "divider:planter-box->bike-lane:outbound|green|road": 13518,
  "divider:planter-box->bike-lane:outbound|green|road|inbound": 15,
  "divider:planter-box->bike-lane:outbound|green|sidewalk": 16,
  "divider:planter-box->bike-lane:outbound|green|sidewalk|inbound": 2,
  "divider:planter-box->bike-lane:outbound|red|road": 23,
  "divider:planter-box->bike-lane:outbound|red|road|inbound": 4,
  "divider:planter-box->bike-lane:outbound|red|sidewalk": 4,
  "divider:planter-box->bike-lane:outbound|red|sidewalk|inbound": 6,
  "divider:planter-box->bike-lane:outbound|regular|road": 22,
  "divider:planter-box->bike-lane:outbound|regular|road|inbound": 5,
  "divider:planter-box->bike-lane:outbound|regular|sidewalk": 4,
  "divider:planter-box->bike-lane:outbound|regular|sidewalk|inbound": 6,
  "divider:planter-box->bike-lane:red|inbound|sidewalk": 1,
  "divider:planter-box->bike-lane:regular": 1,
  "divider:planter-box->bike-lane:regular|red|sidewalk": 1,
  "divider:planter-box->bike-lane:road|red|outbound": 1,
  "divider:planter-box->bikeshare:left|road": 2,
  "divider:planter-box->bus-lane:inbound|colored|typical": 2,
  "divider:planter-box->bus-lane:inbound|regular|typical": 5,
  "divider:planter-box->bus-lane:inbound|shared|typical": 9,
  "divider:planter-box->bus-lane:outbound|regular|typical": 6,
  "divider:planter-box->divider:big-tree": 3,
  "divider:planter-box->divider:bollard": 1,
  "divider:planter-box->divider:bush": 25,
  "divider:planter-box->divider:flowers": 3,
  "divider:planter-box->divider:palm-tree": 2,
  "divider:planter-box->divider:planter-box": 5,
  "divider:planter-box->divider:planting-strip": 1,
  "divider:planter-box->drive-lane:inbound|car": 74,
  "divider:planter-box->drive-lane:inbound|truck": 2,
  "divider:planter-box->drive-lane:outbound|car": 41,
  "divider:planter-box->drive-lane:outbound|sharrow": 1,
  "divider:planter-box->drive-lane:outbound|truck": 1,
  "divider:planter-box->flex-zone-curb:sparse|left": 2,
  "divider:planter-box->flex-zone-curb:sparse|right": 2,
  "divider:planter-box->flex-zone:rideshare|inbound|left": 1,
  "divider:planter-box->flex-zone:taxi|inbound|left": 1,
  "divider:planter-box->flex-zone:taxi|outbound|left": 3,
  "divider:planter-box->light-rail:inbound|grass": 3,
  "divider:planter-box->light-rail:inbound|regular": 3,
  "divider:planter-box->light-rail:outbound|colored": 1,
  "divider:planter-box->light-rail:outbound|grass": 1,
  "divider:planter-box->light-rail:outbound|regular": 6,
  "divider:planter-box->outdoor-dining:occupied|sidewalk": 1,
  "divider:planter-box->parking-lane:inbound|left": 16,
  "divider:planter-box->parking-lane:inbound|right": 3,
  "divider:planter-box->parking-lane:outbound|left": 925,
  "divider:planter-box->parking-lane:outbound|right": 39,
  "divider:planter-box->parklet:right": 3,
  "divider:planter-box->scooter-drop-zone:right|sidewalk|empty": 1,
  "divider:planter-box->scooter-drop-zone:right|sidewalk|sparse": 1,
  "divider:planter-box->scooter:inbound|red": 1,
  "divider:planter-box->scooter:inbound|regular": 3,
  "divider:planter-box->scooter:outbound|green": 1,
  "divider:planter-box->scooter:outbound|regular": 3,
  "divider:planter-box->sidewalk-bench:left": 3,
  "divider:planter-box->sidewalk-bench:right": 3,
  "divider:planter-box->sidewalk-bike-rack:left|sidewalk": 8,
  "divider:planter-box->sidewalk-lamp:both|modern": 6,
  "divider:planter-box->sidewalk-lamp:both|traditional": 6,
  "divider:planter-box->sidewalk-lamp:left|modern": 545,
  "divider:planter-box->sidewalk-lamp:left|pride": 1,
  "divider:planter-box->sidewalk-lamp:left|traditional": 13,
  "divider:planter-box->sidewalk-lamp:right|modern": 7,
  "divider:planter-box->sidewalk-lamp:right|pride": 1,
  "divider:planter-box->sidewalk-lamp:right|traditional": 8,
  "divider:planter-box->sidewalk-tree:big": 71,
  "divider:planter-box->sidewalk-tree:palm-tree": 9,
  "divider:planter-box->sidewalk-wayfinding:large": 2,
  "divider:planter-box->sidewalk-wayfinding:small": 2,
  "divider:planter-box->sidewalk:dense": 19,
  "divider:planter-box->sidewalk:normal": 30,
  "divider:planter-box->sidewalk:sparse": 2,
  "divider:planter-box->streetcar:inbound|grass": 3,
  "divider:planter-box->streetcar:inbound|regular": 7,
  "divider:planter-box->streetcar:outbound|colored": 6,
  "divider:planter-box->streetcar:outbound|grass": 3,
  "divider:planter-box->streetcar:outbound|regular": 3,
  "divider:planter-box->transit-shelter:left|street-level": 4,
  "divider:planter-box->transit-shelter:right|street-level": 4,
  "divider:planter-box->turn-lane:inbound|left": 5,
  "divider:planter-box->turn-lane:inbound|left-right-straight": 2,
  "divider:planter-box->turn-lane:inbound|left-straight": 2,
  "divider:planter-box->turn-lane:inbound|right-straight": 1,
  "divider:planter-box->turn-lane:inbound|straight": 1,
  "divider:planter-box->turn-lane:left|inbound": 1,
  "divider:planter-box->turn-lane:left|outbound": 1,
  "divider:planter-box->turn-lane:outbound|both": 4,
  "divider:planter-box->turn-lane:outbound|left-right-straight": 9,
  "divider:planter-box->turn-lane:outbound|left-straight": 50,
  "divider:planter-box->turn-lane:outbound|right-straight": 35,
  "divider:planter-box->turn-lane:outbound|straight": 31,
  "divider:planter-box->turn-lane:shared|outbound": 1,
  "divider:planting-strip->bike-lane:green|outbound|sidewalk": 1,
  "divider:planting-strip->bike-lane:inbound|colored": 11,
  "divider:planting-strip->bike-lane:inbound|green|road": 24,
  "divider:planting-strip->bike-lane:inbound|green|sidewalk": 1,
  "divider:planting-strip->bike-lane:inbound|red|road": 3,
  "divider:planting-strip->bike-lane:inbound|red|sidewalk": 1,
  "divider:planting-strip->bike-lane:inbound|regular": 22,
  "divider:planting-strip->bike-lane:inbound|regular|road": 40,
  "divider:planting-strip->bike-lane:inbound|regular|sidewalk": 6,
  "divider:planting-strip->bike-lane:outbound|colored": 33,
  "divider:planting-strip->bike-lane:outbound|green|road": 59,
  "divider:planting-strip->bike-lane:outbound|green|sidewalk": 9,
  "divider:planting-strip->bike-lane:outbound|red|road": 1,
  "divider:planting-strip->bike-lane:outbound|red|road|inbound": 1,
  "divider:planting-strip->bike-lane:outbound|red|sidewalk": 3,
  "divider:planting-strip->bike-lane:outbound|regular": 9,
  "divider:planting-strip->bike-lane:outbound|regular|road": 16,
  "divider:planting-strip->bike-lane:outbound|regular|sidewalk": 6,
  "divider:planting-strip->bikeshare:left|road": 1,
  "divider:planting-strip->bus-lane:inbound|colored|typical": 1,
  "divider:planting-strip->bus-lane:inbound|regular": 13,
  "divider:planting-strip->bus-lane:inbound|regular|typical": 8,
  "divider:planting-strip->bus-lane:inbound|shared|typical": 4,
  "divider:planting-strip->bus-lane:outbound|colored": 1,
  "divider:planting-strip->bus-lane:outbound|colored|typical": 1,
  "divider:planting-strip->bus-lane:outbound|regular": 4,
  "divider:planting-strip->bus-lane:outbound|regular|typical": 3,
  "divider:planting-strip->bus-lane:outbound|shared|typical": 1,
  "divider:planting-strip->divider:big-tree": 40,
  "divider:planting-strip->divider:bollard": 5,
  "divider:planting-strip->divider:bush": 26,
  "divider:planting-strip->divider:dome": 2,
  "divider:planting-strip->divider:flowers": 25,
  "divider:planting-strip->divider:median": 19,
  "divider:planting-strip->divider:palm-tree": 9,
  "divider:planting-strip->divider:planter-box": 1,
  "divider:planting-strip->divider:planting-strip": 224,
  "divider:planting-strip->divider:striped-buffer": 16,
  "divider:planting-strip->drive-lane:inbound|car": 122,
  "divider:planting-strip->drive-lane:inbound|sharrow": 1,
  "divider:planting-strip->drive-lane:inbound|truck": 11,
  "divider:planting-strip->drive-lane:outbound|car": 115,
  "divider:planting-strip->drive-lane:outbound|sharrow": 2,
  "divider:planting-strip->drive-lane:outbound|truck": 34,
  "divider:planting-strip->flex-zone-curb:sparse|left": 2,
  "divider:planting-strip->flex-zone:taxi|inbound|left": 1,
  "divider:planting-strip->flex-zone:taxi|outbound|right": 1,
  "divider:planting-strip->light-rail:inbound|grass": 1,
  "divider:planting-strip->light-rail:inbound|regular": 16,
  "divider:planting-strip->light-rail:outbound|colored": 1,
  "divider:planting-strip->light-rail:outbound|regular": 19,
  "divider:planting-strip->parking-lane:angled-front-left|right": 1,
  "divider:planting-strip->parking-lane:angled-rear-left|left": 1,
  "divider:planting-strip->parking-lane:angled-rear-right|left": 1,
  "divider:planting-strip->parking-lane:inbound|left": 40,
  "divider:planting-strip->parking-lane:inbound|right": 4,
  "divider:planting-strip->parking-lane:outbound|left": 9,
  "divider:planting-strip->parking-lane:outbound|right": 5,
  "divider:planting-strip->parking-lane:sideways|left": 1,
  "divider:planting-strip->parklet:left": 1,
  "divider:planting-strip->scooter:inbound|regular": 2,
  "divider:planting-strip->scooter:outbound|regular": 1,
  "divider:planting-strip->sidewalk-bench:center": 8,
  "divider:planting-strip->sidewalk-bench:left": 11,
  "divider:planting-strip->sidewalk-bench:right": 9,
  "divider:planting-strip->sidewalk-bike-rack:left|sidewalk": 2,
  "divider:planting-strip->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "divider:planting-strip->sidewalk-bike-rack:right|sidewalk": 1,
  "divider:planting-strip->sidewalk-lamp:both|modern": 13,
  "divider:planting-strip->sidewalk-lamp:both|pride": 1,
  "divider:planting-strip->sidewalk-lamp:both|traditional": 19,
  "divider:planting-strip->sidewalk-lamp:left|modern": 28,
  "divider:planting-strip->sidewalk-lamp:left|traditional": 7,
  "divider:planting-strip->sidewalk-lamp:right|modern": 27,
  "divider:planting-strip->sidewalk-lamp:right|traditional": 11,
  "divider:planting-strip->sidewalk-tree:big": 139,
  "divider:planting-strip->sidewalk-tree:palm-tree": 11,
  "divider:planting-strip->sidewalk-wayfinding:medium": 1,
  "divider:planting-strip->sidewalk-wayfinding:small": 2,
  "divider:planting-strip->sidewalk:dense": 182,
  "divider:planting-strip->sidewalk:empty": 4,
  "divider:planting-strip->sidewalk:normal": 52,
  "divider:planting-strip->sidewalk:sparse": 22,
  "divider:planting-strip->streetcar:inbound|regular": 10,
  "divider:planting-strip->streetcar:outbound|colored": 7,
  "divider:planting-strip->streetcar:outbound|grass": 1,
  "divider:planting-strip->streetcar:outbound|regular": 7,
  "divider:planting-strip->transit-shelter:left|light-rail": 2,
  "divider:planting-strip->transit-shelter:left|street-level": 4,
  "divider:planting-strip->transit-shelter:right|street-level": 2,
  "divider:planting-strip->turn-lane:inbound|both": 1,
  "divider:planting-strip->turn-lane:inbound|left": 2,
  "divider:planting-strip->turn-lane:inbound|left-straight": 2,
  "divider:planting-strip->turn-lane:inbound|straight": 3,
  "divider:planting-strip->turn-lane:outbound|left": 25,
  "divider:planting-strip->turn-lane:outbound|left-right-straight": 2,
  "divider:planting-strip->turn-lane:outbound|left-straight": 25,
  "divider:planting-strip->turn-lane:outbound|right": 1,
  "divider:planting-strip->turn-lane:outbound|right-straight": 6,
  "divider:planting-strip->turn-lane:outbound|straight": 13,
  "divider:striped-buffer->bike-lane:inbound|colored": 41,
  "divider:striped-buffer->bike-lane:inbound|green|road": 14,
  "divider:striped-buffer->bike-lane:inbound|green|sidewalk": 1,
  "divider:striped-buffer->bike-lane:inbound|red|road": 5,
  "divider:striped-buffer->bike-lane:inbound|regular": 24,
  "divider:striped-buffer->bike-lane:inbound|regular|road": 51,
  "divider:striped-buffer->bike-lane:outbound|colored": 127,
  "divider:striped-buffer->bike-lane:outbound|green|road": 61,
  "divider:striped-buffer->bike-lane:outbound|green|sidewalk": 1,
  "divider:striped-buffer->bike-lane:outbound|red|road": 11,
  "divider:striped-buffer->bike-lane:outbound|red|sidewalk": 2,
  "divider:striped-buffer->bike-lane:outbound|regular": 45,
  "divider:striped-buffer->bike-lane:outbound|regular|road": 55,
  "divider:striped-buffer->bike-lane:outbound|regular|road|inbound": 1,
  "divider:striped-buffer->bus-lane:inbound|colored": 3,
  "divider:striped-buffer->bus-lane:inbound|colored|typical": 5,
  "divider:striped-buffer->bus-lane:inbound|regular": 12,
  "divider:striped-buffer->bus-lane:inbound|regular|typical": 15,
  "divider:striped-buffer->bus-lane:inbound|shared|typical": 2,
  "divider:striped-buffer->bus-lane:outbound|colored": 5,
  "divider:striped-buffer->bus-lane:outbound|colored|typical": 3,
  "divider:striped-buffer->bus-lane:outbound|regular": 9,
  "divider:striped-buffer->bus-lane:outbound|regular|typical": 14,
  "divider:striped-buffer->bus-lane:outbound|shared|typical": 6,
  "divider:striped-buffer->divider:big-tree": 17,
  "divider:striped-buffer->divider:bollard": 9,
  "divider:striped-buffer->divider:bush": 19,
  "divider:striped-buffer->divider:dome": 1,
  "divider:striped-buffer->divider:flowers": 25,
  "divider:striped-buffer->divider:median": 16,
  "divider:striped-buffer->divider:palm-tree": 2,
  "divider:striped-buffer->divider:planter-box": 1,
  "divider:striped-buffer->divider:planting-strip": 21,
  "divider:striped-buffer->divider:striped-buffer": 9,
  "divider:striped-buffer->drive-lane:car": 1,
  "divider:striped-buffer->drive-lane:inbound|car": 295,
  "divider:striped-buffer->drive-lane:inbound|sharrow": 1,
  "divider:striped-buffer->drive-lane:inbound|truck": 20,
  "divider:striped-buffer->drive-lane:outbound|car": 181,
  "divider:striped-buffer->drive-lane:outbound|sharrow": 9,
  "divider:striped-buffer->drive-lane:outbound|truck": 60,
  "divider:striped-buffer->flex-zone-curb:sparse|left": 1,
  "divider:striped-buffer->light-rail:inbound|colored": 2,
  "divider:striped-buffer->light-rail:inbound|regular": 7,
  "divider:striped-buffer->light-rail:outbound|colored": 7,
  "divider:striped-buffer->light-rail:outbound|regular": 13,
  "divider:striped-buffer->outdoor-dining:occupied|road": 1,
  "divider:striped-buffer->parking-lane:angled-front-left|left": 1,
  "divider:striped-buffer->parking-lane:angled-front-left|right": 1,
  "divider:striped-buffer->parking-lane:inbound|left": 69,
  "divider:striped-buffer->parking-lane:inbound|right": 23,
  "divider:striped-buffer->parking-lane:outbound|left": 14,
  "divider:striped-buffer->parking-lane:outbound|right": 37,
  "divider:striped-buffer->parking-lane:sideways|left": 3,
  "divider:striped-buffer->parking-lane:sideways|right": 6,
  "divider:striped-buffer->parklet:left": 4,
  "divider:striped-buffer->parklet:right": 2,
  "divider:striped-buffer->scooter-drop-zone:right|sidewalk|empty": 1,
  "divider:striped-buffer->scooter:inbound|regular": 1,
  "divider:striped-buffer->sidewalk-bench:center": 1,
  "divider:striped-buffer->sidewalk-bench:right": 1,
  "divider:striped-buffer->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "divider:striped-buffer->sidewalk-bike-rack:right|sidewalk": 1,
  "divider:striped-buffer->sidewalk-bike-rack:right|sidewalk-parallel": 2,
  "divider:striped-buffer->sidewalk-lamp:both|modern": 8,
  "divider:striped-buffer->sidewalk-lamp:both|traditional": 3,
  "divider:striped-buffer->sidewalk-lamp:left|modern": 21,
  "divider:striped-buffer->sidewalk-lamp:left|pride": 1,
  "divider:striped-buffer->sidewalk-lamp:left|traditional": 4,
  "divider:striped-buffer->sidewalk-lamp:right|modern": 5,
  "divider:striped-buffer->sidewalk-lamp:right|traditional": 2,
  "divider:striped-buffer->sidewalk-tree:big": 30,
  "divider:striped-buffer->sidewalk-tree:palm-tree": 3,
  "divider:striped-buffer->sidewalk-wayfinding:small": 3,
  "divider:striped-buffer->sidewalk:dense": 22,
  "divider:striped-buffer->sidewalk:normal": 9,
  "divider:striped-buffer->sidewalk:sparse": 9,
  "divider:striped-buffer->streetcar:inbound|colored": 2,
  "divider:striped-buffer->streetcar:inbound|regular": 10,
  "divider:striped-buffer->streetcar:outbound|colored": 1,
  "divider:striped-buffer->streetcar:outbound|regular": 9,
  "divider:striped-buffer->transit-shelter:left|street-level": 3,
  "divider:striped-buffer->transit-shelter:right|light-rail": 1,
  "divider:striped-buffer->transit-shelter:right|street-level": 4,
  "divider:striped-buffer->turn-lane:inbound|left": 9,
  "divider:striped-buffer->turn-lane:inbound|left-straight": 25,
  "divider:striped-buffer->turn-lane:inbound|right": 8,
  "divider:striped-buffer->turn-lane:inbound|right-straight": 5,
  "divider:striped-buffer->turn-lane:inbound|straight": 12,
  "divider:striped-buffer->turn-lane:outbound|both": 4,
  "divider:striped-buffer->turn-lane:outbound|left": 57,
  "divider:striped-buffer->turn-lane:outbound|left-right-straight": 3,
  "divider:striped-buffer->turn-lane:outbound|left-straight": 90,
  "divider:striped-buffer->turn-lane:outbound|right": 4,
  "divider:striped-buffer->turn-lane:outbound|right-straight": 4,
  "divider:striped-buffer->turn-lane:outbound|shared": 1,
  "divider:striped-buffer->turn-lane:outbound|straight": 35,
  "drive-lane:car->divider:bollard": 1,
  "drive-lane:car->divider:bush": 2,
  "drive-lane:car->divider:flowers": 2,
  "drive-lane:car->drive-lane:car|car": 1,
  "drive-lane:car->parking-lane:outbound|right": 1,
  "drive-lane:car->sidewalk-tree:big": 1,
  "drive-lane:car->sidewalk:sparse": 1,
  "drive-lane:car->streetcar:inbound|regular": 1,
  "drive-lane:car|car->drive-lane:car": 1,
  "drive-lane:car|car->turn-lane:outbound|straight": 1,
  "drive-lane:car|outbound->divider:bush": 1,
  "drive-lane:car|outbound->divider:flowers": 1,
  "drive-lane:inbound->divider:bush": 1,
  "drive-lane:inbound->drive-lane:outbound": 2,
  "drive-lane:inbound->light-rail:inbound|grass": 1,
  "drive-lane:inbound->sidewalk:dense": 1,
  "drive-lane:inbound|->parking-lane:inbound|right": 1,
  "drive-lane:inbound|car->bike-lane:inbound|colored": 68,
  "drive-lane:inbound|car->bike-lane:inbound|green|road": 26,
  "drive-lane:inbound|car->bike-lane:inbound|green|sidewalk": 7,
  "drive-lane:inbound|car->bike-lane:inbound|red|road": 14,
  "drive-lane:inbound|car->bike-lane:inbound|red|sidewalk": 5,
  "drive-lane:inbound|car->bike-lane:inbound|regular": 117,
  "drive-lane:inbound|car->bike-lane:inbound|regular|road": 136,
  "drive-lane:inbound|car->bike-lane:inbound|regular|sidewalk": 1,
  "drive-lane:inbound|car->bike-lane:outbound|colored": 68,
  "drive-lane:inbound|car->bike-lane:outbound|green|road": 50,
  "drive-lane:inbound|car->bike-lane:outbound|green|road|inbound": 1,
  "drive-lane:inbound|car->bike-lane:outbound|green|sidewalk": 6,
  "drive-lane:inbound|car->bike-lane:outbound|red|road": 5,
  "drive-lane:inbound|car->bike-lane:outbound|red|sidewalk": 2,
  "drive-lane:inbound|car->bike-lane:outbound|regular": 13,
  "drive-lane:inbound|car->bike-lane:outbound|regular|road": 29,
  "drive-lane:inbound|car->bike-lane:outbound|regular|sidewalk": 3,
  "drive-lane:inbound|car->bikeshare:left|road": 3,
  "drive-lane:inbound|car->brt-lane:inbound|regular": 1,
  "drive-lane:inbound|car->bus-lane:inbound|colored": 66,
  "drive-lane:inbound|car->bus-lane:inbound|colored|typical": 28,
  "drive-lane:inbound|car->bus-lane:inbound|regular": 313,
  "drive-lane:inbound|car->bus-lane:inbound|regular|typical": 190,
  "drive-lane:inbound|car->bus-lane:inbound|shared|typical": 1004,
  "drive-lane:inbound|car->bus-lane:outbound": 1,
  "drive-lane:inbound|car->bus-lane:outbound|colored": 1,
  "drive-lane:inbound|car->bus-lane:outbound|colored|typical": 6,
  "drive-lane:inbound|car->bus-lane:outbound|regular": 21,
  "drive-lane:inbound|car->bus-lane:outbound|regular|typical": 25,
  "drive-lane:inbound|car->bus-lane:outbound|shared|typical": 4,
  "drive-lane:inbound|car->divider:big-tree": 895,
  "drive-lane:inbound|car->divider:bollard": 237,
  "drive-lane:inbound|car->divider:bush": 13548,
  "drive-lane:inbound|car->divider:dome": 167,
  "drive-lane:inbound|car->divider:flowers": 3117,
  "drive-lane:inbound|car->divider:median": 510,
  "drive-lane:inbound|car->divider:palm-tree": 380,
  "drive-lane:inbound|car->divider:planter-box": 201,
  "drive-lane:inbound|car->divider:planting-strip": 178,
  "drive-lane:inbound|car->divider:striped-buffer": 320,
  "drive-lane:inbound|car->drive-lane:inbound": 1,
  "drive-lane:inbound|car->drive-lane:inbound|car": 4235,
  "drive-lane:inbound|car->drive-lane:inbound|sharrow": 8,
  "drive-lane:inbound|car->drive-lane:inbound|truck": 169,
  "drive-lane:inbound|car->drive-lane:outbound|car": 2245,
  "drive-lane:inbound|car->drive-lane:outbound|sharrow": 12,
  "drive-lane:inbound|car->drive-lane:outbound|truck": 346,
  "drive-lane:inbound|car->drive-lane:right-straight|car": 2,
  "drive-lane:inbound|car->flex-zone-curb:sparse|left": 15,
  "drive-lane:inbound|car->flex-zone-curb:sparse|right": 8,
  "drive-lane:inbound|car->flex-zone:rideshare|inbound|left": 2,
  "drive-lane:inbound|car->flex-zone:rideshare|inbound|right": 1,
  "drive-lane:inbound|car->flex-zone:taxi|inbound|left": 13,
  "drive-lane:inbound|car->flex-zone:taxi|inbound|right": 8,
  "drive-lane:inbound|car->food-truck:left": 27,
  "drive-lane:inbound|car->food-truck:right": 1,
  "drive-lane:inbound|car->light-rail:inbound|colored": 47,
  "drive-lane:inbound|car->light-rail:inbound|grass": 15,
  "drive-lane:inbound|car->light-rail:inbound|regular": 284,
  "drive-lane:inbound|car->light-rail:outbound|colored": 7,
  "drive-lane:inbound|car->light-rail:outbound|regular": 25,
  "drive-lane:inbound|car->parking-lane:angled-front-right|right": 16,
  "drive-lane:inbound|car->parking-lane:angled-rear-right|right": 3,
  "drive-lane:inbound|car->parking-lane:inbound": 1,
  "drive-lane:inbound|car->parking-lane:inbound|left": 97,
  "drive-lane:inbound|car->parking-lane:inbound|right": 453,
  "drive-lane:inbound|car->parking-lane:outbound|left": 11,
  "drive-lane:inbound|car->parking-lane:outbound|right": 312,
  "drive-lane:inbound|car->parking-lane:sideways|left": 1,
  "drive-lane:inbound|car->parking-lane:sideways|right": 20,
  "drive-lane:inbound|car->parklet:left": 20,
  "drive-lane:inbound|car->parklet:right": 62,
  "drive-lane:inbound|car->scooter-drop-zone:right|sidewalk|empty": 2,
  "drive-lane:inbound|car->scooter:inbound|green": 1,
  "drive-lane:inbound|car->scooter:inbound|regular": 3,
  "drive-lane:inbound|car->sidewalk-bench:center": 2,
  "drive-lane:inbound|car->sidewalk-bench:left": 12,
  "drive-lane:inbound|car->sidewalk-bench:right": 6,
  "drive-lane:inbound|car->sidewalk-bike-rack:left|road": 1,
  "drive-lane:inbound|car->sidewalk-bike-rack:left|sidewalk": 6,
  "drive-lane:inbound|car->sidewalk-bike-rack:left|sidewalk-parallel": 10,
  "drive-lane:inbound|car->sidewalk-bike-rack:right|sidewalk": 1,
  "drive-lane:inbound|car->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "drive-lane:inbound|car->sidewalk-lamp:both|modern": 147,
  "drive-lane:inbound|car->sidewalk-lamp:both|pride": 4,
  "drive-lane:inbound|car->sidewalk-lamp:both|traditional": 82,
  "drive-lane:inbound|car->sidewalk-lamp:left|modern": 362,
  "drive-lane:inbound|car->sidewalk-lamp:left|pride": 2,
  "drive-lane:inbound|car->sidewalk-lamp:left|traditional": 96,
  "drive-lane:inbound|car->sidewalk-lamp:right|modern": 40,
  "drive-lane:inbound|car->sidewalk-lamp:right|traditional": 11,
  "drive-lane:inbound|car->sidewalk-tree:big": 829,
  "drive-lane:inbound|car->sidewalk-tree:palm-tree": 30,
  "drive-lane:inbound|car->sidewalk-wayfinding:large": 17,
  "drive-lane:inbound|car->sidewalk-wayfinding:medium": 4,
  "drive-lane:inbound|car->sidewalk-wayfinding:small": 6,
  "drive-lane:inbound|car->sidewalk:dense": 219,
  "drive-lane:inbound|car->sidewalk:empty": 10,
  "drive-lane:inbound|car->sidewalk:normal": 75,
  "drive-lane:inbound|car->sidewalk:sparse": 14,
  "drive-lane:inbound|car->street-vendor:table|concrete": 2,
  "drive-lane:inbound|car->streetcar:inbound|colored": 48,
  "drive-lane:inbound|car->streetcar:inbound|grass": 7,
  "drive-lane:inbound|car->streetcar:inbound|regular": 258,
  "drive-lane:inbound|car->streetcar:outbound|colored": 4,
  "drive-lane:inbound|car->streetcar:outbound|grass": 2,
  "drive-lane:inbound|car->streetcar:outbound|regular": 35,
  "drive-lane:inbound|car->temporary:jersey-barrier-concrete": 1,
  "drive-lane:inbound|car->transit-shelter:left|light-rail": 58,
  "drive-lane:inbound|car->transit-shelter:left|street-level": 112,
  "drive-lane:inbound|car->transit-shelter:right|light-rail": 3,
  "drive-lane:inbound|car->transit-shelter:right|street-level": 51,
  "drive-lane:inbound|car->turn-lane:both": 1,
  "drive-lane:inbound|car->turn-lane:inbound|both": 9,
  "drive-lane:inbound|car->turn-lane:inbound|left": 91,
  "drive-lane:inbound|car->turn-lane:inbound|left-right-straight": 4,
  "drive-lane:inbound|car->turn-lane:inbound|left-straight": 19,
  "drive-lane:inbound|car->turn-lane:inbound|right": 566,
  "drive-lane:inbound|car->turn-lane:inbound|right-straight": 195,
  "drive-lane:inbound|car->turn-lane:inbound|shared": 238,
  "drive-lane:inbound|car->turn-lane:inbound|straight": 84,
  "drive-lane:inbound|car->turn-lane:left-right-straight|inbound": 1,
  "drive-lane:inbound|car->turn-lane:left-right-straight|outbound": 1,
  "drive-lane:inbound|car->turn-lane:left-straight": 1,
  "drive-lane:inbound|car->turn-lane:outbound|both": 12,
  "drive-lane:inbound|car->turn-lane:outbound|left": 373,
  "drive-lane:inbound|car->turn-lane:outbound|left-right-straight": 14,
  "drive-lane:inbound|car->turn-lane:outbound|left-straight": 571,
  "drive-lane:inbound|car->turn-lane:outbound|right": 23,
  "drive-lane:inbound|car->turn-lane:outbound|right-straight": 41,
  "drive-lane:inbound|car->turn-lane:outbound|shared": 108,
  "drive-lane:inbound|car->turn-lane:outbound|straight": 368,
  "drive-lane:inbound|car->turn-lane:right|outbound": 2,
  "drive-lane:inbound|car->turn-lane:straight": 2,
  "drive-lane:inbound|car->utilities:left": 1,
  "drive-lane:inbound|pedestrian->divider:bush": 1,
  "drive-lane:inbound|sharrow->bike-lane:inbound|colored": 1,
  "drive-lane:inbound|sharrow->bike-lane:inbound|regular": 2,
  "drive-lane:inbound|sharrow->bike-lane:inbound|regular|road": 1,
  "drive-lane:inbound|sharrow->bike-lane:outbound|colored": 3,
  "drive-lane:inbound|sharrow->bike-lane:outbound|regular": 3,
  "drive-lane:inbound|sharrow->bike-lane:outbound|regular|road": 3,
  "drive-lane:inbound|sharrow->bus-lane:inbound|colored": 2,
  "drive-lane:inbound|sharrow->bus-lane:inbound|regular": 1,
  "drive-lane:inbound|sharrow->bus-lane:inbound|regular|typical": 1,
  "drive-lane:inbound|sharrow->bus-lane:inbound|shared|typical": 1,
  "drive-lane:inbound|sharrow->bus-lane:outbound|regular": 1,
  "drive-lane:inbound|sharrow->bus-lane:outbound|shared|typical": 2,
  "drive-lane:inbound|sharrow->divider:big-tree": 9,
  "drive-lane:inbound|sharrow->divider:bollard": 5,
  "drive-lane:inbound|sharrow->divider:bush": 8,
  "drive-lane:inbound|sharrow->divider:dome": 5,
  "drive-lane:inbound|sharrow->divider:flowers": 28,
  "drive-lane:inbound|sharrow->divider:median": 3,
  "drive-lane:inbound|sharrow->divider:palm-tree": 10,
  "drive-lane:inbound|sharrow->divider:planter-box": 2,
  "drive-lane:inbound|sharrow->divider:planting-strip": 2,
  "drive-lane:inbound|sharrow->divider:striped-buffer": 10,
  "drive-lane:inbound|sharrow->drive-lane:inbound|car": 29,
  "drive-lane:inbound|sharrow->drive-lane:inbound|sharrow": 11,
  "drive-lane:inbound|sharrow->drive-lane:inbound|truck": 11,
  "drive-lane:inbound|sharrow->drive-lane:outbound|car": 5,
  "drive-lane:inbound|sharrow->drive-lane:outbound|sharrow": 57,
  "drive-lane:inbound|sharrow->drive-lane:outbound|truck": 4,
  "drive-lane:inbound|sharrow->light-rail:inbound|grass": 2,
  "drive-lane:inbound|sharrow->light-rail:inbound|regular": 8,
  "drive-lane:inbound|sharrow->light-rail:outbound|grass": 1,
  "drive-lane:inbound|sharrow->parking-lane:angled-front-right|right": 1,
  "drive-lane:inbound|sharrow->parking-lane:inbound|left": 2,
  "drive-lane:inbound|sharrow->parking-lane:inbound|right": 10,
  "drive-lane:inbound|sharrow->parking-lane:outbound|right": 3,
  "drive-lane:inbound|sharrow->parklet:right": 2,
  "drive-lane:inbound|sharrow->sidewalk-bike-rack:left|sidewalk": 1,
  "drive-lane:inbound|sharrow->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "drive-lane:inbound|sharrow->sidewalk-lamp:both|modern": 5,
  "drive-lane:inbound|sharrow->sidewalk-lamp:both|traditional": 2,
  "drive-lane:inbound|sharrow->sidewalk-lamp:left|modern": 8,
  "drive-lane:inbound|sharrow->sidewalk-lamp:left|traditional": 5,
  "drive-lane:inbound|sharrow->sidewalk-lamp:right|traditional": 4,
  "drive-lane:inbound|sharrow->sidewalk-tree:big": 16,
  "drive-lane:inbound|sharrow->sidewalk-tree:palm-tree": 1,
  "drive-lane:inbound|sharrow->sidewalk-wayfinding:small": 1,
  "drive-lane:inbound|sharrow->sidewalk:dense": 3,
  "drive-lane:inbound|sharrow->sidewalk:normal": 1,
  "drive-lane:inbound|sharrow->streetcar:inbound|colored": 4,
  "drive-lane:inbound|sharrow->streetcar:inbound|regular": 3,
  "drive-lane:inbound|sharrow->transit-shelter:left|light-rail": 10,
  "drive-lane:inbound|sharrow->transit-shelter:left|street-level": 2,
  "drive-lane:inbound|sharrow->turn-lane:inbound|left-straight": 1,
  "drive-lane:inbound|sharrow->turn-lane:inbound|right": 1,
  "drive-lane:inbound|sharrow->turn-lane:inbound|right-straight": 9,
  "drive-lane:inbound|sharrow->turn-lane:inbound|shared": 6,
  "drive-lane:inbound|sharrow->turn-lane:inbound|straight": 1,
  "drive-lane:inbound|sharrow->turn-lane:outbound|both": 2,
  "drive-lane:inbound|sharrow->turn-lane:outbound|left": 1,
  "drive-lane:inbound|sharrow->turn-lane:outbound|left-straight": 1,
  "drive-lane:inbound|sharrow->turn-lane:outbound|shared": 2,
  "drive-lane:inbound|sharrow->turn-lane:outbound|straight": 1,
  "drive-lane:inbound|truck->bike-lane:inbound|colored": 13,
  "drive-lane:inbound|truck->bike-lane:inbound|green|road": 6,
  "drive-lane:inbound|truck->bike-lane:inbound|green|sidewalk": 1,
  "drive-lane:inbound|truck->bike-lane:inbound|regular": 11,
  "drive-lane:inbound|truck->bike-lane:inbound|regular|road": 3,
  "drive-lane:inbound|truck->bike-lane:outbound|colored": 36,
  "drive-lane:inbound|truck->bike-lane:outbound|red|road": 1,
  "drive-lane:inbound|truck->bike-lane:outbound|regular": 1,
  "drive-lane:inbound|truck->bus-lane:inbound|colored": 8,
  "drive-lane:inbound|truck->bus-lane:inbound|colored|typical": 1,
  "drive-lane:inbound|truck->bus-lane:inbound|regular": 26,
  "drive-lane:inbound|truck->bus-lane:inbound|regular|typical": 8,
  "drive-lane:inbound|truck->bus-lane:inbound|shared|typical": 11,
  "drive-lane:inbound|truck->bus-lane:outbound|colored|typical": 1,
  "drive-lane:inbound|truck->bus-lane:outbound|regular": 1,
  "drive-lane:inbound|truck->bus-lane:outbound|regular|typical": 2,
  "drive-lane:inbound|truck->divider:big-tree": 21,
  "drive-lane:inbound|truck->divider:bollard": 12,
  "drive-lane:inbound|truck->divider:bush": 93,
  "drive-lane:inbound|truck->divider:dome": 10,
  "drive-lane:inbound|truck->divider:flowers": 124,
  "drive-lane:inbound|truck->divider:median": 11,
  "drive-lane:inbound|truck->divider:palm-tree": 13,
  "drive-lane:inbound|truck->divider:planter-box": 6,
  "drive-lane:inbound|truck->divider:planting-strip": 11,
  "drive-lane:inbound|truck->divider:striped-buffer": 26,
  "drive-lane:inbound|truck->drive-lane:inbound|car": 224,
  "drive-lane:inbound|truck->drive-lane:inbound|sharrow": 4,
  "drive-lane:inbound|truck->drive-lane:inbound|truck": 36,
  "drive-lane:inbound|truck->drive-lane:outbound|car": 93,
  "drive-lane:inbound|truck->drive-lane:outbound|truck": 38,
  "drive-lane:inbound|truck->flex-zone-curb:sparse|left": 1,
  "drive-lane:inbound|truck->flex-zone:rideshare|outbound|left": 1,
  "drive-lane:inbound|truck->flex-zone:taxi|inbound|right": 1,
  "drive-lane:inbound|truck->light-rail:inbound|colored": 1,
  "drive-lane:inbound|truck->light-rail:inbound|grass": 1,
  "drive-lane:inbound|truck->light-rail:inbound|regular": 6,
  "drive-lane:inbound|truck->light-rail:outbound|colored": 2,
  "drive-lane:inbound|truck->light-rail:outbound|regular": 1,
  "drive-lane:inbound|truck->parking-lane:inbound|left": 7,
  "drive-lane:inbound|truck->parking-lane:inbound|right": 28,
  "drive-lane:inbound|truck->parking-lane:outbound|left": 2,
  "drive-lane:inbound|truck->parking-lane:outbound|right": 24,
  "drive-lane:inbound|truck->parking-lane:sideways|right": 1,
  "drive-lane:inbound|truck->parklet:right": 2,
  "drive-lane:inbound|truck->sidewalk-bench:right": 1,
  "drive-lane:inbound|truck->sidewalk-bike-rack:right|sidewalk": 1,
  "drive-lane:inbound|truck->sidewalk-lamp:both|modern": 8,
  "drive-lane:inbound|truck->sidewalk-lamp:both|traditional": 2,
  "drive-lane:inbound|truck->sidewalk-lamp:left|modern": 21,
  "drive-lane:inbound|truck->sidewalk-lamp:left|traditional": 7,
  "drive-lane:inbound|truck->sidewalk-lamp:right|modern": 10,
  "drive-lane:inbound|truck->sidewalk-lamp:right|traditional": 2,
  "drive-lane:inbound|truck->sidewalk-tree:big": 27,
  "drive-lane:inbound|truck->sidewalk-tree:palm-tree": 5,
  "drive-lane:inbound|truck->sidewalk-wayfinding:large": 1,
  "drive-lane:inbound|truck->sidewalk-wayfinding:medium": 5,
  "drive-lane:inbound|truck->sidewalk:dense": 12,
  "drive-lane:inbound|truck->sidewalk:empty": 1,
  "drive-lane:inbound|truck->sidewalk:normal": 4,
  "drive-lane:inbound|truck->sidewalk:sparse": 1,
  "drive-lane:inbound|truck->streetcar:inbound|colored": 3,
  "drive-lane:inbound|truck->streetcar:inbound|regular": 9,
  "drive-lane:inbound|truck->transit-shelter:left|light-rail": 2,
  "drive-lane:inbound|truck->transit-shelter:left|street-level": 7,
  "drive-lane:inbound|truck->transit-shelter:right|street-level": 2,
  "drive-lane:inbound|truck->turn-lane:inbound|both": 2,
  "drive-lane:inbound|truck->turn-lane:inbound|left": 2,
  "drive-lane:inbound|truck->turn-lane:inbound|left-straight": 1,
  "drive-lane:inbound|truck->turn-lane:inbound|right": 31,
  "drive-lane:inbound|truck->turn-lane:inbound|right-straight": 70,
  "drive-lane:inbound|truck->turn-lane:inbound|shared": 31,
  "drive-lane:inbound|truck->turn-lane:inbound|straight": 4,
  "drive-lane:inbound|truck->turn-lane:left|outbound": 1,
  "drive-lane:inbound|truck->turn-lane:outbound|left": 4,
  "drive-lane:inbound|truck->turn-lane:outbound|left-straight": 12,
  "drive-lane:inbound|truck->turn-lane:outbound|right": 2,
  "drive-lane:inbound|truck->turn-lane:outbound|right-straight": 1,
  "drive-lane:inbound|truck->turn-lane:outbound|shared": 5,
  "drive-lane:inbound|truck->turn-lane:outbound|straight": 20,
  "drive-lane:outbound->divider:bush": 2,
  "drive-lane:outbound->divider:planting-strip": 1,
  "drive-lane:outbound->drive-lane:outbound": 1,
  "drive-lane:outbound->drive-lane:outbound|car": 1,
  "drive-lane:outbound->sidewalk-lamp:both|traditional": 1,
  "drive-lane:outbound->sidewalk:sparse": 1,
  "drive-lane:outbound->turn-lane:left|outbound": 1,
  "drive-lane:outbound->turn-lane:straight": 1,
  "drive-lane:outbound|->parking-lane:inbound|right": 1,
  "drive-lane:outbound|car->bike-lane:inbound|colored": 17,
  "drive-lane:outbound|car->bike-lane:inbound|green|road": 8,
  "drive-lane:outbound|car->bike-lane:inbound|red|road": 3,
  "drive-lane:outbound|car->bike-lane:inbound|red|sidewalk": 2,
  "drive-lane:outbound|car->bike-lane:inbound|regular": 6,
  "drive-lane:outbound|car->bike-lane:inbound|regular|road": 86,
  "drive-lane:outbound|car->bike-lane:inbound|regular|sidewalk": 3,
  "drive-lane:outbound|car->bike-lane:outbound": 1,
  "drive-lane:outbound|car->bike-lane:outbound|colored": 566,
  "drive-lane:outbound|car->bike-lane:outbound|green|road": 166,
  "drive-lane:outbound|car->bike-lane:outbound|green|sidewalk": 19,
  "drive-lane:outbound|car->bike-lane:outbound|red|road": 30,
  "drive-lane:outbound|car->bike-lane:outbound|red|sidewalk": 9,
  "drive-lane:outbound|car->bike-lane:outbound|regular": 222,
  "drive-lane:outbound|car->bike-lane:outbound|regular|road": 157,
  "drive-lane:outbound|car->bike-lane:outbound|regular|sidewalk": 10,
  "drive-lane:outbound|car->bikeshare:left|road": 1,
  "drive-lane:outbound|car->bikeshare:right|road": 2,
  "drive-lane:outbound|car->bus-lane:inbound": 2,
  "drive-lane:outbound|car->bus-lane:inbound|colored|typical": 5,
  "drive-lane:outbound|car->bus-lane:inbound|regular": 11,
  "drive-lane:outbound|car->bus-lane:inbound|regular|typical": 28,
  "drive-lane:outbound|car->bus-lane:inbound|shared|typical": 6,
  "drive-lane:outbound|car->bus-lane:inbound||": 1,
  "drive-lane:outbound|car->bus-lane:outbound|colored": 59,
  "drive-lane:outbound|car->bus-lane:outbound|colored|typical": 72,
  "drive-lane:outbound|car->bus-lane:outbound|regular": 205,
  "drive-lane:outbound|car->bus-lane:outbound|regular|typical": 288,
  "drive-lane:outbound|car->bus-lane:outbound|shared|typical": 36,
  "drive-lane:outbound|car->divider:big-tree": 189,
  "drive-lane:outbound|car->divider:bollard": 210,
  "drive-lane:outbound|car->divider:bush": 210,
  "drive-lane:outbound|car->divider:dome": 97,
  "drive-lane:outbound|car->divider:flowers": 367,
  "drive-lane:outbound|car->divider:median": 256,
  "drive-lane:outbound|car->divider:palm-tree": 38,
  "drive-lane:outbound|car->divider:planter-box": 74,
  "drive-lane:outbound|car->divider:planting-strip": 105,
  "drive-lane:outbound|car->divider:striped-buffer": 253,
  "drive-lane:outbound|car->drive-lane:inbound|car": 388,
  "drive-lane:outbound|car->drive-lane:inbound|truck": 29,
  "drive-lane:outbound|car->drive-lane:outbound|car": 2817,
  "drive-lane:outbound|car->drive-lane:outbound|sharrow": 30,
  "drive-lane:outbound|car->drive-lane:outbound|truck": 338,
  "drive-lane:outbound|car->flex-zone-curb:empty|left": 1,
  "drive-lane:outbound|car->flex-zone-curb:empty|right": 1,
  "drive-lane:outbound|car->flex-zone-curb:sparse|left": 19,
  "drive-lane:outbound|car->flex-zone-curb:sparse|right": 1,
  "drive-lane:outbound|car->flex-zone:rideshare|outbound|right": 4,
  "drive-lane:outbound|car->flex-zone:taxi|inbound|right": 1,
  "drive-lane:outbound|car->flex-zone:taxi|outbound|left": 7,
  "drive-lane:outbound|car->flex-zone:taxi|outbound|right": 33,
  "drive-lane:outbound|car->food-truck:left": 3,
  "drive-lane:outbound|car->food-truck:right": 3,
  "drive-lane:outbound|car->light-rail:inbound|colored": 10,
  "drive-lane:outbound|car->light-rail:inbound|grass": 2,
  "drive-lane:outbound|car->light-rail:inbound|regular": 11,
  "drive-lane:outbound|car->light-rail:outbound|colored": 17,
  "drive-lane:outbound|car->light-rail:outbound|grass": 7,
  "drive-lane:outbound|car->light-rail:outbound|regular": 92,
  "drive-lane:outbound|car->parking-lane:angled-front-right|left": 1,
  "drive-lane:outbound|car->parking-lane:angled-front-right|right": 1,
  "drive-lane:outbound|car->parking-lane:angled-rear-left|right": 2,
  "drive-lane:outbound|car->parking-lane:angled-rear-right|left": 3,
  "drive-lane:outbound|car->parking-lane:angled-rear-right|right": 15,
  "drive-lane:outbound|car->parking-lane:inbound|left": 29,
  "drive-lane:outbound|car->parking-lane:inbound|right": 275,
  "drive-lane:outbound|car->parking-lane:outbound": 2,
  "drive-lane:outbound|car->parking-lane:outbound|left": 113,
  "drive-lane:outbound|car->parking-lane:outbound|right": 994,
  "drive-lane:outbound|car->parking-lane:sideways|left": 6,
  "drive-lane:outbound|car->parking-lane:sideways|right": 32,
  "drive-lane:outbound|car->parklet:left": 1,
  "drive-lane:outbound|car->parklet:right": 42,
  "drive-lane:outbound|car->scooter:inbound|regular": 1,
  "drive-lane:outbound|car->scooter:outbound|green": 2,
  "drive-lane:outbound|car->scooter:outbound|regular": 8,
  "drive-lane:outbound|car->sidewalk-bench:center": 1,
  "drive-lane:outbound|car->sidewalk-bench:left": 5,
  "drive-lane:outbound|car->sidewalk-bike-rack:left|sidewalk": 9,
  "drive-lane:outbound|car->sidewalk-bike-rack:left|sidewalk-parallel": 9,
  "drive-lane:outbound|car->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "drive-lane:outbound|car->sidewalk-lamp:both|modern": 90,
  "drive-lane:outbound|car->sidewalk-lamp:both|pride": 1,
  "drive-lane:outbound|car->sidewalk-lamp:both|traditional": 83,
  "drive-lane:outbound|car->sidewalk-lamp:left|modern": 469,
  "drive-lane:outbound|car->sidewalk-lamp:left|pride": 1,
  "drive-lane:outbound|car->sidewalk-lamp:left|traditional": 139,
  "drive-lane:outbound|car->sidewalk-lamp:modern": 1,
  "drive-lane:outbound|car->sidewalk-lamp:right|modern": 10,
  "drive-lane:outbound|car->sidewalk-lamp:right|pride": 1,
  "drive-lane:outbound|car->sidewalk-lamp:right|traditional": 6,
  "drive-lane:outbound|car->sidewalk-tree:big": 562,
  "drive-lane:outbound|car->sidewalk-tree:palm-tree": 25,
  "drive-lane:outbound|car->sidewalk-wayfinding:large": 11,
  "drive-lane:outbound|car->sidewalk-wayfinding:medium": 5,
  "drive-lane:outbound|car->sidewalk-wayfinding:small": 12,
  "drive-lane:outbound|car->sidewalk:dense": 229,
  "drive-lane:outbound|car->sidewalk:empty": 19,
  "drive-lane:outbound|car->sidewalk:normal": 126,
  "drive-lane:outbound|car->sidewalk:sparse": 26,
  "drive-lane:outbound|car->streetcar:inbound|colored": 1,
  "drive-lane:outbound|car->streetcar:inbound|grass": 1,
  "drive-lane:outbound|car->streetcar:inbound|regular": 10,
  "drive-lane:outbound|car->streetcar:outbound|colored": 18,
  "drive-lane:outbound|car->streetcar:outbound|grass": 4,
  "drive-lane:outbound|car->streetcar:outbound|regular": 84,
  "drive-lane:outbound|car->temporary:jersey-barrier-concrete": 1,
  "drive-lane:outbound|car->transit-shelter:left|light-rail": 16,
  "drive-lane:outbound|car->transit-shelter:left|street-level": 23,
  "drive-lane:outbound|car->transit-shelter:right|street-level": 54,
  "drive-lane:outbound|car->turn-lane:inbound|both": 1,
  "drive-lane:outbound|car->turn-lane:inbound|left": 4,
  "drive-lane:outbound|car->turn-lane:inbound|left-straight": 7,
  "drive-lane:outbound|car->turn-lane:inbound|right": 14,
  "drive-lane:outbound|car->turn-lane:inbound|right-straight": 3,
  "drive-lane:outbound|car->turn-lane:inbound|shared": 2,
  "drive-lane:outbound|car->turn-lane:inbound|straight": 15,
  "drive-lane:outbound|car->turn-lane:outbound|both": 4,
  "drive-lane:outbound|car->turn-lane:outbound|left": 47,
  "drive-lane:outbound|car->turn-lane:outbound|left-straight": 12,
  "drive-lane:outbound|car->turn-lane:outbound|right": 166,
  "drive-lane:outbound|car->turn-lane:outbound|right-straight": 168,
  "drive-lane:outbound|car->turn-lane:outbound|shared": 5,
  "drive-lane:outbound|car->turn-lane:outbound|straight": 63,
  "drive-lane:outbound|car->turn-lane:straight|outbound": 1,
  "drive-lane:outbound|car->utilities:left": 1,
  "drive-lane:outbound|sharrow->bike-lane:inbound|regular|road": 1,
  "drive-lane:outbound|sharrow->bike-lane:outbound|colored": 28,
  "drive-lane:outbound|sharrow->bus-lane:outbound|colored": 12,
  "drive-lane:outbound|sharrow->bus-lane:outbound|colored|typical": 1,
  "drive-lane:outbound|sharrow->bus-lane:outbound|regular": 4,
  "drive-lane:outbound|sharrow->bus-lane:outbound|regular|typical": 1,
  "drive-lane:outbound|sharrow->divider:big-tree": 1,
  "drive-lane:outbound|sharrow->divider:bollard": 2,
  "drive-lane:outbound|sharrow->divider:bush": 2,
  "drive-lane:outbound|sharrow->divider:dome": 1,
  "drive-lane:outbound|sharrow->divider:flowers": 9,
  "drive-lane:outbound|sharrow->divider:median": 5,
  "drive-lane:outbound|sharrow->divider:palm-tree": 2,
  "drive-lane:outbound|sharrow->divider:striped-buffer": 2,
  "drive-lane:outbound|sharrow->drive-lane:inbound|car": 1,
  "drive-lane:outbound|sharrow->drive-lane:inbound|sharrow": 6,
  "drive-lane:outbound|sharrow->drive-lane:outbound|car": 10,
  "drive-lane:outbound|sharrow->drive-lane:outbound|sharrow": 7,
  "drive-lane:outbound|sharrow->drive-lane:outbound|truck": 6,
  "drive-lane:outbound|sharrow->light-rail:inbound|colored": 2,
  "drive-lane:outbound|sharrow->light-rail:inbound|regular": 1,
  "drive-lane:outbound|sharrow->light-rail:outbound|colored": 4,
  "drive-lane:outbound|sharrow->light-rail:outbound|regular": 8,
  "drive-lane:outbound|sharrow->parking-lane:angled-rear-left|right": 1,
  "drive-lane:outbound|sharrow->parking-lane:inbound|left": 5,
  "drive-lane:outbound|sharrow->parking-lane:inbound|right": 10,
  "drive-lane:outbound|sharrow->parking-lane:outbound|left": 1,
  "drive-lane:outbound|sharrow->parking-lane:outbound|right": 44,
  "drive-lane:outbound|sharrow->parking-lane:sideways|right": 3,
  "drive-lane:outbound|sharrow->parklet:right": 6,
  "drive-lane:outbound|sharrow->sidewalk-lamp:both|modern": 9,
  "drive-lane:outbound|sharrow->sidewalk-lamp:both|pride": 1,
  "drive-lane:outbound|sharrow->sidewalk-lamp:both|traditional": 12,
  "drive-lane:outbound|sharrow->sidewalk-lamp:left|modern": 29,
  "drive-lane:outbound|sharrow->sidewalk-lamp:left|traditional": 21,
  "drive-lane:outbound|sharrow->sidewalk-lamp:right|traditional": 1,
  "drive-lane:outbound|sharrow->sidewalk-tree:big": 24,
  "drive-lane:outbound|sharrow->sidewalk-tree:palm-tree": 4,
  "drive-lane:outbound|sharrow->sidewalk-wayfinding:large": 1,
  "drive-lane:outbound|sharrow->sidewalk-wayfinding:medium": 2,
  "drive-lane:outbound|sharrow->sidewalk:dense": 22,
  "drive-lane:outbound|sharrow->sidewalk:empty": 1,
  "drive-lane:outbound|sharrow->sidewalk:normal": 8,
  "drive-lane:outbound|sharrow->sidewalk:sparse": 6,
  "drive-lane:outbound|sharrow->streetcar:inbound|colored": 1,
  "drive-lane:outbound|sharrow->streetcar:outbound|colored": 2,
  "drive-lane:outbound|sharrow->streetcar:outbound|regular": 1,
  "drive-lane:outbound|sharrow->transit-shelter:left|street-level": 1,
  "drive-lane:outbound|sharrow->transit-shelter:right|light-rail": 2,
  "drive-lane:outbound|sharrow->transit-shelter:right|street-level": 3,
  "drive-lane:outbound|sharrow->turn-lane:outbound|left": 3,
  "drive-lane:outbound|sharrow->turn-lane:outbound|right": 1,
  "drive-lane:outbound|sharrow->turn-lane:outbound|right-straight": 2,
  "drive-lane:outbound|sharrow->turn-lane:outbound|shared": 2,
  "drive-lane:outbound|sharrow->turn-lane:outbound|straight": 1,
  "drive-lane:outbound|truck->bike-lane:inbound|colored": 44,
  "drive-lane:outbound|truck->bike-lane:inbound|regular": 11,
  "drive-lane:outbound|truck->bike-lane:inbound|regular|sidewalk": 1,
  "drive-lane:outbound|truck->bike-lane:outbound|colored": 15186,
  "drive-lane:outbound|truck->bike-lane:outbound|green": 8,
  "drive-lane:outbound|truck->bike-lane:outbound|green|road": 9,
  "drive-lane:outbound|truck->bike-lane:outbound|green|sidewalk": 4,
  "drive-lane:outbound|truck->bike-lane:outbound|red|road": 2,
  "drive-lane:outbound|truck->bike-lane:outbound|red|sidewalk": 1,
  "drive-lane:outbound|truck->bike-lane:outbound|regular": 105,
  "drive-lane:outbound|truck->bike-lane:outbound|regular|road": 7,
  "drive-lane:outbound|truck->bus-lane:inbound|colored": 1,
  "drive-lane:outbound|truck->bus-lane:inbound|regular": 3,
  "drive-lane:outbound|truck->bus-lane:inbound|shared|typical": 1,
  "drive-lane:outbound|truck->bus-lane:outbound|colored": 17,
  "drive-lane:outbound|truck->bus-lane:outbound|colored|typical": 1,
  "drive-lane:outbound|truck->bus-lane:outbound|regular": 104,
  "drive-lane:outbound|truck->bus-lane:outbound|regular|typical": 5,
  "drive-lane:outbound|truck->divider:big-tree": 40,
  "drive-lane:outbound|truck->divider:bollard": 50,
  "drive-lane:outbound|truck->divider:bush": 23,
  "drive-lane:outbound|truck->divider:dome": 23,
  "drive-lane:outbound|truck->divider:flowers": 411,
  "drive-lane:outbound|truck->divider:median": 74,
  "drive-lane:outbound|truck->divider:palm-tree": 7,
  "drive-lane:outbound|truck->divider:planter-box": 2,
  "drive-lane:outbound|truck->divider:planting-strip": 12,
  "drive-lane:outbound|truck->divider:striped-buffer": 50,
  "drive-lane:outbound|truck->drive-lane:inbound|car": 22,
  "drive-lane:outbound|truck->drive-lane:inbound|truck": 3,
  "drive-lane:outbound|truck->drive-lane:outbound|car": 261,
  "drive-lane:outbound|truck->drive-lane:outbound|sharrow": 9,
  "drive-lane:outbound|truck->drive-lane:outbound|truck": 37,
  "drive-lane:outbound|truck->flex-zone-curb:sparse|left": 1,
  "drive-lane:outbound|truck->light-rail:inbound|colored": 1,
  "drive-lane:outbound|truck->light-rail:inbound|regular": 5,
  "drive-lane:outbound|truck->light-rail:outbound|colored": 7,
  "drive-lane:outbound|truck->light-rail:outbound|grass": 1,
  "drive-lane:outbound|truck->light-rail:outbound|regular": 58,
  "drive-lane:outbound|truck->parking-lane:angled-rear-right|right": 1,
  "drive-lane:outbound|truck->parking-lane:inbound|left": 21,
  "drive-lane:outbound|truck->parking-lane:inbound|right": 55,
  "drive-lane:outbound|truck->parking-lane:outbound|left": 35,
  "drive-lane:outbound|truck->parking-lane:outbound|right": 1079,
  "drive-lane:outbound|truck->parking-lane:sideways|left": 3,
  "drive-lane:outbound|truck->parking-lane:sideways|right": 6,
  "drive-lane:outbound|truck->parklet:left": 3,
  "drive-lane:outbound|truck->parklet:right": 21,
  "drive-lane:outbound|truck->scooter:outbound|green": 1,
  "drive-lane:outbound|truck->sidewalk-bench:left": 1,
  "drive-lane:outbound|truck->sidewalk-bench:right": 11,
  "drive-lane:outbound|truck->sidewalk-bike-rack:left|road": 1,
  "drive-lane:outbound|truck->sidewalk-bike-rack:left|sidewalk": 1,
  "drive-lane:outbound|truck->sidewalk-bike-rack:left|sidewalk-parallel": 11,
  "drive-lane:outbound|truck->sidewalk-lamp:both|modern": 75,
  "drive-lane:outbound|truck->sidewalk-lamp:both|traditional": 21,
  "drive-lane:outbound|truck->sidewalk-lamp:left|modern": 525,
  "drive-lane:outbound|truck->sidewalk-lamp:left|traditional": 30,
  "drive-lane:outbound|truck->sidewalk-lamp:right|modern": 5,
  "drive-lane:outbound|truck->sidewalk-lamp:right|traditional": 2,
  "drive-lane:outbound|truck->sidewalk-tree:big": 170,
  "drive-lane:outbound|truck->sidewalk-tree:palm-tree": 5,
  "drive-lane:outbound|truck->sidewalk-wayfinding:large": 2,
  "drive-lane:outbound|truck->sidewalk-wayfinding:medium": 27,
  "drive-lane:outbound|truck->sidewalk:dense": 20,
  "drive-lane:outbound|truck->sidewalk:empty": 2,
  "drive-lane:outbound|truck->sidewalk:normal": 49,
  "drive-lane:outbound|truck->sidewalk:sparse": 4,
  "drive-lane:outbound|truck->streetcar:inbound|regular": 2,
  "drive-lane:outbound|truck->streetcar:outbound|colored": 3,
  "drive-lane:outbound|truck->streetcar:outbound|grass": 1,
  "drive-lane:outbound|truck->streetcar:outbound|regular": 50,
  "drive-lane:outbound|truck->transit-shelter:left|light-rail": 1,
  "drive-lane:outbound|truck->transit-shelter:left|street-level": 12,
  "drive-lane:outbound|truck->transit-shelter:right|street-level": 12,
  "drive-lane:outbound|truck->turn-lane:inbound|left-right-straight": 1,
  "drive-lane:outbound|truck->turn-lane:inbound|left-straight": 9,
  "drive-lane:outbound|truck->turn-lane:inbound|shared": 1,
  "drive-lane:outbound|truck->turn-lane:inbound|straight": 4,
  "drive-lane:outbound|truck->turn-lane:outbound|both": 2,
  "drive-lane:outbound|truck->turn-lane:outbound|left": 84,
  "drive-lane:outbound|truck->turn-lane:outbound|right": 108,
  "drive-lane:outbound|truck->turn-lane:outbound|right-straight": 27,
  "drive-lane:outbound|truck->turn-lane:outbound|straight": 11,
  "drive-lane:right-straight|car->parking-lane:outbound|right": 1,
  "drive-lane:right-straight|car->sidewalk-lamp:left|modern": 2,
  "drive-lane:right-straight|car->turn-lane:right-straight": 1,
  "drive-lane:sharrow->divider:bush": 1,
  "drive-lane:straight|car->parking-lane:outbound|right": 2,
  "drive-lane:truck->divider:bush": 3,
  "drive-lane:truck->drive-lane:car": 1,
  "drive-lane:truck->drive-lane:inbound|car": 2,
  "drive-lane:truck|inbound->divider:bush": 1,
  "drive-lane:truck|outbound->divider:bush": 1,
  "drive-lane:truck|truck->divider:bush": 1,
  "flex-zone-curb:empty|left->bike-lane:outbound|regular|road": 1,
  "flex-zone-curb:empty|left->drive-lane:inbound|car": 2,
  "flex-zone-curb:empty|left->drive-lane:outbound|car": 1,
  "flex-zone-curb:empty|left->flex-zone:taxi|inbound|left": 1,
  "flex-zone-curb:empty|left->parking-lane:inbound|left": 1,
  "flex-zone-curb:empty|left->parking-lane:inbound|right": 1,
  "flex-zone-curb:empty|left->sidewalk-tree:big": 1,
  "flex-zone-curb:empty|left->sidewalk:dense": 1,
  "flex-zone-curb:empty|left->sidewalk:normal": 2,
  "flex-zone-curb:empty|right->bike-lane:inbound|green|road": 1,
  "flex-zone-curb:empty|right->bike-lane:outbound|red|sidewalk": 1,
  "flex-zone-curb:empty|right->bus-lane:inbound|regular|typical": 1,
  "flex-zone-curb:empty|right->drive-lane:inbound|car": 2,
  "flex-zone-curb:empty|right->drive-lane:outbound|car": 1,
  "flex-zone-curb:empty|right->flex-zone:taxi|inbound|left": 1,
  "flex-zone-curb:empty|right->light-rail:outbound|regular": 1,
  "flex-zone-curb:empty|right->parking-lane:inbound|left": 2,
  "flex-zone-curb:empty|right->sidewalk-bench:right": 1,
  "flex-zone-curb:empty|right->sidewalk-lamp:right|modern": 1,
  "flex-zone-curb:empty|right->sidewalk-tree:big": 1,
  "flex-zone-curb:empty|right->streetcar:outbound|regular": 2,
  "flex-zone-curb:empty|right->transit-shelter:left|light-rail": 1,
  "flex-zone-curb:sparse|left->bike-lane:inbound|red|sidewalk": 2,
  "flex-zone-curb:sparse|left->bike-lane:inbound|regular|road": 11,
  "flex-zone-curb:sparse|left->bike-lane:inbound|regular|sidewalk": 1,
  "flex-zone-curb:sparse|left->bike-lane:outbound|green|road": 5,
  "flex-zone-curb:sparse|left->bike-lane:outbound|green|sidewalk": 1,
  "flex-zone-curb:sparse|left->bike-lane:outbound|red|road": 1,
  "flex-zone-curb:sparse|left->bike-lane:outbound|regular|road": 4,
  "flex-zone-curb:sparse|left->bike-lane:outbound|regular|sidewalk": 1,
  "flex-zone-curb:sparse|left->bikeshare:left|road": 2,
  "flex-zone-curb:sparse|left->bus-lane:inbound|colored|typical": 2,
  "flex-zone-curb:sparse|left->bus-lane:inbound|regular|typical": 10,
  "flex-zone-curb:sparse|left->bus-lane:inbound|shared|typical": 3,
  "flex-zone-curb:sparse|left->bus-lane:outbound|regular|typical": 1,
  "flex-zone-curb:sparse|left->divider:big-tree": 7,
  "flex-zone-curb:sparse|left->divider:bollard": 3,
  "flex-zone-curb:sparse|left->divider:bush": 1,
  "flex-zone-curb:sparse|left->divider:flowers": 8,
  "flex-zone-curb:sparse|left->divider:median": 1,
  "flex-zone-curb:sparse|left->divider:planter-box": 3,
  "flex-zone-curb:sparse|left->divider:planting-strip": 1,
  "flex-zone-curb:sparse|left->drive-lane:inbound|car": 10,
  "flex-zone-curb:sparse|left->drive-lane:outbound|car": 19,
  "flex-zone-curb:sparse|left->drive-lane:outbound|truck": 1,
  "flex-zone-curb:sparse|left->flex-zone-curb:sparse|left": 9,
  "flex-zone-curb:sparse|left->flex-zone-curb:sparse|right": 4,
  "flex-zone-curb:sparse|left->flex-zone:taxi|inbound|left": 4,
  "flex-zone-curb:sparse|left->food-truck:left": 4,
  "flex-zone-curb:sparse|left->light-rail:inbound|regular": 4,
  "flex-zone-curb:sparse|left->light-rail:outbound|grass": 1,
  "flex-zone-curb:sparse|left->light-rail:outbound|regular": 2,
  "flex-zone-curb:sparse|left->parking-lane:inbound|left": 3,
  "flex-zone-curb:sparse|left->parking-lane:inbound|right": 5,
  "flex-zone-curb:sparse|left->parking-lane:outbound|right": 7,
  "flex-zone-curb:sparse|left->parklet:left": 1,
  "flex-zone-curb:sparse|left->parklet:right": 1,
  "flex-zone-curb:sparse|left->scooter-drop-zone:right|sidewalk|empty": 5,
  "flex-zone-curb:sparse|left->scooter:inbound|regular": 3,
  "flex-zone-curb:sparse|left->sidewalk-bench:center": 1,
  "flex-zone-curb:sparse|left->sidewalk-bench:left": 1,
  "flex-zone-curb:sparse|left->sidewalk-bench:right": 4,
  "flex-zone-curb:sparse|left->sidewalk-bike-rack:left|sidewalk": 3,
  "flex-zone-curb:sparse|left->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "flex-zone-curb:sparse|left->sidewalk-bike-rack:right|sidewalk": 2,
  "flex-zone-curb:sparse|left->sidewalk-lamp:both|modern": 10,
  "flex-zone-curb:sparse|left->sidewalk-lamp:both|traditional": 7,
  "flex-zone-curb:sparse|left->sidewalk-lamp:left|modern": 18,
  "flex-zone-curb:sparse|left->sidewalk-lamp:left|traditional": 8,
  "flex-zone-curb:sparse|left->sidewalk-lamp:right|modern": 3,
  "flex-zone-curb:sparse|left->sidewalk-lamp:right|pride": 1,
  "flex-zone-curb:sparse|left->sidewalk-lamp:right|traditional": 1,
  "flex-zone-curb:sparse|left->sidewalk-tree:big": 45,
  "flex-zone-curb:sparse|left->sidewalk-tree:palm-tree": 6,
  "flex-zone-curb:sparse|left->sidewalk-wayfinding:large": 3,
  "flex-zone-curb:sparse|left->sidewalk-wayfinding:small": 1,
  "flex-zone-curb:sparse|left->sidewalk:dense": 43,
  "flex-zone-curb:sparse|left->sidewalk:empty": 2,
  "flex-zone-curb:sparse|left->sidewalk:normal": 13,
  "flex-zone-curb:sparse|left->sidewalk:sparse": 3,
  "flex-zone-curb:sparse|left->streetcar:inbound|colored": 1,
  "flex-zone-curb:sparse|left->streetcar:inbound|regular": 7,
  "flex-zone-curb:sparse|left->streetcar:outbound|regular": 6,
  "flex-zone-curb:sparse|left->transit-shelter:left|light-rail": 2,
  "flex-zone-curb:sparse|left->transit-shelter:left|street-level": 1,
  "flex-zone-curb:sparse|left->transit-shelter:right|light-rail": 1,
  "flex-zone-curb:sparse|left->transit-shelter:right|street-level": 5,
  "flex-zone-curb:sparse|left->turn-lane:inbound|left": 1,
  "flex-zone-curb:sparse|left->turn-lane:inbound|right": 1,
  "flex-zone-curb:sparse|left->turn-lane:outbound|right-straight": 2,
  "flex-zone-curb:sparse|left->turn-lane:outbound|straight": 6,
  "flex-zone-curb:sparse|right->bike-lane:inbound|green|road": 3,
  "flex-zone-curb:sparse|right->bike-lane:inbound|regular|road": 10,
  "flex-zone-curb:sparse|right->bus-lane:inbound|colored|typical": 7,
  "flex-zone-curb:sparse|right->bus-lane:inbound|regular|typical": 12,
  "flex-zone-curb:sparse|right->bus-lane:inbound|shared|typical": 3,
  "flex-zone-curb:sparse|right->bus-lane:outbound|colored|typical": 1,
  "flex-zone-curb:sparse|right->bus-lane:outbound|regular|typical": 5,
  "flex-zone-curb:sparse|right->bus-lane:outbound|shared|typical": 1,
  "flex-zone-curb:sparse|right->divider:big-tree": 1,
  "flex-zone-curb:sparse|right->divider:bush": 1,
  "flex-zone-curb:sparse|right->divider:median": 1,
  "flex-zone-curb:sparse|right->divider:planter-box": 1,
  "flex-zone-curb:sparse|right->divider:planting-strip": 1,
  "flex-zone-curb:sparse|right->drive-lane:inbound|car": 12,
  "flex-zone-curb:sparse|right->drive-lane:inbound|truck": 1,
  "flex-zone-curb:sparse|right->drive-lane:outbound|car": 1,
  "flex-zone-curb:sparse|right->flex-zone:rideshare|inbound|left": 3,
  "flex-zone-curb:sparse|right->flex-zone:rideshare|outbound|left": 1,
  "flex-zone-curb:sparse|right->flex-zone:taxi|inbound|left": 10,
  "flex-zone-curb:sparse|right->light-rail:inbound|grass": 5,
  "flex-zone-curb:sparse|right->light-rail:inbound|regular": 5,
  "flex-zone-curb:sparse|right->light-rail:outbound|grass": 1,
  "flex-zone-curb:sparse|right->light-rail:outbound|regular": 3,
  "flex-zone-curb:sparse|right->parking-lane:inbound|left": 4,
  "flex-zone-curb:sparse|right->parking-lane:inbound|right": 1,
  "flex-zone-curb:sparse|right->parking-lane:outbound|right": 1,
  "flex-zone-curb:sparse|right->scooter-drop-zone:right|sidewalk|empty": 1,
  "flex-zone-curb:sparse|right->sidewalk-bench:center": 2,
  "flex-zone-curb:sparse|right->sidewalk-bench:left": 1,
  "flex-zone-curb:sparse|right->sidewalk-lamp:both|modern": 7,
  "flex-zone-curb:sparse|right->sidewalk-lamp:right|modern": 6,
  "flex-zone-curb:sparse|right->sidewalk-lamp:right|traditional": 2,
  "flex-zone-curb:sparse|right->sidewalk-tree:big": 6,
  "flex-zone-curb:sparse|right->sidewalk-tree:palm-tree": 1,
  "flex-zone-curb:sparse|right->sidewalk:dense": 5,
  "flex-zone-curb:sparse|right->sidewalk:normal": 1,
  "flex-zone-curb:sparse|right->streetcar:inbound|grass": 4,
  "flex-zone-curb:sparse|right->streetcar:inbound|regular": 6,
  "flex-zone-curb:sparse|right->streetcar:outbound|regular": 3,
  "flex-zone-curb:sparse|right->transit-shelter:left|street-level": 1,
  "flex-zone-curb:sparse|right->turn-lane:inbound|left-straight": 1,
  "flex-zone-curb:sparse|right->turn-lane:inbound|straight": 1,
  "flex-zone:rideshare|inbound|left->divider:striped-buffer": 1,
  "flex-zone:rideshare|inbound|left->drive-lane:inbound|car": 17,
  "flex-zone:rideshare|inbound|left->drive-lane:inbound|truck": 6,
  "flex-zone:rideshare|inbound|left->flex-zone:taxi|inbound|left": 1,
  "flex-zone:rideshare|inbound|left->parking-lane:outbound|left": 2,
  "flex-zone:rideshare|inbound|left->parklet:left": 1,
  "flex-zone:rideshare|inbound|left->sidewalk-lamp:right|modern": 1,
  "flex-zone:rideshare|inbound|left->sidewalk-tree:big": 1,
  "flex-zone:rideshare|inbound|left->sidewalk:dense": 2,
  "flex-zone:rideshare|inbound|left->transit-shelter:left|street-level": 1,
  "flex-zone:rideshare|inbound|right->drive-lane:outbound|truck": 1,
  "flex-zone:rideshare|inbound|right->sidewalk-lamp:both|modern": 1,
  "flex-zone:rideshare|inbound|right->sidewalk-lamp:left|modern": 1,
  "flex-zone:rideshare|inbound|right->sidewalk:normal": 1,
  "flex-zone:rideshare|outbound|left->bus-lane:outbound|shared|typical": 1,
  "flex-zone:rideshare|outbound|left->drive-lane:inbound|car": 1,
  "flex-zone:rideshare|outbound|left->light-rail:inbound|regular": 1,
  "flex-zone:rideshare|outbound|left->sidewalk-tree:big": 1,
  "flex-zone:rideshare|outbound|left->turn-lane:outbound|shared": 1,
  "flex-zone:rideshare|outbound|right->divider:bollard": 1,
  "flex-zone:rideshare|outbound|right->divider:flowers": 1,
  "flex-zone:rideshare|outbound|right->divider:planter-box": 1,
  "flex-zone:rideshare|outbound|right->divider:striped-buffer": 1,
  "flex-zone:rideshare|outbound|right->flex-zone-curb:sparse|left": 3,
  "flex-zone:rideshare|outbound|right->flex-zone:taxi|outbound|right": 18,
  "flex-zone:rideshare|outbound|right->sidewalk-tree:big": 2,
  "flex-zone:rideshare|outbound|right->sidewalk:dense": 2,
  "flex-zone:taxi|inbound|left->bike-lane:inbound|green|sidewalk": 1,
  "flex-zone:taxi|inbound|left->bike-lane:inbound|red|road": 1,
  "flex-zone:taxi|inbound|left->bike-lane:inbound|regular|road": 7,
  "flex-zone:taxi|inbound|left->bike-lane:outbound|green|road": 1,
  "flex-zone:taxi|inbound|left->bikeshare:left|road": 2,
  "flex-zone:taxi|inbound|left->bus-lane:inbound|regular|typical": 14,
  "flex-zone:taxi|inbound|left->bus-lane:inbound|shared|typical": 4,
  "flex-zone:taxi|inbound|left->bus-lane:outbound|regular|typical": 1,
  "flex-zone:taxi|inbound|left->divider:big-tree": 1,
  "flex-zone:taxi|inbound|left->divider:bollard": 1,
  "flex-zone:taxi|inbound|left->divider:bush": 2,
  "flex-zone:taxi|inbound|left->divider:flowers": 3,
  "flex-zone:taxi|inbound|left->divider:median": 1,
  "flex-zone:taxi|inbound|left->divider:planter-box": 4,
  "flex-zone:taxi|inbound|left->divider:planting-strip": 1,
  "flex-zone:taxi|inbound|left->drive-lane:inbound|car": 91,
  "flex-zone:taxi|inbound|left->drive-lane:outbound|car": 3,
  "flex-zone:taxi|inbound|left->drive-lane:outbound|truck": 1,
  "flex-zone:taxi|inbound|left->flex-zone-curb:sparse|left": 8,
  "flex-zone:taxi|inbound|left->flex-zone:rideshare|outbound|left": 1,
  "flex-zone:taxi|inbound|left->flex-zone:taxi|inbound|left": 2,
  "flex-zone:taxi|inbound|left->flex-zone:taxi|inbound|right": 1,
  "flex-zone:taxi|inbound|left->flex-zone:taxi|outbound|right": 1,
  "flex-zone:taxi|inbound|left->food-truck:left": 6,
  "flex-zone:taxi|inbound|left->light-rail:inbound|regular": 6,
  "flex-zone:taxi|inbound|left->parking-lane:inbound|left": 2,
  "flex-zone:taxi|inbound|left->parking-lane:inbound|right": 3,
  "flex-zone:taxi|inbound|left->parking-lane:outbound|right": 3,
  "flex-zone:taxi|inbound|left->scooter-drop-zone:right|sidewalk|empty": 1,
  "flex-zone:taxi|inbound|left->sidewalk-lamp:both|traditional": 2,
  "flex-zone:taxi|inbound|left->sidewalk-lamp:left|modern": 2,
  "flex-zone:taxi|inbound|left->sidewalk-lamp:left|traditional": 1,
  "flex-zone:taxi|inbound|left->sidewalk-lamp:right|modern": 3,
  "flex-zone:taxi|inbound|left->sidewalk-lamp:right|traditional": 3,
  "flex-zone:taxi|inbound|left->sidewalk-tree:big": 6,
  "flex-zone:taxi|inbound|left->sidewalk-tree:palm-tree": 2,
  "flex-zone:taxi|inbound|left->sidewalk:dense": 8,
  "flex-zone:taxi|inbound|left->sidewalk:sparse": 1,
  "flex-zone:taxi|inbound|left->streetcar:inbound|regular": 2,
  "flex-zone:taxi|inbound|left->transit-shelter:left|street-level": 2,
  "flex-zone:taxi|inbound|left->transit-shelter:right|street-level": 2,
  "flex-zone:taxi|inbound|left->turn-lane:inbound|left": 3,
  "flex-zone:taxi|inbound|left->turn-lane:inbound|left-straight": 3,
  "flex-zone:taxi|inbound|left->turn-lane:inbound|right": 1,
  "flex-zone:taxi|inbound|left->turn-lane:inbound|straight": 1,
  "flex-zone:taxi|inbound|right->divider:striped-buffer": 1,
  "flex-zone:taxi|inbound|right->flex-zone-curb:sparse|left": 5,
  "flex-zone:taxi|inbound|right->parking-lane:inbound|left": 1,
  "flex-zone:taxi|inbound|right->scooter:outbound|regular": 1,
  "flex-zone:taxi|inbound|right->sidewalk-lamp:both|modern": 1,
  "flex-zone:taxi|inbound|right->sidewalk-lamp:both|traditional": 1,
  "flex-zone:taxi|inbound|right->sidewalk-lamp:left|modern": 10,
  "flex-zone:taxi|inbound|right->sidewalk-tree:big": 1,
  "flex-zone:taxi|inbound|right->sidewalk:dense": 4,
  "flex-zone:taxi|inbound|right->transit-shelter:right|street-level": 2,
  "flex-zone:taxi|outbound|left->bike-lane:outbound|green|road": 1,
  "flex-zone:taxi|outbound|left->bus-lane:outbound|regular|typical": 4,
  "flex-zone:taxi|outbound|left->divider:flowers": 4,
  "flex-zone:taxi|outbound|left->divider:planter-box": 1,
  "flex-zone:taxi|outbound|left->drive-lane:outbound|car": 5,
  "flex-zone:taxi|outbound|left->drive-lane:outbound|truck": 1,
  "flex-zone:taxi|outbound|left->flex-zone-curb:sparse|left": 2,
  "flex-zone:taxi|outbound|left->flex-zone:rideshare|outbound|right": 1,
  "flex-zone:taxi|outbound|left->light-rail:outbound|regular": 1,
  "flex-zone:taxi|outbound|left->parking-lane:inbound|left": 1,
  "flex-zone:taxi|outbound|left->parking-lane:outbound|left": 2,
  "flex-zone:taxi|outbound|left->sidewalk-bike-rack:left|sidewalk": 1,
  "flex-zone:taxi|outbound|left->sidewalk-lamp:left|modern": 2,
  "flex-zone:taxi|outbound|left->sidewalk-tree:big": 2,
  "flex-zone:taxi|outbound|left->sidewalk:dense": 2,
  "flex-zone:taxi|outbound|left->sidewalk:normal": 2,
  "flex-zone:taxi|outbound|left->streetcar:outbound|regular": 1,
  "flex-zone:taxi|outbound|left->transit-shelter:right|street-level": 1,
  "flex-zone:taxi|outbound|left->turn-lane:outbound|left-straight": 3,
  "flex-zone:taxi|outbound|right->bike-lane:inbound|regular|road": 1,
  "flex-zone:taxi|outbound|right->bike-lane:outbound|green|road": 2,
  "flex-zone:taxi|outbound|right->bike-lane:outbound|red|road": 1,
  "flex-zone:taxi|outbound|right->bike-lane:outbound|regular|road": 4,
  "flex-zone:taxi|outbound|right->divider:dome": 2,
  "flex-zone:taxi|outbound|right->divider:flowers": 1,
  "flex-zone:taxi|outbound|right->flex-zone-curb:sparse|left": 2,
  "flex-zone:taxi|outbound|right->flex-zone-curb:sparse|right": 1,
  "flex-zone:taxi|outbound|right->flex-zone:rideshare|outbound|right": 1,
  "flex-zone:taxi|outbound|right->flex-zone:taxi|outbound|right": 14,
  "flex-zone:taxi|outbound|right->parking-lane:inbound|right": 2,
  "flex-zone:taxi|outbound|right->parking-lane:sideways|right": 4,
  "flex-zone:taxi|outbound|right->sidewalk-bench:left": 10,
  "flex-zone:taxi|outbound|right->sidewalk-lamp:both|modern": 12,
  "flex-zone:taxi|outbound|right->sidewalk-lamp:left|modern": 16,
  "flex-zone:taxi|outbound|right->sidewalk-lamp:left|traditional": 1,
  "flex-zone:taxi|outbound|right->sidewalk-lamp:right|traditional": 1,
  "flex-zone:taxi|outbound|right->sidewalk-tree:big": 2,
  "flex-zone:taxi|outbound|right->sidewalk:dense": 2,
  "flex-zone:taxi|shared|left->drive-lane:inbound|car": 1,
  "food-truck:left->bike-lane:inbound|regular|road": 6,
  "food-truck:left->bike-lane:outbound|green|road": 2,
  "food-truck:left->bikeshare:left|road": 5,
  "food-truck:left->bus-lane:inbound|regular|typical": 1,
  "food-truck:left->bus-lane:inbound|shared|typical": 3,
  "food-truck:left->bus-lane:outbound|regular|typical": 2,
  "food-truck:left->divider:big-tree": 1,
  "food-truck:left->divider:bollard": 1,
  "food-truck:left->divider:bush": 4,
  "food-truck:left->divider:dome": 1,
  "food-truck:left->divider:flowers": 1,
  "food-truck:left->divider:median": 1,
  "food-truck:left->divider:planter-box": 13,
  "food-truck:left->divider:striped-buffer": 1,
  "food-truck:left->drive-lane:inbound|car": 57,
  "food-truck:left->drive-lane:inbound|truck": 2,
  "food-truck:left->drive-lane:outbound|car": 2,
  "food-truck:left->flex-zone-curb:sparse|left": 5,
  "food-truck:left->flex-zone:taxi|inbound|left": 2,
  "food-truck:left->food-truck:left": 17,
  "food-truck:left->food-truck:right": 1,
  "food-truck:left->light-rail:inbound|regular": 2,
  "food-truck:left->light-rail:outbound|regular": 1,
  "food-truck:left->parking-lane:inbound|left": 3,
  "food-truck:left->parking-lane:inbound|right": 2,
  "food-truck:left->parking-lane:outbound|right": 1,
  "food-truck:left->parklet:left": 9,
  "food-truck:left->parklet:right": 1,
  "food-truck:left->scooter-drop-zone:right|sidewalk|empty": 2,
  "food-truck:left->scooter:inbound|regular": 3,
  "food-truck:left->sidewalk-bench:left": 1,
  "food-truck:left->sidewalk-bike-rack:left|sidewalk": 1,
  "food-truck:left->sidewalk-bike-rack:right|road": 2,
  "food-truck:left->sidewalk-bike-rack:right|sidewalk": 1,
  "food-truck:left->sidewalk-lamp:both|pride": 1,
  "food-truck:left->sidewalk-lamp:left|modern": 3,
  "food-truck:left->sidewalk-lamp:right|modern": 4,
  "food-truck:left->sidewalk-lamp:right|traditional": 1,
  "food-truck:left->sidewalk-tree:big": 11,
  "food-truck:left->sidewalk-tree:palm-tree": 4,
  "food-truck:left->sidewalk:dense": 7,
  "food-truck:left->sidewalk:normal": 7,
  "food-truck:left->streetcar:outbound|regular": 1,
  "food-truck:left->transit-shelter:left|street-level": 3,
  "food-truck:left->transit-shelter:right|street-level": 2,
  "food-truck:left->turn-lane:inbound|right": 1,
  "food-truck:left->turn-lane:left": 1,
  "food-truck:left->turn-lane:outbound|straight": 4,
  "food-truck:right->bike-lane:outbound|green|road": 1,
  "food-truck:right->divider:bush": 1,
  "food-truck:right->divider:median": 2,
  "food-truck:right->drive-lane:inbound|car": 1,
  "food-truck:right->drive-lane:outbound|car": 1,
  "food-truck:right->flex-zone-curb:sparse|left": 2,
  "food-truck:right->parking-lane:angled-rear-right|right": 1,
  "food-truck:right->parklet:right": 1,
  "food-truck:right->sidewalk-bench:right": 1,
  "food-truck:right->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "food-truck:right->sidewalk-lamp:both|pride": 1,
  "food-truck:right->sidewalk-lamp:left|modern": 2,
  "food-truck:right->sidewalk-lamp:left|pride": 2,
  "food-truck:right->sidewalk-lamp:left|traditional": 1,
  "food-truck:right->sidewalk-lamp:right|traditional": 2,
  "food-truck:right->sidewalk-tree:big": 7,
  "food-truck:right->sidewalk-tree:palm-tree": 1,
  "food-truck:right->sidewalk:dense": 6,
  "food-truck:right->sidewalk:empty": 1,
  "food-truck:right->sidewalk:normal": 2,
  "food-truck:right->streetcar:outbound|colored": 1,
  "food-truck:right->streetcar:outbound|grass": 1,
  "food-truck:right->transit-shelter:left|street-level": 1,
  "light-rail:colored|inbound->divider:planter-box": 1,
  "light-rail:inbound|colored->bike-lane:inbound|colored": 9,
  "light-rail:inbound|colored->bike-lane:inbound|regular": 4,
  "light-rail:inbound|colored->bike-lane:outbound|colored": 4,
  "light-rail:inbound|colored->bike-lane:outbound|regular": 1,
  "light-rail:inbound|colored->bus-lane:inbound|colored": 1,
  "light-rail:inbound|colored->bus-lane:inbound|colored|typical": 1,
  "light-rail:inbound|colored->bus-lane:inbound|regular": 12,
  "light-rail:inbound|colored->bus-lane:outbound|regular": 1,
  "light-rail:inbound|colored->divider:big-tree": 13,
  "light-rail:inbound|colored->divider:bollard": 13,
  "light-rail:inbound|colored->divider:bush": 29,
  "light-rail:inbound|colored->divider:dome": 4,
  "light-rail:inbound|colored->divider:flowers": 38,
  "light-rail:inbound|colored->divider:median": 9,
  "light-rail:inbound|colored->divider:palm-tree": 11,
  "light-rail:inbound|colored->divider:planter-box": 6,
  "light-rail:inbound|colored->divider:striped-buffer": 4,
  "light-rail:inbound|colored->drive-lane:inbound|car": 35,
  "light-rail:inbound|colored->drive-lane:inbound|sharrow": 5,
  "light-rail:inbound|colored->drive-lane:inbound|truck": 2,
  "light-rail:inbound|colored->drive-lane:outbound|car": 3,
  "light-rail:inbound|colored->drive-lane:outbound|truck": 3,
  "light-rail:inbound|colored->light-rail:inbound|colored": 11,
  "light-rail:inbound|colored->light-rail:inbound|grass": 1,
  "light-rail:inbound|colored->light-rail:inbound|regular": 3,
  "light-rail:inbound|colored->light-rail:outbound|colored": 113,
  "light-rail:inbound|colored->light-rail:outbound|regular": 7,
  "light-rail:inbound|colored->parking-lane:outbound|right": 2,
  "light-rail:inbound|colored->parklet:right": 2,
  "light-rail:inbound|colored->sidewalk-bench:center": 1,
  "light-rail:inbound|colored->sidewalk-bench:right": 1,
  "light-rail:inbound|colored->sidewalk-lamp:both|modern": 6,
  "light-rail:inbound|colored->sidewalk-lamp:both|pride": 1,
  "light-rail:inbound|colored->sidewalk-lamp:both|traditional": 3,
  "light-rail:inbound|colored->sidewalk-lamp:left|modern": 3,
  "light-rail:inbound|colored->sidewalk-lamp:left|traditional": 1,
  "light-rail:inbound|colored->sidewalk-lamp:right|modern": 1,
  "light-rail:inbound|colored->sidewalk-lamp:right|traditional": 3,
  "light-rail:inbound|colored->sidewalk-tree:big": 10,
  "light-rail:inbound|colored->sidewalk-tree:palm-tree": 4,
  "light-rail:inbound|colored->sidewalk-wayfinding:medium": 1,
  "light-rail:inbound|colored->sidewalk:dense": 6,
  "light-rail:inbound|colored->sidewalk:normal": 2,
  "light-rail:inbound|colored->streetcar:inbound|colored": 1,
  "light-rail:inbound|colored->streetcar:inbound|regular": 3,
  "light-rail:inbound|colored->streetcar:outbound|colored": 1,
  "light-rail:inbound|colored->transit-shelter:left|light-rail": 4,
  "light-rail:inbound|colored->transit-shelter:left|street-level": 3,
  "light-rail:inbound|colored->transit-shelter:right|light-rail": 62,
  "light-rail:inbound|colored->transit-shelter:right|street-level": 18,
  "light-rail:inbound|colored->turn-lane:inbound|both": 1,
  "light-rail:inbound|colored->turn-lane:inbound|left": 2,
  "light-rail:inbound|colored->turn-lane:inbound|left-straight": 3,
  "light-rail:inbound|colored->turn-lane:inbound|right-straight": 1,
  "light-rail:inbound|colored->turn-lane:inbound|straight": 2,
  "light-rail:inbound|colored->turn-lane:outbound|left": 2,
  "light-rail:inbound|colored->turn-lane:outbound|left-straight": 1,
  "light-rail:inbound|grass->bike-lane:inbound|green|road": 1,
  "light-rail:inbound|grass->bike-lane:outbound|regular|sidewalk": 1,
  "light-rail:inbound|grass->bus-lane:inbound|regular|typical": 1,
  "light-rail:inbound|grass->divider:big-tree": 3,
  "light-rail:inbound|grass->divider:bollard": 2,
  "light-rail:inbound|grass->divider:bush": 1,
  "light-rail:inbound|grass->divider:flowers": 7,
  "light-rail:inbound|grass->divider:median": 1,
  "light-rail:inbound|grass->divider:palm-tree": 2,
  "light-rail:inbound|grass->divider:planter-box": 1,
  "light-rail:inbound|grass->divider:planting-strip": 4,
  "light-rail:inbound|grass->divider:striped-buffer": 1,
  "light-rail:inbound|grass->drive-lane:inbound|car": 13,
  "light-rail:inbound|grass->drive-lane:outbound|car": 3,
  "light-rail:inbound|grass->drive-lane:outbound|sharrow": 1,
  "light-rail:inbound|grass->flex-zone-curb:empty|right": 1,
  "light-rail:inbound|grass->light-rail:inbound|grass": 7,
  "light-rail:inbound|grass->light-rail:outbound|grass": 47,
  "light-rail:inbound|grass->light-rail:outbound|regular": 1,
  "light-rail:inbound|grass->parking-lane:inbound|left": 1,
  "light-rail:inbound|grass->scooter:inbound|regular": 1,
  "light-rail:inbound|grass->sidewalk-lamp:both|modern": 1,
  "light-rail:inbound|grass->sidewalk-lamp:both|traditional": 2,
  "light-rail:inbound|grass->sidewalk-lamp:left|modern": 1,
  "light-rail:inbound|grass->sidewalk-lamp:modern|left": 1,
  "light-rail:inbound|grass->sidewalk-tree:big": 6,
  "light-rail:inbound|grass->sidewalk-tree:palm-tree": 1,
  "light-rail:inbound|grass->sidewalk:normal": 1,
  "light-rail:inbound|grass->streetcar:outbound|grass": 1,
  "light-rail:inbound|grass->transit-shelter:left|light-rail": 1,
  "light-rail:inbound|grass->transit-shelter:left|street-level": 1,
  "light-rail:inbound|grass->transit-shelter:right|light-rail": 11,
  "light-rail:inbound|grass->transit-shelter:right|street-level": 4,
  "light-rail:inbound|grass->turn-lane:outbound|straight": 1,
  "light-rail:inbound|regular->bike-lane:inbound|colored": 21,
  "light-rail:inbound|regular->bike-lane:inbound|green|sidewalk": 3,
  "light-rail:inbound|regular->bike-lane:inbound|regular": 41,
  "light-rail:inbound|regular->bike-lane:inbound|regular|road": 11,
  "light-rail:inbound|regular->bike-lane:outbound|colored": 28,
  "light-rail:inbound|regular->bike-lane:outbound|green|road": 2,
  "light-rail:inbound|regular->bikeshare:left|road": 1,
  "light-rail:inbound|regular->bus-lane:inbound|colored": 4,
  "light-rail:inbound|regular->bus-lane:inbound|regular": 22,
  "light-rail:inbound|regular->bus-lane:inbound|regular|typical": 2,
  "light-rail:inbound|regular->bus-lane:inbound|shared|typical": 1,
  "light-rail:inbound|regular->bus-lane:outbound|regular": 5,
  "light-rail:inbound|regular->bus-lane:outbound|regular|typical": 1,
  "light-rail:inbound|regular->bus-lane:outbound|shared|typical": 1,
  "light-rail:inbound|regular->divider:big-tree": 40,
  "light-rail:inbound|regular->divider:bollard": 44,
  "light-rail:inbound|regular->divider:bush": 84,
  "light-rail:inbound|regular->divider:dome": 20,
  "light-rail:inbound|regular->divider:flowers": 382,
  "light-rail:inbound|regular->divider:median": 61,
  "light-rail:inbound|regular->divider:palm-tree": 23,
  "light-rail:inbound|regular->divider:planter-box": 6,
  "light-rail:inbound|regular->divider:planting-strip": 28,
  "light-rail:inbound|regular->divider:striped-buffer": 7,
  "light-rail:inbound|regular->drive-lane:inbound|car": 212,
  "light-rail:inbound|regular->drive-lane:inbound|sharrow": 8,
  "light-rail:inbound|regular->drive-lane:inbound|truck": 12,
  "light-rail:inbound|regular->drive-lane:outbound|car": 54,
  "light-rail:inbound|regular->drive-lane:outbound|sharrow": 2,
  "light-rail:inbound|regular->drive-lane:outbound|truck": 24,
  "light-rail:inbound|regular->flex-zone-curb:sparse|left": 10,
  "light-rail:inbound|regular->flex-zone-curb:sparse|right": 1,
  "light-rail:inbound|regular->flex-zone:taxi|inbound|left": 1,
  "light-rail:inbound|regular->light-rail:inbound|colored": 2,
  "light-rail:inbound|regular->light-rail:inbound|regular": 194,
  "light-rail:inbound|regular->light-rail:outbound": 1,
  "light-rail:inbound|regular->light-rail:outbound|colored": 12,
  "light-rail:inbound|regular->light-rail:outbound|regular": 328,
  "light-rail:inbound|regular->parking-lane:inbound|left": 8,
  "light-rail:inbound|regular->parking-lane:inbound|right": 11,
  "light-rail:inbound|regular->parking-lane:outbound|right": 11,
  "light-rail:inbound|regular->parklet:left": 3,
  "light-rail:inbound|regular->parklet:right": 17,
  "light-rail:inbound|regular->scooter-drop-zone:right|sidewalk|empty": 2,
  "light-rail:inbound|regular->scooter:inbound|regular": 1,
  "light-rail:inbound|regular->sidewalk-bench:center": 2,
  "light-rail:inbound|regular->sidewalk-bench:right": 6,
  "light-rail:inbound|regular->sidewalk-bike-rack:left|road": 1,
  "light-rail:inbound|regular->sidewalk-bike-rack:left|sidewalk": 6,
  "light-rail:inbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 3,
  "light-rail:inbound|regular->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "light-rail:inbound|regular->sidewalk-lamp:both|modern": 30,
  "light-rail:inbound|regular->sidewalk-lamp:both|traditional": 19,
  "light-rail:inbound|regular->sidewalk-lamp:left|modern": 59,
  "light-rail:inbound|regular->sidewalk-lamp:left|pride": 2,
  "light-rail:inbound|regular->sidewalk-lamp:left|traditional": 15,
  "light-rail:inbound|regular->sidewalk-lamp:right|modern": 22,
  "light-rail:inbound|regular->sidewalk-lamp:right|pride": 1,
  "light-rail:inbound|regular->sidewalk-lamp:right|traditional": 3,
  "light-rail:inbound|regular->sidewalk-tree:big": 111,
  "light-rail:inbound|regular->sidewalk-tree:palm-tree": 10,
  "light-rail:inbound|regular->sidewalk-wayfinding:large": 3,
  "light-rail:inbound|regular->sidewalk-wayfinding:medium": 5,
  "light-rail:inbound|regular->sidewalk-wayfinding:small": 2,
  "light-rail:inbound|regular->sidewalk:dense": 49,
  "light-rail:inbound|regular->sidewalk:normal": 15,
  "light-rail:inbound|regular->sidewalk:sparse": 1,
  "light-rail:inbound|regular->streetcar:inbound|grass": 8,
  "light-rail:inbound|regular->streetcar:inbound|regular": 46,
  "light-rail:inbound|regular->streetcar:outbound|regular": 1,
  "light-rail:inbound|regular->transit-shelter:left|light-rail": 19,
  "light-rail:inbound|regular->transit-shelter:left|street-level": 18,
  "light-rail:inbound|regular->transit-shelter:right|light-rail": 204,
  "light-rail:inbound|regular->transit-shelter:right|street-level": 64,
  "light-rail:inbound|regular->turn-lane:inbound|both": 3,
  "light-rail:inbound|regular->turn-lane:inbound|left": 10,
  "light-rail:inbound|regular->turn-lane:inbound|left-straight": 12,
  "light-rail:inbound|regular->turn-lane:inbound|right": 10,
  "light-rail:inbound|regular->turn-lane:inbound|right-straight": 57,
  "light-rail:inbound|regular->turn-lane:inbound|shared": 1,
  "light-rail:inbound|regular->turn-lane:inbound|straight": 9,
  "light-rail:inbound|regular->turn-lane:outbound|both": 1,
  "light-rail:inbound|regular->turn-lane:outbound|left": 14,
  "light-rail:inbound|regular->turn-lane:outbound|left-right-straight": 1,
  "light-rail:inbound|regular->turn-lane:outbound|left-straight": 5,
  "light-rail:inbound|regular->turn-lane:outbound|right-straight": 2,
  "light-rail:inbound|regular->turn-lane:outbound|straight": 7,
  "light-rail:outbound->light-rail:outbound|regular": 1,
  "light-rail:outbound|colored->bike-lane:inbound|colored": 3,
  "light-rail:outbound|colored->bike-lane:outbound|colored": 16,
  "light-rail:outbound|colored->bike-lane:outbound|regular": 1,
  "light-rail:outbound|colored->bus-lane:outbound|colored": 2,
  "light-rail:outbound|colored->bus-lane:outbound|regular": 3,
  "light-rail:outbound|colored->divider:big-tree": 18,
  "light-rail:outbound|colored->divider:bollard": 12,
  "light-rail:outbound|colored->divider:bush": 7,
  "light-rail:outbound|colored->divider:dome": 3,
  "light-rail:outbound|colored->divider:flowers": 14,
  "light-rail:outbound|colored->divider:median": 5,
  "light-rail:outbound|colored->divider:palm-tree": 4,
  "light-rail:outbound|colored->divider:striped-buffer": 4,
  "light-rail:outbound|colored->drive-lane:inbound|car": 4,
  "light-rail:outbound|colored->drive-lane:outbound|car": 45,
  "light-rail:outbound|colored->drive-lane:outbound|sharrow": 1,
  "light-rail:outbound|colored->drive-lane:outbound|truck": 14,
  "light-rail:outbound|colored->light-rail:inbound|colored": 25,
  "light-rail:outbound|colored->light-rail:inbound|regular": 4,
  "light-rail:outbound|colored->light-rail:outbound|colored": 3,
  "light-rail:outbound|colored->light-rail:outbound|regular": 1,
  "light-rail:outbound|colored->parking-lane:inbound|left": 1,
  "light-rail:outbound|colored->parking-lane:outbound|right": 2,
  "light-rail:outbound|colored->parklet:left": 2,
  "light-rail:outbound|colored->parklet:right": 3,
  "light-rail:outbound|colored->sidewalk-bench:center": 1,
  "light-rail:outbound|colored->sidewalk-lamp:both|modern": 9,
  "light-rail:outbound|colored->sidewalk-lamp:both|traditional": 9,
  "light-rail:outbound|colored->sidewalk-lamp:left|modern": 18,
  "light-rail:outbound|colored->sidewalk-lamp:left|traditional": 2,
  "light-rail:outbound|colored->sidewalk-lamp:right|modern": 4,
  "light-rail:outbound|colored->sidewalk-tree:big": 21,
  "light-rail:outbound|colored->sidewalk-tree:palm-tree": 4,
  "light-rail:outbound|colored->sidewalk-wayfinding:medium": 3,
  "light-rail:outbound|colored->sidewalk:dense": 6,
  "light-rail:outbound|colored->streetcar:inbound|regular": 1,
  "light-rail:outbound|colored->streetcar:outbound|colored": 1,
  "light-rail:outbound|colored->streetcar:outbound|regular": 2,
  "light-rail:outbound|colored->transit-shelter:left|light-rail": 2,
  "light-rail:outbound|colored->transit-shelter:right|light-rail": 109,
  "light-rail:outbound|colored->transit-shelter:right|street-level": 24,
  "light-rail:outbound|colored->turn-lane:inbound|left-straight": 1,
  "light-rail:outbound|colored->turn-lane:outbound|both": 1,
  "light-rail:outbound|colored->turn-lane:outbound|left": 1,
  "light-rail:outbound|colored->turn-lane:outbound|left-straight": 2,
  "light-rail:outbound|colored->turn-lane:outbound|right": 1,
  "light-rail:outbound|colored->turn-lane:outbound|straight": 1,
  "light-rail:outbound|grass->bike-lane:inbound|red|road": 1,
  "light-rail:outbound|grass->bike-lane:outbound|green|road": 1,
  "light-rail:outbound|grass->bike-lane:outbound|green|sidewalk": 1,
  "light-rail:outbound|grass->bike-lane:outbound|red|sidewalk": 1,
  "light-rail:outbound|grass->bus-lane:outbound|regular|typical": 1,
  "light-rail:outbound|grass->divider:big-tree": 4,
  "light-rail:outbound|grass->divider:bollard": 1,
  "light-rail:outbound|grass->divider:bush": 2,
  "light-rail:outbound|grass->divider:flowers": 3,
  "light-rail:outbound|grass->divider:median": 5,
  "light-rail:outbound|grass->divider:planter-box": 2,
  "light-rail:outbound|grass->divider:planting-strip": 1,
  "light-rail:outbound|grass->divider:striped-buffer": 1,
  "light-rail:outbound|grass->drive-lane:inbound|car": 1,
  "light-rail:outbound|grass->drive-lane:outbound|car": 9,
  "light-rail:outbound|grass->flex-zone-curb:empty|left": 1,
  "light-rail:outbound|grass->flex-zone-curb:sparse|left": 7,
  "light-rail:outbound|grass->light-rail:inbound|grass": 14,
  "light-rail:outbound|grass->light-rail:outbound|colored": 1,
  "light-rail:outbound|grass->light-rail:outbound|grass": 2,
  "light-rail:outbound|grass->sidewalk-bench:right": 1,
  "light-rail:outbound|grass->sidewalk-lamp:both|modern": 3,
  "light-rail:outbound|grass->sidewalk-lamp:both|traditional": 2,
  "light-rail:outbound|grass->sidewalk-lamp:left|modern": 3,
  "light-rail:outbound|grass->sidewalk-lamp:right|pride": 1,
  "light-rail:outbound|grass->sidewalk-tree:big": 7,
  "light-rail:outbound|grass->sidewalk-wayfinding:small": 2,
  "light-rail:outbound|grass->sidewalk:dense": 1,
  "light-rail:outbound|grass->sidewalk:normal": 2,
  "light-rail:outbound|grass->transit-shelter:right|light-rail": 16,
  "light-rail:outbound|grass->transit-shelter:right|street-level": 6,
  "light-rail:outbound|grass->turn-lane:outbound|left-straight": 1,
  "light-rail:outbound|regular->bike-lane:inbound|colored": 3,
  "light-rail:outbound|regular->bike-lane:inbound|green|road": 1,
  "light-rail:outbound|regular->bike-lane:inbound|regular": 7,
  "light-rail:outbound|regular->bike-lane:inbound|regular|road": 1,
  "light-rail:outbound|regular->bike-lane:outbound|colored": 157,
  "light-rail:outbound|regular->bike-lane:outbound|green|road": 5,
  "light-rail:outbound|regular->bike-lane:outbound|green|sidewalk": 1,
  "light-rail:outbound|regular->bike-lane:outbound|regular": 15,
  "light-rail:outbound|regular->bike-lane:outbound|regular|road": 2,
  "light-rail:outbound|regular->bikeshare:left|road": 1,
  "light-rail:outbound|regular->brt-station:center": 1,
  "light-rail:outbound|regular->brt-station:right": 1,
  "light-rail:outbound|regular->bus-lane:inbound|colored": 1,
  "light-rail:outbound|regular->bus-lane:inbound|regular": 3,
  "light-rail:outbound|regular->bus-lane:inbound|regular|typical": 1,
  "light-rail:outbound|regular->bus-lane:outbound|colored": 2,
  "light-rail:outbound|regular->bus-lane:outbound|regular": 21,
  "light-rail:outbound|regular->bus-lane:outbound|regular|typical": 3,
  "light-rail:outbound|regular->bus-lane:outbound|shared|typical": 1,
  "light-rail:outbound|regular->divider:big-tree": 23,
  "light-rail:outbound|regular->divider:bollard": 28,
  "light-rail:outbound|regular->divider:bush": 20,
  "light-rail:outbound|regular->divider:dome": 13,
  "light-rail:outbound|regular->divider:flowers": 71,
  "light-rail:outbound|regular->divider:median": 59,
  "light-rail:outbound|regular->divider:palm-tree": 7,
  "light-rail:outbound|regular->divider:planter-box": 3,
  "light-rail:outbound|regular->divider:planting-strip": 14,
  "light-rail:outbound|regular->divider:striped-buffer": 12,
  "light-rail:outbound|regular->drive-lane:inbound|car": 12,
  "light-rail:outbound|regular->drive-lane:inbound|sharrow": 1,
  "light-rail:outbound|regular->drive-lane:inbound|truck": 2,
  "light-rail:outbound|regular->drive-lane:outbound|car": 124,
  "light-rail:outbound|regular->drive-lane:outbound|sharrow": 2,
  "light-rail:outbound|regular->drive-lane:outbound|truck": 107,
  "light-rail:outbound|regular->flex-zone-curb:empty|right": 1,
  "light-rail:outbound|regular->flex-zone-curb:sparse|left": 6,
  "light-rail:outbound|regular->flex-zone-curb:sparse|right": 1,
  "light-rail:outbound|regular->light-rail:inbound|colored": 1,
  "light-rail:outbound|regular->light-rail:inbound|regular": 71,
  "light-rail:outbound|regular->light-rail:outbound|regular": 76,
  "light-rail:outbound|regular->parking-lane:inbound|right": 4,
  "light-rail:outbound|regular->parking-lane:outbound|right": 40,
  "light-rail:outbound|regular->parklet:left": 1,
  "light-rail:outbound|regular->parklet:right": 13,
  "light-rail:outbound|regular->scooter:outbound|green": 1,
  "light-rail:outbound|regular->sidewalk-bench:center": 1,
  "light-rail:outbound|regular->sidewalk-bench:left": 1,
  "light-rail:outbound|regular->sidewalk-bench:right": 3,
  "light-rail:outbound|regular->sidewalk-bike-rack:left|sidewalk": 1,
  "light-rail:outbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "light-rail:outbound|regular->sidewalk-lamp:both|modern": 33,
  "light-rail:outbound|regular->sidewalk-lamp:both|traditional": 14,
  "light-rail:outbound|regular->sidewalk-lamp:left|modern": 89,
  "light-rail:outbound|regular->sidewalk-lamp:left|traditional": 15,
  "light-rail:outbound|regular->sidewalk-lamp:right|modern": 3,
  "light-rail:outbound|regular->sidewalk-lamp:right|traditional": 2,
  "light-rail:outbound|regular->sidewalk-tree:big": 99,
  "light-rail:outbound|regular->sidewalk-tree:palm-tree": 6,
  "light-rail:outbound|regular->sidewalk-wayfinding:large": 4,
  "light-rail:outbound|regular->sidewalk-wayfinding:medium": 1,
  "light-rail:outbound|regular->sidewalk-wayfinding:small": 6,
  "light-rail:outbound|regular->sidewalk:dense": 29,
  "light-rail:outbound|regular->sidewalk:empty": 2,
  "light-rail:outbound|regular->sidewalk:normal": 10,
  "light-rail:outbound|regular->sidewalk:sparse": 1,
  "light-rail:outbound|regular->streetcar:inbound|colored": 1,
  "light-rail:outbound|regular->streetcar:inbound|regular": 2,
  "light-rail:outbound|regular->streetcar:outbound|regular": 23,
  "light-rail:outbound|regular->transit-shelter:left|light-rail": 8,
  "light-rail:outbound|regular->transit-shelter:left|street-level": 4,
  "light-rail:outbound|regular->transit-shelter:right|light-rail": 287,
  "light-rail:outbound|regular->transit-shelter:right|street-level": 74,
  "light-rail:outbound|regular->turn-lane:inbound|left-right-straight": 1,
  "light-rail:outbound|regular->turn-lane:inbound|right-straight": 2,
  "light-rail:outbound|regular->turn-lane:outbound|left": 25,
  "light-rail:outbound|regular->turn-lane:outbound|left-straight": 10,
  "light-rail:outbound|regular->turn-lane:outbound|right": 2,
  "light-rail:outbound|regular->turn-lane:outbound|right-straight": 11,
  "light-rail:outbound|regular->turn-lane:outbound|shared": 1,
  "light-rail:outbound|regular->turn-lane:outbound|straight": 15,
  "outdoor-dining:occupied|road->bike-lane:inbound|green|road": 4,
  "outdoor-dining:occupied|road->bike-lane:inbound|green|sidewalk": 1,
  "outdoor-dining:occupied|road->temporary:traffic-cone": 1,
  "outdoor-dining:occupied|sidewalk->bike-lane:inbound|green|sidewalk": 1,
  "outdoor-dining:occupied|sidewalk->divider:flowers": 1,
  "outdoor-dining:occupied|sidewalk->outdoor-dining:occupied|sidewalk": 2,
  "outdoor-dining:occupied|sidewalk->parklet:left": 1,
  "outdoor-dining:occupied|sidewalk->sidewalk-lamp:both|traditional": 3,
  "outdoor-dining:occupied|sidewalk->sidewalk-lamp:left|traditional": 1,
  "outdoor-dining:occupied|sidewalk->sidewalk-lamp:right|modern": 1,
  "outdoor-dining:occupied|sidewalk->sidewalk-tree:big": 3,
  "outdoor-dining:occupied|sidewalk->sidewalk-wayfinding:small": 1,
  "outdoor-dining:occupied|sidewalk->sidewalk:normal": 2,
  "outdoor-dining:occupied|sidewalk->transit-shelter:left|street-level": 1,
  "parking-lane:angled-front-left|left->divider:striped-buffer": 1,
  "parking-lane:angled-front-left|left->drive-lane:inbound|car": 19,
  "parking-lane:angled-front-left|left->drive-lane:inbound|sharrow": 1,
  "parking-lane:angled-front-left|left->drive-lane:inbound|truck": 2,
  "parking-lane:angled-front-left|left->transit-shelter:right|street-level": 1,
  "parking-lane:angled-front-left|right->divider:striped-buffer": 1,
  "parking-lane:angled-front-left|right->drive-lane:inbound|car": 7,
  "parking-lane:angled-front-right->bus-lane:inbound": 1,
  "parking-lane:angled-front-right|left->divider:flowers": 1,
  "parking-lane:angled-front-right|left->divider:planter-box": 1,
  "parking-lane:angled-front-right|left->drive-lane:inbound|car": 2,
  "parking-lane:angled-front-right|right->bike-lane:outbound|green|road": 1,
  "parking-lane:angled-front-right|right->divider:median": 1,
  "parking-lane:angled-front-right|right->divider:planter-box": 3,
  "parking-lane:angled-front-right|right->divider:planting-strip": 2,
  "parking-lane:angled-front-right|right->parking-lane:angled-rear-left|right": 2,
  "parking-lane:angled-front-right|right->sidewalk-lamp:left|modern": 11,
  "parking-lane:angled-front-right|right->sidewalk-lamp:left|traditional": 1,
  "parking-lane:angled-front-right|right->sidewalk-tree:big": 1,
  "parking-lane:angled-front-right|right->sidewalk-tree:palm-tree": 1,
  "parking-lane:angled-front-right|right->sidewalk:dense": 1,
  "parking-lane:angled-front-right|right->sidewalk:normal": 1,
  "parking-lane:angled-rear-left->divider:planter-box": 1,
  "parking-lane:angled-rear-left->drive-lane:inbound|car": 1,
  "parking-lane:angled-rear-left|left->bike-lane:outbound|green|road": 1,
  "parking-lane:angled-rear-left|left->drive-lane:inbound|car": 3,
  "parking-lane:angled-rear-left|left->drive-lane:outbound|car": 2,
  "parking-lane:angled-rear-left|left->turn-lane:inbound|left-straight": 1,
  "parking-lane:angled-rear-left|right->divider:flowers": 2,
  "parking-lane:angled-rear-left|right->divider:striped-buffer": 1,
  "parking-lane:angled-rear-left|right->drive-lane:inbound|car": 1,
  "parking-lane:angled-rear-left|right->drive-lane:outbound|car": 1,
  "parking-lane:angled-rear-left|right->sidewalk-lamp:left|modern": 1,
  "parking-lane:angled-rear-left|right->sidewalk-tree:big": 3,
  "parking-lane:angled-rear-right->divider:bollard": 1,
  "parking-lane:angled-rear-right|left->divider:striped-buffer": 1,
  "parking-lane:angled-rear-right|left->drive-lane:outbound|car": 1,
  "parking-lane:angled-rear-right|left->scooter:outbound|regular": 1,
  "parking-lane:angled-rear-right|left->sidewalk-lamp:left|modern": 1,
  "parking-lane:angled-rear-right|left->sidewalk-lamp:left|traditional": 1,
  "parking-lane:angled-rear-right|left->sidewalk-tree:big": 1,
  "parking-lane:angled-rear-right|left->sidewalk:dense": 1,
  "parking-lane:angled-rear-right|right->divider:big-tree": 1,
  "parking-lane:angled-rear-right|right->divider:bollard": 4,
  "parking-lane:angled-rear-right|right->divider:median": 1,
  "parking-lane:angled-rear-right|right->divider:planter-box": 2,
  "parking-lane:angled-rear-right|right->divider:planting-strip": 1,
  "parking-lane:angled-rear-right|right->drive-lane:inbound|car": 1,
  "parking-lane:angled-rear-right|right->drive-lane:outbound|truck": 1,
  "parking-lane:angled-rear-right|right->sidewalk-bike-rack:left|sidewalk": 1,
  "parking-lane:angled-rear-right|right->sidewalk-lamp:both|traditional": 2,
  "parking-lane:angled-rear-right|right->sidewalk-lamp:left|modern": 4,
  "parking-lane:angled-rear-right|right->sidewalk-tree:big": 2,
  "parking-lane:angled-rear-right|right->sidewalk:normal": 2,
  "parking-lane:angled-rear-right|right->transit-shelter:right|street-level": 1,
  "parking-lane:inbound->sidewalk:normal": 1,
  "parking-lane:inbound|left->bike-lane:inbound|colored": 68,
  "parking-lane:inbound|left->bike-lane:inbound|green|road": 11,
  "parking-lane:inbound|left->bike-lane:inbound|green|sidewalk": 2,
  "parking-lane:inbound|left->bike-lane:inbound|red|road": 5,
  "parking-lane:inbound|left->bike-lane:inbound|regular": 97,
  "parking-lane:inbound|left->bike-lane:inbound|regular|road": 64,
  "parking-lane:inbound|left->bike-lane:outbound|colored": 36,
  "parking-lane:inbound|left->bike-lane:outbound|green": 1,
  "parking-lane:inbound|left->bike-lane:outbound|green|road": 15,
  "parking-lane:inbound|left->bike-lane:outbound|regular": 11,
  "parking-lane:inbound|left->bike-lane:outbound|regular|road": 1,
  "parking-lane:inbound|left->bus-lane:inbound|colored": 8,
  "parking-lane:inbound|left->bus-lane:inbound|colored|typical": 2,
  "parking-lane:inbound|left->bus-lane:inbound|regular": 74,
  "parking-lane:inbound|left->bus-lane:inbound|regular|typical": 48,
  "parking-lane:inbound|left->bus-lane:inbound|shared|typical": 13,
  "parking-lane:inbound|left->bus-lane:outbound|colored": 1,
  "parking-lane:inbound|left->bus-lane:outbound|colored|typical": 1,
  "parking-lane:inbound|left->bus-lane:outbound|regular": 5,
  "parking-lane:inbound|left->bus-lane:outbound|regular|typical": 7,
  "parking-lane:inbound|left->bus-lane:outbound|shared|typical": 1,
  "parking-lane:inbound|left->divider:big-tree": 9,
  "parking-lane:inbound|left->divider:bollard": 5,
  "parking-lane:inbound|left->divider:bush": 3,
  "parking-lane:inbound|left->divider:dome": 6,
  "parking-lane:inbound|left->divider:flowers": 23,
  "parking-lane:inbound|left->divider:median": 11,
  "parking-lane:inbound|left->divider:palm-tree": 1,
  "parking-lane:inbound|left->divider:planter-box": 10,
  "parking-lane:inbound|left->divider:planting-strip": 7,
  "parking-lane:inbound|left->divider:striped-buffer": 48,
  "parking-lane:inbound|left->drive-lane:car": 2,
  "parking-lane:inbound|left->drive-lane:inbound|car": 1481,
  "parking-lane:inbound|left->drive-lane:inbound|sharrow": 47,
  "parking-lane:inbound|left->drive-lane:inbound|truck": 77,
  "parking-lane:inbound|left->drive-lane:outbound|car": 100,
  "parking-lane:inbound|left->drive-lane:outbound|sharrow": 2,
  "parking-lane:inbound|left->drive-lane:outbound|truck": 18,
  "parking-lane:inbound|left->drive-lane:truck": 2,
  "parking-lane:inbound|left->flex-zone:taxi|inbound|left": 2,
  "parking-lane:inbound|left->light-rail:inbound|colored": 1,
  "parking-lane:inbound|left->light-rail:inbound|regular": 16,
  "parking-lane:inbound|left->light-rail:outbound|grass": 1,
  "parking-lane:inbound|left->light-rail:outbound|regular": 5,
  "parking-lane:inbound|left->parking-lane:angled-rear-left": 1,
  "parking-lane:inbound|left->parking-lane:inbound|left": 27,
  "parking-lane:inbound|left->parking-lane:inbound|right": 13,
  "parking-lane:inbound|left->parking-lane:outbound|left": 6,
  "parking-lane:inbound|left->parking-lane:outbound|right": 16,
  "parking-lane:inbound|left->parklet:right": 1,
  "parking-lane:inbound|left->scooter:inbound|green": 1,
  "parking-lane:inbound|left->scooter:inbound|red": 1,
  "parking-lane:inbound|left->scooter:inbound|regular": 2,
  "parking-lane:inbound|left->sidewalk-bench:left": 1,
  "parking-lane:inbound|left->sidewalk-bike-rack:left|sidewalk-parallel": 3,
  "parking-lane:inbound|left->sidewalk-bike-rack:right|sidewalk-parallel": 2,
  "parking-lane:inbound|left->sidewalk-lamp:both|modern": 5,
  "parking-lane:inbound|left->sidewalk-lamp:both|traditional": 13,
  "parking-lane:inbound|left->sidewalk-lamp:left|modern": 28,
  "parking-lane:inbound|left->sidewalk-lamp:left|traditional": 8,
  "parking-lane:inbound|left->sidewalk-tree:big": 31,
  "parking-lane:inbound|left->sidewalk-tree:palm-tree": 2,
  "parking-lane:inbound|left->sidewalk-wayfinding:medium": 2,
  "parking-lane:inbound|left->sidewalk:dense": 13,
  "parking-lane:inbound|left->sidewalk:normal": 5,
  "parking-lane:inbound|left->sidewalk:sparse": 2,
  "parking-lane:inbound|left->streetcar:inbound|colored": 1,
  "parking-lane:inbound|left->streetcar:inbound|regular": 31,
  "parking-lane:inbound|left->streetcar:outbound|regular": 4,
  "parking-lane:inbound|left->transit-shelter:left|street-level": 3,
  "parking-lane:inbound|left->transit-shelter:right|street-level": 5,
  "parking-lane:inbound|left->turn-lane:inbound|both": 1,
  "parking-lane:inbound|left->turn-lane:inbound|left": 50,
  "parking-lane:inbound|left->turn-lane:inbound|left-right-straight": 5,
  "parking-lane:inbound|left->turn-lane:inbound|left-straight": 38,
  "parking-lane:inbound|left->turn-lane:inbound|right": 7,
  "parking-lane:inbound|left->turn-lane:inbound|right-straight": 52,
  "parking-lane:inbound|left->turn-lane:inbound|shared": 4,
  "parking-lane:inbound|left->turn-lane:inbound|straight": 31,
  "parking-lane:inbound|left->turn-lane:outbound|both": 1,
  "parking-lane:inbound|left->turn-lane:outbound|left": 2,
  "parking-lane:inbound|left->turn-lane:outbound|left-straight": 9,
  "parking-lane:inbound|left->turn-lane:outbound|right-straight": 3,
  "parking-lane:inbound|left->turn-lane:outbound|straight": 14,
  "parking-lane:inbound|left->turn-lane:straight|inbound": 1,
  "parking-lane:inbound|right->bike-lane:inbound|colored": 18,
  "parking-lane:inbound|right->bike-lane:inbound|green|road": 5,
  "parking-lane:inbound|right->bike-lane:inbound|green|sidewalk": 2,
  "parking-lane:inbound|right->bike-lane:inbound|red|road": 1,
  "parking-lane:inbound|right->bike-lane:inbound|regular": 15,
  "parking-lane:inbound|right->bike-lane:inbound|regular|road": 17,
  "parking-lane:inbound|right->bike-lane:outbound|colored": 15,
  "parking-lane:inbound|right->bike-lane:outbound|green|road": 4,
  "parking-lane:inbound|right->bike-lane:outbound|green|sidewalk": 2,
  "parking-lane:inbound|right->bike-lane:outbound|red|road": 1,
  "parking-lane:inbound|right->bike-lane:outbound|regular": 11,
  "parking-lane:inbound|right->bike-lane:outbound|regular|road": 9,
  "parking-lane:inbound|right->bus-lane:inbound|regular": 19,
  "parking-lane:inbound|right->bus-lane:inbound|regular|typical": 9,
  "parking-lane:inbound|right->bus-lane:outbound|colored": 1,
  "parking-lane:inbound|right->bus-lane:outbound|regular": 2,
  "parking-lane:inbound|right->bus-lane:outbound|regular|typical": 2,
  "parking-lane:inbound|right->divider:big-tree": 38,
  "parking-lane:inbound|right->divider:bollard": 27,
  "parking-lane:inbound|right->divider:bush": 24,
  "parking-lane:inbound|right->divider:dome": 14,
  "parking-lane:inbound|right->divider:flowers": 66,
  "parking-lane:inbound|right->divider:median": 56,
  "parking-lane:inbound|right->divider:palm-tree": 3,
  "parking-lane:inbound|right->divider:planter-box": 60,
  "parking-lane:inbound|right->divider:planting-strip": 17,
  "parking-lane:inbound|right->divider:striped-buffer": 18,
  "parking-lane:inbound|right->drive-lane:inbound|car": 230,
  "parking-lane:inbound|right->drive-lane:inbound|sharrow": 6,
  "parking-lane:inbound|right->drive-lane:inbound|truck": 8,
  "parking-lane:inbound|right->drive-lane:outbound|car": 11,
  "parking-lane:inbound|right->drive-lane:outbound|sharrow": 1,
  "parking-lane:inbound|right->flex-zone-curb:empty|right": 1,
  "parking-lane:inbound|right->flex-zone-curb:sparse|left": 6,
  "parking-lane:inbound|right->flex-zone:rideshare|inbound|right": 1,
  "parking-lane:inbound|right->flex-zone:taxi|inbound|left": 1,
  "parking-lane:inbound|right->flex-zone:taxi|inbound|right": 1,
  "parking-lane:inbound|right->food-truck:left": 1,
  "parking-lane:inbound|right->light-rail:inbound|colored": 2,
  "parking-lane:inbound|right->light-rail:inbound|regular": 10,
  "parking-lane:inbound|right->light-rail:outbound|regular": 3,
  "parking-lane:inbound|right->parking-lane:inbound|left": 6,
  "parking-lane:inbound|right->parking-lane:inbound|right": 30,
  "parking-lane:inbound|right->parking-lane:outbound|left": 1,
  "parking-lane:inbound|right->parking-lane:outbound|right": 6,
  "parking-lane:inbound|right->parklet:left": 1,
  "parking-lane:inbound|right->parklet:right": 15,
  "parking-lane:inbound|right->scooter:outbound|regular": 1,
  "parking-lane:inbound|right->sidewalk-bench:left": 1,
  "parking-lane:inbound|right->sidewalk-bench:right": 1,
  "parking-lane:inbound|right->sidewalk-bike-rack:left|sidewalk-parallel": 6,
  "parking-lane:inbound|right->sidewalk-bike-rack:right|sidewalk": 1,
  "parking-lane:inbound|right->sidewalk-lamp:both|modern": 29,
  "parking-lane:inbound|right->sidewalk-lamp:both|traditional": 25,
  "parking-lane:inbound|right->sidewalk-lamp:left|modern": 249,
  "parking-lane:inbound|right->sidewalk-lamp:left|pride": 2,
  "parking-lane:inbound|right->sidewalk-lamp:left|traditional": 49,
  "parking-lane:inbound|right->sidewalk-lamp:right|modern": 3,
  "parking-lane:inbound|right->sidewalk-lamp:right|traditional": 4,
  "parking-lane:inbound|right->sidewalk-tree:big": 274,
  "parking-lane:inbound|right->sidewalk-tree:palm-tree": 12,
  "parking-lane:inbound|right->sidewalk-wayfinding:large": 4,
  "parking-lane:inbound|right->sidewalk-wayfinding:medium": 1,
  "parking-lane:inbound|right->sidewalk:dense": 124,
  "parking-lane:inbound|right->sidewalk:empty": 4,
  "parking-lane:inbound|right->sidewalk:normal": 59,
  "parking-lane:inbound|right->sidewalk:sparse": 10,
  "parking-lane:inbound|right->streetcar:inbound|regular": 6,
  "parking-lane:inbound|right->streetcar:outbound|regular": 1,
  "parking-lane:inbound|right->transit-shelter:left|light-rail": 1,
  "parking-lane:inbound|right->transit-shelter:left|street-level": 4,
  "parking-lane:inbound|right->transit-shelter:right|light-rail": 2,
  "parking-lane:inbound|right->transit-shelter:right|street-level": 9,
  "parking-lane:inbound|right->turn-lane:inbound|left": 4,
  "parking-lane:inbound|right->turn-lane:inbound|left-straight": 11,
  "parking-lane:inbound|right->turn-lane:inbound|right": 22,
  "parking-lane:inbound|right->turn-lane:inbound|right-straight": 6,
  "parking-lane:inbound|right->turn-lane:inbound|straight": 8,
  "parking-lane:inbound|right->turn-lane:outbound|left": 1,
  "parking-lane:inbound|right->turn-lane:outbound|left-straight": 10,
  "parking-lane:inbound|right->turn-lane:outbound|right": 3,
  "parking-lane:inbound|right->turn-lane:outbound|right-straight": 1,
  "parking-lane:inbound|right->turn-lane:outbound|straight": 2,
  "parking-lane:outbound->divider:bollard": 1,
  "parking-lane:outbound->divider:planter-box": 1,
  "parking-lane:outbound->sidewalk-tree:big": 1,
  "parking-lane:outbound|left->bike-lane:inbound|colored": 19,
  "parking-lane:outbound|left->bike-lane:inbound|regular|road": 3,
  "parking-lane:outbound|left->bike-lane:outbound|colored": 364,
  "parking-lane:outbound|left->bike-lane:outbound|green|road": 17,
  "parking-lane:outbound|left->bike-lane:outbound|green|sidewalk": 2,
  "parking-lane:outbound|left->bike-lane:outbound|red|road": 1,
  "parking-lane:outbound|left->bike-lane:outbound|red|sidewalk": 1,
  "parking-lane:outbound|left->bike-lane:outbound|regular": 9,
  "parking-lane:outbound|left->bike-lane:outbound|regular|road": 9,
  "parking-lane:outbound|left->bus-lane:inbound|regular": 8,
  "parking-lane:outbound|left->bus-lane:inbound|shared|typical": 2,
  "parking-lane:outbound|left->bus-lane:outbound|regular": 6,
  "parking-lane:outbound|left->bus-lane:outbound|regular|typical": 3,
  "parking-lane:outbound|left->bus-lane:outbound|shared|typical": 1,
  "parking-lane:outbound|left->divider:big-tree": 4,
  "parking-lane:outbound|left->divider:bollard": 7,
  "parking-lane:outbound|left->divider:bush": 44,
  "parking-lane:outbound|left->divider:dome": 3,
  "parking-lane:outbound|left->divider:flowers": 13,
  "parking-lane:outbound|left->divider:median": 7,
  "parking-lane:outbound|left->divider:palm-tree": 2,
  "parking-lane:outbound|left->divider:planter-box": 40,
  "parking-lane:outbound|left->divider:planting-strip": 3,
  "parking-lane:outbound|left->divider:striped-buffer": 19,
  "parking-lane:outbound|left->drive-lane:inbound|car": 47,
  "parking-lane:outbound|left->drive-lane:inbound|sharrow": 1,
  "parking-lane:outbound|left->drive-lane:inbound|truck": 3,
  "parking-lane:outbound|left->drive-lane:outbound|car": 208,
  "parking-lane:outbound|left->drive-lane:outbound|sharrow": 8,
  "parking-lane:outbound|left->drive-lane:outbound|truck": 60,
  "parking-lane:outbound|left->light-rail:inbound|colored": 1,
  "parking-lane:outbound|left->light-rail:inbound|regular": 5,
  "parking-lane:outbound|left->light-rail:outbound|regular": 4,
  "parking-lane:outbound|left->parking-lane:inbound|left": 2,
  "parking-lane:outbound|left->parking-lane:outbound|left": 1,
  "parking-lane:outbound|left->parking-lane:outbound|right": 9,
  "parking-lane:outbound|left->parking-lane:sideways|right": 1,
  "parking-lane:outbound|left->parklet:right": 1,
  "parking-lane:outbound|left->sidewalk-bike-rack:left|sidewalk": 2,
  "parking-lane:outbound|left->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "parking-lane:outbound|left->sidewalk-lamp:both|modern": 15,
  "parking-lane:outbound|left->sidewalk-lamp:both|pride": 1,
  "parking-lane:outbound|left->sidewalk-lamp:both|traditional": 7,
  "parking-lane:outbound|left->sidewalk-lamp:left|modern": 58,
  "parking-lane:outbound|left->sidewalk-lamp:left|traditional": 13,
  "parking-lane:outbound|left->sidewalk-lamp:right|modern": 1,
  "parking-lane:outbound|left->sidewalk-lamp:right|traditional": 1,
  "parking-lane:outbound|left->sidewalk-tree:big": 32,
  "parking-lane:outbound|left->sidewalk-tree:palm-tree": 4,
  "parking-lane:outbound|left->sidewalk-wayfinding:large": 1,
  "parking-lane:outbound|left->sidewalk:dense": 7,
  "parking-lane:outbound|left->sidewalk:empty": 1,
  "parking-lane:outbound|left->sidewalk:normal": 11,
  "parking-lane:outbound|left->sidewalk:sparse": 2,
  "parking-lane:outbound|left->streetcar:inbound|regular": 2,
  "parking-lane:outbound|left->streetcar:outbound|regular": 3,
  "parking-lane:outbound|left->transit-shelter:left|light-rail": 1,
  "parking-lane:outbound|left->transit-shelter:left|street-level": 1,
  "parking-lane:outbound|left->transit-shelter:right|street-level": 5,
  "parking-lane:outbound|left->turn-lane:inbound|left-straight": 4,
  "parking-lane:outbound|left->turn-lane:inbound|right": 4,
  "parking-lane:outbound|left->turn-lane:inbound|right-straight": 6,
  "parking-lane:outbound|left->turn-lane:inbound|shared": 1,
  "parking-lane:outbound|left->turn-lane:inbound|straight": 5,
  "parking-lane:outbound|left->turn-lane:outbound|both": 3,
  "parking-lane:outbound|left->turn-lane:outbound|left": 6,
  "parking-lane:outbound|left->turn-lane:outbound|left-right-straight": 2,
  "parking-lane:outbound|left->turn-lane:outbound|left-straight": 31,
  "parking-lane:outbound|left->turn-lane:outbound|right": 8,
  "parking-lane:outbound|left->turn-lane:outbound|right-straight": 913,
  "parking-lane:outbound|left->turn-lane:outbound|shared": 2,
  "parking-lane:outbound|left->turn-lane:outbound|straight": 43,
  "parking-lane:outbound|left->turn-lane:straight": 1,
  "parking-lane:outbound|right->bike-lane:inbound|colored": 16,
  "parking-lane:outbound|right->bike-lane:inbound|green|road": 2,
  "parking-lane:outbound|right->bike-lane:inbound|regular": 6,
  "parking-lane:outbound|right->bike-lane:inbound|regular|road": 25,
  "parking-lane:outbound|right->bike-lane:outbound|colored": 534,
  "parking-lane:outbound|right->bike-lane:outbound|green|road": 299,
  "parking-lane:outbound|right->bike-lane:outbound|green|sidewalk": 7,
  "parking-lane:outbound|right->bike-lane:outbound|red|road": 6,
  "parking-lane:outbound|right->bike-lane:outbound|regular": 40,
  "parking-lane:outbound|right->bike-lane:outbound|regular|road": 23,
  "parking-lane:outbound|right->bike-lane:outbound|regular|sidewalk": 1,
  "parking-lane:outbound|right->bike-lane:sidewalk|green|inbound": 1,
  "parking-lane:outbound|right->bikeshare:left|road": 1,
  "parking-lane:outbound|right->bus-lane:inbound|regular": 8,
  "parking-lane:outbound|right->bus-lane:inbound|regular|typical": 5,
  "parking-lane:outbound|right->bus-lane:inbound|shared|typical": 9,
  "parking-lane:outbound|right->bus-lane:outbound|colored": 3,
  "parking-lane:outbound|right->bus-lane:outbound|regular": 20,
  "parking-lane:outbound|right->bus-lane:outbound|regular|typical": 10,
  "parking-lane:outbound|right->bus-lane:outbound|shared|typical": 1,
  "parking-lane:outbound|right->divider:big-tree": 107,
  "parking-lane:outbound|right->divider:bollard": 90,
  "parking-lane:outbound|right->divider:bush": 73,
  "parking-lane:outbound|right->divider:dome": 49,
  "parking-lane:outbound|right->divider:flowers": 188,
  "parking-lane:outbound|right->divider:median": 121,
  "parking-lane:outbound|right->divider:palm-tree": 38,
  "parking-lane:outbound|right->divider:planter-box": 13353,
  "parking-lane:outbound|right->divider:planting-strip": 53,
  "parking-lane:outbound|right->divider:striped-buffer": 78,
  "parking-lane:outbound|right->drive-lane:inbound|car": 40,
  "parking-lane:outbound|right->drive-lane:inbound|sharrow": 2,
  "parking-lane:outbound|right->drive-lane:inbound|truck": 4,
  "parking-lane:outbound|right->drive-lane:outbound|car": 92,
  "parking-lane:outbound|right->drive-lane:outbound|sharrow": 1,
  "parking-lane:outbound|right->drive-lane:outbound|truck": 38,
  "parking-lane:outbound|right->flex-zone-curb:empty|right": 1,
  "parking-lane:outbound|right->flex-zone-curb:sparse|left": 7,
  "parking-lane:outbound|right->flex-zone-curb:sparse|right": 2,
  "parking-lane:outbound|right->flex-zone:rideshare|inbound|right": 1,
  "parking-lane:outbound|right->flex-zone:taxi|inbound|left": 3,
  "parking-lane:outbound|right->flex-zone:taxi|inbound|right": 3,
  "parking-lane:outbound|right->flex-zone:taxi|outbound|left": 1,
  "parking-lane:outbound|right->flex-zone:taxi|outbound|right": 1,
  "parking-lane:outbound|right->food-truck:left": 9,
  "parking-lane:outbound|right->food-truck:right": 5,
  "parking-lane:outbound|right->light-rail:inbound|grass": 1,
  "parking-lane:outbound|right->light-rail:inbound|regular": 19,
  "parking-lane:outbound|right->light-rail:outbound|colored": 1,
  "parking-lane:outbound|right->light-rail:outbound|regular": 11,
  "parking-lane:outbound|right->parking-lane:inbound|left": 1,
  "parking-lane:outbound|right->parking-lane:inbound|right": 6,
  "parking-lane:outbound|right->parking-lane:outbound|left": 2,
  "parking-lane:outbound|right->parking-lane:outbound|right": 7,
  "parking-lane:outbound|right->parklet:left": 3,
  "parking-lane:outbound|right->parklet:right": 19,
  "parking-lane:outbound|right->scooter-drop-zone:right|sidewalk|empty": 2,
  "parking-lane:outbound|right->scooter:inbound|regular": 1,
  "parking-lane:outbound|right->scooter:outbound|regular": 2,
  "parking-lane:outbound|right->sidewalk-bench:left": 2,
  "parking-lane:outbound|right->sidewalk-bench:right": 21,
  "parking-lane:outbound|right->sidewalk-bike-rack:left|sidewalk": 1,
  "parking-lane:outbound|right->sidewalk-bike-rack:left|sidewalk-parallel": 6,
  "parking-lane:outbound|right->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "parking-lane:outbound|right->sidewalk-lamp:both|modern": 109,
  "parking-lane:outbound|right->sidewalk-lamp:both|pride": 1,
  "parking-lane:outbound|right->sidewalk-lamp:both|traditional": 68,
  "parking-lane:outbound|right->sidewalk-lamp:left|modern": 13626,
  "parking-lane:outbound|right->sidewalk-lamp:left|pride": 3,
  "parking-lane:outbound|right->sidewalk-lamp:left|traditional": 272,
  "parking-lane:outbound|right->sidewalk-lamp:modern": 1,
  "parking-lane:outbound|right->sidewalk-lamp:right|modern": 14,
  "parking-lane:outbound|right->sidewalk-lamp:right|traditional": 23,
  "parking-lane:outbound|right->sidewalk-tree:big": 527,
  "parking-lane:outbound|right->sidewalk-tree:palm-tree": 18,
  "parking-lane:outbound|right->sidewalk-wayfinding:large": 1,
  "parking-lane:outbound|right->sidewalk-wayfinding:medium": 15,
  "parking-lane:outbound|right->sidewalk-wayfinding:small": 5,
  "parking-lane:outbound|right->sidewalk:dense": 131,
  "parking-lane:outbound|right->sidewalk:empty": 10,
  "parking-lane:outbound|right->sidewalk:normal": 180,
  "parking-lane:outbound|right->sidewalk:sparse": 20,
  "parking-lane:outbound|right->streetcar:inbound|regular": 4,
  "parking-lane:outbound|right->streetcar:outbound|regular": 6,
  "parking-lane:outbound|right->transit-shelter:left|light-rail": 1,
  "parking-lane:outbound|right->transit-shelter:left|street-level": 14,
  "parking-lane:outbound|right->transit-shelter:right|street-level": 38,
  "parking-lane:outbound|right->turn-lane:inbound|left-right-straight": 1,
  "parking-lane:outbound|right->turn-lane:inbound|right": 13,
  "parking-lane:outbound|right->turn-lane:inbound|right-straight": 5,
  "parking-lane:outbound|right->turn-lane:inbound|straight": 2,
  "parking-lane:outbound|right->turn-lane:outbound|left": 2,
  "parking-lane:outbound|right->turn-lane:outbound|left-straight": 33,
  "parking-lane:outbound|right->turn-lane:outbound|right": 12,
  "parking-lane:outbound|right->turn-lane:outbound|right-straight": 2,
  "parking-lane:outbound|right->turn-lane:outbound|straight": 5,
  "parking-lane:right->sidewalk:normal": 1,
  "parking-lane:sideways|left->bike-lane:inbound|regular": 6,
  "parking-lane:sideways|left->bike-lane:outbound|colored": 1,
  "parking-lane:sideways|left->bus-lane:inbound|colored": 1,
  "parking-lane:sideways|left->bus-lane:outbound|regular|typical": 2,
  "parking-lane:sideways|left->divider:palm-tree": 1,
  "parking-lane:sideways|left->divider:striped-buffer": 5,
  "parking-lane:sideways|left->drive-lane:inbound|car": 36,
  "parking-lane:sideways|left->drive-lane:inbound|sharrow": 3,
  "parking-lane:sideways|left->drive-lane:inbound|truck": 1,
  "parking-lane:sideways|left->drive-lane:outbound|car": 9,
  "parking-lane:sideways|left->drive-lane:outbound|sharrow": 1,
  "parking-lane:sideways|left->parking-lane:outbound|right": 1,
  "parking-lane:sideways|left->parking-lane:sideways|left": 2,
  "parking-lane:sideways|left->parking-lane:sideways|right": 1,
  "parking-lane:sideways|left->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "parking-lane:sideways|left->sidewalk-lamp:both|traditional": 1,
  "parking-lane:sideways|left->sidewalk-lamp:left|modern": 2,
  "parking-lane:sideways|left->sidewalk-lamp:left|traditional": 2,
  "parking-lane:sideways|left->sidewalk-lamp:right|modern": 3,
  "parking-lane:sideways|left->sidewalk-tree:big": 2,
  "parking-lane:sideways|left->sidewalk:dense": 3,
  "parking-lane:sideways|left->sidewalk:normal": 1,
  "parking-lane:sideways|left->sidewalk:sparse": 1,
  "parking-lane:sideways|left->turn-lane:inbound|left": 1,
  "parking-lane:sideways|left->turn-lane:inbound|left-right-straight": 1,
  "parking-lane:sideways|left->turn-lane:inbound|straight": 1,
  "parking-lane:sideways|left->turn-lane:outbound|left-straight": 2,
  "parking-lane:sideways|left->turn-lane:outbound|straight": 2,
  "parking-lane:sideways|right->bike-lane:inbound|colored": 2,
  "parking-lane:sideways|right->bike-lane:outbound|colored": 2,
  "parking-lane:sideways|right->bike-lane:outbound|regular": 1,
  "parking-lane:sideways|right->divider:big-tree": 2,
  "parking-lane:sideways|right->divider:bush": 4,
  "parking-lane:sideways|right->divider:dome": 1,
  "parking-lane:sideways|right->divider:flowers": 4,
  "parking-lane:sideways|right->divider:median": 4,
  "parking-lane:sideways|right->divider:palm-tree": 1,
  "parking-lane:sideways|right->divider:planter-box": 4,
  "parking-lane:sideways|right->divider:planting-strip": 1,
  "parking-lane:sideways|right->divider:striped-buffer": 3,
  "parking-lane:sideways|right->drive-lane:inbound|car": 9,
  "parking-lane:sideways|right->drive-lane:outbound|car": 5,
  "parking-lane:sideways|right->drive-lane:outbound|sharrow": 1,
  "parking-lane:sideways|right->parking-lane:sideways|left": 4,
  "parking-lane:sideways|right->parking-lane:sideways|right": 1,
  "parking-lane:sideways|right->sidewalk-lamp:both|modern": 4,
  "parking-lane:sideways|right->sidewalk-lamp:both|traditional": 1,
  "parking-lane:sideways|right->sidewalk-lamp:left|modern": 10,
  "parking-lane:sideways|right->sidewalk-lamp:left|traditional": 2,
  "parking-lane:sideways|right->sidewalk-tree:big": 23,
  "parking-lane:sideways|right->sidewalk-tree:palm-tree": 1,
  "parking-lane:sideways|right->sidewalk-wayfinding:small": 2,
  "parking-lane:sideways|right->sidewalk:dense": 14,
  "parking-lane:sideways|right->sidewalk:empty": 1,
  "parking-lane:sideways|right->sidewalk:normal": 5,
  "parking-lane:sideways|right->sidewalk:sparse": 2,
  "parklet:left->bike-lane:inbound|colored": 28,
  "parklet:left->bike-lane:inbound|green|road": 1,
  "parklet:left->bike-lane:inbound|red|road": 1,
  "parklet:left->bike-lane:inbound|regular": 64,
  "parklet:left->bike-lane:inbound|regular|road": 16,
  "parklet:left->bike-lane:outbound|colored": 22,
  "parklet:left->bike-lane:outbound|green|road": 4,
  "parklet:left->bike-lane:outbound|red|road": 1,
  "parklet:left->bike-lane:outbound|regular": 5,
  "parklet:left->bikeshare:left|road": 2,
  "parklet:left->bus-lane:inbound|colored|typical": 1,
  "parklet:left->bus-lane:inbound|regular": 30,
  "parklet:left->bus-lane:inbound|regular|typical": 4,
  "parklet:left->bus-lane:inbound|shared|typical": 7,
  "parklet:left->bus-lane:outbound|regular": 3,
  "parklet:left->bus-lane:outbound|regular|typical": 1,
  "parklet:left->divider:big-tree": 9,
  "parklet:left->divider:bollard": 3,
  "parklet:left->divider:bush": 8,
  "parklet:left->divider:flowers": 30,
  "parklet:left->divider:median": 14,
  "parklet:left->divider:palm-tree": 3,
  "parklet:left->divider:planter-box": 5,
  "parklet:left->divider:planting-strip": 2,
  "parklet:left->divider:striped-buffer": 3,
  "parklet:left->drive-lane:inbound|car": 83,
  "parklet:left->drive-lane:inbound|sharrow": 5,
  "parklet:left->drive-lane:inbound|truck": 2,
  "parklet:left->drive-lane:outbound|car": 18,
  "parklet:left->drive-lane:outbound|sharrow": 1,
  "parklet:left->drive-lane:outbound|truck": 8,
  "parklet:left->flex-zone-curb:sparse|left": 2,
  "parklet:left->flex-zone:taxi|inbound|left": 1,
  "parklet:left->food-truck:left": 5,
  "parklet:left->light-rail:inbound|colored": 1,
  "parklet:left->light-rail:inbound|regular": 19,
  "parklet:left->light-rail:outbound|regular": 1,
  "parklet:left->outdoor-dining:occupied|sidewalk": 1,
  "parklet:left->parking-lane:inbound|left": 11,
  "parklet:left->parking-lane:inbound|right": 3,
  "parklet:left->parking-lane:outbound|left": 1,
  "parklet:left->parking-lane:outbound|right": 4,
  "parklet:left->parking-lane:sideways|left": 1,
  "parklet:left->parklet:left": 31,
  "parklet:left->parklet:right": 12,
  "parklet:left->scooter:inbound|regular": 9,
  "parklet:left->sidewalk-bench:center": 2,
  "parklet:left->sidewalk-bench:left": 31,
  "parklet:left->sidewalk-bench:right": 10,
  "parklet:left->sidewalk-bike-rack:left|road": 4,
  "parklet:left->sidewalk-bike-rack:left|sidewalk": 12,
  "parklet:left->sidewalk-bike-rack:left|sidewalk-parallel": 25,
  "parklet:left->sidewalk-bike-rack:right|road": 1,
  "parklet:left->sidewalk-bike-rack:right|sidewalk": 1,
  "parklet:left->sidewalk-bike-rack:right|sidewalk-parallel": 5,
  "parklet:left->sidewalk-lamp:both|modern": 15,
  "parklet:left->sidewalk-lamp:both|traditional": 19,
  "parklet:left->sidewalk-lamp:left|modern": 4,
  "parklet:left->sidewalk-lamp:left|pride": 1,
  "parklet:left->sidewalk-lamp:left|traditional": 14,
  "parklet:left->sidewalk-lamp:right|modern": 56,
  "parklet:left->sidewalk-lamp:right|traditional": 23,
  "parklet:left->sidewalk-tree:big": 138,
  "parklet:left->sidewalk-tree:palm-tree": 16,
  "parklet:left->sidewalk-wayfinding:large": 42,
  "parklet:left->sidewalk-wayfinding:medium": 5,
  "parklet:left->sidewalk-wayfinding:small": 5,
  "parklet:left->sidewalk:dense": 116,
  "parklet:left->sidewalk:normal": 25,
  "parklet:left->sidewalk:sparse": 9,
  "parklet:left->streetcar:inbound|colored": 2,
  "parklet:left->streetcar:inbound|regular": 20,
  "parklet:left->streetcar:outbound|colored": 1,
  "parklet:left->streetcar:outbound|regular": 8,
  "parklet:left->transit-shelter:left|light-rail": 5,
  "parklet:left->transit-shelter:left|street-level": 33,
  "parklet:left->transit-shelter:right|street-level": 8,
  "parklet:left->turn-lane:inbound|both": 2,
  "parklet:left->turn-lane:inbound|left": 2,
  "parklet:left->turn-lane:inbound|left-straight": 3,
  "parklet:left->turn-lane:inbound|right": 3,
  "parklet:left->turn-lane:inbound|right-straight": 16,
  "parklet:left->turn-lane:inbound|straight": 5,
  "parklet:left->turn-lane:outbound|both": 1,
  "parklet:left->turn-lane:outbound|left": 6,
  "parklet:left->turn-lane:outbound|left-straight": 6,
  "parklet:left->turn-lane:outbound|right": 1,
  "parklet:left->turn-lane:outbound|right-straight": 1,
  "parklet:left->turn-lane:outbound|straight": 1,
  "parklet:right->bike-lane:inbound|colored": 16,
  "parklet:right->bike-lane:inbound|green|road": 1,
  "parklet:right->bike-lane:inbound|green|sidewalk": 1,
  "parklet:right->bike-lane:inbound|regular": 18,
  "parklet:right->bike-lane:inbound|regular|road": 9,
  "parklet:right->bike-lane:outbound|colored": 38,
  "parklet:right->bike-lane:outbound|green|road": 6,
  "parklet:right->bike-lane:outbound|red|road": 1,
  "parklet:right->bike-lane:outbound|regular": 11,
  "parklet:right->bus-lane:inbound|regular": 7,
  "parklet:right->bus-lane:inbound|regular|typical": 2,
  "parklet:right->bus-lane:outbound|regular": 4,
  "parklet:right->bus-lane:outbound|regular|typical": 1,
  "parklet:right->divider:big-tree": 10,
  "parklet:right->divider:bollard": 1,
  "parklet:right->divider:bush": 9,
  "parklet:right->divider:dome": 1,
  "parklet:right->divider:flowers": 33,
  "parklet:right->divider:median": 15,
  "parklet:right->divider:palm-tree": 3,
  "parklet:right->divider:planter-box": 1,
  "parklet:right->divider:planting-strip": 6,
  "parklet:right->divider:striped-buffer": 2,
  "parklet:right->drive-lane:inbound|car": 40,
  "parklet:right->drive-lane:inbound|sharrow": 1,
  "parklet:right->drive-lane:inbound|truck": 1,
  "parklet:right->drive-lane:outbound|car": 12,
  "parklet:right->drive-lane:outbound|sharrow": 2,
  "parklet:right->drive-lane:outbound|truck": 10,
  "parklet:right->flex-zone-curb:sparse|left": 2,
  "parklet:right->food-truck:left": 2,
  "parklet:right->light-rail:inbound|colored": 1,
  "parklet:right->light-rail:inbound|regular": 11,
  "parklet:right->light-rail:outbound|colored": 2,
  "parklet:right->light-rail:outbound|regular": 6,
  "parklet:right->parking-lane:inbound|right": 2,
  "parklet:right->parking-lane:outbound|right": 4,
  "parklet:right->parklet:left": 8,
  "parklet:right->parklet:right": 4,
  "parklet:right->scooter:inbound|regular": 2,
  "parklet:right->sidewalk-bench:center": 2,
  "parklet:right->sidewalk-bench:left": 11,
  "parklet:right->sidewalk-bench:right": 8,
  "parklet:right->sidewalk-bike-rack:left|sidewalk": 4,
  "parklet:right->sidewalk-bike-rack:left|sidewalk-parallel": 7,
  "parklet:right->sidewalk-lamp:both|modern": 20,
  "parklet:right->sidewalk-lamp:both|pride": 1,
  "parklet:right->sidewalk-lamp:both|traditional": 40,
  "parklet:right->sidewalk-lamp:left|modern": 62,
  "parklet:right->sidewalk-lamp:left|pride": 1,
  "parklet:right->sidewalk-lamp:left|traditional": 34,
  "parklet:right->sidewalk-lamp:right|modern": 21,
  "parklet:right->sidewalk-lamp:right|pride": 1,
  "parklet:right->sidewalk-lamp:right|traditional": 10,
  "parklet:right->sidewalk-tree:big": 110,
  "parklet:right->sidewalk-tree:palm-tree": 8,
  "parklet:right->sidewalk-wayfinding:large": 17,
  "parklet:right->sidewalk-wayfinding:medium": 13,
  "parklet:right->sidewalk-wayfinding:small": 5,
  "parklet:right->sidewalk:dense": 144,
  "parklet:right->sidewalk:empty": 1,
  "parklet:right->sidewalk:normal": 54,
  "parklet:right->sidewalk:sparse": 5,
  "parklet:right->streetcar:inbound|regular": 8,
  "parklet:right->streetcar:outbound|regular": 3,
  "parklet:right->transit-shelter:left|light-rail": 3,
  "parklet:right->transit-shelter:left|street-level": 11,
  "parklet:right->transit-shelter:right|light-rail": 2,
  "parklet:right->transit-shelter:right|street-level": 2,
  "parklet:right->turn-lane:inbound|both": 1,
  "parklet:right->turn-lane:inbound|left": 2,
  "parklet:right->turn-lane:inbound|left-right-straight": 1,
  "parklet:right->turn-lane:inbound|left-straight": 2,
  "parklet:right->turn-lane:inbound|right": 2,
  "parklet:right->turn-lane:inbound|right-straight": 11,
  "parklet:right->turn-lane:inbound|straight": 1,
  "parklet:right->turn-lane:outbound|left": 5,
  "parklet:right->turn-lane:outbound|right": 1,
  "parklet:right->turn-lane:outbound|right-straight": 1,
  "scooter-drop-zone:left|road|empty->bike-lane:inbound|regular|road": 2,
  "scooter-drop-zone:left|road|empty->scooter:inbound|red": 1,
  "scooter-drop-zone:left|sidewalk|empty->scooter-drop-zone:right|sidewalk|empty": 1,
  "scooter-drop-zone:left|sidewalk|empty->sidewalk-bench:center": 1,
  "scooter-drop-zone:left|sidewalk|empty->sidewalk-lamp:left|pride": 6,
  "scooter-drop-zone:left|sidewalk|empty->sidewalk-wayfinding:medium": 1,
  "scooter-drop-zone:left|sidewalk|sparse->divider:flowers": 1,
  "scooter-drop-zone:left|sidewalk|sparse->scooter-drop-zone:left|sidewalk|sparse": 1,
  "scooter-drop-zone:left|sidewalk|sparse->sidewalk-tree:big": 1,
  "scooter-drop-zone:left|sidewalk|sparse->sidewalk-wayfinding:large": 1,
  "scooter-drop-zone:right|road|empty->bike-lane:outbound|green|road": 1,
  "scooter-drop-zone:right|road|empty->drive-lane:outbound|car": 1,
  "scooter-drop-zone:right|road|sparse->light-rail:inbound|regular": 1,
  "scooter-drop-zone:right|sidewalk|empty->bike-lane:inbound|regular|road": 6,
  "scooter-drop-zone:right|sidewalk|empty->bike-lane:inbound|regular|sidewalk": 1,
  "scooter-drop-zone:right|sidewalk|empty->bike-lane:outbound|green|road": 3,
  "scooter-drop-zone:right|sidewalk|empty->bikeshare:left|road": 6,
  "scooter-drop-zone:right|sidewalk|empty->bikeshare:right|road": 1,
  "scooter-drop-zone:right|sidewalk|empty->bus-lane:inbound|shared|typical": 3,
  "scooter-drop-zone:right|sidewalk|empty->divider:bush": 1,
  "scooter-drop-zone:right|sidewalk|empty->divider:flowers": 3,
  "scooter-drop-zone:right|sidewalk|empty->divider:planter-box": 4,
  "scooter-drop-zone:right|sidewalk|empty->divider:planting-strip": 1,
  "scooter-drop-zone:right|sidewalk|empty->drive-lane:inbound|car": 5,
  "scooter-drop-zone:right|sidewalk|empty->flex-zone-curb:sparse|left": 7,
  "scooter-drop-zone:right|sidewalk|empty->flex-zone-curb:sparse|right": 1,
  "scooter-drop-zone:right|sidewalk|empty->flex-zone:taxi|inbound|left": 2,
  "scooter-drop-zone:right|sidewalk|empty->food-truck:left": 4,
  "scooter-drop-zone:right|sidewalk|empty->parking-lane:inbound|right": 1,
  "scooter-drop-zone:right|sidewalk|empty->parking-lane:outbound|right": 2,
  "scooter-drop-zone:right|sidewalk|empty->parklet:left": 1,
  "scooter-drop-zone:right|sidewalk|empty->parklet:right": 1,
  "scooter-drop-zone:right|sidewalk|empty->scooter-drop-zone:right|sidewalk|empty": 14,
  "scooter-drop-zone:right|sidewalk|empty->scooter:inbound|regular": 10,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-bench:center": 1,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-bench:left": 1,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-bench:right": 1,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-bike-rack:left|sidewalk": 1,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-lamp:both|modern": 2,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-lamp:left|modern": 4,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-lamp:left|traditional": 3,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-lamp:right|modern": 1,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-lamp:right|traditional": 4,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-tree:big": 18,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-wayfinding:large": 2,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-wayfinding:medium": 1,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk-wayfinding:small": 1,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk:dense": 23,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk:normal": 5,
  "scooter-drop-zone:right|sidewalk|empty->sidewalk:sparse": 1,
  "scooter-drop-zone:right|sidewalk|empty->streetcar:inbound|regular": 1,
  "scooter-drop-zone:right|sidewalk|empty->transit-shelter:left|street-level": 2,
  "scooter-drop-zone:right|sidewalk|empty->transit-shelter:right|street-level": 8,
  "scooter-drop-zone:right|sidewalk|empty->turn-lane:outbound|right-straight": 1,
  "scooter-drop-zone:right|sidewalk|sparse->divider:flowers": 1,
  "scooter-drop-zone:right|sidewalk|sparse->drive-lane:inbound|car": 2,
  "scooter-drop-zone:right|sidewalk|sparse->sidewalk-bench:center": 1,
  "scooter-drop-zone:right|sidewalk|sparse->sidewalk-lamp:left|modern": 1,
  "scooter:inbound|green->bike-lane:inbound|green|road": 2,
  "scooter:inbound|green->bike-lane:inbound|red|road": 2,
  "scooter:inbound|green->bike-lane:inbound|red|sidewalk": 1,
  "scooter:inbound|green->bike-lane:inbound|regular|sidewalk": 1,
  "scooter:inbound|green->bike-lane:outbound": 1,
  "scooter:inbound|green->bike-lane:outbound|green|road": 3,
  "scooter:inbound|green->divider:big-tree": 2,
  "scooter:inbound|green->divider:bollard": 1,
  "scooter:inbound|green->divider:bush": 1,
  "scooter:inbound|green->divider:flowers": 2,
  "scooter:inbound|green->divider:median": 1,
  "scooter:inbound|green->divider:planter-box": 1,
  "scooter:inbound|green->drive-lane:inbound|truck": 1,
  "scooter:inbound|green->parking-lane:inbound|right": 1,
  "scooter:inbound|green->scooter-drop-zone:right|road|sparse": 1,
  "scooter:inbound|green->scooter:outbound|green": 1,
  "scooter:inbound|green->sidewalk-bike-rack:right|sidewalk": 1,
  "scooter:inbound|green->sidewalk-lamp:both|pride": 1,
  "scooter:inbound|green->sidewalk-lamp:left|pride": 1,
  "scooter:inbound|green->sidewalk-lamp:left|traditional": 1,
  "scooter:inbound|green->sidewalk-lamp:right|traditional": 1,
  "scooter:inbound|green->sidewalk-tree:big": 2,
  "scooter:inbound|green->sidewalk:dense": 1,
  "scooter:inbound|green->turn-lane:inbound|left-straight": 1,
  "scooter:inbound|red->bike-lane:inbound|green|road": 1,
  "scooter:inbound|red->divider:big-tree": 1,
  "scooter:inbound|red->divider:palm-tree": 2,
  "scooter:inbound|red->drive-lane:inbound|car": 1,
  "scooter:inbound|red->scooter-drop-zone:right|sidewalk|empty": 2,
  "scooter:inbound|red->scooter:outbound|green": 1,
  "scooter:inbound|red->scooter:outbound|regular": 1,
  "scooter:inbound|red->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "scooter:inbound|red->sidewalk-lamp:both|modern": 1,
  "scooter:inbound|red->sidewalk-tree:big": 1,
  "scooter:inbound|red->turn-lane:outbound|straight": 1,
  "scooter:inbound|regular->bike-lane:inbound|green|road": 1,
  "scooter:inbound|regular->bike-lane:inbound|regular|road": 30,
  "scooter:inbound|regular->bike-lane:inbound|regular|sidewalk": 1,
  "scooter:inbound|regular->bike-lane:outbound|green|road": 11,
  "scooter:inbound|regular->bike-lane:outbound|regular|road": 4,
  "scooter:inbound|regular->bikeshare:left|road": 2,
  "scooter:inbound|regular->bus-lane:inbound|regular|typical": 5,
  "scooter:inbound|regular->bus-lane:inbound|shared|typical": 6,
  "scooter:inbound|regular->divider:big-tree": 2,
  "scooter:inbound|regular->divider:bollard": 1,
  "scooter:inbound|regular->divider:bush": 1,
  "scooter:inbound|regular->divider:dome": 3,
  "scooter:inbound|regular->divider:flowers": 10,
  "scooter:inbound|regular->divider:median": 1,
  "scooter:inbound|regular->divider:planter-box": 5,
  "scooter:inbound|regular->divider:planting-strip": 5,
  "scooter:inbound|regular->drive-lane:inbound|car": 15,
  "scooter:inbound|regular->drive-lane:inbound|truck": 1,
  "scooter:inbound|regular->drive-lane:outbound|car": 1,
  "scooter:inbound|regular->flex-zone-curb:sparse|left": 6,
  "scooter:inbound|regular->flex-zone:taxi|inbound|left": 1,
  "scooter:inbound|regular->food-truck:left": 2,
  "scooter:inbound|regular->food-truck:right": 2,
  "scooter:inbound|regular->light-rail:inbound|regular": 4,
  "scooter:inbound|regular->parking-lane:inbound|left": 3,
  "scooter:inbound|regular->parking-lane:inbound|right": 1,
  "scooter:inbound|regular->parking-lane:outbound|left": 1,
  "scooter:inbound|regular->parklet:left": 2,
  "scooter:inbound|regular->parklet:right": 1,
  "scooter:inbound|regular->scooter-drop-zone:right|road|sparse": 1,
  "scooter:inbound|regular->scooter-drop-zone:right|sidewalk|empty": 6,
  "scooter:inbound|regular->scooter:inbound|regular": 108,
  "scooter:inbound|regular->scooter:outbound|regular": 8,
  "scooter:inbound|regular->sidewalk-bench:left": 2,
  "scooter:inbound|regular->sidewalk-bench:right": 1,
  "scooter:inbound|regular->sidewalk-bike-rack:left|sidewalk": 12,
  "scooter:inbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "scooter:inbound|regular->sidewalk-lamp:both|modern": 4,
  "scooter:inbound|regular->sidewalk-lamp:both|traditional": 2,
  "scooter:inbound|regular->sidewalk-lamp:left|modern": 13,
  "scooter:inbound|regular->sidewalk-lamp:left|pride": 8,
  "scooter:inbound|regular->sidewalk-lamp:left|traditional": 16,
  "scooter:inbound|regular->sidewalk-lamp:right|modern": 8,
  "scooter:inbound|regular->sidewalk-lamp:right|traditional": 6,
  "scooter:inbound|regular->sidewalk-tree:big": 37,
  "scooter:inbound|regular->sidewalk-tree:palm-tree": 3,
  "scooter:inbound|regular->sidewalk-wayfinding:large": 6,
  "scooter:inbound|regular->sidewalk:dense": 14,
  "scooter:inbound|regular->sidewalk:normal": 12,
  "scooter:inbound|regular->sidewalk:sparse": 6,
  "scooter:inbound|regular->streetcar:inbound|grass": 1,
  "scooter:inbound|regular->streetcar:inbound|regular": 2,
  "scooter:inbound|regular->transit-shelter:left|light-rail": 1,
  "scooter:inbound|regular->turn-lane:inbound|both": 2,
  "scooter:inbound|regular->turn-lane:inbound|left": 1,
  "scooter:inbound|regular->turn-lane:outbound|left-straight": 2,
  "scooter:outbound|green->bike-lane:outbound|green|road": 1,
  "scooter:outbound|green->bikeshare:left|road": 1,
  "scooter:outbound|green->divider:big-tree": 3,
  "scooter:outbound|green->divider:bush": 1,
  "scooter:outbound|green->divider:flowers": 3,
  "scooter:outbound|green->divider:palm-tree": 1,
  "scooter:outbound|green->divider:planter-box": 1,
  "scooter:outbound|green->divider:planting-strip": 2,
  "scooter:outbound|green->flex-zone-curb:sparse|left": 2,
  "scooter:outbound|green->scooter:inbound|green": 1,
  "scooter:outbound|green->sidewalk-lamp:both|modern": 1,
  "scooter:outbound|green->sidewalk-lamp:left|modern": 2,
  "scooter:outbound|green->sidewalk-lamp:left|pride": 1,
  "scooter:outbound|green->sidewalk-lamp:left|traditional": 2,
  "scooter:outbound|green->sidewalk-tree:big": 2,
  "scooter:outbound|green->sidewalk-tree:palm-tree": 3,
  "scooter:outbound|green->turn-lane:outbound|left-straight": 1,
  "scooter:outbound|green->turn-lane:outbound|right": 1,
  "scooter:outbound|red->bike-lane:outbound|green|road": 3,
  "scooter:outbound|red->bike-lane:outbound|green|sidewalk": 1,
  "scooter:outbound|red->bike-lane:outbound|red|road": 1,
  "scooter:outbound|red->divider:planter-box": 1,
  "scooter:outbound|red->drive-lane:outbound|car": 1,
  "scooter:outbound|red->parking-lane:inbound|right": 1,
  "scooter:outbound|red->parking-lane:outbound|left": 1,
  "scooter:outbound|red->parking-lane:outbound|right": 1,
  "scooter:outbound|red->sidewalk-lamp:left|modern": 1,
  "scooter:outbound|red->sidewalk-lamp:left|traditional": 1,
  "scooter:outbound|red->sidewalk-tree:big": 1,
  "scooter:outbound|red->sidewalk:dense": 1,
  "scooter:outbound|regular->bike-lane:inbound|regular|road": 3,
  "scooter:outbound|regular->bike-lane:inbound|regular|sidewalk": 1,
  "scooter:outbound|regular->bike-lane:outbound|green|road": 5,
  "scooter:outbound|regular->bike-lane:outbound|regular|road": 4,
  "scooter:outbound|regular->bikeshare:left|road": 1,
  "scooter:outbound|regular->bus-lane:inbound|shared|typical": 1,
  "scooter:outbound|regular->bus-lane:outbound|regular|typical": 1,
  "scooter:outbound|regular->divider:big-tree": 2,
  "scooter:outbound|regular->divider:dome": 1,
  "scooter:outbound|regular->divider:flowers": 3,
  "scooter:outbound|regular->divider:median": 1,
  "scooter:outbound|regular->divider:planter-box": 2,
  "scooter:outbound|regular->divider:planting-strip": 1,
  "scooter:outbound|regular->drive-lane:inbound|car": 1,
  "scooter:outbound|regular->drive-lane:outbound|car": 1,
  "scooter:outbound|regular->flex-zone:taxi|outbound|left": 1,
  "scooter:outbound|regular->food-truck:left": 2,
  "scooter:outbound|regular->parking-lane:inbound|left": 1,
  "scooter:outbound|regular->parking-lane:outbound|left": 1,
  "scooter:outbound|regular->parking-lane:outbound|right": 6,
  "scooter:outbound|regular->scooter-drop-zone:right|sidewalk|empty": 4,
  "scooter:outbound|regular->scooter:inbound|regular": 4,
  "scooter:outbound|regular->scooter:outbound|regular": 7,
  "scooter:outbound|regular->sidewalk-bike-rack:left|sidewalk": 1,
  "scooter:outbound|regular->sidewalk-lamp:left|modern": 4,
  "scooter:outbound|regular->sidewalk-lamp:left|traditional": 2,
  "scooter:outbound|regular->sidewalk-lamp:right|modern": 2,
  "scooter:outbound|regular->sidewalk-lamp:right|traditional": 1,
  "scooter:outbound|regular->sidewalk-tree:big": 17,
  "scooter:outbound|regular->sidewalk:dense": 3,
  "scooter:outbound|regular->sidewalk:normal": 1,
  "scooter:outbound|regular->turn-lane:inbound|left-straight": 1,
  "scooter:outbound|regular->turn-lane:inbound|right": 3,
  "scooter:outbound|regular->turn-lane:outbound|both": 1,
  "scooter:outbound|regular->turn-lane:outbound|left-straight": 4,
  "sidewalk-bench:center->bike-lane:inbound|colored": 6,
  "sidewalk-bench:center->bike-lane:inbound|green|road": 1,
  "sidewalk-bench:center->bike-lane:inbound|green|sidewalk": 1,
  "sidewalk-bench:center->bike-lane:inbound|regular": 1,
  "sidewalk-bench:center->bike-lane:inbound|regular|sidewalk": 1,
  "sidewalk-bench:center->bike-lane:outbound|green|road": 1,
  "sidewalk-bench:center->bike-lane:outbound|red|sidewalk": 1,
  "sidewalk-bench:center->bikeshare:left|road": 1,
  "sidewalk-bench:center->bus-lane:inbound|regular|typical": 1,
  "sidewalk-bench:center->divider:big-tree": 6,
  "sidewalk-bench:center->divider:bush": 9,
  "sidewalk-bench:center->divider:flowers": 10,
  "sidewalk-bench:center->divider:median": 2,
  "sidewalk-bench:center->divider:palm-tree": 4,
  "sidewalk-bench:center->divider:planting-strip": 2,
  "sidewalk-bench:center->drive-lane:inbound|car": 4,
  "sidewalk-bench:center->flex-zone-curb:sparse|right": 1,
  "sidewalk-bench:center->light-rail:inbound|colored": 1,
  "sidewalk-bench:center->light-rail:inbound|regular": 2,
  "sidewalk-bench:center->light-rail:outbound|regular": 2,
  "sidewalk-bench:center->outdoor-dining:occupied|road": 4,
  "sidewalk-bench:center->parking-lane:inbound|left": 2,
  "sidewalk-bench:center->parklet:left": 5,
  "sidewalk-bench:center->parklet:right": 2,
  "sidewalk-bench:center->scooter:inbound|regular": 1,
  "sidewalk-bench:center->sidewalk-bench:center": 4,
  "sidewalk-bench:center->sidewalk-bench:right": 11,
  "sidewalk-bench:center->sidewalk-bike-rack:left|road": 1,
  "sidewalk-bench:center->sidewalk-bike-rack:left|sidewalk": 3,
  "sidewalk-bench:center->sidewalk-bike-rack:left|sidewalk-parallel": 4,
  "sidewalk-bench:center->sidewalk-bike-rack:right|sidewalk-parallel": 2,
  "sidewalk-bench:center->sidewalk-lamp:both|modern": 4,
  "sidewalk-bench:center->sidewalk-lamp:both|traditional": 30,
  "sidewalk-bench:center->sidewalk-lamp:left|traditional": 8,
  "sidewalk-bench:center->sidewalk-lamp:right|modern": 4,
  "sidewalk-bench:center->sidewalk-lamp:right|traditional": 9,
  "sidewalk-bench:center->sidewalk-tree:big": 107,
  "sidewalk-bench:center->sidewalk-tree:palm-tree": 12,
  "sidewalk-bench:center->sidewalk-wayfinding:large": 13,
  "sidewalk-bench:center->sidewalk-wayfinding:medium": 4,
  "sidewalk-bench:center->sidewalk-wayfinding:small": 8,
  "sidewalk-bench:center->sidewalk:dense": 107,
  "sidewalk-bench:center->sidewalk:empty": 1,
  "sidewalk-bench:center->sidewalk:normal": 36,
  "sidewalk-bench:center->sidewalk:sparse": 20,
  "sidewalk-bench:center->streetcar:inbound|colored": 1,
  "sidewalk-bench:center->turn-lane:inbound|shared": 1,
  "sidewalk-bench:center->utilities:left": 1,
  "sidewalk-bench:left->bike-lane:inbound|colored": 12,
  "sidewalk-bench:left->bike-lane:inbound|green|sidewalk": 1,
  "sidewalk-bench:left->bike-lane:inbound|red|road": 1,
  "sidewalk-bench:left->bike-lane:inbound|regular": 24,
  "sidewalk-bench:left->bike-lane:inbound|regular|road": 11,
  "sidewalk-bench:left->bike-lane:outbound|colored": 6,
  "sidewalk-bench:left->bike-lane:outbound|green|road": 6,
  "sidewalk-bench:left->bikeshare:left|road": 4,
  "sidewalk-bench:left->bikeshare:left|sidewalk": 2,
  "sidewalk-bench:left->bus-lane:inbound|colored": 1,
  "sidewalk-bench:left->bus-lane:inbound|colored|typical": 1,
  "sidewalk-bench:left->bus-lane:inbound|regular": 9,
  "sidewalk-bench:left->bus-lane:inbound|regular|typical": 2,
  "sidewalk-bench:left->bus-lane:inbound|shared|typical": 4,
  "sidewalk-bench:left->bus-lane:outbound|colored": 1,
  "sidewalk-bench:left->bus-lane:outbound|regular": 1,
  "sidewalk-bench:left->bus-lane:outbound|regular|typical": 3,
  "sidewalk-bench:left->divider:big-tree": 9,
  "sidewalk-bench:left->divider:bollard": 1,
  "sidewalk-bench:left->divider:bush": 23,
  "sidewalk-bench:left->divider:flowers": 47,
  "sidewalk-bench:left->divider:median": 7,
  "sidewalk-bench:left->divider:palm-tree": 2,
  "sidewalk-bench:left->divider:planter-box": 2,
  "sidewalk-bench:left->divider:planting-strip": 32,
  "sidewalk-bench:left->divider:striped-buffer": 3,
  "sidewalk-bench:left->drive-lane:inbound|car": 13,
  "sidewalk-bench:left->drive-lane:inbound|sharrow": 1,
  "sidewalk-bench:left->drive-lane:inbound|truck": 1,
  "sidewalk-bench:left->drive-lane:outbound|car": 6,
  "sidewalk-bench:left->drive-lane:outbound|truck": 3,
  "sidewalk-bench:left->flex-zone-curb:sparse|left": 6,
  "sidewalk-bench:left->flex-zone-curb:sparse|right": 1,
  "sidewalk-bench:left->flex-zone:taxi|inbound|left": 1,
  "sidewalk-bench:left->food-truck:left": 4,
  "sidewalk-bench:left->light-rail:inbound|colored": 1,
  "sidewalk-bench:left->light-rail:inbound|grass": 1,
  "sidewalk-bench:left->light-rail:inbound|regular": 8,
  "sidewalk-bench:left->light-rail:outbound|colored": 1,
  "sidewalk-bench:left->light-rail:outbound|regular": 5,
  "sidewalk-bench:left->parking-lane:inbound|left": 4,
  "sidewalk-bench:left->parking-lane:outbound|left": 1,
  "sidewalk-bench:left->parking-lane:right": 1,
  "sidewalk-bench:left->parklet:left": 46,
  "sidewalk-bench:left->parklet:right": 2,
  "sidewalk-bench:left->scooter-drop-zone:right|sidewalk|empty": 2,
  "sidewalk-bench:left->scooter:outbound|regular": 3,
  "sidewalk-bench:left->sidewalk-bench:center": 12,
  "sidewalk-bench:left->sidewalk-bench:left": 20,
  "sidewalk-bench:left->sidewalk-bench:right": 11,
  "sidewalk-bench:left->sidewalk-bike-rack:left|sidewalk": 64,
  "sidewalk-bench:left->sidewalk-bike-rack:left|sidewalk-parallel": 19,
  "sidewalk-bench:left->sidewalk-bike-rack:right|sidewalk": 9,
  "sidewalk-bench:left->sidewalk-bike-rack:right|sidewalk-parallel": 21,
  "sidewalk-bench:left->sidewalk-lamp:both|modern": 18,
  "sidewalk-bench:left->sidewalk-lamp:both|traditional": 55,
  "sidewalk-bench:left->sidewalk-lamp:left|modern": 9,
  "sidewalk-bench:left->sidewalk-lamp:left|traditional": 13,
  "sidewalk-bench:left->sidewalk-lamp:right|modern": 76,
  "sidewalk-bench:left->sidewalk-lamp:right|pride": 8,
  "sidewalk-bench:left->sidewalk-lamp:right|traditional": 42,
  "sidewalk-bench:left->sidewalk-tree:big": 328,
  "sidewalk-bench:left->sidewalk-tree:palm-tree": 36,
  "sidewalk-bench:left->sidewalk-wayfinding:large": 29,
  "sidewalk-bench:left->sidewalk-wayfinding:medium": 17,
  "sidewalk-bench:left->sidewalk-wayfinding:small": 13,
  "sidewalk-bench:left->sidewalk:dense": 1073,
  "sidewalk-bench:left->sidewalk:empty": 15,
  "sidewalk-bench:left->sidewalk:normal": 619,
  "sidewalk-bench:left->sidewalk:sparse": 60,
  "sidewalk-bench:left->streetcar:inbound|colored": 2,
  "sidewalk-bench:left->streetcar:inbound|regular": 9,
  "sidewalk-bench:left->streetcar:outbound|colored": 1,
  "sidewalk-bench:left->streetcar:outbound|regular": 3,
  "sidewalk-bench:left->transit-shelter:left|light-rail": 5,
  "sidewalk-bench:left->transit-shelter:left|street-level": 23,
  "sidewalk-bench:left->transit-shelter:right|street-level": 3,
  "sidewalk-bench:left->turn-lane:inbound|left": 1,
  "sidewalk-bench:left->turn-lane:inbound|left-straight": 1,
  "sidewalk-bench:left->turn-lane:inbound|right": 1,
  "sidewalk-bench:left->turn-lane:inbound|right-straight": 2,
  "sidewalk-bench:left->turn-lane:outbound|left": 3,
  "sidewalk-bench:left->turn-lane:outbound|left-straight": 2,
  "sidewalk-bench:left->turn-lane:outbound|right": 3,
  "sidewalk-bench:right->bike-lane:inbound|colored": 7,
  "sidewalk-bench:right->bike-lane:inbound|red|sidewalk": 1,
  "sidewalk-bench:right->bike-lane:inbound|regular": 9,
  "sidewalk-bench:right->bike-lane:inbound|regular|road": 1,
  "sidewalk-bench:right->bike-lane:outbound|colored": 22,
  "sidewalk-bench:right->bike-lane:outbound|green|road": 1,
  "sidewalk-bench:right->bike-lane:outbound|regular": 3,
  "sidewalk-bench:right->bikeshare:left|sidewalk": 1,
  "sidewalk-bench:right->bikeshare:right|sidewalk": 1,
  "sidewalk-bench:right->bus-lane:inbound|regular": 3,
  "sidewalk-bench:right->bus-lane:outbound|regular": 4,
  "sidewalk-bench:right->divider:big-tree": 44,
  "sidewalk-bench:right->divider:bollard": 1,
  "sidewalk-bench:right->divider:bush": 34,
  "sidewalk-bench:right->divider:dome": 3,
  "sidewalk-bench:right->divider:flowers": 46,
  "sidewalk-bench:right->divider:median": 3,
  "sidewalk-bench:right->divider:palm-tree": 13,
  "sidewalk-bench:right->divider:planter-box": 4,
  "sidewalk-bench:right->divider:planting-strip": 6,
  "sidewalk-bench:right->drive-lane:inbound|car": 3,
  "sidewalk-bench:right->drive-lane:inbound|sharrow": 1,
  "sidewalk-bench:right->drive-lane:outbound|car": 5,
  "sidewalk-bench:right->drive-lane:outbound|truck": 12,
  "sidewalk-bench:right->flex-zone-curb:sparse|left": 1,
  "sidewalk-bench:right->light-rail:inbound|colored": 1,
  "sidewalk-bench:right->light-rail:inbound|grass": 1,
  "sidewalk-bench:right->light-rail:inbound|regular": 8,
  "sidewalk-bench:right->light-rail:outbound|regular": 1,
  "sidewalk-bench:right->parking-lane:inbound|left": 1,
  "sidewalk-bench:right->parking-lane:inbound|right": 2,
  "sidewalk-bench:right->parking-lane:outbound|right": 21,
  "sidewalk-bench:right->parklet:left": 18,
  "sidewalk-bench:right->parklet:right": 17,
  "sidewalk-bench:right->scooter-drop-zone:left|sidewalk|empty": 1,
  "sidewalk-bench:right->scooter-drop-zone:right|sidewalk|empty": 1,
  "sidewalk-bench:right->sidewalk-bench:left": 36,
  "sidewalk-bench:right->sidewalk-bench:right": 2,
  "sidewalk-bench:right->sidewalk-bike-rack:left|road": 3,
  "sidewalk-bench:right->sidewalk-bike-rack:left|sidewalk": 9,
  "sidewalk-bench:right->sidewalk-bike-rack:left|sidewalk-parallel": 29,
  "sidewalk-bench:right->sidewalk-bike-rack:right|sidewalk": 1,
  "sidewalk-bench:right->sidewalk-bike-rack:right|sidewalk-parallel": 30,
  "sidewalk-bench:right->sidewalk-lamp:both": 1,
  "sidewalk-bench:right->sidewalk-lamp:both|modern": 10,
  "sidewalk-bench:right->sidewalk-lamp:both|pride": 1,
  "sidewalk-bench:right->sidewalk-lamp:both|traditional": 81,
  "sidewalk-bench:right->sidewalk-lamp:left|modern": 47,
  "sidewalk-bench:right->sidewalk-lamp:left|pride": 1,
  "sidewalk-bench:right->sidewalk-lamp:left|traditional": 59,
  "sidewalk-bench:right->sidewalk-lamp:right|modern": 8,
  "sidewalk-bench:right->sidewalk-lamp:right|traditional": 18,
  "sidewalk-bench:right->sidewalk-tree:big": 333,
  "sidewalk-bench:right->sidewalk-tree:palm-tree": 45,
  "sidewalk-bench:right->sidewalk-wayfinding:large": 10,
  "sidewalk-bench:right->sidewalk-wayfinding:medium": 48,
  "sidewalk-bench:right->sidewalk-wayfinding:small": 6,
  "sidewalk-bench:right->sidewalk:dense": 108,
  "sidewalk-bench:right->sidewalk:empty": 1,
  "sidewalk-bench:right->sidewalk:normal": 103,
  "sidewalk-bench:right->sidewalk:sparse": 15,
  "sidewalk-bench:right->streetcar:inbound|colored": 2,
  "sidewalk-bench:right->streetcar:inbound|regular": 6,
  "sidewalk-bench:right->streetcar:outbound|regular": 1,
  "sidewalk-bench:right->transit-shelter:left|light-rail": 2,
  "sidewalk-bench:right->transit-shelter:left|street-level": 13,
  "sidewalk-bench:right->transit-shelter:right|street-level": 5,
  "sidewalk-bench:right->turn-lane:inbound|right": 2,
  "sidewalk-bench:right->turn-lane:inbound|right-straight": 1,
  "sidewalk-bench:right->turn-lane:outbound|left": 1,
  "sidewalk-bench:right->turn-lane:outbound|left-straight": 1,
  "sidewalk-bench:right->turn-lane:outbound|right": 5,
  "sidewalk-bike-rack:left|road->bike-lane:inbound|colored": 6,
  "sidewalk-bike-rack:left|road->bike-lane:inbound|red|road": 1,
  "sidewalk-bike-rack:left|road->bike-lane:inbound|regular": 10,
  "sidewalk-bike-rack:left|road->bike-lane:outbound|colored": 3,
  "sidewalk-bike-rack:left|road->bike-lane:outbound|regular": 2,
  "sidewalk-bike-rack:left|road->bus-lane:inbound|regular": 1,
  "sidewalk-bike-rack:left|road->bus-lane:outbound|regular": 1,
  "sidewalk-bike-rack:left|road->divider:big-tree": 1,
  "sidewalk-bike-rack:left|road->divider:bollard": 1,
  "sidewalk-bike-rack:left|road->divider:flowers": 1,
  "sidewalk-bike-rack:left|road->drive-lane:inbound|car": 1,
  "sidewalk-bike-rack:left|road->drive-lane:inbound|sharrow": 5,
  "sidewalk-bike-rack:left|road->food-truck:right": 2,
  "sidewalk-bike-rack:left|road->parklet:right": 5,
  "sidewalk-bike-rack:left|road->sidewalk-bench:center": 1,
  "sidewalk-bike-rack:left|road->sidewalk-bench:left": 1,
  "sidewalk-bike-rack:left|road->sidewalk-lamp:left|modern": 2,
  "sidewalk-bike-rack:left|road->sidewalk-lamp:left|traditional": 2,
  "sidewalk-bike-rack:left|road->sidewalk-lamp:right|modern": 4,
  "sidewalk-bike-rack:left|road->sidewalk-tree:big": 5,
  "sidewalk-bike-rack:left|road->sidewalk-tree:palm-tree": 3,
  "sidewalk-bike-rack:left|road->sidewalk-wayfinding:medium": 2,
  "sidewalk-bike-rack:left|road->sidewalk-wayfinding:small": 1,
  "sidewalk-bike-rack:left|road->sidewalk:dense": 1,
  "sidewalk-bike-rack:left|road->sidewalk:normal": 1,
  "sidewalk-bike-rack:left|road->sidewalk:sparse": 1,
  "sidewalk-bike-rack:left|road->transit-shelter:left|light-rail": 1,
  "sidewalk-bike-rack:left|road->transit-shelter:left|street-level": 1,
  "sidewalk-bike-rack:left|road->transit-shelter:right|light-rail": 2,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:inbound|colored": 37,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:inbound|green|road": 3,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:inbound|red|road": 3,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:inbound|regular": 55,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:inbound|regular|road": 9,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:outbound|colored": 22,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:outbound|green|road": 8,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:outbound|green|sidewalk": 1,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:outbound|red|road": 1,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:outbound|regular": 4,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:outbound|regular|road": 2,
  "sidewalk-bike-rack:left|sidewalk->bike-lane:outbound|regular|sidewalk": 1,
  "sidewalk-bike-rack:left|sidewalk->bikeshare:left|road": 3,
  "sidewalk-bike-rack:left|sidewalk->bus-lane:inbound|colored": 1,
  "sidewalk-bike-rack:left|sidewalk->bus-lane:inbound|colored|typical": 1,
  "sidewalk-bike-rack:left|sidewalk->bus-lane:inbound|regular": 14,
  "sidewalk-bike-rack:left|sidewalk->bus-lane:inbound|regular|typical": 1,
  "sidewalk-bike-rack:left|sidewalk->bus-lane:outbound|regular": 1,
  "sidewalk-bike-rack:left|sidewalk->divider:big-tree": 4,
  "sidewalk-bike-rack:left|sidewalk->divider:bollard": 1,
  "sidewalk-bike-rack:left|sidewalk->divider:bush": 6,
  "sidewalk-bike-rack:left|sidewalk->divider:flowers": 23,
  "sidewalk-bike-rack:left|sidewalk->divider:median": 4,
  "sidewalk-bike-rack:left|sidewalk->divider:palm-tree": 3,
  "sidewalk-bike-rack:left|sidewalk->divider:planter-box": 7,
  "sidewalk-bike-rack:left|sidewalk->divider:planting-strip": 3,
  "sidewalk-bike-rack:left|sidewalk->drive-lane:inbound|car": 24,
  "sidewalk-bike-rack:left|sidewalk->drive-lane:outbound|car": 1,
  "sidewalk-bike-rack:left|sidewalk->drive-lane:outbound|sharrow": 1,
  "sidewalk-bike-rack:left|sidewalk->flex-zone-curb:sparse|left": 1,
  "sidewalk-bike-rack:left|sidewalk->flex-zone-curb:sparse|right": 1,
  "sidewalk-bike-rack:left|sidewalk->food-truck:left": 1,
  "sidewalk-bike-rack:left|sidewalk->light-rail:inbound|regular": 12,
  "sidewalk-bike-rack:left|sidewalk->light-rail:outbound|grass": 1,
  "sidewalk-bike-rack:left|sidewalk->light-rail:outbound|regular": 2,
  "sidewalk-bike-rack:left|sidewalk->parking-lane:inbound|left": 1,
  "sidewalk-bike-rack:left|sidewalk->parking-lane:inbound|right": 4,
  "sidewalk-bike-rack:left|sidewalk->parking-lane:outbound|right": 2,
  "sidewalk-bike-rack:left|sidewalk->parklet:left": 6,
  "sidewalk-bike-rack:left|sidewalk->parklet:right": 14,
  "sidewalk-bike-rack:left|sidewalk->scooter-drop-zone:right|sidewalk|empty": 4,
  "sidewalk-bike-rack:left|sidewalk->scooter:inbound|regular": 4,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-bench:center": 1,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-bench:left": 28,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-bench:right": 5,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-bike-rack:left|sidewalk": 5,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-lamp:both|modern": 12,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-lamp:both|pride": 2,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-lamp:both|traditional": 43,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-lamp:left|modern": 58,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-lamp:left|traditional": 24,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-lamp:right|modern": 58,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-lamp:right|traditional": 33,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-tree:big": 573,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-tree:palm-tree": 29,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-wayfinding:large": 23,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-wayfinding:medium": 27,
  "sidewalk-bike-rack:left|sidewalk->sidewalk-wayfinding:small": 9,
  "sidewalk-bike-rack:left|sidewalk->sidewalk:dense": 161,
  "sidewalk-bike-rack:left|sidewalk->sidewalk:empty": 6,
  "sidewalk-bike-rack:left|sidewalk->sidewalk:normal": 67,
  "sidewalk-bike-rack:left|sidewalk->sidewalk:sparse": 5,
  "sidewalk-bike-rack:left|sidewalk->streetcar:inbound|regular": 4,
  "sidewalk-bike-rack:left|sidewalk->streetcar:outbound|grass": 1,
  "sidewalk-bike-rack:left|sidewalk->streetcar:outbound|regular": 1,
  "sidewalk-bike-rack:left|sidewalk->transit-shelter:left|light-rail": 7,
  "sidewalk-bike-rack:left|sidewalk->transit-shelter:left|street-level": 67,
  "sidewalk-bike-rack:left|sidewalk->transit-shelter:right|street-level": 6,
  "sidewalk-bike-rack:left|sidewalk->turn-lane:inbound|left": 2,
  "sidewalk-bike-rack:left|sidewalk->turn-lane:outbound|left": 1,
  "sidewalk-bike-rack:left|sidewalk->turn-lane:outbound|right-straight": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->bike-lane:inbound|colored": 38,
  "sidewalk-bike-rack:left|sidewalk-parallel->bike-lane:inbound|regular": 40,
  "sidewalk-bike-rack:left|sidewalk-parallel->bike-lane:outbound|colored": 40,
  "sidewalk-bike-rack:left|sidewalk-parallel->bike-lane:outbound|green|road": 3,
  "sidewalk-bike-rack:left|sidewalk-parallel->bike-lane:outbound|regular": 8,
  "sidewalk-bike-rack:left|sidewalk-parallel->bus-lane:inbound|regular": 5,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:big-tree": 6,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:bollard": 3,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:bush": 4,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:dome": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:flowers": 8,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:median": 4,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:palm-tree": 3,
  "sidewalk-bike-rack:left|sidewalk-parallel->divider:striped-buffer": 3,
  "sidewalk-bike-rack:left|sidewalk-parallel->drive-lane:inbound|car": 10,
  "sidewalk-bike-rack:left|sidewalk-parallel->drive-lane:outbound|truck": 3,
  "sidewalk-bike-rack:left|sidewalk-parallel->food-truck:left": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->light-rail:inbound|regular": 2,
  "sidewalk-bike-rack:left|sidewalk-parallel->light-rail:outbound|regular": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->parking-lane:inbound|left": 4,
  "sidewalk-bike-rack:left|sidewalk-parallel->parking-lane:outbound|right": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->parklet:left": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->parklet:right": 18,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-bench:center": 9,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-bench:left": 22,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-bench:right": 14,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-bike-rack:left|sidewalk-parallel": 20,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-bike-rack:right|sidewalk-parallel": 4,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-lamp:both|modern": 21,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-lamp:both|traditional": 27,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-lamp:left|modern": 45,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-lamp:left|traditional": 18,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-lamp:right|modern": 72,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-lamp:right|traditional": 17,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-tree:big": 111,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-tree:palm-tree": 10,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-wayfinding:large": 19,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-wayfinding:medium": 39,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk-wayfinding:small": 9,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk:dense": 157,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk:empty": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk:normal": 102,
  "sidewalk-bike-rack:left|sidewalk-parallel->sidewalk:sparse": 7,
  "sidewalk-bike-rack:left|sidewalk-parallel->streetcar:inbound|regular": 3,
  "sidewalk-bike-rack:left|sidewalk-parallel->transit-shelter:left|light-rail": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->transit-shelter:left|street-level": 53,
  "sidewalk-bike-rack:left|sidewalk-parallel->transit-shelter:right|street-level": 6,
  "sidewalk-bike-rack:left|sidewalk-parallel->turn-lane:inbound|left": 2,
  "sidewalk-bike-rack:left|sidewalk-parallel->turn-lane:inbound|left-straight": 1,
  "sidewalk-bike-rack:left|sidewalk-parallel->turn-lane:inbound|right": 3,
  "sidewalk-bike-rack:left|sidewalk-parallel->turn-lane:inbound|right-straight": 4,
  "sidewalk-bike-rack:right|road->bike-lane:inbound|colored": 5,
  "sidewalk-bike-rack:right|road->bike-lane:inbound|green|road": 3,
  "sidewalk-bike-rack:right|road->bikeshare:right|road": 3,
  "sidewalk-bike-rack:right|road->divider:flowers": 3,
  "sidewalk-bike-rack:right|road->divider:palm-tree": 2,
  "sidewalk-bike-rack:right|road->drive-lane:inbound|car": 1,
  "sidewalk-bike-rack:right|road->drive-lane:inbound|truck": 1,
  "sidewalk-bike-rack:right|road->parklet:right": 4,
  "sidewalk-bike-rack:right|road->sidewalk-lamp:both|traditional": 3,
  "sidewalk-bike-rack:right|road->sidewalk-lamp:left|modern": 2,
  "sidewalk-bike-rack:right|road->sidewalk-lamp:left|traditional": 1,
  "sidewalk-bike-rack:right|road->sidewalk-lamp:right|traditional": 2,
  "sidewalk-bike-rack:right|road->sidewalk-tree:big": 8,
  "sidewalk-bike-rack:right|road->sidewalk-tree:palm-tree": 3,
  "sidewalk-bike-rack:right|road->sidewalk-wayfinding:small": 1,
  "sidewalk-bike-rack:right|road->sidewalk:dense": 2,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|colored": 8,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|green|road": 7,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|green|sidewalk": 1,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|red|road": 2,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|red|sidewalk": 1,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|regular": 6,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|regular|road": 16,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:inbound|regular|sidewalk": 2,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:outbound|colored": 6,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:outbound|green|road": 5,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:outbound|green|sidewalk": 1,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:outbound|red|road": 4,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:outbound|regular": 2,
  "sidewalk-bike-rack:right|sidewalk->bike-lane:outbound|regular|sidewalk": 1,
  "sidewalk-bike-rack:right|sidewalk->bikeshare:left|road": 3,
  "sidewalk-bike-rack:right|sidewalk->bus-lane:inbound|colored": 1,
  "sidewalk-bike-rack:right|sidewalk->bus-lane:inbound|regular": 2,
  "sidewalk-bike-rack:right|sidewalk->bus-lane:inbound|regular|typical": 2,
  "sidewalk-bike-rack:right|sidewalk->bus-lane:inbound|shared|typical": 7,
  "sidewalk-bike-rack:right|sidewalk->divider:big-tree": 3,
  "sidewalk-bike-rack:right|sidewalk->divider:bush": 2,
  "sidewalk-bike-rack:right|sidewalk->divider:dome": 1,
  "sidewalk-bike-rack:right|sidewalk->divider:flowers": 7,
  "sidewalk-bike-rack:right|sidewalk->divider:median": 1,
  "sidewalk-bike-rack:right|sidewalk->divider:planter-box": 3,
  "sidewalk-bike-rack:right|sidewalk->drive-lane:inbound|car": 11,
  "sidewalk-bike-rack:right|sidewalk->drive-lane:outbound|car": 2,
  "sidewalk-bike-rack:right|sidewalk->drive-lane:outbound|truck": 1,
  "sidewalk-bike-rack:right|sidewalk->flex-zone-curb:sparse|left": 1,
  "sidewalk-bike-rack:right|sidewalk->light-rail:inbound|colored": 1,
  "sidewalk-bike-rack:right|sidewalk->parking-lane:angled-rear-left|right": 1,
  "sidewalk-bike-rack:right|sidewalk->parking-lane:inbound|left": 3,
  "sidewalk-bike-rack:right|sidewalk->parking-lane:inbound|right": 1,
  "sidewalk-bike-rack:right|sidewalk->parking-lane:sideways|left": 1,
  "sidewalk-bike-rack:right|sidewalk->parklet:left": 4,
  "sidewalk-bike-rack:right|sidewalk->scooter-drop-zone:left|sidewalk|empty": 1,
  "sidewalk-bike-rack:right|sidewalk->scooter-drop-zone:right|sidewalk|empty": 7,
  "sidewalk-bike-rack:right|sidewalk->scooter:inbound|regular": 1,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-bench:left": 12,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-bench:right": 1,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-bike-rack:left|sidewalk": 4,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-bike-rack:right|sidewalk": 26,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-lamp:both|modern": 6,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-lamp:both|pride": 2,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-lamp:both|traditional": 23,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-lamp:left|modern": 5,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-lamp:left|traditional": 9,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-lamp:right|modern": 13,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-lamp:right|traditional": 9,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-tree:big": 92,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-tree:palm-tree": 6,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-wayfinding:large": 4,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-wayfinding:medium": 4,
  "sidewalk-bike-rack:right|sidewalk->sidewalk-wayfinding:small": 3,
  "sidewalk-bike-rack:right|sidewalk->sidewalk:dense": 19,
  "sidewalk-bike-rack:right|sidewalk->sidewalk:empty": 1,
  "sidewalk-bike-rack:right|sidewalk->sidewalk:normal": 8,
  "sidewalk-bike-rack:right|sidewalk->streetcar:inbound|colored": 1,
  "sidewalk-bike-rack:right|sidewalk->streetcar:inbound|regular": 1,
  "sidewalk-bike-rack:right|sidewalk->transit-shelter:left|light-rail": 2,
  "sidewalk-bike-rack:right|sidewalk->transit-shelter:left|street-level": 6,
  "sidewalk-bike-rack:right|sidewalk->turn-lane:inbound|left": 1,
  "sidewalk-bike-rack:right|sidewalk->turn-lane:outbound|left-straight": 2,
  "sidewalk-bike-rack:right|sidewalk->turn-lane:outbound|straight": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->bike-lane:inbound|colored": 21,
  "sidewalk-bike-rack:right|sidewalk-parallel->bike-lane:inbound|green|road": 4,
  "sidewalk-bike-rack:right|sidewalk-parallel->bike-lane:inbound|green|sidewalk": 3,
  "sidewalk-bike-rack:right|sidewalk-parallel->bike-lane:inbound|regular": 34,
  "sidewalk-bike-rack:right|sidewalk-parallel->bike-lane:inbound|regular|road": 4,
  "sidewalk-bike-rack:right|sidewalk-parallel->bike-lane:outbound|colored": 11,
  "sidewalk-bike-rack:right|sidewalk-parallel->bike-lane:outbound|regular": 3,
  "sidewalk-bike-rack:right|sidewalk-parallel->bus-lane:inbound|colored": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->bus-lane:inbound|regular": 21,
  "sidewalk-bike-rack:right|sidewalk-parallel->bus-lane:inbound|shared|typical": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->divider:big-tree": 7,
  "sidewalk-bike-rack:right|sidewalk-parallel->divider:bush": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->divider:flowers": 7,
  "sidewalk-bike-rack:right|sidewalk-parallel->divider:median": 6,
  "sidewalk-bike-rack:right|sidewalk-parallel->divider:palm-tree": 4,
  "sidewalk-bike-rack:right|sidewalk-parallel->drive-lane:inbound|car": 12,
  "sidewalk-bike-rack:right|sidewalk-parallel->drive-lane:outbound|car": 5,
  "sidewalk-bike-rack:right|sidewalk-parallel->drive-lane:outbound|sharrow": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->drive-lane:outbound|truck": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->flex-zone:rideshare|inbound|left": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->light-rail:inbound|colored": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->light-rail:inbound|regular": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->light-rail:outbound|regular": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->parking-lane:inbound|left": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->parking-lane:inbound|right": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->parklet:left": 14,
  "sidewalk-bike-rack:right|sidewalk-parallel->parklet:right": 7,
  "sidewalk-bike-rack:right|sidewalk-parallel->scooter:inbound|regular": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-bench:center": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-bench:left": 12,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-bench:right": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-bike-rack:left|sidewalk": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-bike-rack:left|sidewalk-parallel": 11,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-bike-rack:right|sidewalk-parallel": 11,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-lamp:both|modern": 17,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-lamp:both|pride": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-lamp:both|traditional": 13,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-lamp:left|modern": 21,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-lamp:left|traditional": 15,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-lamp:right|modern": 9,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-lamp:right|traditional": 8,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-tree:big": 217,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-tree:palm-tree": 26,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-wayfinding:large": 17,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-wayfinding:medium": 17,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk-wayfinding:small": 7,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk:dense": 62,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk:empty": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk:normal": 17,
  "sidewalk-bike-rack:right|sidewalk-parallel->sidewalk:sparse": 2,
  "sidewalk-bike-rack:right|sidewalk-parallel->streetcar:inbound|regular": 7,
  "sidewalk-bike-rack:right|sidewalk-parallel->streetcar:outbound|regular": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->transit-shelter:left|light-rail": 6,
  "sidewalk-bike-rack:right|sidewalk-parallel->transit-shelter:left|street-level": 16,
  "sidewalk-bike-rack:right|sidewalk-parallel->transit-shelter:right|street-level": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->turn-lane:inbound|left": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->turn-lane:inbound|left-straight": 3,
  "sidewalk-bike-rack:right|sidewalk-parallel->turn-lane:inbound|right": 4,
  "sidewalk-bike-rack:right|sidewalk-parallel->turn-lane:inbound|right-straight": 3,
  "sidewalk-bike-rack:right|sidewalk-parallel->turn-lane:outbound|right-straight": 1,
  "sidewalk-bike-rack:right|sidewalk-parallel->turn-lane:outbound|straight": 1,
  "sidewalk-lamp:both->bike-lane:inbound|regular|sidewalk": 1,
  "sidewalk-lamp:both|modern->bike-lane:inbound|colored": 69,
  "sidewalk-lamp:both|modern->bike-lane:inbound|green|road": 12,
  "sidewalk-lamp:both|modern->bike-lane:inbound|green|sidewalk": 5,
  "sidewalk-lamp:both|modern->bike-lane:inbound|red|road": 13,
  "sidewalk-lamp:both|modern->bike-lane:inbound|regular": 60,
  "sidewalk-lamp:both|modern->bike-lane:inbound|regular|road": 38,
  "sidewalk-lamp:both|modern->bike-lane:inbound|regular|sidewalk": 2,
  "sidewalk-lamp:both|modern->bike-lane:outbound|colored": 132,
  "sidewalk-lamp:both|modern->bike-lane:outbound|green|road": 19,
  "sidewalk-lamp:both|modern->bike-lane:outbound|green|sidewalk": 3,
  "sidewalk-lamp:both|modern->bike-lane:outbound|red|road": 12,
  "sidewalk-lamp:both|modern->bike-lane:outbound|red|sidewalk": 1,
  "sidewalk-lamp:both|modern->bike-lane:outbound|regular": 24,
  "sidewalk-lamp:both|modern->bike-lane:outbound|regular|road": 9,
  "sidewalk-lamp:both|modern->bike-lane:outbound|regular|sidewalk": 2,
  "sidewalk-lamp:both|modern->bikeshare:left|road": 3,
  "sidewalk-lamp:both|modern->bikeshare:left|sidewalk": 1,
  "sidewalk-lamp:both|modern->brt-station:left": 2,
  "sidewalk-lamp:both|modern->bus-lane:inbound|colored": 14,
  "sidewalk-lamp:both|modern->bus-lane:inbound|colored|typical": 7,
  "sidewalk-lamp:both|modern->bus-lane:inbound|regular": 113,
  "sidewalk-lamp:both|modern->bus-lane:inbound|regular|typical": 21,
  "sidewalk-lamp:both|modern->bus-lane:inbound|shared|typical": 12,
  "sidewalk-lamp:both|modern->bus-lane:outbound|colored": 6,
  "sidewalk-lamp:both|modern->bus-lane:outbound|colored|typical": 1,
  "sidewalk-lamp:both|modern->bus-lane:outbound|regular": 19,
  "sidewalk-lamp:both|modern->bus-lane:outbound|regular|typical": 6,
  "sidewalk-lamp:both|modern->bus-lane:outbound|shared|typical": 2,
  "sidewalk-lamp:both|modern->divider:big-tree": 31,
  "sidewalk-lamp:both|modern->divider:bollard": 11,
  "sidewalk-lamp:both|modern->divider:bush": 27,
  "sidewalk-lamp:both|modern->divider:dome": 3,
  "sidewalk-lamp:both|modern->divider:flowers": 88,
  "sidewalk-lamp:both|modern->divider:median": 20,
  "sidewalk-lamp:both|modern->divider:palm-tree": 4,
  "sidewalk-lamp:both|modern->divider:planter-box": 1,
  "sidewalk-lamp:both|modern->divider:planting-strip": 15,
  "sidewalk-lamp:both|modern->divider:striped-buffer": 11,
  "sidewalk-lamp:both|modern->drive-lane:inbound|car": 165,
  "sidewalk-lamp:both|modern->drive-lane:inbound|sharrow": 6,
  "sidewalk-lamp:both|modern->drive-lane:inbound|truck": 15,
  "sidewalk-lamp:both|modern->drive-lane:outbound|car": 100,
  "sidewalk-lamp:both|modern->drive-lane:outbound|sharrow": 5,
  "sidewalk-lamp:both|modern->drive-lane:outbound|truck": 45,
  "sidewalk-lamp:both|modern->flex-zone-curb:sparse|left": 3,
  "sidewalk-lamp:both|modern->flex-zone-curb:sparse|right": 6,
  "sidewalk-lamp:both|modern->flex-zone:rideshare|inbound|left": 1,
  "sidewalk-lamp:both|modern->flex-zone:taxi|outbound|left": 2,
  "sidewalk-lamp:both|modern->food-truck:left": 1,
  "sidewalk-lamp:both|modern->light-rail:inbound|colored": 9,
  "sidewalk-lamp:both|modern->light-rail:inbound|grass": 2,
  "sidewalk-lamp:both|modern->light-rail:inbound|regular": 44,
  "sidewalk-lamp:both|modern->light-rail:outbound|colored": 6,
  "sidewalk-lamp:both|modern->light-rail:outbound|grass": 1,
  "sidewalk-lamp:both|modern->light-rail:outbound|regular": 26,
  "sidewalk-lamp:both|modern->parking-lane:inbound|left": 38,
  "sidewalk-lamp:both|modern->parking-lane:inbound|right": 12,
  "sidewalk-lamp:both|modern->parking-lane:outbound|left": 47,
  "sidewalk-lamp:both|modern->parking-lane:outbound|right": 7,
  "sidewalk-lamp:both|modern->parklet:left": 25,
  "sidewalk-lamp:both|modern->parklet:right": 16,
  "sidewalk-lamp:both|modern->scooter-drop-zone:right|sidewalk|empty": 1,
  "sidewalk-lamp:both|modern->scooter:inbound|green": 1,
  "sidewalk-lamp:both|modern->scooter:inbound|regular": 2,
  "sidewalk-lamp:both|modern->scooter:outbound|green": 1,
  "sidewalk-lamp:both|modern->scooter:outbound|red": 1,
  "sidewalk-lamp:both|modern->sidewalk-bench:center": 2,
  "sidewalk-lamp:both|modern->sidewalk-bench:left": 25,
  "sidewalk-lamp:both|modern->sidewalk-bench:right": 21,
  "sidewalk-lamp:both|modern->sidewalk-bike-rack:left|sidewalk": 19,
  "sidewalk-lamp:both|modern->sidewalk-bike-rack:left|sidewalk-parallel": 22,
  "sidewalk-lamp:both|modern->sidewalk-bike-rack:right|sidewalk": 8,
  "sidewalk-lamp:both|modern->sidewalk-bike-rack:right|sidewalk-parallel": 11,
  "sidewalk-lamp:both|modern->sidewalk-lamp:both|modern": 4,
  "sidewalk-lamp:both|modern->sidewalk-lamp:left|modern": 1,
  "sidewalk-lamp:both|modern->sidewalk-lamp:right|modern": 9,
  "sidewalk-lamp:both|modern->sidewalk-tree:big": 524,
  "sidewalk-lamp:both|modern->sidewalk-tree:palm-tree": 38,
  "sidewalk-lamp:both|modern->sidewalk-wayfinding:large": 16,
  "sidewalk-lamp:both|modern->sidewalk-wayfinding:medium": 37,
  "sidewalk-lamp:both|modern->sidewalk-wayfinding:small": 13,
  "sidewalk-lamp:both|modern->sidewalk:dense": 141,
  "sidewalk-lamp:both|modern->sidewalk:empty": 8,
  "sidewalk-lamp:both|modern->sidewalk:normal": 68,
  "sidewalk-lamp:both|modern->sidewalk:sparse": 16,
  "sidewalk-lamp:both|modern->streetcar:inbound|colored": 1,
  "sidewalk-lamp:both|modern->streetcar:inbound|regular": 15,
  "sidewalk-lamp:both|modern->streetcar:outbound|colored": 2,
  "sidewalk-lamp:both|modern->streetcar:outbound|grass": 1,
  "sidewalk-lamp:both|modern->streetcar:outbound|regular": 8,
  "sidewalk-lamp:both|modern->transit-shelter:left|light-rail": 64,
  "sidewalk-lamp:both|modern->transit-shelter:left|street-level": 62,
  "sidewalk-lamp:both|modern->transit-shelter:right|light-rail": 7,
  "sidewalk-lamp:both|modern->transit-shelter:right|street-level": 55,
  "sidewalk-lamp:both|modern->turn-lane:inbound|left": 3,
  "sidewalk-lamp:both|modern->turn-lane:inbound|left-right-straight": 1,
  "sidewalk-lamp:both|modern->turn-lane:inbound|left-straight": 14,
  "sidewalk-lamp:both|modern->turn-lane:inbound|right": 6,
  "sidewalk-lamp:both|modern->turn-lane:inbound|right-straight": 12,
  "sidewalk-lamp:both|modern->turn-lane:inbound|shared": 2,
  "sidewalk-lamp:both|modern->turn-lane:inbound|straight": 9,
  "sidewalk-lamp:both|modern->turn-lane:outbound|left": 18,
  "sidewalk-lamp:both|modern->turn-lane:outbound|left-right-straight": 1,
  "sidewalk-lamp:both|modern->turn-lane:outbound|left-straight": 27,
  "sidewalk-lamp:both|modern->turn-lane:outbound|right": 4,
  "sidewalk-lamp:both|modern->turn-lane:outbound|right-straight": 3,
  "sidewalk-lamp:both|modern->turn-lane:outbound|shared": 1,
  "sidewalk-lamp:both|modern->turn-lane:outbound|straight": 17,
  "sidewalk-lamp:both|pride->bike-lane:inbound|green|road": 1,
  "sidewalk-lamp:both|pride->bike-lane:inbound|green|sidewalk": 1,
  "sidewalk-lamp:both|pride->bike-lane:inbound|red|sidewalk": 1,
  "sidewalk-lamp:both|pride->bike-lane:inbound|regular|road": 5,
  "sidewalk-lamp:both|pride->bike-lane:outbound|green|sidewalk": 1,
  "sidewalk-lamp:both|pride->bus-lane:inbound|regular|typical": 4,
  "sidewalk-lamp:both|pride->bus-lane:inbound|shared|typical": 3,
  "sidewalk-lamp:both|pride->divider:big-tree": 1,
  "sidewalk-lamp:both|pride->divider:bush": 1,
  "sidewalk-lamp:both|pride->divider:flowers": 4,
  "sidewalk-lamp:both|pride->divider:palm-tree": 2,
  "sidewalk-lamp:both|pride->divider:planting-strip": 1,
  "sidewalk-lamp:both|pride->drive-lane:inbound|car": 22,
  "sidewalk-lamp:both|pride->drive-lane:outbound|car": 3,
  "sidewalk-lamp:both|pride->light-rail:inbound|regular": 1,
  "sidewalk-lamp:both|pride->light-rail:outbound|colored": 1,
  "sidewalk-lamp:both|pride->parking-lane:outbound|right": 1,
  "sidewalk-lamp:both|pride->sidewalk-bench:left": 2,
  "sidewalk-lamp:both|pride->sidewalk-bike-rack:left|sidewalk": 1,
  "sidewalk-lamp:both|pride->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "sidewalk-lamp:both|pride->sidewalk-lamp:left|modern": 1,
  "sidewalk-lamp:both|pride->sidewalk-lamp:right|pride": 1,
  "sidewalk-lamp:both|pride->sidewalk-tree:big": 12,
  "sidewalk-lamp:both|pride->sidewalk-tree:palm-tree": 4,
  "sidewalk-lamp:both|pride->sidewalk:dense": 24,
  "sidewalk-lamp:both|pride->sidewalk:empty": 1,
  "sidewalk-lamp:both|pride->sidewalk:normal": 1,
  "sidewalk-lamp:both|pride->streetcar:inbound|grass": 1,
  "sidewalk-lamp:both|pride->streetcar:inbound|regular": 1,
  "sidewalk-lamp:both|pride->streetcar:outbound|regular": 2,
  "sidewalk-lamp:both|pride->transit-shelter:left|light-rail": 1,
  "sidewalk-lamp:both|pride->transit-shelter:right|street-level": 7,
  "sidewalk-lamp:both|pride->turn-lane:inbound|left-straight": 1,
  "sidewalk-lamp:both|pride->turn-lane:outbound|straight": 1,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|colored": 160,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|green|road": 12,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|green|sidewalk": 14,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|red|road": 6,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|red|sidewalk": 6,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|regular": 85,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|regular|road": 29,
  "sidewalk-lamp:both|traditional->bike-lane:inbound|regular|sidewalk": 2,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|colored": 70,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|green|road": 18,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|green|sidewalk": 8,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|green|sidewalk|inbound": 1,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|red|road": 5,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|red|sidewalk": 3,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|regular": 61,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|regular|road": 9,
  "sidewalk-lamp:both|traditional->bike-lane:outbound|regular|sidewalk": 2,
  "sidewalk-lamp:both|traditional->bikeshare:left|road": 4,
  "sidewalk-lamp:both|traditional->bikeshare:right|sidewalk": 1,
  "sidewalk-lamp:both|traditional->bus-lane:inbound|colored": 29,
  "sidewalk-lamp:both|traditional->bus-lane:inbound|colored|typical": 2,
  "sidewalk-lamp:both|traditional->bus-lane:inbound|regular": 66,
  "sidewalk-lamp:both|traditional->bus-lane:inbound|regular|typical": 19,
  "sidewalk-lamp:both|traditional->bus-lane:inbound|shared|typical": 12,
  "sidewalk-lamp:both|traditional->bus-lane:outbound|colored|typical": 1,
  "sidewalk-lamp:both|traditional->bus-lane:outbound|regular": 2,
  "sidewalk-lamp:both|traditional->bus-lane:outbound|regular|typical": 7,
  "sidewalk-lamp:both|traditional->divider:big-tree": 53,
  "sidewalk-lamp:both|traditional->divider:bollard": 11,
  "sidewalk-lamp:both|traditional->divider:bush": 25,
  "sidewalk-lamp:both|traditional->divider:dome": 3,
  "sidewalk-lamp:both|traditional->divider:flowers": 101,
  "sidewalk-lamp:both|traditional->divider:median": 11,
  "sidewalk-lamp:both|traditional->divider:palm-tree": 12,
  "sidewalk-lamp:both|traditional->divider:planter-box": 8,
  "sidewalk-lamp:both|traditional->divider:planting-strip": 18,
  "sidewalk-lamp:both|traditional->divider:striped-buffer": 5,
  "sidewalk-lamp:both|traditional->drive-lane:inbound": 1,
  "sidewalk-lamp:both|traditional->drive-lane:inbound|car": 113,
  "sidewalk-lamp:both|traditional->drive-lane:inbound|sharrow": 18,
  "sidewalk-lamp:both|traditional->drive-lane:inbound|truck": 13,
  "sidewalk-lamp:both|traditional->drive-lane:outbound|car": 63,
  "sidewalk-lamp:both|traditional->drive-lane:outbound|sharrow": 5,
  "sidewalk-lamp:both|traditional->drive-lane:outbound|truck": 7,
  "sidewalk-lamp:both|traditional->flex-zone-curb:empty|right": 1,
  "sidewalk-lamp:both|traditional->flex-zone-curb:sparse|left": 2,
  "sidewalk-lamp:both|traditional->flex-zone-curb:sparse|right": 3,
  "sidewalk-lamp:both|traditional->flex-zone:rideshare|inbound|left": 4,
  "sidewalk-lamp:both|traditional->flex-zone:taxi|inbound|left": 2,
  "sidewalk-lamp:both|traditional->flex-zone:taxi|outbound|left": 1,
  "sidewalk-lamp:both|traditional->food-truck:left": 4,
  "sidewalk-lamp:both|traditional->light-rail:inbound|colored": 11,
  "sidewalk-lamp:both|traditional->light-rail:inbound|grass": 2,
  "sidewalk-lamp:both|traditional->light-rail:inbound|regular": 34,
  "sidewalk-lamp:both|traditional->light-rail:outbound|colored": 3,
  "sidewalk-lamp:both|traditional->light-rail:outbound|grass": 2,
  "sidewalk-lamp:both|traditional->light-rail:outbound|regular": 10,
  "sidewalk-lamp:both|traditional->outdoor-dining:occupied|sidewalk": 1,
  "sidewalk-lamp:both|traditional->parking-lane:angled-front-left|right": 1,
  "sidewalk-lamp:both|traditional->parking-lane:inbound|left": 59,
  "sidewalk-lamp:both|traditional->parking-lane:inbound|right": 10,
  "sidewalk-lamp:both|traditional->parking-lane:outbound|left": 7,
  "sidewalk-lamp:both|traditional->parking-lane:outbound|right": 26,
  "sidewalk-lamp:both|traditional->parking-lane:sideways|left": 2,
  "sidewalk-lamp:both|traditional->parklet:left": 34,
  "sidewalk-lamp:both|traditional->parklet:right": 33,
  "sidewalk-lamp:both|traditional->scooter-drop-zone:left|road|empty": 1,
  "sidewalk-lamp:both|traditional->scooter:inbound|regular": 2,
  "sidewalk-lamp:both|traditional->scooter:outbound|red": 1,
  "sidewalk-lamp:both|traditional->scooter:outbound|regular": 1,
  "sidewalk-lamp:both|traditional->sidewalk-bench:center": 24,
  "sidewalk-lamp:both|traditional->sidewalk-bench:left": 106,
  "sidewalk-lamp:both|traditional->sidewalk-bench:right": 36,
  "sidewalk-lamp:both|traditional->sidewalk-bike-rack:left|road": 6,
  "sidewalk-lamp:both|traditional->sidewalk-bike-rack:left|sidewalk": 36,
  "sidewalk-lamp:both|traditional->sidewalk-bike-rack:left|sidewalk-parallel": 31,
  "sidewalk-lamp:both|traditional->sidewalk-bike-rack:right|road": 2,
  "sidewalk-lamp:both|traditional->sidewalk-bike-rack:right|sidewalk": 13,
  "sidewalk-lamp:both|traditional->sidewalk-bike-rack:right|sidewalk-parallel": 16,
  "sidewalk-lamp:both|traditional->sidewalk-lamp:both|traditional": 124,
  "sidewalk-lamp:both|traditional->sidewalk-lamp:left|modern": 4,
  "sidewalk-lamp:both|traditional->sidewalk-lamp:left|traditional": 2,
  "sidewalk-lamp:both|traditional->sidewalk-lamp:right|modern": 6,
  "sidewalk-lamp:both|traditional->sidewalk-lamp:right|traditional": 5,
  "sidewalk-lamp:both|traditional->sidewalk-tree:big": 654,
  "sidewalk-lamp:both|traditional->sidewalk-tree:palm-tree": 78,
  "sidewalk-lamp:both|traditional->sidewalk-wayfinding:large": 22,
  "sidewalk-lamp:both|traditional->sidewalk-wayfinding:medium": 46,
  "sidewalk-lamp:both|traditional->sidewalk-wayfinding:small": 42,
  "sidewalk-lamp:both|traditional->sidewalk:dense": 392,
  "sidewalk-lamp:both|traditional->sidewalk:empty": 5,
  "sidewalk-lamp:both|traditional->sidewalk:normal": 188,
  "sidewalk-lamp:both|traditional->sidewalk:sparse": 16,
  "sidewalk-lamp:both|traditional->streetcar:inbound|colored": 4,
  "sidewalk-lamp:both|traditional->streetcar:inbound|regular": 29,
  "sidewalk-lamp:both|traditional->streetcar:outbound|colored": 5,
  "sidewalk-lamp:both|traditional->streetcar:outbound|regular": 7,
  "sidewalk-lamp:both|traditional->transit-shelter:left|light-rail": 30,
  "sidewalk-lamp:both|traditional->transit-shelter:left|street-level": 57,
  "sidewalk-lamp:both|traditional->transit-shelter:right|light-rail": 4,
  "sidewalk-lamp:both|traditional->transit-shelter:right|street-level": 22,
  "sidewalk-lamp:both|traditional->turn-lane:inbound|left": 3,
  "sidewalk-lamp:both|traditional->turn-lane:inbound|left-straight": 3,
  "sidewalk-lamp:both|traditional->turn-lane:inbound|right": 1,
  "sidewalk-lamp:both|traditional->turn-lane:inbound|right-straight": 5,
  "sidewalk-lamp:both|traditional->turn-lane:inbound|straight": 3,
  "sidewalk-lamp:both|traditional->turn-lane:outbound|left": 7,
  "sidewalk-lamp:both|traditional->turn-lane:outbound|left-straight": 15,
  "sidewalk-lamp:both|traditional->turn-lane:outbound|right": 1,
  "sidewalk-lamp:both|traditional->turn-lane:outbound|straight": 5,
  "sidewalk-lamp:left->bus-lane:inbound|shared|typical": 1,
  "sidewalk-lamp:left|modern->bike-lane:inbound|colored": 12,
  "sidewalk-lamp:left|modern->bike-lane:inbound|green|road": 3,
  "sidewalk-lamp:left|modern->bike-lane:inbound|green|sidewalk": 2,
  "sidewalk-lamp:left|modern->bike-lane:inbound|red|road": 11,
  "sidewalk-lamp:left|modern->bike-lane:inbound|regular": 21,
  "sidewalk-lamp:left|modern->bike-lane:inbound|regular|road": 42,
  "sidewalk-lamp:left|modern->bike-lane:inbound|regular|sidewalk": 1,
  "sidewalk-lamp:left|modern->bike-lane:outbound|colored": 124,
  "sidewalk-lamp:left|modern->bike-lane:outbound|green|road": 41,
  "sidewalk-lamp:left|modern->bike-lane:outbound|green|sidewalk": 4,
  "sidewalk-lamp:left|modern->bike-lane:outbound|red|road": 14,
  "sidewalk-lamp:left|modern->bike-lane:outbound|red|sidewalk": 1,
  "sidewalk-lamp:left|modern->bike-lane:outbound|regular": 13,
  "sidewalk-lamp:left|modern->bike-lane:outbound|regular|road": 12,
  "sidewalk-lamp:left|modern->bike-lane:outbound|regular|sidewalk": 3,
  "sidewalk-lamp:left|modern->bikeshare:left|road": 13,
  "sidewalk-lamp:left|modern->bikeshare:left|sidewalk": 1,
  "sidewalk-lamp:left|modern->bikeshare:right|road": 1,
  "sidewalk-lamp:left|modern->bus-lane:inbound|colored": 2,
  "sidewalk-lamp:left|modern->bus-lane:inbound|regular": 29,
  "sidewalk-lamp:left|modern->bus-lane:inbound|regular|typical": 2,
  "sidewalk-lamp:left|modern->bus-lane:inbound|shared|typical": 16,
  "sidewalk-lamp:left|modern->bus-lane:outbound|colored|typical": 1,
  "sidewalk-lamp:left|modern->bus-lane:outbound|regular": 3,
  "sidewalk-lamp:left|modern->bus-lane:outbound|regular|typical": 5,
  "sidewalk-lamp:left|modern->bus-lane:outbound|shared|typical": 1,
  "sidewalk-lamp:left|modern->divider:big-tree": 67,
  "sidewalk-lamp:left|modern->divider:bollard": 5,
  "sidewalk-lamp:left|modern->divider:bush": 29,
  "sidewalk-lamp:left|modern->divider:dome": 1,
  "sidewalk-lamp:left|modern->divider:flowers": 101,
  "sidewalk-lamp:left|modern->divider:median": 13,
  "sidewalk-lamp:left|modern->divider:palm-tree": 15,
  "sidewalk-lamp:left|modern->divider:planter-box": 17,
  "sidewalk-lamp:left|modern->divider:planting-strip": 34,
  "sidewalk-lamp:left|modern->divider:striped-buffer": 3,
  "sidewalk-lamp:left|modern->drive-lane:inbound|car": 19,
  "sidewalk-lamp:left|modern->drive-lane:inbound|sharrow": 2,
  "sidewalk-lamp:left|modern->drive-lane:outbound|car": 12,
  "sidewalk-lamp:left|modern->drive-lane:outbound|truck": 14,
  "sidewalk-lamp:left|modern->flex-zone-curb:empty|left": 1,
  "sidewalk-lamp:left|modern->flex-zone-curb:sparse|left": 13,
  "sidewalk-lamp:left|modern->flex-zone:taxi|inbound|left": 3,
  "sidewalk-lamp:left|modern->food-truck:left": 3,
  "sidewalk-lamp:left|modern->food-truck:right": 1,
  "sidewalk-lamp:left|modern->light-rail:inbound|colored": 1,
  "sidewalk-lamp:left|modern->light-rail:inbound|regular": 17,
  "sidewalk-lamp:left|modern->light-rail:outbound|colored": 2,
  "sidewalk-lamp:left|modern->light-rail:outbound|regular": 11,
  "sidewalk-lamp:left|modern->outdoor-dining:occupied|sidewalk": 1,
  "sidewalk-lamp:left|modern->parking-lane:inbound|left": 3,
  "sidewalk-lamp:left|modern->parking-lane:inbound|right": 2,
  "sidewalk-lamp:left|modern->parking-lane:outbound|left": 1,
  "sidewalk-lamp:left|modern->parking-lane:outbound|right": 8,
  "sidewalk-lamp:left|modern->parklet:left": 16,
  "sidewalk-lamp:left|modern->parklet:right": 39,
  "sidewalk-lamp:left|modern->scooter-drop-zone:right|sidewalk|empty": 10,
  "sidewalk-lamp:left|modern->scooter-drop-zone:right|sidewalk|sparse": 1,
  "sidewalk-lamp:left|modern->scooter:inbound|regular": 7,
  "sidewalk-lamp:left|modern->sidewalk-bench:center": 4,
  "sidewalk-lamp:left|modern->sidewalk-bench:left": 23,
  "sidewalk-lamp:left|modern->sidewalk-bench:right": 69,
  "sidewalk-lamp:left|modern->sidewalk-bike-rack:left|sidewalk": 53,
  "sidewalk-lamp:left|modern->sidewalk-bike-rack:left|sidewalk-parallel": 12,
  "sidewalk-lamp:left|modern->sidewalk-bike-rack:right|road": 3,
  "sidewalk-lamp:left|modern->sidewalk-bike-rack:right|sidewalk": 13,
  "sidewalk-lamp:left|modern->sidewalk-bike-rack:right|sidewalk-parallel": 41,
  "sidewalk-lamp:left|modern->sidewalk-lamp:both|modern": 6,
  "sidewalk-lamp:left|modern->sidewalk-lamp:both|traditional": 7,
  "sidewalk-lamp:left|modern->sidewalk-lamp:left|modern": 30,
  "sidewalk-lamp:left|modern->sidewalk-lamp:left|traditional": 2,
  "sidewalk-lamp:left|modern->sidewalk-lamp:right|modern": 33,
  "sidewalk-lamp:left|modern->sidewalk-lamp:right|traditional": 4,
  "sidewalk-lamp:left|modern->sidewalk-tree:big": 35462,
  "sidewalk-lamp:left|modern->sidewalk-tree:palm-tree": 299,
  "sidewalk-lamp:left|modern->sidewalk-wayfinding:large": 32,
  "sidewalk-lamp:left|modern->sidewalk-wayfinding:medium": 293,
  "sidewalk-lamp:left|modern->sidewalk-wayfinding:small": 24,
  "sidewalk-lamp:left|modern->sidewalk:dense": 364,
  "sidewalk-lamp:left|modern->sidewalk:empty": 16,
  "sidewalk-lamp:left|modern->sidewalk:normal": 800,
  "sidewalk-lamp:left|modern->sidewalk:sparse": 59,
  "sidewalk-lamp:left|modern->streetcar:inbound|colored": 1,
  "sidewalk-lamp:left|modern->streetcar:inbound|regular": 9,
  "sidewalk-lamp:left|modern->streetcar:outbound|colored": 1,
  "sidewalk-lamp:left|modern->streetcar:outbound|regular": 2,
  "sidewalk-lamp:left|modern->transit-shelter:left|light-rail": 5,
  "sidewalk-lamp:left|modern->transit-shelter:left|street-level": 50,
  "sidewalk-lamp:left|modern->transit-shelter:right|light-rail": 17,
  "sidewalk-lamp:left|modern->transit-shelter:right|street-level": 1585,
  "sidewalk-lamp:left|modern->turn-lane:inbound|both": 1,
  "sidewalk-lamp:left|modern->turn-lane:inbound|left": 1,
  "sidewalk-lamp:left|modern->turn-lane:inbound|left-straight": 1,
  "sidewalk-lamp:left|modern->turn-lane:inbound|right": 2,
  "sidewalk-lamp:left|modern->turn-lane:inbound|right-straight": 2,
  "sidewalk-lamp:left|modern->turn-lane:outbound|left": 1,
  "sidewalk-lamp:left|modern->turn-lane:outbound|left-straight": 1,
  "sidewalk-lamp:left|modern->turn-lane:outbound|right": 3,
  "sidewalk-lamp:left|modern->turn-lane:outbound|right-straight": 1,
  "sidewalk-lamp:left|modern->turn-lane:outbound|straight": 3,
  "sidewalk-lamp:left|modern->utilities:right": 7,
  "sidewalk-lamp:left|pride->bike-lane:outbound|regular|road": 1,
  "sidewalk-lamp:left|pride->bus-lane:inbound|shared|typical": 2,
  "sidewalk-lamp:left|pride->bus-lane:outbound|regular|typical": 1,
  "sidewalk-lamp:left|pride->divider:flowers": 1,
  "sidewalk-lamp:left|pride->divider:median": 1,
  "sidewalk-lamp:left|pride->divider:planter-box": 1,
  "sidewalk-lamp:left|pride->drive-lane:inbound|car": 1,
  "sidewalk-lamp:left|pride->light-rail:outbound|grass": 1,
  "sidewalk-lamp:left|pride->light-rail:outbound|regular": 1,
  "sidewalk-lamp:left|pride->sidewalk-bench:left": 2,
  "sidewalk-lamp:left|pride->sidewalk-bike-rack:left|sidewalk": 2,
  "sidewalk-lamp:left|pride->sidewalk-tree:big": 71,
  "sidewalk-lamp:left|pride->sidewalk-tree:palm-tree": 15,
  "sidewalk-lamp:left|pride->sidewalk-wayfinding:medium": 2,
  "sidewalk-lamp:left|pride->sidewalk-wayfinding:small": 1,
  "sidewalk-lamp:left|pride->sidewalk:dense": 7,
  "sidewalk-lamp:left|pride->sidewalk:normal": 4,
  "sidewalk-lamp:left|pride->sidewalk:sparse": 1,
  "sidewalk-lamp:left|pride->streetcar:inbound|regular": 1,
  "sidewalk-lamp:left|pride->transit-shelter:right|street-level": 6,
  "sidewalk-lamp:left|pride->turn-lane:inbound|left": 1,
  "sidewalk-lamp:left|traditional->bike-lane:inbound|colored": 9,
  "sidewalk-lamp:left|traditional->bike-lane:inbound|green|road": 3,
  "sidewalk-lamp:left|traditional->bike-lane:inbound|regular": 9,
  "sidewalk-lamp:left|traditional->bike-lane:inbound|regular|road": 18,
  "sidewalk-lamp:left|traditional->bike-lane:inbound|regular|sidewalk": 1,
  "sidewalk-lamp:left|traditional->bike-lane:outbound|colored": 22,
  "sidewalk-lamp:left|traditional->bike-lane:outbound|green|road": 9,
  "sidewalk-lamp:left|traditional->bike-lane:outbound|green|sidewalk": 3,
  "sidewalk-lamp:left|traditional->bike-lane:outbound|regular": 3,
  "sidewalk-lamp:left|traditional->bike-lane:outbound|regular|road": 6,
  "sidewalk-lamp:left|traditional->bike-lane:outbound|regular|sidewalk": 4,
  "sidewalk-lamp:left|traditional->bikeshare:left|road": 3,
  "sidewalk-lamp:left|traditional->bus-lane:inbound|colored": 4,
  "sidewalk-lamp:left|traditional->bus-lane:inbound|regular": 17,
  "sidewalk-lamp:left|traditional->bus-lane:inbound|regular|typical": 1,
  "sidewalk-lamp:left|traditional->bus-lane:inbound|shared|typical": 4,
  "sidewalk-lamp:left|traditional->bus-lane:outbound|colored|typical": 1,
  "sidewalk-lamp:left|traditional->bus-lane:outbound|regular|typical": 3,
  "sidewalk-lamp:left|traditional->divider:big-tree": 59,
  "sidewalk-lamp:left|traditional->divider:bollard": 3,
  "sidewalk-lamp:left|traditional->divider:bush": 16,
  "sidewalk-lamp:left|traditional->divider:flowers": 63,
  "sidewalk-lamp:left|traditional->divider:median": 14,
  "sidewalk-lamp:left|traditional->divider:palm-tree": 16,
  "sidewalk-lamp:left|traditional->divider:planter-box": 6,
  "sidewalk-lamp:left|traditional->divider:planting-strip": 8,
  "sidewalk-lamp:left|traditional->divider:striped-buffer": 2,
  "sidewalk-lamp:left|traditional->drive-lane:inbound|car": 17,
  "sidewalk-lamp:left|traditional->drive-lane:inbound|sharrow": 3,
  "sidewalk-lamp:left|traditional->drive-lane:inbound|truck": 3,
  "sidewalk-lamp:left|traditional->drive-lane:outbound|car": 3,
  "sidewalk-lamp:left|traditional->drive-lane:outbound|sharrow": 2,
  "sidewalk-lamp:left|traditional->drive-lane:outbound|truck": 2,
  "sidewalk-lamp:left|traditional->flex-zone-curb:sparse|left": 3,
  "sidewalk-lamp:left|traditional->flex-zone:taxi|inbound|left": 1,
  "sidewalk-lamp:left|traditional->food-truck:left": 3,
  "sidewalk-lamp:left|traditional->light-rail:inbound|regular": 3,
  "sidewalk-lamp:left|traditional->light-rail:outbound|colored": 3,
  "sidewalk-lamp:left|traditional->light-rail:outbound|regular": 2,
  "sidewalk-lamp:left|traditional->parking-lane:inbound|left": 14,
  "sidewalk-lamp:left|traditional->parking-lane:inbound|right": 3,
  "sidewalk-lamp:left|traditional->parking-lane:outbound|left": 2,
  "sidewalk-lamp:left|traditional->parking-lane:outbound|right": 3,
  "sidewalk-lamp:left|traditional->parking-lane:sideways|right": 1,
  "sidewalk-lamp:left|traditional->parklet:left": 19,
  "sidewalk-lamp:left|traditional->parklet:right": 9,
  "sidewalk-lamp:left|traditional->scooter-drop-zone:right|sidewalk|empty": 5,
  "sidewalk-lamp:left|traditional->scooter:inbound|regular": 4,
  "sidewalk-lamp:left|traditional->scooter:outbound|regular": 1,
  "sidewalk-lamp:left|traditional->sidewalk-bench:center": 16,
  "sidewalk-lamp:left|traditional->sidewalk-bench:left": 20,
  "sidewalk-lamp:left|traditional->sidewalk-bench:right": 24,
  "sidewalk-lamp:left|traditional->sidewalk-bike-rack:left|sidewalk": 22,
  "sidewalk-lamp:left|traditional->sidewalk-bike-rack:left|sidewalk-parallel": 15,
  "sidewalk-lamp:left|traditional->sidewalk-bike-rack:right|sidewalk": 8,
  "sidewalk-lamp:left|traditional->sidewalk-bike-rack:right|sidewalk-parallel": 15,
  "sidewalk-lamp:left|traditional->sidewalk-lamp:both|traditional": 4,
  "sidewalk-lamp:left|traditional->sidewalk-lamp:left|modern": 4,
  "sidewalk-lamp:left|traditional->sidewalk-lamp:left|pride": 1,
  "sidewalk-lamp:left|traditional->sidewalk-lamp:left|traditional": 4,
  "sidewalk-lamp:left|traditional->sidewalk-lamp:right|modern": 14,
  "sidewalk-lamp:left|traditional->sidewalk-lamp:right|traditional": 5,
  "sidewalk-lamp:left|traditional->sidewalk-tree:big": 1045,
  "sidewalk-lamp:left|traditional->sidewalk-tree:palm-tree": 163,
  "sidewalk-lamp:left|traditional->sidewalk-wayfinding:large": 18,
  "sidewalk-lamp:left|traditional->sidewalk-wayfinding:medium": 31,
  "sidewalk-lamp:left|traditional->sidewalk-wayfinding:small": 14,
  "sidewalk-lamp:left|traditional->sidewalk:dense": 198,
  "sidewalk-lamp:left|traditional->sidewalk:empty": 5,
  "sidewalk-lamp:left|traditional->sidewalk:normal": 145,
  "sidewalk-lamp:left|traditional->sidewalk:sparse": 39,
  "sidewalk-lamp:left|traditional->streetcar:inbound|colored": 1,
  "sidewalk-lamp:left|traditional->streetcar:inbound|regular": 10,
  "sidewalk-lamp:left|traditional->streetcar:outbound|regular": 5,
  "sidewalk-lamp:left|traditional->transit-shelter:left|light-rail": 3,
  "sidewalk-lamp:left|traditional->transit-shelter:left|street-level": 18,
  "sidewalk-lamp:left|traditional->transit-shelter:right|light-rail": 1,
  "sidewalk-lamp:left|traditional->transit-shelter:right|street-level": 38,
  "sidewalk-lamp:left|traditional->turn-lane:inbound|left-straight": 1,
  "sidewalk-lamp:left|traditional->turn-lane:outbound|left-straight": 1,
  "sidewalk-lamp:left|traditional->turn-lane:outbound|straight": 1,
  "sidewalk-lamp:modern->drive-lane:outbound": 1,
  "sidewalk-lamp:modern->sidewalk-lamp:left|traditional": 1,
  "sidewalk-lamp:modern->sidewalk-tree:big": 1,
  "sidewalk-lamp:modern->transit-shelter:right|street-level": 1,
  "sidewalk-lamp:modern|both->transit-shelter:right|street-level": 1,
  "sidewalk-lamp:modern|left->sidewalk-tree:big": 1,
  "sidewalk-lamp:modern|left->transit-shelter:right|street-level": 1,
  "sidewalk-lamp:pride->drive-lane:car": 1,
  "sidewalk-lamp:pride->sidewalk-tree:big": 1,
  "sidewalk-lamp:pride->sidewalk-tree:palm-tree": 1,
  "sidewalk-lamp:pride|both->divider:bollard": 1,
  "sidewalk-lamp:pride|left->bus-lane:inbound|shared|typical": 1,
  "sidewalk-lamp:pride|left->drive-lane:sharrow": 1,
  "sidewalk-lamp:right|modern->bike-lane:green": 1,
  "sidewalk-lamp:right|modern->bike-lane:inbound|colored": 374,
  "sidewalk-lamp:right|modern->bike-lane:inbound|green|road": 104,
  "sidewalk-lamp:right|modern->bike-lane:inbound|green|sidewalk": 14,
  "sidewalk-lamp:right|modern->bike-lane:inbound|red|road": 15,
  "sidewalk-lamp:right|modern->bike-lane:inbound|red|sidewalk": 3,
  "sidewalk-lamp:right|modern->bike-lane:inbound|regular": 468,
  "sidewalk-lamp:right|modern->bike-lane:inbound|regular|road": 307,
  "sidewalk-lamp:right|modern->bike-lane:inbound|regular|sidewalk": 7,
  "sidewalk-lamp:right|modern->bike-lane:outbound|colored": 182,
  "sidewalk-lamp:right|modern->bike-lane:outbound|green|road": 1101,
  "sidewalk-lamp:right|modern->bike-lane:outbound|green|road|inbound": 4,
  "sidewalk-lamp:right|modern->bike-lane:outbound|green|sidewalk": 6,
  "sidewalk-lamp:right|modern->bike-lane:outbound|red|road": 7,
  "sidewalk-lamp:right|modern->bike-lane:outbound|red|road|inbound": 1,
  "sidewalk-lamp:right|modern->bike-lane:outbound|red|sidewalk|inbound": 2,
  "sidewalk-lamp:right|modern->bike-lane:outbound|regular": 29,
  "sidewalk-lamp:right|modern->bike-lane:outbound|regular|road": 23,
  "sidewalk-lamp:right|modern->bike-lane:outbound|regular|sidewalk|inbound": 1,
  "sidewalk-lamp:right|modern->bike-lane:red": 1,
  "sidewalk-lamp:right|modern->bike-lane:red||sidewalk": 1,
  "sidewalk-lamp:right|modern->bike-lane:regular|inbound|sidewalk": 1,
  "sidewalk-lamp:right|modern->bikeshare:left|road": 10,
  "sidewalk-lamp:right|modern->bikeshare:left|sidewalk": 1,
  "sidewalk-lamp:right|modern->bus-lane:colored": 2,
  "sidewalk-lamp:right|modern->bus-lane:colored|inbound": 1,
  "sidewalk-lamp:right|modern->bus-lane:colored|inbound|typical": 1,
  "sidewalk-lamp:right|modern->bus-lane:inbound|colored": 306,
  "sidewalk-lamp:right|modern->bus-lane:inbound|colored|typical": 98,
  "sidewalk-lamp:right|modern->bus-lane:inbound|regular": 16060,
  "sidewalk-lamp:right|modern->bus-lane:inbound|regular|typical": 329,
  "sidewalk-lamp:right|modern->bus-lane:inbound|shared|typical": 12909,
  "sidewalk-lamp:right|modern->bus-lane:inbound|typical": 1,
  "sidewalk-lamp:right|modern->bus-lane:outbound": 1,
  "sidewalk-lamp:right|modern->bus-lane:outbound|colored": 30,
  "sidewalk-lamp:right|modern->bus-lane:outbound|colored|typical": 16,
  "sidewalk-lamp:right|modern->bus-lane:outbound|regular": 111,
  "sidewalk-lamp:right|modern->bus-lane:outbound|regular|typical": 34,
  "sidewalk-lamp:right|modern->bus-lane:outbound|shared|typical": 56,
  "sidewalk-lamp:right|modern->bus-lane:regular": 1,
  "sidewalk-lamp:right|modern->bus-lane:regular|typical": 1,
  "sidewalk-lamp:right|modern->bus-lane:shared|inbound": 2,
  "sidewalk-lamp:right|modern->bus-lane:shared|typical|inbound": 1,
  "sidewalk-lamp:right|modern->divider:big-tree": 29,
  "sidewalk-lamp:right|modern->divider:bollard": 15,
  "sidewalk-lamp:right|modern->divider:bush": 241,
  "sidewalk-lamp:right|modern->divider:dome": 8,
  "sidewalk-lamp:right|modern->divider:flowers": 502,
  "sidewalk-lamp:right|modern->divider:median": 57,
  "sidewalk-lamp:right|modern->divider:palm-tree": 18,
  "sidewalk-lamp:right|modern->divider:planter-box": 61,
  "sidewalk-lamp:right|modern->divider:planting-strip": 25,
  "sidewalk-lamp:right|modern->divider:striped-buffer": 28,
  "sidewalk-lamp:right|modern->drive-lane:car": 1,
  "sidewalk-lamp:right|modern->drive-lane:car|car": 1,
  "sidewalk-lamp:right|modern->drive-lane:car|outbound": 1,
  "sidewalk-lamp:right|modern->drive-lane:inbound": 1,
  "sidewalk-lamp:right|modern->drive-lane:inbound|car": 2409,
  "sidewalk-lamp:right|modern->drive-lane:inbound|sharrow": 31,
  "sidewalk-lamp:right|modern->drive-lane:inbound|truck": 93,
  "sidewalk-lamp:right|modern->drive-lane:outbound": 3,
  "sidewalk-lamp:right|modern->drive-lane:outbound|car": 177,
  "sidewalk-lamp:right|modern->drive-lane:outbound|sharrow": 2,
  "sidewalk-lamp:right|modern->drive-lane:outbound|truck": 62,
  "sidewalk-lamp:right|modern->flex-zone-curb:empty|right": 1,
  "sidewalk-lamp:right|modern->flex-zone-curb:sparse|left": 4,
  "sidewalk-lamp:right|modern->flex-zone-curb:sparse|right": 5,
  "sidewalk-lamp:right|modern->flex-zone:rideshare|inbound|left": 3,
  "sidewalk-lamp:right|modern->flex-zone:taxi|inbound|left": 54,
  "sidewalk-lamp:right|modern->flex-zone:taxi|outbound|left": 2,
  "sidewalk-lamp:right|modern->food-truck:left": 10,
  "sidewalk-lamp:right|modern->light-rail:inbound|colored": 24,
  "sidewalk-lamp:right|modern->light-rail:inbound|grass": 5,
  "sidewalk-lamp:right|modern->light-rail:inbound|regular": 190,
  "sidewalk-lamp:right|modern->light-rail:outbound|colored": 11,
  "sidewalk-lamp:right|modern->light-rail:outbound|grass": 1,
  "sidewalk-lamp:right|modern->light-rail:outbound|regular": 46,
  "sidewalk-lamp:right|modern->parking-lane:angled-front-left|left": 3,
  "sidewalk-lamp:right|modern->parking-lane:angled-rear-left|left": 1,
  "sidewalk-lamp:right|modern->parking-lane:inbound|left": 492,
  "sidewalk-lamp:right|modern->parking-lane:inbound|right": 45,
  "sidewalk-lamp:right|modern->parking-lane:outbound|left": 414,
  "sidewalk-lamp:right|modern->parking-lane:outbound|right": 33,
  "sidewalk-lamp:right|modern->parking-lane:sideways|left": 6,
  "sidewalk-lamp:right|modern->parking-lane:sideways|right": 2,
  "sidewalk-lamp:right|modern->parklet:left": 101,
  "sidewalk-lamp:right|modern->parklet:right": 19,
  "sidewalk-lamp:right|modern->scooter-drop-zone:right|road|empty": 1,
  "sidewalk-lamp:right|modern->scooter-drop-zone:right|sidewalk|empty": 5,
  "sidewalk-lamp:right|modern->scooter-drop-zone:right|sidewalk|sparse": 1,
  "sidewalk-lamp:right|modern->scooter:inbound|green": 1,
  "sidewalk-lamp:right|modern->scooter:inbound|regular": 14,
  "sidewalk-lamp:right|modern->scooter:outbound|red": 2,
  "sidewalk-lamp:right|modern->scooter:outbound|regular": 4,
  "sidewalk-lamp:right|modern->sidewalk-bench:left": 75,
  "sidewalk-lamp:right|modern->sidewalk-bench:right": 3,
  "sidewalk-lamp:right|modern->sidewalk-bike-rack:left|road": 9,
  "sidewalk-lamp:right|modern->sidewalk-bike-rack:left|sidewalk": 319,
  "sidewalk-lamp:right|modern->sidewalk-bike-rack:left|sidewalk-parallel": 16,
  "sidewalk-lamp:right|modern->sidewalk-bike-rack:right|road": 2,
  "sidewalk-lamp:right|modern->sidewalk-bike-rack:right|sidewalk": 31,
  "sidewalk-lamp:right|modern->sidewalk-bike-rack:right|sidewalk-parallel": 73,
  "sidewalk-lamp:right|modern->sidewalk-lamp:both|modern": 8,
  "sidewalk-lamp:right|modern->sidewalk-lamp:both|traditional": 5,
  "sidewalk-lamp:right|modern->sidewalk-lamp:left|modern": 45,
  "sidewalk-lamp:right|modern->sidewalk-lamp:left|traditional": 3,
  "sidewalk-lamp:right|modern->sidewalk-lamp:right|modern": 34,
  "sidewalk-lamp:right|modern->sidewalk-lamp:right|pride": 1,
  "sidewalk-lamp:right|modern->sidewalk-lamp:right|traditional": 18,
  "sidewalk-lamp:right|modern->sidewalk-tree:big": 297,
  "sidewalk-lamp:right|modern->sidewalk-tree:palm-tree": 13,
  "sidewalk-lamp:right|modern->sidewalk-wayfinding:large": 67,
  "sidewalk-lamp:right|modern->sidewalk-wayfinding:medium": 13,
  "sidewalk-lamp:right|modern->sidewalk-wayfinding:small": 4,
  "sidewalk-lamp:right|modern->sidewalk:dense": 287,
  "sidewalk-lamp:right|modern->sidewalk:empty": 3,
  "sidewalk-lamp:right|modern->sidewalk:normal": 63,
  "sidewalk-lamp:right|modern->sidewalk:sparse": 29,
  "sidewalk-lamp:right|modern->streetcar:inbound|colored": 13,
  "sidewalk-lamp:right|modern->streetcar:inbound|grass": 1,
  "sidewalk-lamp:right|modern->streetcar:inbound|regular": 122,
  "sidewalk-lamp:right|modern->streetcar:outbound|colored": 3,
  "sidewalk-lamp:right|modern->streetcar:outbound|regular": 14,
  "sidewalk-lamp:right|modern->transit-shelter:left|light-rail": 56,
  "sidewalk-lamp:right|modern->transit-shelter:left|street-level": 249,
  "sidewalk-lamp:right|modern->transit-shelter:right|light-rail": 6,
  "sidewalk-lamp:right|modern->transit-shelter:right|street-level": 38,
  "sidewalk-lamp:right|modern->turn-lane:inbound|both": 5,
  "sidewalk-lamp:right|modern->turn-lane:inbound|left": 120,
  "sidewalk-lamp:right|modern->turn-lane:inbound|left-right-straight": 3,
  "sidewalk-lamp:right|modern->turn-lane:inbound|left-straight": 39,
  "sidewalk-lamp:right|modern->turn-lane:inbound|right": 31,
  "sidewalk-lamp:right|modern->turn-lane:inbound|right-straight": 461,
  "sidewalk-lamp:right|modern->turn-lane:inbound|shared": 8,
  "sidewalk-lamp:right|modern->turn-lane:inbound|straight": 69,
  "sidewalk-lamp:right|modern->turn-lane:outbound|both": 2,
  "sidewalk-lamp:right|modern->turn-lane:outbound|left": 50,
  "sidewalk-lamp:right|modern->turn-lane:outbound|left-right-straight": 4,
  "sidewalk-lamp:right|modern->turn-lane:outbound|left-straight": 50,
  "sidewalk-lamp:right|modern->turn-lane:outbound|right": 7,
  "sidewalk-lamp:right|modern->turn-lane:outbound|right-straight": 7,
  "sidewalk-lamp:right|modern->turn-lane:outbound|straight": 17,
  "sidewalk-lamp:right|pride->bike-lane:inbound|green|road": 10,
  "sidewalk-lamp:right|pride->bike-lane:inbound|green|sidewalk": 1,
  "sidewalk-lamp:right|pride->bike-lane:inbound|red|sidewalk": 1,
  "sidewalk-lamp:right|pride->bike-lane:inbound|regular|road": 8,
  "sidewalk-lamp:right|pride->bike-lane:outbound|green|road": 7,
  "sidewalk-lamp:right|pride->bike-lane:outbound|green|sidewalk": 1,
  "sidewalk-lamp:right|pride->bus-lane:inbound|colored|typical": 2,
  "sidewalk-lamp:right|pride->bus-lane:inbound|regular|typical": 7,
  "sidewalk-lamp:right|pride->bus-lane:inbound|shared|typical": 34,
  "sidewalk-lamp:right|pride->bus-lane:outbound|colored|typical": 1,
  "sidewalk-lamp:right|pride->bus-lane:outbound|regular|typical": 1,
  "sidewalk-lamp:right|pride->bus-lane:outbound|shared|typical": 10,
  "sidewalk-lamp:right|pride->divider:bush": 5,
  "sidewalk-lamp:right|pride->divider:flowers": 3,
  "sidewalk-lamp:right|pride->divider:median": 1,
  "sidewalk-lamp:right|pride->divider:planter-box": 1,
  "sidewalk-lamp:right|pride->divider:striped-buffer": 1,
  "sidewalk-lamp:right|pride->drive-lane:inbound|car": 20,
  "sidewalk-lamp:right|pride->drive-lane:inbound|sharrow": 1,
  "sidewalk-lamp:right|pride->drive-lane:inbound|truck": 2,
  "sidewalk-lamp:right|pride->drive-lane:outbound|car": 1,
  "sidewalk-lamp:right|pride->flex-zone-curb:sparse|left": 1,
  "sidewalk-lamp:right|pride->food-truck:left": 1,
  "sidewalk-lamp:right|pride->light-rail:inbound|colored": 1,
  "sidewalk-lamp:right|pride->light-rail:inbound|grass": 1,
  "sidewalk-lamp:right|pride->parking-lane:inbound|left": 5,
  "sidewalk-lamp:right|pride->parking-lane:outbound|left": 1,
  "sidewalk-lamp:right|pride->scooter:inbound|green": 2,
  "sidewalk-lamp:right|pride->sidewalk-bench:left": 1,
  "sidewalk-lamp:right|pride->sidewalk-lamp:right|modern": 1,
  "sidewalk-lamp:right|pride->sidewalk-tree:big": 4,
  "sidewalk-lamp:right|pride->sidewalk-tree:palm-tree": 2,
  "sidewalk-lamp:right|pride->sidewalk-wayfinding:large": 2,
  "sidewalk-lamp:right|pride->sidewalk-wayfinding:small": 2,
  "sidewalk-lamp:right|pride->sidewalk:dense": 8,
  "sidewalk-lamp:right|pride->streetcar:inbound|colored": 1,
  "sidewalk-lamp:right|pride->transit-shelter:left|light-rail": 1,
  "sidewalk-lamp:right|pride->transit-shelter:left|street-level": 1,
  "sidewalk-lamp:right|pride->turn-lane:inbound|left": 2,
  "sidewalk-lamp:right|pride->turn-lane:outbound|left-straight": 2,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|colored": 135,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|green|road": 28,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|green|sidewalk": 6,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|red|road": 4,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|red|sidewalk": 3,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|regular": 116,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|regular|road": 62,
  "sidewalk-lamp:right|traditional->bike-lane:inbound|regular|sidewalk": 4,
  "sidewalk-lamp:right|traditional->bike-lane:outbound|colored": 28,
  "sidewalk-lamp:right|traditional->bike-lane:outbound|green|road": 36,
  "sidewalk-lamp:right|traditional->bike-lane:outbound|green|road|inbound": 1,
  "sidewalk-lamp:right|traditional->bike-lane:outbound|green|sidewalk": 4,
  "sidewalk-lamp:right|traditional->bike-lane:outbound|red|sidewalk": 1,
  "sidewalk-lamp:right|traditional->bike-lane:outbound|regular": 12,
  "sidewalk-lamp:right|traditional->bike-lane:outbound|regular|road": 10,
  "sidewalk-lamp:right|traditional->bikeshare:left|road": 3,
  "sidewalk-lamp:right|traditional->bikeshare:right|road": 1,
  "sidewalk-lamp:right|traditional->bus-lane:inbound|colored": 39,
  "sidewalk-lamp:right|traditional->bus-lane:inbound|colored|typical": 7,
  "sidewalk-lamp:right|traditional->bus-lane:inbound|regular": 181,
  "sidewalk-lamp:right|traditional->bus-lane:inbound|regular|typical": 51,
  "sidewalk-lamp:right|traditional->bus-lane:inbound|shared|typical": 76,
  "sidewalk-lamp:right|traditional->bus-lane:outbound|colored": 1,
  "sidewalk-lamp:right|traditional->bus-lane:outbound|colored|typical": 1,
  "sidewalk-lamp:right|traditional->bus-lane:outbound|regular": 13,
  "sidewalk-lamp:right|traditional->bus-lane:outbound|regular|typical": 3,
  "sidewalk-lamp:right|traditional->bus-lane:outbound|shared|typical": 3,
  "sidewalk-lamp:right|traditional->divider:big-tree": 5,
  "sidewalk-lamp:right|traditional->divider:bollard": 4,
  "sidewalk-lamp:right|traditional->divider:bush": 20,
  "sidewalk-lamp:right|traditional->divider:flowers": 68,
  "sidewalk-lamp:right|traditional->divider:median": 58,
  "sidewalk-lamp:right|traditional->divider:palm-tree": 1,
  "sidewalk-lamp:right|traditional->divider:planter-box": 7,
  "sidewalk-lamp:right|traditional->divider:planting-strip": 10,
  "sidewalk-lamp:right|traditional->divider:striped-buffer": 7,
  "sidewalk-lamp:right|traditional->drive-lane:inbound|car": 292,
  "sidewalk-lamp:right|traditional->drive-lane:inbound|sharrow": 13,
  "sidewalk-lamp:right|traditional->drive-lane:inbound|truck": 12,
  "sidewalk-lamp:right|traditional->drive-lane:outbound|car": 60,
  "sidewalk-lamp:right|traditional->drive-lane:outbound|sharrow": 2,
  "sidewalk-lamp:right|traditional->drive-lane:outbound|truck": 5,
  "sidewalk-lamp:right|traditional->flex-zone-curb:sparse|left": 2,
  "sidewalk-lamp:right|traditional->flex-zone-curb:sparse|right": 9,
  "sidewalk-lamp:right|traditional->flex-zone:rideshare|inbound|left": 4,
  "sidewalk-lamp:right|traditional->flex-zone:taxi|inbound|left": 8,
  "sidewalk-lamp:right|traditional->flex-zone:taxi|inbound|right": 1,
  "sidewalk-lamp:right|traditional->food-truck:left": 6,
  "sidewalk-lamp:right|traditional->light-rail:inbound|colored": 3,
  "sidewalk-lamp:right|traditional->light-rail:inbound|grass": 1,
  "sidewalk-lamp:right|traditional->light-rail:inbound|regular": 30,
  "sidewalk-lamp:right|traditional->light-rail:outbound|colored": 1,
  "sidewalk-lamp:right|traditional->light-rail:outbound|regular": 4,
  "sidewalk-lamp:right|traditional->parking-lane:inbound|left": 145,
  "sidewalk-lamp:right|traditional->parking-lane:inbound|right": 12,
  "sidewalk-lamp:right|traditional->parking-lane:outbound|left": 11,
  "sidewalk-lamp:right|traditional->parking-lane:outbound|right": 14,
  "sidewalk-lamp:right|traditional->parking-lane:sideways|left": 8,
  "sidewalk-lamp:right|traditional->parklet:left": 34,
  "sidewalk-lamp:right|traditional->parklet:right": 13,
  "sidewalk-lamp:right|traditional->scooter-drop-zone:right|sidewalk|empty": 1,
  "sidewalk-lamp:right|traditional->scooter:inbound|green": 2,
  "sidewalk-lamp:right|traditional->scooter:inbound|red": 1,
  "sidewalk-lamp:right|traditional->scooter:inbound|regular": 14,
  "sidewalk-lamp:right|traditional->scooter:outbound|regular": 2,
  "sidewalk-lamp:right|traditional->sidewalk-bench:center": 6,
  "sidewalk-lamp:right|traditional->sidewalk-bench:left": 104,
  "sidewalk-lamp:right|traditional->sidewalk-bench:right": 6,
  "sidewalk-lamp:right|traditional->sidewalk-bike-rack:left|sidewalk": 24,
  "sidewalk-lamp:right|traditional->sidewalk-bike-rack:left|sidewalk-parallel": 7,
  "sidewalk-lamp:right|traditional->sidewalk-bike-rack:right|road": 1,
  "sidewalk-lamp:right|traditional->sidewalk-bike-rack:right|sidewalk": 12,
  "sidewalk-lamp:right|traditional->sidewalk-bike-rack:right|sidewalk-parallel": 13,
  "sidewalk-lamp:right|traditional->sidewalk-lamp:both|traditional": 1,
  "sidewalk-lamp:right|traditional->sidewalk-lamp:left|modern": 7,
  "sidewalk-lamp:right|traditional->sidewalk-lamp:left|traditional": 3,
  "sidewalk-lamp:right|traditional->sidewalk-lamp:right|modern": 1,
  "sidewalk-lamp:right|traditional->sidewalk-lamp:right|pride": 1,
  "sidewalk-lamp:right|traditional->sidewalk-lamp:right|traditional": 14,
  "sidewalk-lamp:right|traditional->sidewalk-tree:big": 136,
  "sidewalk-lamp:right|traditional->sidewalk-tree:palm-tree": 17,
  "sidewalk-lamp:right|traditional->sidewalk-wayfinding:large": 15,
  "sidewalk-lamp:right|traditional->sidewalk-wayfinding:medium": 4,
  "sidewalk-lamp:right|traditional->sidewalk-wayfinding:small": 9,
  "sidewalk-lamp:right|traditional->sidewalk:dense": 344,
  "sidewalk-lamp:right|traditional->sidewalk:empty": 4,
  "sidewalk-lamp:right|traditional->sidewalk:normal": 81,
  "sidewalk-lamp:right|traditional->sidewalk:sparse": 21,
  "sidewalk-lamp:right|traditional->streetcar:inbound|colored": 5,
  "sidewalk-lamp:right|traditional->streetcar:inbound|regular": 37,
  "sidewalk-lamp:right|traditional->streetcar:outbound|colored": 2,
  "sidewalk-lamp:right|traditional->streetcar:outbound|regular": 7,
  "sidewalk-lamp:right|traditional->transit-shelter:left|light-rail": 15,
  "sidewalk-lamp:right|traditional->transit-shelter:left|street-level": 48,
  "sidewalk-lamp:right|traditional->transit-shelter:right|street-level": 8,
  "sidewalk-lamp:right|traditional->turn-lane:inbound|both": 1,
  "sidewalk-lamp:right|traditional->turn-lane:inbound|left": 12,
  "sidewalk-lamp:right|traditional->turn-lane:inbound|left-straight": 8,
  "sidewalk-lamp:right|traditional->turn-lane:inbound|right": 2,
  "sidewalk-lamp:right|traditional->turn-lane:inbound|right-straight": 20,
  "sidewalk-lamp:right|traditional->turn-lane:inbound|straight": 13,
  "sidewalk-lamp:right|traditional->turn-lane:outbound|both": 1,
  "sidewalk-lamp:right|traditional->turn-lane:outbound|left": 12,
  "sidewalk-lamp:right|traditional->turn-lane:outbound|left-right-straight": 19,
  "sidewalk-lamp:right|traditional->turn-lane:outbound|left-straight": 15,
  "sidewalk-lamp:right|traditional->turn-lane:outbound|right-straight": 1,
  "sidewalk-lamp:right|traditional->turn-lane:outbound|straight": 11,
  "sidewalk-lamp:traditional->bus-lane:inbound|shared|typical": 1,
  "sidewalk-lamp:traditional|left->bike-lane:red|road|outbound": 1,
  "sidewalk-tree:big->bike-lane:inbound|colored": 396,
  "sidewalk-tree:big->bike-lane:inbound|green|road": 82,
  "sidewalk-tree:big->bike-lane:inbound|green|sidewalk": 50,
  "sidewalk-tree:big->bike-lane:inbound|red|road": 50,
  "sidewalk-tree:big->bike-lane:inbound|red|sidewalk": 21,
  "sidewalk-tree:big->bike-lane:inbound|regular": 646,
  "sidewalk-tree:big->bike-lane:inbound|regular|road": 385,
  "sidewalk-tree:big->bike-lane:inbound|regular|sidewalk": 36,
  "sidewalk-tree:big->bike-lane:outbound|colored": 621,
  "sidewalk-tree:big->bike-lane:outbound|green": 1,
  "sidewalk-tree:big->bike-lane:outbound|green|road": 170,
  "sidewalk-tree:big->bike-lane:outbound|green|road|inbound": 1,
  "sidewalk-tree:big->bike-lane:outbound|green|sidewalk": 41,
  "sidewalk-tree:big->bike-lane:outbound|red|road": 21,
  "sidewalk-tree:big->bike-lane:outbound|red|sidewalk": 22,
  "sidewalk-tree:big->bike-lane:outbound|regular": 162,
  "sidewalk-tree:big->bike-lane:outbound|regular|road": 110,
  "sidewalk-tree:big->bike-lane:outbound|regular|sidewalk": 20,
  "sidewalk-tree:big->bikeshare:left|road": 25,
  "sidewalk-tree:big->bikeshare:left|sidewalk": 10,
  "sidewalk-tree:big->bikeshare:right|road": 2,
  "sidewalk-tree:big->bikeshare:right|sidewalk": 1,
  "sidewalk-tree:big->bus-lane:inbound|colored": 23,
  "sidewalk-tree:big->bus-lane:inbound|colored|typical": 7,
  "sidewalk-tree:big->bus-lane:inbound|regular": 163,
  "sidewalk-tree:big->bus-lane:inbound|regular|typical": 76,
  "sidewalk-tree:big->bus-lane:inbound|shared|typical": 69,
  "sidewalk-tree:big->bus-lane:outbound|colored": 6,
  "sidewalk-tree:big->bus-lane:outbound|colored|typical": 2,
  "sidewalk-tree:big->bus-lane:outbound|regular": 35,
  "sidewalk-tree:big->bus-lane:outbound|regular|typical": 25,
  "sidewalk-tree:big->bus-lane:outbound|shared|typical": 5,
  "sidewalk-tree:big->divider:big-tree": 21,
  "sidewalk-tree:big->divider:bollard": 22,
  "sidewalk-tree:big->divider:bush": 242,
  "sidewalk-tree:big->divider:dome": 14,
  "sidewalk-tree:big->divider:flowers": 640,
  "sidewalk-tree:big->divider:median": 99,
  "sidewalk-tree:big->divider:palm-tree": 10,
  "sidewalk-tree:big->divider:planter-box": 41,
  "sidewalk-tree:big->divider:planting-strip": 133,
  "sidewalk-tree:big->divider:striped-buffer": 24,
  "sidewalk-tree:big->drive-lane:car": 2,
  "sidewalk-tree:big->drive-lane:inbound|car": 917,
  "sidewalk-tree:big->drive-lane:inbound|sharrow": 31,
  "sidewalk-tree:big->drive-lane:inbound|truck": 59,
  "sidewalk-tree:big->drive-lane:outbound|car": 475,
  "sidewalk-tree:big->drive-lane:outbound|sharrow": 23,
  "sidewalk-tree:big->drive-lane:outbound|truck": 124,
  "sidewalk-tree:big->drive-lane:right-straight|car": 1,
  "sidewalk-tree:big->drive-lane:truck|inbound": 1,
  "sidewalk-tree:big->flex-zone-curb:empty|right": 1,
  "sidewalk-tree:big->flex-zone-curb:sparse|left": 30,
  "sidewalk-tree:big->flex-zone-curb:sparse|right": 22,
  "sidewalk-tree:big->flex-zone:rideshare|inbound|left": 1,
  "sidewalk-tree:big->flex-zone:taxi|inbound|left": 6,
  "sidewalk-tree:big->flex-zone:taxi|inbound|right": 1,
  "sidewalk-tree:big->flex-zone:taxi|outbound|left": 1,
  "sidewalk-tree:big->food-truck:left": 23,
  "sidewalk-tree:big->light-rail:inbound|colored": 43,
  "sidewalk-tree:big->light-rail:inbound|grass": 6,
  "sidewalk-tree:big->light-rail:inbound|regular": 380,
  "sidewalk-tree:big->light-rail:outbound|colored": 13,
  "sidewalk-tree:big->light-rail:outbound|grass": 4,
  "sidewalk-tree:big->light-rail:outbound|regular": 63,
  "sidewalk-tree:big->outdoor-dining:occupied|sidewalk": 6,
  "sidewalk-tree:big->parking-lane:angled-front-left|left": 2,
  "sidewalk-tree:big->parking-lane:angled-front-left|right": 1,
  "sidewalk-tree:big->parking-lane:angled-front-right|left": 1,
  "sidewalk-tree:big->parking-lane:angled-front-right|right": 2,
  "sidewalk-tree:big->parking-lane:angled-rear-left|left": 2,
  "sidewalk-tree:big->parking-lane:angled-rear-left|right": 3,
  "sidewalk-tree:big->parking-lane:angled-rear-right|left": 1,
  "sidewalk-tree:big->parking-lane:angled-rear-right|right": 1,
  "sidewalk-tree:big->parking-lane:inbound|left": 480,
  "sidewalk-tree:big->parking-lane:inbound|right": 87,
  "sidewalk-tree:big->parking-lane:outbound|left": 73,
  "sidewalk-tree:big->parking-lane:outbound|right": 61,
  "sidewalk-tree:big->parking-lane:sideways|left": 16,
  "sidewalk-tree:big->parking-lane:sideways|right": 1,
  "sidewalk-tree:big->parklet:left": 170,
  "sidewalk-tree:big->parklet:right": 102,
  "sidewalk-tree:big->scooter-drop-zone:left|sidewalk|empty": 2,
  "sidewalk-tree:big->scooter-drop-zone:right|sidewalk|empty": 18,
  "sidewalk-tree:big->scooter-drop-zone:right|sidewalk|sparse": 1,
  "sidewalk-tree:big->scooter:inbound|green": 6,
  "sidewalk-tree:big->scooter:inbound|regular": 35,
  "sidewalk-tree:big->scooter:outbound|green": 1,
  "sidewalk-tree:big->scooter:outbound|red": 3,
  "sidewalk-tree:big->scooter:outbound|regular": 8,
  "sidewalk-tree:big->sidewalk-bench:center": 100,
  "sidewalk-tree:big->sidewalk-bench:left": 645,
  "sidewalk-tree:big->sidewalk-bench:right": 242,
  "sidewalk-tree:big->sidewalk-bike-rack:left|road": 3,
  "sidewalk-tree:big->sidewalk-bike-rack:left|sidewalk": 256,
  "sidewalk-tree:big->sidewalk-bike-rack:left|sidewalk-parallel": 243,
  "sidewalk-tree:big->sidewalk-bike-rack:right|road": 5,
  "sidewalk-tree:big->sidewalk-bike-rack:right|sidewalk": 47,
  "sidewalk-tree:big->sidewalk-bike-rack:right|sidewalk-parallel": 61,
  "sidewalk-tree:big->sidewalk-lamp:both|modern": 421,
  "sidewalk-tree:big->sidewalk-lamp:both|pride": 9,
  "sidewalk-tree:big->sidewalk-lamp:both|traditional": 634,
  "sidewalk-tree:big->sidewalk-lamp:left|modern": 289,
  "sidewalk-tree:big->sidewalk-lamp:left|pride": 7,
  "sidewalk-tree:big->sidewalk-lamp:left|traditional": 93,
  "sidewalk-tree:big->sidewalk-lamp:modern": 2,
  "sidewalk-tree:big->sidewalk-lamp:right|modern": 5552,
  "sidewalk-tree:big->sidewalk-lamp:right|pride": 38,
  "sidewalk-tree:big->sidewalk-lamp:right|traditional": 812,
  "sidewalk-tree:big->sidewalk-tree:big": 1264,
  "sidewalk-tree:big->sidewalk-tree:palm-tree": 35,
  "sidewalk-tree:big->sidewalk-wayfinding:large": 297,
  "sidewalk-tree:big->sidewalk-wayfinding:medium": 19045,
  "sidewalk-tree:big->sidewalk-wayfinding:small": 350,
  "sidewalk-tree:big->sidewalk:dense": 4711,
  "sidewalk-tree:big->sidewalk:empty": 85,
  "sidewalk-tree:big->sidewalk:normal": 17628,
  "sidewalk-tree:big->sidewalk:sparse": 325,
  "sidewalk-tree:big->streetcar:inbound|colored": 12,
  "sidewalk-tree:big->streetcar:inbound|grass": 9,
  "sidewalk-tree:big->streetcar:inbound|regular": 110,
  "sidewalk-tree:big->streetcar:outbound|colored": 5,
  "sidewalk-tree:big->streetcar:outbound|grass": 3,
  "sidewalk-tree:big->streetcar:outbound|regular": 53,
  "sidewalk-tree:big->transit-shelter:left": 1,
  "sidewalk-tree:big->transit-shelter:left|light-rail": 239,
  "sidewalk-tree:big->transit-shelter:left|street-level": 31801,
  "sidewalk-tree:big->transit-shelter:light-rail": 2,
  "sidewalk-tree:big->transit-shelter:right": 1,
  "sidewalk-tree:big->transit-shelter:right|light-rail": 37,
  "sidewalk-tree:big->transit-shelter:right|street-level": 259,
  "sidewalk-tree:big->transit-shelter:street-level|right": 1,
  "sidewalk-tree:big->turn-lane:inbound|both": 2,
  "sidewalk-tree:big->turn-lane:inbound|left": 48,
  "sidewalk-tree:big->turn-lane:inbound|left-right-straight": 3,
  "sidewalk-tree:big->turn-lane:inbound|left-straight": 46,
  "sidewalk-tree:big->turn-lane:inbound|right": 28,
  "sidewalk-tree:big->turn-lane:inbound|right-straight": 43,
  "sidewalk-tree:big->turn-lane:inbound|shared": 3,
  "sidewalk-tree:big->turn-lane:inbound|straight": 20,
  "sidewalk-tree:big->turn-lane:left-straight|left": 1,
  "sidewalk-tree:big->turn-lane:outbound|both": 4,
  "sidewalk-tree:big->turn-lane:outbound|left": 166,
  "sidewalk-tree:big->turn-lane:outbound|left-right-straight": 1,
  "sidewalk-tree:big->turn-lane:outbound|left-straight": 117,
  "sidewalk-tree:big->turn-lane:outbound|right": 12,
  "sidewalk-tree:big->turn-lane:outbound|right-straight": 5,
  "sidewalk-tree:big->turn-lane:outbound|shared": 1,
  "sidewalk-tree:big->turn-lane:outbound|straight": 78,
  "sidewalk-tree:big->utilities:left": 8,
  "sidewalk-tree:palm-tree->bike-lane:inbound|colored": 37,
  "sidewalk-tree:palm-tree->bike-lane:inbound|green|road": 2,
  "sidewalk-tree:palm-tree->bike-lane:inbound|green|sidewalk": 1,
  "sidewalk-tree:palm-tree->bike-lane:inbound|red|road": 3,
  "sidewalk-tree:palm-tree->bike-lane:inbound|regular": 37,
  "sidewalk-tree:palm-tree->bike-lane:inbound|regular|road": 8,
  "sidewalk-tree:palm-tree->bike-lane:inbound|regular|sidewalk": 2,
  "sidewalk-tree:palm-tree->bike-lane:outbound|colored": 31,
  "sidewalk-tree:palm-tree->bike-lane:outbound|green|road": 11,
  "sidewalk-tree:palm-tree->bike-lane:outbound|green|sidewalk": 1,
  "sidewalk-tree:palm-tree->bike-lane:outbound|red|road": 3,
  "sidewalk-tree:palm-tree->bike-lane:outbound|red|sidewalk": 1,
  "sidewalk-tree:palm-tree->bike-lane:outbound|regular": 4,
  "sidewalk-tree:palm-tree->bike-lane:outbound|regular|road": 6,
  "sidewalk-tree:palm-tree->bikeshare:left|road": 4,
  "sidewalk-tree:palm-tree->bikeshare:left|sidewalk": 2,
  "sidewalk-tree:palm-tree->bus-lane:inbound|regular": 3,
  "sidewalk-tree:palm-tree->bus-lane:inbound|regular|typical": 1,
  "sidewalk-tree:palm-tree->bus-lane:inbound|shared|typical": 5,
  "sidewalk-tree:palm-tree->bus-lane:outbound|colored": 1,
  "sidewalk-tree:palm-tree->bus-lane:outbound|colored|typical": 1,
  "sidewalk-tree:palm-tree->bus-lane:outbound|regular": 2,
  "sidewalk-tree:palm-tree->bus-lane:outbound|regular|typical": 1,
  "sidewalk-tree:palm-tree->divider:big-tree": 1,
  "sidewalk-tree:palm-tree->divider:bollard": 3,
  "sidewalk-tree:palm-tree->divider:bush": 16,
  "sidewalk-tree:palm-tree->divider:dome": 3,
  "sidewalk-tree:palm-tree->divider:flowers": 38,
  "sidewalk-tree:palm-tree->divider:median": 5,
  "sidewalk-tree:palm-tree->divider:palm-tree": 6,
  "sidewalk-tree:palm-tree->divider:planter-box": 6,
  "sidewalk-tree:palm-tree->divider:planting-strip": 6,
  "sidewalk-tree:palm-tree->divider:striped-buffer": 4,
  "sidewalk-tree:palm-tree->drive-lane:inbound|car": 31,
  "sidewalk-tree:palm-tree->drive-lane:inbound|sharrow": 3,
  "sidewalk-tree:palm-tree->drive-lane:inbound|truck": 4,
  "sidewalk-tree:palm-tree->drive-lane:outbound|car": 30,
  "sidewalk-tree:palm-tree->drive-lane:outbound|sharrow": 1,
  "sidewalk-tree:palm-tree->drive-lane:outbound|truck": 8,
  "sidewalk-tree:palm-tree->flex-zone-curb:empty|left": 1,
  "sidewalk-tree:palm-tree->flex-zone-curb:sparse|left": 2,
  "sidewalk-tree:palm-tree->flex-zone-curb:sparse|right": 3,
  "sidewalk-tree:palm-tree->flex-zone:rideshare|outbound|left": 1,
  "sidewalk-tree:palm-tree->flex-zone:taxi|inbound|left": 3,
  "sidewalk-tree:palm-tree->food-truck:left": 3,
  "sidewalk-tree:palm-tree->light-rail:inbound|colored": 4,
  "sidewalk-tree:palm-tree->light-rail:inbound|regular": 16,
  "sidewalk-tree:palm-tree->light-rail:outbound|colored": 7,
  "sidewalk-tree:palm-tree->light-rail:outbound|regular": 10,
  "sidewalk-tree:palm-tree->parking-lane:inbound|left": 19,
  "sidewalk-tree:palm-tree->parking-lane:inbound|right": 5,
  "sidewalk-tree:palm-tree->parking-lane:outbound|left": 7,
  "sidewalk-tree:palm-tree->parking-lane:outbound|right": 5,
  "sidewalk-tree:palm-tree->parklet:left": 19,
  "sidewalk-tree:palm-tree->parklet:right": 8,
  "sidewalk-tree:palm-tree->scooter-drop-zone:right|sidewalk|empty": 1,
  "sidewalk-tree:palm-tree->scooter:inbound|regular": 6,
  "sidewalk-tree:palm-tree->scooter:outbound|green": 1,
  "sidewalk-tree:palm-tree->scooter:outbound|regular": 1,
  "sidewalk-tree:palm-tree->sidewalk-bench:center": 10,
  "sidewalk-tree:palm-tree->sidewalk-bench:left": 62,
  "sidewalk-tree:palm-tree->sidewalk-bench:right": 17,
  "sidewalk-tree:palm-tree->sidewalk-bike-rack:left|sidewalk": 20,
  "sidewalk-tree:palm-tree->sidewalk-bike-rack:left|sidewalk-parallel": 32,
  "sidewalk-tree:palm-tree->sidewalk-bike-rack:right|sidewalk": 7,
  "sidewalk-tree:palm-tree->sidewalk-bike-rack:right|sidewalk-parallel": 2,
  "sidewalk-tree:palm-tree->sidewalk-lamp:both|modern": 26,
  "sidewalk-tree:palm-tree->sidewalk-lamp:both|pride": 2,
  "sidewalk-tree:palm-tree->sidewalk-lamp:both|traditional": 57,
  "sidewalk-tree:palm-tree->sidewalk-lamp:left|modern": 15,
  "sidewalk-tree:palm-tree->sidewalk-lamp:left|pride": 2,
  "sidewalk-tree:palm-tree->sidewalk-lamp:left|traditional": 10,
  "sidewalk-tree:palm-tree->sidewalk-lamp:right|modern": 155,
  "sidewalk-tree:palm-tree->sidewalk-lamp:right|pride": 6,
  "sidewalk-tree:palm-tree->sidewalk-lamp:right|traditional": 90,
  "sidewalk-tree:palm-tree->sidewalk-tree:big": 38,
  "sidewalk-tree:palm-tree->sidewalk-tree:palm-tree": 100,
  "sidewalk-tree:palm-tree->sidewalk-wayfinding:large": 41,
  "sidewalk-tree:palm-tree->sidewalk-wayfinding:medium": 210,
  "sidewalk-tree:palm-tree->sidewalk-wayfinding:small": 59,
  "sidewalk-tree:palm-tree->sidewalk:dense": 209,
  "sidewalk-tree:palm-tree->sidewalk:empty": 5,
  "sidewalk-tree:palm-tree->sidewalk:normal": 208,
  "sidewalk-tree:palm-tree->sidewalk:sparse": 26,
  "sidewalk-tree:palm-tree->streetcar:inbound|colored": 5,
  "sidewalk-tree:palm-tree->streetcar:inbound|regular": 11,
  "sidewalk-tree:palm-tree->streetcar:outbound|grass": 2,
  "sidewalk-tree:palm-tree->streetcar:outbound|regular": 4,
  "sidewalk-tree:palm-tree->transit-shelter:left|light-rail": 52,
  "sidewalk-tree:palm-tree->transit-shelter:left|street-level": 351,
  "sidewalk-tree:palm-tree->transit-shelter:right|light-rail": 9,
  "sidewalk-tree:palm-tree->transit-shelter:right|street-level": 23,
  "sidewalk-tree:palm-tree->turn-lane:inbound|left-straight": 3,
  "sidewalk-tree:palm-tree->turn-lane:inbound|right": 1,
  "sidewalk-tree:palm-tree->turn-lane:inbound|right-straight": 2,
  "sidewalk-tree:palm-tree->turn-lane:inbound|straight": 4,
  "sidewalk-tree:palm-tree->turn-lane:outbound|left": 3,
  "sidewalk-tree:palm-tree->turn-lane:outbound|left-straight": 6,
  "sidewalk-tree:palm-tree->turn-lane:outbound|straight": 3,
  "sidewalk-tree:palm-tree->utilities:left": 1,
  "sidewalk-wayfinding:large->bike-lane:inbound|colored": 10,
  "sidewalk-wayfinding:large->bike-lane:inbound|regular": 41,
  "sidewalk-wayfinding:large->bike-lane:inbound|regular|road": 2,
  "sidewalk-wayfinding:large->bike-lane:outbound|colored": 12,
  "sidewalk-wayfinding:large->bike-lane:outbound|green|road": 2,
  "sidewalk-wayfinding:large->bike-lane:outbound|regular": 10,
  "sidewalk-wayfinding:large->bike-lane:outbound|regular|road": 3,
  "sidewalk-wayfinding:large->bikeshare:left|road": 2,
  "sidewalk-wayfinding:large->bus-lane:inbound|colored": 1,
  "sidewalk-wayfinding:large->bus-lane:inbound|regular": 14,
  "sidewalk-wayfinding:large->bus-lane:inbound|regular|typical": 1,
  "sidewalk-wayfinding:large->bus-lane:inbound|shared|typical": 8,
  "sidewalk-wayfinding:large->bus-lane:outbound|regular": 3,
  "sidewalk-wayfinding:large->divider:big-tree": 6,
  "sidewalk-wayfinding:large->divider:bollard": 3,
  "sidewalk-wayfinding:large->divider:bush": 7,
  "sidewalk-wayfinding:large->divider:flowers": 25,
  "sidewalk-wayfinding:large->divider:median": 16,
  "sidewalk-wayfinding:large->divider:palm-tree": 3,
  "sidewalk-wayfinding:large->divider:planter-box": 1,
  "sidewalk-wayfinding:large->divider:planting-strip": 1,
  "sidewalk-wayfinding:large->divider:striped-buffer": 1,
  "sidewalk-wayfinding:large->drive-lane:inbound|car": 28,
  "sidewalk-wayfinding:large->drive-lane:inbound|sharrow": 2,
  "sidewalk-wayfinding:large->drive-lane:outbound|car": 8,
  "sidewalk-wayfinding:large->drive-lane:outbound|truck": 4,
  "sidewalk-wayfinding:large->flex-zone:taxi|inbound|left": 2,
  "sidewalk-wayfinding:large->food-truck:left": 2,
  "sidewalk-wayfinding:large->light-rail:inbound|colored": 3,
  "sidewalk-wayfinding:large->light-rail:inbound|regular": 9,
  "sidewalk-wayfinding:large->light-rail:outbound|regular": 5,
  "sidewalk-wayfinding:large->parking-lane:inbound|left": 3,
  "sidewalk-wayfinding:large->parking-lane:inbound|right": 5,
  "sidewalk-wayfinding:large->parking-lane:outbound|right": 2,
  "sidewalk-wayfinding:large->parklet:left": 29,
  "sidewalk-wayfinding:large->parklet:right": 10,
  "sidewalk-wayfinding:large->scooter-drop-zone:right|sidewalk|empty": 1,
  "sidewalk-wayfinding:large->scooter:inbound|regular": 1,
  "sidewalk-wayfinding:large->sidewalk-bench:center": 10,
  "sidewalk-wayfinding:large->sidewalk-bench:left": 68,
  "sidewalk-wayfinding:large->sidewalk-bench:right": 16,
  "sidewalk-wayfinding:large->sidewalk-bike-rack:left|sidewalk": 22,
  "sidewalk-wayfinding:large->sidewalk-bike-rack:left|sidewalk-parallel": 11,
  "sidewalk-wayfinding:large->sidewalk-bike-rack:right|sidewalk": 6,
  "sidewalk-wayfinding:large->sidewalk-bike-rack:right|sidewalk-parallel": 11,
  "sidewalk-wayfinding:large->sidewalk-lamp:both|modern": 43,
  "sidewalk-wayfinding:large->sidewalk-lamp:both|traditional": 30,
  "sidewalk-wayfinding:large->sidewalk-lamp:left|modern": 9,
  "sidewalk-wayfinding:large->sidewalk-lamp:left|traditional": 8,
  "sidewalk-wayfinding:large->sidewalk-lamp:right|modern": 67,
  "sidewalk-wayfinding:large->sidewalk-lamp:right|traditional": 29,
  "sidewalk-wayfinding:large->sidewalk-tree:big": 165,
  "sidewalk-wayfinding:large->sidewalk-tree:palm-tree": 18,
  "sidewalk-wayfinding:large->sidewalk-wayfinding:large": 24,
  "sidewalk-wayfinding:large->sidewalk-wayfinding:medium": 2,
  "sidewalk-wayfinding:large->sidewalk-wayfinding:small": 2,
  "sidewalk-wayfinding:large->sidewalk:dense": 202,
  "sidewalk-wayfinding:large->sidewalk:empty": 5,
  "sidewalk-wayfinding:large->sidewalk:normal": 142,
  "sidewalk-wayfinding:large->sidewalk:sparse": 14,
  "sidewalk-wayfinding:large->streetcar:inbound|grass": 1,
  "sidewalk-wayfinding:large->streetcar:inbound|regular": 10,
  "sidewalk-wayfinding:large->streetcar:outbound|regular": 4,
  "sidewalk-wayfinding:large->transit-shelter:left|light-rail": 7,
  "sidewalk-wayfinding:large->transit-shelter:left|street-level": 45,
  "sidewalk-wayfinding:large->transit-shelter:right|light-rail": 3,
  "sidewalk-wayfinding:large->transit-shelter:right|street-level": 23,
  "sidewalk-wayfinding:large->turn-lane:inbound|both": 1,
  "sidewalk-wayfinding:large->turn-lane:inbound|left": 3,
  "sidewalk-wayfinding:large->turn-lane:inbound|right": 1,
  "sidewalk-wayfinding:large->turn-lane:inbound|right-straight": 4,
  "sidewalk-wayfinding:large->turn-lane:inbound|shared": 1,
  "sidewalk-wayfinding:large->turn-lane:outbound|left": 5,
  "sidewalk-wayfinding:large->turn-lane:outbound|left-straight": 4,
  "sidewalk-wayfinding:large->turn-lane:outbound|right": 1,
  "sidewalk-wayfinding:large->turn-lane:outbound|straight": 1,
  "sidewalk-wayfinding:medium->bike-lane:inbound|colored": 11,
  "sidewalk-wayfinding:medium->bike-lane:inbound|regular": 8,
  "sidewalk-wayfinding:medium->bike-lane:outbound|colored": 86,
  "sidewalk-wayfinding:medium->bike-lane:outbound|regular": 11,
  "sidewalk-wayfinding:medium->bike-lane:outbound|regular|road": 2,
  "sidewalk-wayfinding:medium->bus-lane:inbound|colored": 1,
  "sidewalk-wayfinding:medium->bus-lane:inbound|regular": 7,
  "sidewalk-wayfinding:medium->bus-lane:inbound|shared|typical": 2,
  "sidewalk-wayfinding:medium->bus-lane:outbound|regular": 4,
  "sidewalk-wayfinding:medium->divider:big-tree": 3,
  "sidewalk-wayfinding:medium->divider:flowers": 24,
  "sidewalk-wayfinding:medium->divider:median": 5,
  "sidewalk-wayfinding:medium->divider:palm-tree": 1,
  "sidewalk-wayfinding:medium->drive-lane:inbound|car": 6,
  "sidewalk-wayfinding:medium->drive-lane:inbound|truck": 1,
  "sidewalk-wayfinding:medium->drive-lane:outbound|car": 3,
  "sidewalk-wayfinding:medium->drive-lane:outbound|truck": 18,
  "sidewalk-wayfinding:medium->light-rail:inbound|colored": 2,
  "sidewalk-wayfinding:medium->light-rail:inbound|regular": 10,
  "sidewalk-wayfinding:medium->light-rail:outbound|regular": 3,
  "sidewalk-wayfinding:medium->parking-lane:inbound|left": 1,
  "sidewalk-wayfinding:medium->parking-lane:outbound|left": 4,
  "sidewalk-wayfinding:medium->parking-lane:outbound|right": 14,
  "sidewalk-wayfinding:medium->parklet:left": 4,
  "sidewalk-wayfinding:medium->parklet:right": 8,
  "sidewalk-wayfinding:medium->scooter-drop-zone:right|sidewalk|empty": 1,
  "sidewalk-wayfinding:medium->sidewalk-bench:center": 11,
  "sidewalk-wayfinding:medium->sidewalk-bench:left": 18,
  "sidewalk-wayfinding:medium->sidewalk-bench:right": 282,
  "sidewalk-wayfinding:medium->sidewalk-bike-rack:left|sidewalk": 20,
  "sidewalk-wayfinding:medium->sidewalk-bike-rack:left|sidewalk-parallel": 37,
  "sidewalk-wayfinding:medium->sidewalk-bike-rack:right|road": 3,
  "sidewalk-wayfinding:medium->sidewalk-bike-rack:right|sidewalk": 1,
  "sidewalk-wayfinding:medium->sidewalk-bike-rack:right|sidewalk-parallel": 10,
  "sidewalk-wayfinding:medium->sidewalk-lamp:both|modern": 34,
  "sidewalk-wayfinding:medium->sidewalk-lamp:both|traditional": 16,
  "sidewalk-wayfinding:medium->sidewalk-lamp:left|modern": 47,
  "sidewalk-wayfinding:medium->sidewalk-lamp:left|traditional": 8,
  "sidewalk-wayfinding:medium->sidewalk-lamp:right|modern": 29,
  "sidewalk-wayfinding:medium->sidewalk-lamp:right|traditional": 17,
  "sidewalk-wayfinding:medium->sidewalk-tree:big": 572,
  "sidewalk-wayfinding:medium->sidewalk-tree:palm-tree": 20,
  "sidewalk-wayfinding:medium->sidewalk-wayfinding:large": 3,
  "sidewalk-wayfinding:medium->sidewalk:dense": 364,
  "sidewalk-wayfinding:medium->sidewalk:empty": 16,
  "sidewalk-wayfinding:medium->sidewalk:normal": 18646,
  "sidewalk-wayfinding:medium->sidewalk:sparse": 76,
  "sidewalk-wayfinding:medium->streetcar:inbound|regular": 4,
  "sidewalk-wayfinding:medium->streetcar:outbound|regular": 7,
  "sidewalk-wayfinding:medium->transit-shelter:left|light-rail": 2,
  "sidewalk-wayfinding:medium->transit-shelter:left|street-level": 27,
  "sidewalk-wayfinding:medium->transit-shelter:right|light-rail": 1,
  "sidewalk-wayfinding:medium->transit-shelter:right|street-level": 13,
  "sidewalk-wayfinding:medium->turn-lane:inbound|both": 1,
  "sidewalk-wayfinding:medium->turn-lane:inbound|right": 2,
  "sidewalk-wayfinding:medium->turn-lane:inbound|right-straight": 2,
  "sidewalk-wayfinding:medium->turn-lane:outbound|left": 2,
  "sidewalk-wayfinding:medium->turn-lane:outbound|right": 6,
  "sidewalk-wayfinding:small->bike-lane:inbound|colored": 6,
  "sidewalk-wayfinding:small->bike-lane:inbound|green|sidewalk": 1,
  "sidewalk-wayfinding:small->bike-lane:inbound|regular": 7,
  "sidewalk-wayfinding:small->bike-lane:inbound|regular|road": 2,
  "sidewalk-wayfinding:small->bike-lane:outbound|colored": 31,
  "sidewalk-wayfinding:small->bike-lane:outbound|regular": 1,
  "sidewalk-wayfinding:small->bike-lane:outbound|regular|road": 1,
  "sidewalk-wayfinding:small->bus-lane:inbound|colored": 1,
  "sidewalk-wayfinding:small->bus-lane:inbound|regular": 6,
  "sidewalk-wayfinding:small->bus-lane:inbound|regular|typical": 2,
  "sidewalk-wayfinding:small->bus-lane:outbound|regular": 1,
  "sidewalk-wayfinding:small->divider:big-tree": 2,
  "sidewalk-wayfinding:small->divider:bollard": 3,
  "sidewalk-wayfinding:small->divider:bush": 3,
  "sidewalk-wayfinding:small->divider:flowers": 4,
  "sidewalk-wayfinding:small->divider:median": 2,
  "sidewalk-wayfinding:small->divider:palm-tree": 3,
  "sidewalk-wayfinding:small->divider:planting-strip": 1,
  "sidewalk-wayfinding:small->drive-lane:inbound|car": 4,
  "sidewalk-wayfinding:small->drive-lane:outbound|car": 4,
  "sidewalk-wayfinding:small->drive-lane:outbound|truck": 1,
  "sidewalk-wayfinding:small->flex-zone-curb:sparse|right": 1,
  "sidewalk-wayfinding:small->flex-zone:taxi|inbound|left": 1,
  "sidewalk-wayfinding:small->light-rail:inbound|colored": 1,
  "sidewalk-wayfinding:small->light-rail:inbound|grass": 2,
  "sidewalk-wayfinding:small->light-rail:inbound|regular": 6,
  "sidewalk-wayfinding:small->light-rail:outbound|colored": 1,
  "sidewalk-wayfinding:small->light-rail:outbound|regular": 2,
  "sidewalk-wayfinding:small->parking-lane:inbound|left": 6,
  "sidewalk-wayfinding:small->parking-lane:outbound|right": 1,
  "sidewalk-wayfinding:small->parklet:left": 5,
  "sidewalk-wayfinding:small->parklet:right": 1,
  "sidewalk-wayfinding:small->scooter-drop-zone:right|sidewalk|empty": 1,
  "sidewalk-wayfinding:small->sidewalk-bench:center": 10,
  "sidewalk-wayfinding:small->sidewalk-bench:left": 27,
  "sidewalk-wayfinding:small->sidewalk-bench:right": 14,
  "sidewalk-wayfinding:small->sidewalk-bike-rack:left|road": 1,
  "sidewalk-wayfinding:small->sidewalk-bike-rack:left|sidewalk": 2,
  "sidewalk-wayfinding:small->sidewalk-bike-rack:left|sidewalk-parallel": 5,
  "sidewalk-wayfinding:small->sidewalk-bike-rack:right|sidewalk": 7,
  "sidewalk-wayfinding:small->sidewalk-bike-rack:right|sidewalk-parallel": 9,
  "sidewalk-wayfinding:small->sidewalk-lamp:both|modern": 19,
  "sidewalk-wayfinding:small->sidewalk-lamp:both|traditional": 38,
  "sidewalk-wayfinding:small->sidewalk-lamp:left|modern": 5,
  "sidewalk-wayfinding:small->sidewalk-lamp:left|pride": 1,
  "sidewalk-wayfinding:small->sidewalk-lamp:left|traditional": 8,
  "sidewalk-wayfinding:small->sidewalk-lamp:right|modern": 15,
  "sidewalk-wayfinding:small->sidewalk-lamp:right|traditional": 8,
  "sidewalk-wayfinding:small->sidewalk-tree:big": 95,
  "sidewalk-wayfinding:small->sidewalk-tree:palm-tree": 10,
  "sidewalk-wayfinding:small->sidewalk-wayfinding:small": 1,
  "sidewalk-wayfinding:small->sidewalk:dense": 146,
  "sidewalk-wayfinding:small->sidewalk:empty": 6,
  "sidewalk-wayfinding:small->sidewalk:normal": 261,
  "sidewalk-wayfinding:small->sidewalk:sparse": 23,
  "sidewalk-wayfinding:small->streetcar:inbound|grass": 1,
  "sidewalk-wayfinding:small->streetcar:inbound|regular": 3,
  "sidewalk-wayfinding:small->streetcar:outbound|colored": 1,
  "sidewalk-wayfinding:small->streetcar:outbound|regular": 2,
  "sidewalk-wayfinding:small->transit-shelter:left|light-rail": 11,
  "sidewalk-wayfinding:small->transit-shelter:left|street-level": 22,
  "sidewalk-wayfinding:small->transit-shelter:right|light-rail": 2,
  "sidewalk-wayfinding:small->transit-shelter:right|street-level": 13,
  "sidewalk-wayfinding:small->turn-lane:inbound|left": 1,
  "sidewalk:dense->bike-lane:inbound|colored": 180,
  "sidewalk:dense->bike-lane:inbound|green|road": 116,
  "sidewalk:dense->bike-lane:inbound|green|sidewalk": 51,
  "sidewalk:dense->bike-lane:inbound|red|road": 34,
  "sidewalk:dense->bike-lane:inbound|red|sidewalk": 43,
  "sidewalk:dense->bike-lane:inbound|regular": 282,
  "sidewalk:dense->bike-lane:inbound|regular|road": 394,
  "sidewalk:dense->bike-lane:inbound|regular|sidewalk": 54,
  "sidewalk:dense->bike-lane:outbound|colored": 64,
  "sidewalk:dense->bike-lane:outbound|green|road": 49,
  "sidewalk:dense->bike-lane:outbound|green|sidewalk": 7,
  "sidewalk:dense->bike-lane:outbound|red|road": 8,
  "sidewalk:dense->bike-lane:outbound|red|sidewalk": 3,
  "sidewalk:dense->bike-lane:outbound|regular": 30,
  "sidewalk:dense->bike-lane:outbound|regular|road": 27,
  "sidewalk:dense->bike-lane:outbound|regular|sidewalk": 6,
  "sidewalk:dense->bike-lane:outbound|regular|sidewalk|inbound": 1,
  "sidewalk:dense->bike-lane:regular|road": 1,
  "sidewalk:dense->bikeshare:left|road": 21,
  "sidewalk:dense->bikeshare:left|sidewalk": 3,
  "sidewalk:dense->bikeshare:right|road": 2,
  "sidewalk:dense->bus-lane:inbound|colored": 10,
  "sidewalk:dense->bus-lane:inbound|colored|typical": 8,
  "sidewalk:dense->bus-lane:inbound|regular": 104,
  "sidewalk:dense->bus-lane:inbound|regular|typical": 53,
  "sidewalk:dense->bus-lane:inbound|shared|typical": 58,
  "sidewalk:dense->bus-lane:outbound|colored": 5,
  "sidewalk:dense->bus-lane:outbound|colored|typical": 1,
  "sidewalk:dense->bus-lane:outbound|regular": 9,
  "sidewalk:dense->bus-lane:outbound|regular|typical": 5,
  "sidewalk:dense->bus-lane:outbound|shared|typical": 2,
  "sidewalk:dense->divider:big-tree": 331,
  "sidewalk:dense->divider:bollard": 30,
  "sidewalk:dense->divider:bush": 193,
  "sidewalk:dense->divider:dome": 12,
  "sidewalk:dense->divider:flowers": 498,
  "sidewalk:dense->divider:median": 81,
  "sidewalk:dense->divider:palm-tree": 35,
  "sidewalk:dense->divider:planter-box": 35,
  "sidewalk:dense->divider:planting-strip": 167,
  "sidewalk:dense->divider:striped-buffer": 32,
  "sidewalk:dense->drive-lane:inbound|car": 565,
  "sidewalk:dense->drive-lane:inbound|sharrow": 30,
  "sidewalk:dense->drive-lane:inbound|truck": 22,
  "sidewalk:dense->drive-lane:outbound": 1,
  "sidewalk:dense->drive-lane:outbound|car": 119,
  "sidewalk:dense->drive-lane:outbound|sharrow": 3,
  "sidewalk:dense->drive-lane:outbound|truck": 26,
  "sidewalk:dense->flex-zone-curb:empty|right": 1,
  "sidewalk:dense->flex-zone-curb:sparse|left": 16,
  "sidewalk:dense->flex-zone-curb:sparse|right": 25,
  "sidewalk:dense->flex-zone:rideshare|inbound|left": 2,
  "sidewalk:dense->flex-zone:rideshare|outbound|left": 1,
  "sidewalk:dense->flex-zone:taxi|inbound|left": 18,
  "sidewalk:dense->flex-zone:taxi|outbound|left": 3,
  "sidewalk:dense->food-truck:left": 19,
  "sidewalk:dense->light-rail:inbound|colored": 5,
  "sidewalk:dense->light-rail:inbound|grass": 2,
  "sidewalk:dense->light-rail:inbound|regular": 52,
  "sidewalk:dense->light-rail:outbound|colored": 2,
  "sidewalk:dense->light-rail:outbound|regular": 21,
  "sidewalk:dense->outdoor-dining:occupied|sidewalk": 1,
  "sidewalk:dense->parking-lane:angled-front-left|left": 6,
  "sidewalk:dense->parking-lane:angled-front-left|right": 2,
  "sidewalk:dense->parking-lane:angled-front-right|left": 1,
  "sidewalk:dense->parking-lane:angled-rear-left|left": 2,
  "sidewalk:dense->parking-lane:angled-rear-right|left": 1,
  "sidewalk:dense->parking-lane:inbound|left": 271,
  "sidewalk:dense->parking-lane:inbound|right": 57,
  "sidewalk:dense->parking-lane:outbound|left": 35,
  "sidewalk:dense->parking-lane:outbound|right": 39,
  "sidewalk:dense->parking-lane:sideways|left": 10,
  "sidewalk:dense->parking-lane:sideways|right": 1,
  "sidewalk:dense->parklet:left": 227,
  "sidewalk:dense->parklet:right": 54,
  "sidewalk:dense->scooter-drop-zone:left|sidewalk|empty": 1,
  "sidewalk:dense->scooter-drop-zone:right|sidewalk|empty": 10,
  "sidewalk:dense->scooter:inbound|green": 1,
  "sidewalk:dense->scooter:inbound|regular": 27,
  "sidewalk:dense->scooter:outbound|green": 2,
  "sidewalk:dense->scooter:outbound|red": 1,
  "sidewalk:dense->scooter:outbound|regular": 3,
  "sidewalk:dense->sidewalk-bench:center": 148,
  "sidewalk:dense->sidewalk-bench:left": 253,
  "sidewalk:dense->sidewalk-bench:right": 801,
  "sidewalk:dense->sidewalk-bike-rack:left|road": 5,
  "sidewalk:dense->sidewalk-bike-rack:left|sidewalk": 173,
  "sidewalk:dense->sidewalk-bike-rack:left|sidewalk-parallel": 64,
  "sidewalk:dense->sidewalk-bike-rack:right|road": 2,
  "sidewalk:dense->sidewalk-bike-rack:right|sidewalk": 72,
  "sidewalk:dense->sidewalk-bike-rack:right|sidewalk-parallel": 159,
  "sidewalk:dense->sidewalk-lamp:both|modern": 202,
  "sidewalk:dense->sidewalk-lamp:both|pride": 7,
  "sidewalk:dense->sidewalk-lamp:both|traditional": 498,
  "sidewalk:dense->sidewalk-lamp:left|modern": 162,
  "sidewalk:dense->sidewalk-lamp:left|traditional": 301,
  "sidewalk:dense->sidewalk-lamp:pride|left": 1,
  "sidewalk:dense->sidewalk-lamp:right|modern": 1341,
  "sidewalk:dense->sidewalk-lamp:right|pride": 20,
  "sidewalk:dense->sidewalk-lamp:right|traditional": 287,
  "sidewalk:dense->sidewalk-tree:big": 37772,
  "sidewalk:dense->sidewalk-tree:palm-tree": 656,
  "sidewalk:dense->sidewalk-wayfinding:large": 238,
  "sidewalk:dense->sidewalk-wayfinding:medium": 90,
  "sidewalk:dense->sidewalk-wayfinding:small": 90,
  "sidewalk:dense->sidewalk:dense": 532,
  "sidewalk:dense->sidewalk:empty": 13,
  "sidewalk:dense->sidewalk:normal": 89,
  "sidewalk:dense->sidewalk:sparse": 10,
  "sidewalk:dense->streetcar:inbound|colored": 5,
  "sidewalk:dense->streetcar:inbound|grass": 4,
  "sidewalk:dense->streetcar:inbound|regular": 49,
  "sidewalk:dense->streetcar:outbound|colored": 2,
  "sidewalk:dense->streetcar:outbound|regular": 14,
  "sidewalk:dense->transit-shelter:left|light-rail": 85,
  "sidewalk:dense->transit-shelter:left|street-level": 790,
  "sidewalk:dense->transit-shelter:right|light-rail": 4,
  "sidewalk:dense->transit-shelter:right|street-level": 78,
  "sidewalk:dense->turn-lane:inbound|both": 1,
  "sidewalk:dense->turn-lane:inbound|left": 24,
  "sidewalk:dense->turn-lane:inbound|left-right-straight": 2,
  "sidewalk:dense->turn-lane:inbound|left-straight": 11,
  "sidewalk:dense->turn-lane:inbound|right": 14,
  "sidewalk:dense->turn-lane:inbound|right-straight": 11,
  "sidewalk:dense->turn-lane:inbound|straight": 13,
  "sidewalk:dense->turn-lane:outbound|left": 14,
  "sidewalk:dense->turn-lane:outbound|left-right-straight": 1,
  "sidewalk:dense->turn-lane:outbound|left-straight": 28,
  "sidewalk:dense->turn-lane:outbound|right": 5,
  "sidewalk:dense->turn-lane:outbound|right-straight": 2,
  "sidewalk:dense->turn-lane:outbound|straight": 8,
  "sidewalk:dense->utilities:left": 2,
  "sidewalk:empty->bike-lane:inbound|colored": 1,
  "sidewalk:empty->bike-lane:inbound|green|road": 1,
  "sidewalk:empty->bike-lane:inbound|red|road": 2,
  "sidewalk:empty->bike-lane:inbound|regular": 2,
  "sidewalk:empty->bike-lane:inbound|regular|road": 10,
  "sidewalk:empty->bike-lane:outbound|green|road": 1,
  "sidewalk:empty->bike-lane:outbound|green|sidewalk": 1,
  "sidewalk:empty->bike-lane:outbound|regular|road": 1,
  "sidewalk:empty->bikeshare:left|sidewalk": 1,
  "sidewalk:empty->bus-lane:inbound|regular|typical": 3,
  "sidewalk:empty->bus-lane:inbound|shared|typical": 1,
  "sidewalk:empty->bus-lane:outbound|regular": 1,
  "sidewalk:empty->bus-lane:outbound|regular|typical": 2,
  "sidewalk:empty->divider:big-tree": 2,
  "sidewalk:empty->divider:bollard": 4,
  "sidewalk:empty->divider:bush": 2,
  "sidewalk:empty->divider:flowers": 4,
  "sidewalk:empty->divider:median": 9,
  "sidewalk:empty->divider:planting-strip": 10,
  "sidewalk:empty->drive-lane:inbound|car": 15,
  "sidewalk:empty->drive-lane:inbound|truck": 1,
  "sidewalk:empty->drive-lane:outbound|car": 10,
  "sidewalk:empty->drive-lane:outbound|sharrow": 7,
  "sidewalk:empty->drive-lane:outbound|truck": 1,
  "sidewalk:empty->flex-zone-curb:sparse|left": 1,
  "sidewalk:empty->flex-zone-curb:sparse|right": 1,
  "sidewalk:empty->flex-zone:taxi|outbound|left": 1,
  "sidewalk:empty->food-truck:left": 1,
  "sidewalk:empty->light-rail:outbound|regular": 2,
  "sidewalk:empty->parking-lane:inbound|left": 3,
  "sidewalk:empty->parking-lane:inbound|right": 2,
  "sidewalk:empty->parking-lane:outbound|left": 1,
  "sidewalk:empty->parking-lane:outbound|right": 1,
  "sidewalk:empty->parklet:left": 4,
  "sidewalk:empty->parklet:right": 1,
  "sidewalk:empty->sidewalk-bench:center": 9,
  "sidewalk:empty->sidewalk-bench:left": 2,
  "sidewalk:empty->sidewalk-bench:right": 13,
  "sidewalk:empty->sidewalk-bike-rack:left|sidewalk": 2,
  "sidewalk:empty->sidewalk-bike-rack:right|sidewalk": 1,
  "sidewalk:empty->sidewalk-lamp:both|modern": 7,
  "sidewalk:empty->sidewalk-lamp:both|pride": 18,
  "sidewalk:empty->sidewalk-lamp:both|traditional": 2,
  "sidewalk:empty->sidewalk-lamp:left|modern": 9,
  "sidewalk:empty->sidewalk-lamp:left|pride": 1,
  "sidewalk:empty->sidewalk-lamp:left|traditional": 6,
  "sidewalk:empty->sidewalk-lamp:right|modern": 19,
  "sidewalk:empty->sidewalk-lamp:right|traditional": 3,
  "sidewalk:empty->sidewalk-tree:big": 118,
  "sidewalk:empty->sidewalk-tree:palm-tree": 14,
  "sidewalk:empty->sidewalk-wayfinding:large": 1,
  "sidewalk:empty->sidewalk-wayfinding:medium": 1,
  "sidewalk:empty->sidewalk:dense": 9,
  "sidewalk:empty->sidewalk:empty": 2,
  "sidewalk:empty->sidewalk:normal": 1,
  "sidewalk:empty->sidewalk:sparse": 1,
  "sidewalk:empty->streetcar:inbound|regular": 1,
  "sidewalk:empty->transit-shelter:left|light-rail": 1,
  "sidewalk:empty->transit-shelter:left|street-level": 7,
  "sidewalk:empty->transit-shelter:right|street-level": 2,
  "sidewalk:empty->turn-lane:inbound|left": 1,
  "sidewalk:empty->turn-lane:outbound|left-straight": 1,
  "sidewalk:empty->turn-lane:outbound|straight": 2,
  "sidewalk:normal->bike-lane:inbound|colored": 19,
  "sidewalk:normal->bike-lane:inbound|green|road": 2,
  "sidewalk:normal->bike-lane:inbound|green|sidewalk": 3,
  "sidewalk:normal->bike-lane:inbound|red|road": 2,
  "sidewalk:normal->bike-lane:inbound|red|sidewalk": 2,
  "sidewalk:normal->bike-lane:inbound|regular": 25,
  "sidewalk:normal->bike-lane:inbound|regular|road": 33,
  "sidewalk:normal->bike-lane:inbound|regular|sidewalk": 11,
  "sidewalk:normal->bike-lane:outbound|colored": 68,
  "sidewalk:normal->bike-lane:outbound|green|road": 46,
  "sidewalk:normal->bike-lane:outbound|green|sidewalk": 8,
  "sidewalk:normal->bike-lane:outbound|red|road": 3,
  "sidewalk:normal->bike-lane:outbound|regular": 7,
  "sidewalk:normal->bike-lane:outbound|regular|road": 4,
  "sidewalk:normal->bike-lane:outbound|regular|sidewalk": 4,
  "sidewalk:normal->bikeshare:left|road": 15,
  "sidewalk:normal->bikeshare:left|sidewalk": 2,
  "sidewalk:normal->bus-lane:inbound|colored": 1,
  "sidewalk:normal->bus-lane:inbound|regular": 8,
  "sidewalk:normal->bus-lane:inbound|regular|typical": 12,
  "sidewalk:normal->bus-lane:inbound|shared|typical": 7,
  "sidewalk:normal->bus-lane:outbound|regular": 10,
  "sidewalk:normal->divider:big-tree": 45,
  "sidewalk:normal->divider:bollard": 6,
  "sidewalk:normal->divider:bush": 54,
  "sidewalk:normal->divider:dome": 3,
  "sidewalk:normal->divider:flowers": 157,
  "sidewalk:normal->divider:median": 11,
  "sidewalk:normal->divider:palm-tree": 6,
  "sidewalk:normal->divider:planter-box": 12,
  "sidewalk:normal->divider:planting-strip": 59,
  "sidewalk:normal->divider:striped-buffer": 7,
  "sidewalk:normal->drive-lane:inbound|car": 65,
  "sidewalk:normal->drive-lane:inbound|sharrow": 5,
  "sidewalk:normal->drive-lane:inbound|truck": 2,
  "sidewalk:normal->drive-lane:outbound|car": 22,
  "sidewalk:normal->drive-lane:outbound|sharrow": 1,
  "sidewalk:normal->drive-lane:outbound|truck": 31,
  "sidewalk:normal->flex-zone-curb:sparse|left": 5,
  "sidewalk:normal->flex-zone-curb:sparse|right": 9,
  "sidewalk:normal->flex-zone:taxi|inbound|left": 4,
  "sidewalk:normal->flex-zone:taxi|outbound|left": 1,
  "sidewalk:normal->food-truck:left": 9,
  "sidewalk:normal->food-truck:right": 1,
  "sidewalk:normal->light-rail:inbound|colored": 3,
  "sidewalk:normal->light-rail:inbound|grass": 1,
  "sidewalk:normal->light-rail:inbound|regular": 21,
  "sidewalk:normal->light-rail:outbound|grass": 1,
  "sidewalk:normal->light-rail:outbound|regular": 5,
  "sidewalk:normal->outdoor-dining:occupied|sidewalk": 5,
  "sidewalk:normal->parking-lane:inbound|left": 40,
  "sidewalk:normal->parking-lane:inbound|right": 2,
  "sidewalk:normal->parking-lane:outbound|left": 15,
  "sidewalk:normal->parking-lane:outbound|right": 35,
  "sidewalk:normal->parking-lane:sideways|left": 5,
  "sidewalk:normal->parking-lane:sideways|right": 1,
  "sidewalk:normal->parklet:left": 64,
  "sidewalk:normal->parklet:right": 28,
  "sidewalk:normal->scooter-drop-zone:left|sidewalk|empty": 1,
  "sidewalk:normal->scooter-drop-zone:right|sidewalk|empty": 3,
  "sidewalk:normal->scooter:inbound|green": 1,
  "sidewalk:normal->scooter:inbound|regular": 7,
  "sidewalk:normal->scooter:outbound|regular": 1,
  "sidewalk:normal->sidewalk-bench:center": 46,
  "sidewalk:normal->sidewalk-bench:left": 50,
  "sidewalk:normal->sidewalk-bench:right": 14888,
  "sidewalk:normal->sidewalk-bike-rack:left|sidewalk": 53,
  "sidewalk:normal->sidewalk-bike-rack:left|sidewalk-parallel": 34,
  "sidewalk:normal->sidewalk-bike-rack:right|sidewalk": 23,
  "sidewalk:normal->sidewalk-bike-rack:right|sidewalk-parallel": 24,
  "sidewalk:normal->sidewalk-lamp:both|modern": 39,
  "sidewalk:normal->sidewalk-lamp:both|traditional": 79,
  "sidewalk:normal->sidewalk-lamp:left|modern": 189,
  "sidewalk:normal->sidewalk-lamp:left|pride": 2,
  "sidewalk:normal->sidewalk-lamp:left|traditional": 98,
  "sidewalk:normal->sidewalk-lamp:right|modern": 116,
  "sidewalk:normal->sidewalk-lamp:right|pride": 6,
  "sidewalk:normal->sidewalk-lamp:right|traditional": 65,
  "sidewalk:normal->sidewalk-lamp:traditional|left": 1,
  "sidewalk:normal->sidewalk-tree:big": 2041,
  "sidewalk:normal->sidewalk-tree:palm-tree": 58,
  "sidewalk:normal->sidewalk-wayfinding:large": 38,
  "sidewalk:normal->sidewalk-wayfinding:medium": 475,
  "sidewalk:normal->sidewalk-wayfinding:small": 29,
  "sidewalk:normal->sidewalk:dense": 75,
  "sidewalk:normal->sidewalk:empty": 3,
  "sidewalk:normal->sidewalk:normal": 9,
  "sidewalk:normal->sidewalk:sparse": 3,
  "sidewalk:normal->streetcar:inbound|regular": 18,
  "sidewalk:normal->streetcar:outbound|regular": 2,
  "sidewalk:normal->transit-shelter:left|light-rail": 8,
  "sidewalk:normal->transit-shelter:left|street-level": 52,
  "sidewalk:normal->transit-shelter:right": 7,
  "sidewalk:normal->transit-shelter:right|light-rail": 1,
  "sidewalk:normal->transit-shelter:right|street-level": 57,
  "sidewalk:normal->turn-lane:inbound|left": 6,
  "sidewalk:normal->turn-lane:inbound|left-straight": 3,
  "sidewalk:normal->turn-lane:inbound|right": 5,
  "sidewalk:normal->turn-lane:inbound|right-straight": 5,
  "sidewalk:normal->turn-lane:inbound|straight": 3,
  "sidewalk:normal->turn-lane:outbound|left": 14,
  "sidewalk:normal->turn-lane:outbound|left-straight": 3,
  "sidewalk:normal->turn-lane:outbound|right": 7,
  "sidewalk:normal->turn-lane:outbound|right-straight": 9,
  "sidewalk:normal->turn-lane:outbound|straight": 4,
  "sidewalk:normal->utilities:right": 2,
  "sidewalk:sparse->bike-lane:inbound|colored": 19,
  "sidewalk:sparse->bike-lane:inbound|green|road": 4,
  "sidewalk:sparse->bike-lane:inbound|green|sidewalk": 3,
  "sidewalk:sparse->bike-lane:inbound|red|road": 1,
  "sidewalk:sparse->bike-lane:inbound|red|sidewalk": 5,
  "sidewalk:sparse->bike-lane:inbound|regular": 12,
  "sidewalk:sparse->bike-lane:inbound|regular|road": 23,
  "sidewalk:sparse->bike-lane:inbound|regular|sidewalk": 1,
  "sidewalk:sparse->bike-lane:outbound|colored": 10,
  "sidewalk:sparse->bike-lane:outbound|green|road": 3,
  "sidewalk:sparse->bike-lane:outbound|green|sidewalk": 1,
  "sidewalk:sparse->bike-lane:outbound|red|sidewalk": 2,
  "sidewalk:sparse->bike-lane:outbound|regular": 2,
  "sidewalk:sparse->bikeshare:left|road": 2,
  "sidewalk:sparse->bus-lane:inbound|regular": 2,
  "sidewalk:sparse->bus-lane:inbound|regular|typical": 3,
  "sidewalk:sparse->bus-lane:inbound|shared|typical": 3,
  "sidewalk:sparse->bus-lane:outbound|colored": 1,
  "sidewalk:sparse->bus-lane:outbound|regular|typical": 2,
  "sidewalk:sparse->bus-lane:outbound|shared|typical": 1,
  "sidewalk:sparse->divider:big-tree": 18,
  "sidewalk:sparse->divider:bollard": 3,
  "sidewalk:sparse->divider:bush": 42,
  "sidewalk:sparse->divider:dome": 1,
  "sidewalk:sparse->divider:flowers": 49,
  "sidewalk:sparse->divider:median": 5,
  "sidewalk:sparse->divider:palm-tree": 1,
  "sidewalk:sparse->divider:planter-box": 1,
  "sidewalk:sparse->divider:planting-strip": 26,
  "sidewalk:sparse->divider:striped-buffer": 8,
  "sidewalk:sparse->drive-lane:inbound|car": 39,
  "sidewalk:sparse->drive-lane:inbound|sharrow": 7,
  "sidewalk:sparse->drive-lane:inbound|truck": 3,
  "sidewalk:sparse->drive-lane:outbound|car": 14,
  "sidewalk:sparse->drive-lane:outbound|sharrow": 2,
  "sidewalk:sparse->drive-lane:outbound|truck": 3,
  "sidewalk:sparse->drive-lane:truck": 1,
  "sidewalk:sparse->flex-zone-curb:empty|left": 1,
  "sidewalk:sparse->flex-zone-curb:sparse|left": 1,
  "sidewalk:sparse->flex-zone:taxi|inbound|left": 4,
  "sidewalk:sparse->food-truck:left": 1,
  "sidewalk:sparse->food-truck:right": 1,
  "sidewalk:sparse->light-rail:inbound|regular": 2,
  "sidewalk:sparse->parking-lane:inbound|left": 23,
  "sidewalk:sparse->parking-lane:inbound|right": 8,
  "sidewalk:sparse->parking-lane:outbound|left": 6,
  "sidewalk:sparse->parking-lane:outbound|right": 3,
  "sidewalk:sparse->parking-lane:sideways|left": 2,
  "sidewalk:sparse->parking-lane:sideways|right": 2,
  "sidewalk:sparse->parklet:left": 12,
  "sidewalk:sparse->parklet:right": 4,
  "sidewalk:sparse->scooter:inbound|regular": 3,
  "sidewalk:sparse->sidewalk-bench:center": 3,
  "sidewalk:sparse->sidewalk-bench:left": 10,
  "sidewalk:sparse->sidewalk-bench:right": 80,
  "sidewalk:sparse->sidewalk-bike-rack:left|sidewalk": 8,
  "sidewalk:sparse->sidewalk-bike-rack:left|sidewalk-parallel": 4,
  "sidewalk:sparse->sidewalk-bike-rack:right|sidewalk": 9,
  "sidewalk:sparse->sidewalk-bike-rack:right|sidewalk-parallel": 10,
  "sidewalk:sparse->sidewalk-lamp:both|modern": 11,
  "sidewalk:sparse->sidewalk-lamp:both|pride": 1,
  "sidewalk:sparse->sidewalk-lamp:both|traditional": 25,
  "sidewalk:sparse->sidewalk-lamp:left|modern": 32,
  "sidewalk:sparse->sidewalk-lamp:left|pride": 1,
  "sidewalk:sparse->sidewalk-lamp:left|traditional": 14,
  "sidewalk:sparse->sidewalk-lamp:right|modern": 85,
  "sidewalk:sparse->sidewalk-lamp:right|traditional": 35,
  "sidewalk:sparse->sidewalk-tree:big": 528,
  "sidewalk:sparse->sidewalk-tree:palm-tree": 56,
  "sidewalk:sparse->sidewalk-wayfinding:large": 7,
  "sidewalk:sparse->sidewalk-wayfinding:medium": 5,
  "sidewalk:sparse->sidewalk-wayfinding:small": 9,
  "sidewalk:sparse->sidewalk:dense": 10,
  "sidewalk:sparse->sidewalk:empty": 1,
  "sidewalk:sparse->sidewalk:normal": 4,
  "sidewalk:sparse->sidewalk:sparse": 7,
  "sidewalk:sparse->streetcar:inbound|grass": 1,
  "sidewalk:sparse->streetcar:inbound|regular": 4,
  "sidewalk:sparse->transit-shelter:left|light-rail": 4,
  "sidewalk:sparse->transit-shelter:left|street-level": 30,
  "sidewalk:sparse->transit-shelter:right|street-level": 3,
  "sidewalk:sparse->turn-lane:inbound|straight": 1,
  "sidewalk:sparse->turn-lane:outbound|left": 1,
  "sidewalk:sparse->turn-lane:outbound|left-straight": 2,
  "sidewalk:sparse->turn-lane:outbound|right-straight": 1,
  "sidewalk:sparse->turn-lane:outbound|straight": 3,
  "sidewalk:sparse->utilities:left": 1,
  "street-vendor:table|concrete->divider:bush": 2,
  "street-vendor:tarp|concrete->sidewalk-lamp:both|modern": 1,
  "streetcar:inbound|colored->bike-lane:inbound|colored": 1,
  "streetcar:inbound|colored->bike-lane:inbound|regular": 4,
  "streetcar:inbound|colored->bike-lane:outbound|colored": 1,
  "streetcar:inbound|colored->bike-lane:outbound|regular": 1,
  "streetcar:inbound|colored->bus-lane:inbound|regular": 6,
  "streetcar:inbound|colored->bus-lane:outbound|regular": 1,
  "streetcar:inbound|colored->divider:big-tree": 11,
  "streetcar:inbound|colored->divider:bollard": 3,
  "streetcar:inbound|colored->divider:bush": 9,
  "streetcar:inbound|colored->divider:dome": 1,
  "streetcar:inbound|colored->divider:flowers": 8,
  "streetcar:inbound|colored->divider:median": 5,
  "streetcar:inbound|colored->divider:palm-tree": 2,
  "streetcar:inbound|colored->divider:planting-strip": 14,
  "streetcar:inbound|colored->divider:striped-buffer": 5,
  "streetcar:inbound|colored->drive-lane:inbound|car": 22,
  "streetcar:inbound|colored->drive-lane:inbound|sharrow": 2,
  "streetcar:inbound|colored->drive-lane:inbound|truck": 1,
  "streetcar:inbound|colored->drive-lane:outbound|car": 6,
  "streetcar:inbound|colored->drive-lane:outbound|sharrow": 1,
  "streetcar:inbound|colored->drive-lane:outbound|truck": 3,
  "streetcar:inbound|colored->light-rail:inbound|colored": 2,
  "streetcar:inbound|colored->light-rail:inbound|regular": 1,
  "streetcar:inbound|colored->light-rail:outbound|colored": 1,
  "streetcar:inbound|colored->parking-lane:outbound|right": 2,
  "streetcar:inbound|colored->parklet:right": 1,
  "streetcar:inbound|colored->sidewalk-bench:center": 1,
  "streetcar:inbound|colored->sidewalk-bench:left": 1,
  "streetcar:inbound|colored->sidewalk-bench:right": 1,
  "streetcar:inbound|colored->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "streetcar:inbound|colored->sidewalk-lamp:both|modern": 1,
  "streetcar:inbound|colored->sidewalk-lamp:both|traditional": 5,
  "streetcar:inbound|colored->sidewalk-lamp:left|modern": 2,
  "streetcar:inbound|colored->sidewalk-lamp:right|modern": 1,
  "streetcar:inbound|colored->sidewalk-lamp:right|traditional": 1,
  "streetcar:inbound|colored->sidewalk-tree:big": 7,
  "streetcar:inbound|colored->sidewalk-tree:palm-tree": 2,
  "streetcar:inbound|colored->sidewalk-wayfinding:small": 2,
  "streetcar:inbound|colored->sidewalk:dense": 1,
  "streetcar:inbound|colored->streetcar:inbound|colored": 4,
  "streetcar:inbound|colored->streetcar:outbound|colored": 52,
  "streetcar:inbound|colored->streetcar:outbound|regular": 3,
  "streetcar:inbound|colored->transit-shelter:right|light-rail": 3,
  "streetcar:inbound|colored->transit-shelter:right|street-level": 25,
  "streetcar:inbound|colored->turn-lane:inbound|right-straight": 6,
  "streetcar:inbound|colored->turn-lane:outbound|left": 1,
  "streetcar:inbound|colored->turn-lane:outbound|straight": 2,
  "streetcar:inbound|grass->bike-lane:inbound|red|road": 1,
  "streetcar:inbound|grass->bike-lane:inbound|regular|road": 2,
  "streetcar:inbound|grass->bus-lane:inbound|regular|typical": 1,
  "streetcar:inbound|grass->divider:big-tree": 1,
  "streetcar:inbound|grass->divider:bollard": 1,
  "streetcar:inbound|grass->divider:bush": 1,
  "streetcar:inbound|grass->divider:dome": 2,
  "streetcar:inbound|grass->divider:flowers": 10,
  "streetcar:inbound|grass->divider:median": 2,
  "streetcar:inbound|grass->divider:palm-tree": 1,
  "streetcar:inbound|grass->divider:planter-box": 4,
  "streetcar:inbound|grass->divider:planting-strip": 2,
  "streetcar:inbound|grass->drive-lane:inbound|car": 2,
  "streetcar:inbound|grass->drive-lane:inbound|truck": 1,
  "streetcar:inbound|grass->drive-lane:outbound|car": 2,
  "streetcar:inbound|grass->light-rail:outbound|grass": 1,
  "streetcar:inbound|grass->sidewalk-lamp:both|pride": 2,
  "streetcar:inbound|grass->sidewalk-lamp:left|modern": 2,
  "streetcar:inbound|grass->sidewalk-tree:big": 4,
  "streetcar:inbound|grass->sidewalk-tree:palm-tree": 2,
  "streetcar:inbound|grass->streetcar:inbound|grass": 2,
  "streetcar:inbound|grass->streetcar:inbound|regular": 4,
  "streetcar:inbound|grass->streetcar:outbound|grass": 24,
  "streetcar:inbound|grass->streetcar:outbound|regular": 1,
  "streetcar:inbound|grass->transit-shelter:left|street-level": 1,
  "streetcar:inbound|grass->transit-shelter:right|street-level": 4,
  "streetcar:inbound|grass->turn-lane:inbound|left": 1,
  "streetcar:inbound|grass->turn-lane:outbound|left-straight": 1,
  "streetcar:inbound|regular->bike-lane:inbound|colored": 11,
  "streetcar:inbound|regular->bike-lane:inbound|green|sidewalk": 1,
  "streetcar:inbound|regular->bike-lane:inbound|red|road": 1,
  "streetcar:inbound|regular->bike-lane:inbound|regular": 23,
  "streetcar:inbound|regular->bike-lane:inbound|regular|road": 8,
  "streetcar:inbound|regular->bike-lane:outbound|colored": 16,
  "streetcar:inbound|regular->bike-lane:outbound|green|road": 2,
  "streetcar:inbound|regular->bike-lane:outbound|regular": 3,
  "streetcar:inbound|regular->bike-lane:outbound|regular|road": 4,
  "streetcar:inbound|regular->bikeshare:left|road": 1,
  "streetcar:inbound|regular->brt-lane:inbound|red": 1,
  "streetcar:inbound|regular->bus-lane:inbound|colored": 2,
  "streetcar:inbound|regular->bus-lane:inbound|regular": 32,
  "streetcar:inbound|regular->bus-lane:inbound|regular|typical": 7,
  "streetcar:inbound|regular->bus-lane:inbound|shared|typical": 4,
  "streetcar:inbound|regular->bus-lane:outbound|regular": 4,
  "streetcar:inbound|regular->divider:big-tree": 21,
  "streetcar:inbound|regular->divider:bollard": 17,
  "streetcar:inbound|regular->divider:bush": 39,
  "streetcar:inbound|regular->divider:dome": 9,
  "streetcar:inbound|regular->divider:flowers": 134,
  "streetcar:inbound|regular->divider:median": 50,
  "streetcar:inbound|regular->divider:palm-tree": 17,
  "streetcar:inbound|regular->divider:planter-box": 4,
  "streetcar:inbound|regular->divider:planting-strip": 9,
  "streetcar:inbound|regular->divider:striped-buffer": 13,
  "streetcar:inbound|regular->drive-lane:inbound|car": 197,
  "streetcar:inbound|regular->drive-lane:inbound|sharrow": 2,
  "streetcar:inbound|regular->drive-lane:inbound|truck": 6,
  "streetcar:inbound|regular->drive-lane:outbound|car": 57,
  "streetcar:inbound|regular->drive-lane:outbound|truck": 26,
  "streetcar:inbound|regular->flex-zone-curb:sparse|left": 15,
  "streetcar:inbound|regular->flex-zone:taxi|inbound|left": 2,
  "streetcar:inbound|regular->flex-zone:taxi|inbound|right": 1,
  "streetcar:inbound|regular->food-truck:left": 3,
  "streetcar:inbound|regular->light-rail:inbound|colored": 3,
  "streetcar:inbound|regular->light-rail:inbound|regular": 67,
  "streetcar:inbound|regular->light-rail:outbound|colored": 2,
  "streetcar:inbound|regular->light-rail:outbound|regular": 12,
  "streetcar:inbound|regular->parking-lane:inbound|left": 1,
  "streetcar:inbound|regular->parking-lane:inbound|right": 12,
  "streetcar:inbound|regular->parking-lane:outbound|left": 2,
  "streetcar:inbound|regular->parking-lane:outbound|right": 9,
  "streetcar:inbound|regular->parking-lane:sideways|left": 1,
  "streetcar:inbound|regular->parklet:left": 3,
  "streetcar:inbound|regular->parklet:right": 13,
  "streetcar:inbound|regular->scooter:inbound|regular": 2,
  "streetcar:inbound|regular->sidewalk-bench:center": 1,
  "streetcar:inbound|regular->sidewalk-bench:left": 5,
  "streetcar:inbound|regular->sidewalk-bench:right": 6,
  "streetcar:inbound|regular->sidewalk-bike-rack:left|sidewalk": 2,
  "streetcar:inbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 5,
  "streetcar:inbound|regular->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "streetcar:inbound|regular->sidewalk-lamp:both|modern": 12,
  "streetcar:inbound|regular->sidewalk-lamp:both|traditional": 5,
  "streetcar:inbound|regular->sidewalk-lamp:left|modern": 33,
  "streetcar:inbound|regular->sidewalk-lamp:left|traditional": 5,
  "streetcar:inbound|regular->sidewalk-lamp:right|modern": 10,
  "streetcar:inbound|regular->sidewalk-lamp:right|traditional": 2,
  "streetcar:inbound|regular->sidewalk-tree:big": 73,
  "streetcar:inbound|regular->sidewalk-tree:palm-tree": 7,
  "streetcar:inbound|regular->sidewalk-wayfinding:large": 6,
  "streetcar:inbound|regular->sidewalk-wayfinding:medium": 4,
  "streetcar:inbound|regular->sidewalk-wayfinding:small": 3,
  "streetcar:inbound|regular->sidewalk:dense": 32,
  "streetcar:inbound|regular->sidewalk:normal": 1,
  "streetcar:inbound|regular->streetcar:inbound|colored": 1,
  "streetcar:inbound|regular->streetcar:inbound|regular": 136,
  "streetcar:inbound|regular->streetcar:outbound|colored": 3,
  "streetcar:inbound|regular->streetcar:outbound|regular": 257,
  "streetcar:inbound|regular->transit-shelter:left|light-rail": 3,
  "streetcar:inbound|regular->transit-shelter:left|street-level": 16,
  "streetcar:inbound|regular->transit-shelter:right|light-rail": 15,
  "streetcar:inbound|regular->transit-shelter:right|street-level": 119,
  "streetcar:inbound|regular->turn-lane:inbound|left": 9,
  "streetcar:inbound|regular->turn-lane:inbound|left-straight": 3,
  "streetcar:inbound|regular->turn-lane:inbound|right": 12,
  "streetcar:inbound|regular->turn-lane:inbound|right-straight": 41,
  "streetcar:inbound|regular->turn-lane:inbound|shared": 4,
  "streetcar:inbound|regular->turn-lane:inbound|straight": 4,
  "streetcar:inbound|regular->turn-lane:outbound|left": 7,
  "streetcar:inbound|regular->turn-lane:outbound|left-straight": 8,
  "streetcar:inbound|regular->turn-lane:outbound|right-straight": 2,
  "streetcar:inbound|regular->turn-lane:outbound|shared": 2,
  "streetcar:inbound|regular->turn-lane:outbound|straight": 6,
  "streetcar:outbound->streetcar:inbound|regular": 1,
  "streetcar:outbound|colored->bike-lane:outbound|colored": 13,
  "streetcar:outbound|colored->bike-lane:outbound|green|road": 1,
  "streetcar:outbound|colored->bike-lane:outbound|regular": 1,
  "streetcar:outbound|colored->bus-lane:outbound|colored": 3,
  "streetcar:outbound|colored->bus-lane:outbound|regular": 3,
  "streetcar:outbound|colored->divider:big-tree": 3,
  "streetcar:outbound|colored->divider:bollard": 4,
  "streetcar:outbound|colored->divider:bush": 6,
  "streetcar:outbound|colored->divider:dome": 2,
  "streetcar:outbound|colored->divider:flowers": 4,
  "streetcar:outbound|colored->divider:median": 19,
  "streetcar:outbound|colored->divider:palm-tree": 1,
  "streetcar:outbound|colored->divider:striped-buffer": 2,
  "streetcar:outbound|colored->drive-lane:inbound|car": 6,
  "streetcar:outbound|colored->drive-lane:outbound|car": 24,
  "streetcar:outbound|colored->drive-lane:outbound|sharrow": 4,
  "streetcar:outbound|colored->drive-lane:outbound|truck": 15,
  "streetcar:outbound|colored->light-rail:outbound|colored": 4,
  "streetcar:outbound|colored->light-rail:outbound|regular": 1,
  "streetcar:outbound|colored->parking-lane:inbound|left": 1,
  "streetcar:outbound|colored->parking-lane:outbound|right": 3,
  "streetcar:outbound|colored->parklet:left": 1,
  "streetcar:outbound|colored->parklet:right": 1,
  "streetcar:outbound|colored->scooter:outbound|regular": 1,
  "streetcar:outbound|colored->sidewalk-bench:left": 1,
  "streetcar:outbound|colored->sidewalk-bench:right": 2,
  "streetcar:outbound|colored->sidewalk-lamp:both|modern": 5,
  "streetcar:outbound|colored->sidewalk-lamp:both|traditional": 3,
  "streetcar:outbound|colored->sidewalk-lamp:left|modern": 8,
  "streetcar:outbound|colored->sidewalk-lamp:left|traditional": 1,
  "streetcar:outbound|colored->sidewalk-lamp:right|traditional": 1,
  "streetcar:outbound|colored->sidewalk-tree:big": 9,
  "streetcar:outbound|colored->sidewalk:dense": 2,
  "streetcar:outbound|colored->sidewalk:normal": 1,
  "streetcar:outbound|colored->streetcar:inbound|colored": 7,
  "streetcar:outbound|colored->streetcar:outbound|colored": 1,
  "streetcar:outbound|colored->streetcar:outbound|regular": 1,
  "streetcar:outbound|colored->temporary:traffic-cone": 6,
  "streetcar:outbound|colored->transit-shelter:right|light-rail": 4,
  "streetcar:outbound|colored->transit-shelter:right|street-level": 38,
  "streetcar:outbound|colored->turn-lane:inbound|right-straight": 2,
  "streetcar:outbound|colored->turn-lane:outbound|left": 2,
  "streetcar:outbound|colored->turn-lane:outbound|left-straight": 1,
  "streetcar:outbound|colored->turn-lane:outbound|straight": 2,
  "streetcar:outbound|grass->bike-lane:outbound|green|road": 1,
  "streetcar:outbound|grass->bikeshare:left|road": 1,
  "streetcar:outbound|grass->bus-lane:outbound|regular|typical": 1,
  "streetcar:outbound|grass->divider:big-tree": 1,
  "streetcar:outbound|grass->divider:flowers": 4,
  "streetcar:outbound|grass->divider:median": 5,
  "streetcar:outbound|grass->divider:palm-tree": 2,
  "streetcar:outbound|grass->divider:planter-box": 2,
  "streetcar:outbound|grass->drive-lane:inbound|car": 1,
  "streetcar:outbound|grass->drive-lane:outbound|car": 6,
  "streetcar:outbound|grass->drive-lane:outbound|truck": 1,
  "streetcar:outbound|grass->flex-zone-curb:sparse|left": 4,
  "streetcar:outbound|grass->light-rail:outbound|grass": 1,
  "streetcar:outbound|grass->sidewalk-lamp:both|modern": 1,
  "streetcar:outbound|grass->sidewalk-tree:big": 6,
  "streetcar:outbound|grass->sidewalk:dense": 5,
  "streetcar:outbound|grass->sidewalk:normal": 2,
  "streetcar:outbound|grass->streetcar:inbound|grass": 1,
  "streetcar:outbound|grass->streetcar:outbound|grass": 1,
  "streetcar:outbound|grass->transit-shelter:right|light-rail": 2,
  "streetcar:outbound|grass->transit-shelter:right|street-level": 8,
  "streetcar:outbound|grass->turn-lane:outbound|left-right-straight": 1,
  "streetcar:outbound|grass->turn-lane:outbound|left-straight": 2,
  "streetcar:outbound|grass->turn-lane:outbound|right-straight": 1,
  "streetcar:outbound|regular->bike-lane:outbound|colored": 115,
  "streetcar:outbound|regular->bike-lane:outbound|green|road": 4,
  "streetcar:outbound|regular->bike-lane:outbound|green|sidewalk": 1,
  "streetcar:outbound|regular->bike-lane:outbound|red|road": 1,
  "streetcar:outbound|regular->bike-lane:outbound|regular": 13,
  "streetcar:outbound|regular->bike-lane:outbound|regular|road": 7,
  "streetcar:outbound|regular->bikeshare:left|road": 1,
  "streetcar:outbound|regular->bus-lane:inbound|colored|typical": 1,
  "streetcar:outbound|regular->bus-lane:inbound|regular": 1,
  "streetcar:outbound|regular->bus-lane:outbound|regular": 11,
  "streetcar:outbound|regular->bus-lane:outbound|regular|typical": 3,
  "streetcar:outbound|regular->divider:big-tree": 9,
  "streetcar:outbound|regular->divider:bollard": 18,
  "streetcar:outbound|regular->divider:bush": 21,
  "streetcar:outbound|regular->divider:dome": 17,
  "streetcar:outbound|regular->divider:flowers": 49,
  "streetcar:outbound|regular->divider:median": 40,
  "streetcar:outbound|regular->divider:palm-tree": 7,
  "streetcar:outbound|regular->divider:planter-box": 6,
  "streetcar:outbound|regular->divider:planting-strip": 12,
  "streetcar:outbound|regular->divider:striped-buffer": 10,
  "streetcar:outbound|regular->drive-lane:inbound|car": 13,
  "streetcar:outbound|regular->drive-lane:inbound|truck": 1,
  "streetcar:outbound|regular->drive-lane:outbound|car": 124,
  "streetcar:outbound|regular->drive-lane:outbound|sharrow": 4,
  "streetcar:outbound|regular->drive-lane:outbound|truck": 74,
  "streetcar:outbound|regular->flex-zone-curb:empty|right": 1,
  "streetcar:outbound|regular->flex-zone-curb:sparse|left": 8,
  "streetcar:outbound|regular->food-truck:left": 1,
  "streetcar:outbound|regular->light-rail:outbound|colored": 4,
  "streetcar:outbound|regular->light-rail:outbound|regular": 15,
  "streetcar:outbound|regular->parking-lane:inbound|left": 2,
  "streetcar:outbound|regular->parking-lane:inbound|right": 8,
  "streetcar:outbound|regular->parking-lane:outbound|left": 4,
  "streetcar:outbound|regular->parking-lane:outbound|right": 57,
  "streetcar:outbound|regular->parklet:left": 2,
  "streetcar:outbound|regular->parklet:right": 8,
  "streetcar:outbound|regular->scooter:inbound|regular": 1,
  "streetcar:outbound|regular->sidewalk-bench:right": 5,
  "streetcar:outbound|regular->sidewalk-bike-rack:left|sidewalk": 1,
  "streetcar:outbound|regular->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "streetcar:outbound|regular->sidewalk-bike-rack:right|sidewalk-parallel": 3,
  "streetcar:outbound|regular->sidewalk-lamp:both|modern": 13,
  "streetcar:outbound|regular->sidewalk-lamp:both|pride": 1,
  "streetcar:outbound|regular->sidewalk-lamp:both|traditional": 12,
  "streetcar:outbound|regular->sidewalk-lamp:left|modern": 71,
  "streetcar:outbound|regular->sidewalk-lamp:left|traditional": 14,
  "streetcar:outbound|regular->sidewalk-lamp:right|modern": 4,
  "streetcar:outbound|regular->sidewalk-lamp:right|traditional": 2,
  "streetcar:outbound|regular->sidewalk-tree:big": 62,
  "streetcar:outbound|regular->sidewalk-tree:palm-tree": 5,
  "streetcar:outbound|regular->sidewalk-wayfinding:large": 7,
  "streetcar:outbound|regular->sidewalk-wayfinding:medium": 3,
  "streetcar:outbound|regular->sidewalk-wayfinding:small": 2,
  "streetcar:outbound|regular->sidewalk:dense": 21,
  "streetcar:outbound|regular->sidewalk:empty": 1,
  "streetcar:outbound|regular->sidewalk:normal": 10,
  "streetcar:outbound|regular->streetcar:inbound|regular": 27,
  "streetcar:outbound|regular->streetcar:outbound|regular": 45,
  "streetcar:outbound|regular->transit-shelter:left|light-rail": 3,
  "streetcar:outbound|regular->transit-shelter:left|street-level": 2,
  "streetcar:outbound|regular->transit-shelter:right|light-rail": 18,
  "streetcar:outbound|regular->transit-shelter:right|street-level": 137,
  "streetcar:outbound|regular->turn-lane:inbound|left": 2,
  "streetcar:outbound|regular->turn-lane:inbound|straight": 2,
  "streetcar:outbound|regular->turn-lane:outbound|left": 41,
  "streetcar:outbound|regular->turn-lane:outbound|left-straight": 17,
  "streetcar:outbound|regular->turn-lane:outbound|right": 1,
  "streetcar:outbound|regular->turn-lane:outbound|right-straight": 3,
  "streetcar:outbound|regular->turn-lane:outbound|straight": 9,
  "temporary:barricade->temporary:barricade": 1,
  "temporary:barricade->temporary:traffic-cone": 1,
  "temporary:jersey-barrier-concrete->bike-lane:outbound|regular|road": 1,
  "temporary:jersey-barrier-concrete->bus-lane:inbound|regular|typical": 1,
  "temporary:jersey-barrier-concrete->bus-lane:outbound|regular|typical": 1,
  "temporary:jersey-barrier-concrete->drive-lane:inbound|car": 1,
  "temporary:jersey-barrier-concrete->drive-lane:outbound|car": 1,
  "temporary:traffic-cone->bike-lane:outbound|green|road": 7,
  "temporary:traffic-cone->divider:planter-box": 1,
  "temporary:traffic-cone->temporary:barricade": 1,
  "train:->divider:flowers": 1,
  "transit-shelter:left->sidewalk-lamp:right|modern": 1,
  "transit-shelter:left|light-rail->bike-lane:inbound|colored": 1,
  "transit-shelter:left|light-rail->bike-lane:inbound|regular": 5,
  "transit-shelter:left|light-rail->bus-lane:inbound|colored": 7,
  "transit-shelter:left|light-rail->bus-lane:inbound|colored|typical": 6,
  "transit-shelter:left|light-rail->bus-lane:inbound|regular": 22,
  "transit-shelter:left|light-rail->bus-lane:inbound|regular|typical": 5,
  "transit-shelter:left|light-rail->bus-lane:inbound|shared|typical": 4,
  "transit-shelter:left|light-rail->bus-lane:outbound|colored": 12,
  "transit-shelter:left|light-rail->bus-lane:outbound|colored|typical": 4,
  "transit-shelter:left|light-rail->bus-lane:outbound|regular": 7,
  "transit-shelter:left|light-rail->bus-lane:outbound|regular|typical": 3,
  "transit-shelter:left|light-rail->bus-lane:outbound|shared|typical": 1,
  "transit-shelter:left|light-rail->divider:big-tree": 4,
  "transit-shelter:left|light-rail->divider:bollard": 1,
  "transit-shelter:left|light-rail->divider:bush": 3,
  "transit-shelter:left|light-rail->divider:flowers": 3,
  "transit-shelter:left|light-rail->divider:palm-tree": 1,
  "transit-shelter:left|light-rail->drive-lane:inbound|car": 3,
  "transit-shelter:left|light-rail->drive-lane:inbound|sharrow": 1,
  "transit-shelter:left|light-rail->drive-lane:inbound|truck": 1,
  "transit-shelter:left|light-rail->drive-lane:outbound|car": 2,
  "transit-shelter:left|light-rail->light-rail:inbound|colored": 105,
  "transit-shelter:left|light-rail->light-rail:inbound|grass": 17,
  "transit-shelter:left|light-rail->light-rail:inbound|regular": 315,
  "transit-shelter:left|light-rail->light-rail:outbound|colored": 47,
  "transit-shelter:left|light-rail->light-rail:outbound|grass": 7,
  "transit-shelter:left|light-rail->light-rail:outbound|regular": 92,
  "transit-shelter:left|light-rail->sidewalk-bench:left": 1,
  "transit-shelter:left|light-rail->sidewalk-bike-rack:left|sidewalk": 1,
  "transit-shelter:left|light-rail->sidewalk-lamp:both|modern": 22,
  "transit-shelter:left|light-rail->sidewalk-lamp:both|pride": 1,
  "transit-shelter:left|light-rail->sidewalk-lamp:both|traditional": 20,
  "transit-shelter:left|light-rail->sidewalk-lamp:left|modern": 6,
  "transit-shelter:left|light-rail->sidewalk-lamp:right|modern": 133,
  "transit-shelter:left|light-rail->sidewalk-lamp:right|pride": 2,
  "transit-shelter:left|light-rail->sidewalk-lamp:right|traditional": 37,
  "transit-shelter:left|light-rail->sidewalk-tree:big": 6,
  "transit-shelter:left|light-rail->sidewalk-tree:palm-tree": 2,
  "transit-shelter:left|light-rail->sidewalk-wayfinding:large": 5,
  "transit-shelter:left|light-rail->sidewalk-wayfinding:small": 4,
  "transit-shelter:left|light-rail->sidewalk:dense": 8,
  "transit-shelter:left|light-rail->sidewalk:normal": 1,
  "transit-shelter:left|light-rail->sidewalk:sparse": 1,
  "transit-shelter:left|light-rail->streetcar:inbound|colored": 4,
  "transit-shelter:left|light-rail->streetcar:inbound|grass": 4,
  "transit-shelter:left|light-rail->streetcar:inbound|regular": 26,
  "transit-shelter:left|light-rail->streetcar:outbound|colored": 1,
  "transit-shelter:left|light-rail->streetcar:outbound|grass": 1,
  "transit-shelter:left|light-rail->streetcar:outbound|regular": 13,
  "transit-shelter:left|light-rail->transit-shelter:left|light-rail": 1,
  "transit-shelter:left|light-rail->transit-shelter:right|light-rail": 4,
  "transit-shelter:left|light-rail->turn-lane:outbound|both": 1,
  "transit-shelter:left|light-rail->turn-lane:outbound|left": 1,
  "transit-shelter:left|street-level->bike-lane:inbound|colored": 52,
  "transit-shelter:left|street-level->bike-lane:inbound|green|road": 5,
  "transit-shelter:left|street-level->bike-lane:inbound|green|sidewalk": 2,
  "transit-shelter:left|street-level->bike-lane:inbound|red|road": 1,
  "transit-shelter:left|street-level->bike-lane:inbound|regular": 48,
  "transit-shelter:left|street-level->bike-lane:inbound|regular|road": 18,
  "transit-shelter:left|street-level->bike-lane:outbound|colored": 23,
  "transit-shelter:left|street-level->bike-lane:outbound|green|road": 4,
  "transit-shelter:left|street-level->bike-lane:outbound|green|sidewalk": 1,
  "transit-shelter:left|street-level->bike-lane:outbound|regular": 8,
  "transit-shelter:left|street-level->bike-lane:outbound|regular|road": 4,
  "transit-shelter:left|street-level->bikeshare:left|road": 4,
  "transit-shelter:left|street-level->bus-lane:colored": 1,
  "transit-shelter:left|street-level->bus-lane:colored||typical": 1,
  "transit-shelter:left|street-level->bus-lane:inbound|colored": 90,
  "transit-shelter:left|street-level->bus-lane:inbound|colored|typical": 34,
  "transit-shelter:left|street-level->bus-lane:inbound|regular": 437,
  "transit-shelter:left|street-level->bus-lane:inbound|regular|typical": 135,
  "transit-shelter:left|street-level->bus-lane:inbound|shared|typical": 120,
  "transit-shelter:left|street-level->bus-lane:outbound|colored": 31,
  "transit-shelter:left|street-level->bus-lane:outbound|colored|typical": 9,
  "transit-shelter:left|street-level->bus-lane:outbound|regular": 56,
  "transit-shelter:left|street-level->bus-lane:outbound|regular|typical": 27,
  "transit-shelter:left|street-level->bus-lane:outbound|shared|typical": 14,
  "transit-shelter:left|street-level->divider:big-tree": 3,
  "transit-shelter:left|street-level->divider:bollard": 2,
  "transit-shelter:left|street-level->divider:bush": 26,
  "transit-shelter:left|street-level->divider:flowers": 54,
  "transit-shelter:left|street-level->divider:median": 19,
  "transit-shelter:left|street-level->divider:palm-tree": 4,
  "transit-shelter:left|street-level->divider:planter-box": 8,
  "transit-shelter:left|street-level->divider:planting-strip": 3,
  "transit-shelter:left|street-level->divider:striped-buffer": 1,
  "transit-shelter:left|street-level->drive-lane:inbound|car": 139,
  "transit-shelter:left|street-level->drive-lane:inbound|sharrow": 3,
  "transit-shelter:left|street-level->drive-lane:inbound|truck": 12,
  "transit-shelter:left|street-level->drive-lane:outbound|car": 11,
  "transit-shelter:left|street-level->drive-lane:outbound|truck": 18,
  "transit-shelter:left|street-level->flex-zone-curb:sparse|left": 1,
  "transit-shelter:left|street-level->flex-zone-curb:sparse|right": 4,
  "transit-shelter:left|street-level->flex-zone:taxi|inbound|left": 5,
  "transit-shelter:left|street-level->flex-zone:taxi|outbound|left": 1,
  "transit-shelter:left|street-level->food-truck:left": 7,
  "transit-shelter:left|street-level->light-rail:inbound|colored": 35,
  "transit-shelter:left|street-level->light-rail:inbound|grass": 9,
  "transit-shelter:left|street-level->light-rail:inbound|regular": 118,
  "transit-shelter:left|street-level->light-rail:outbound|colored": 16,
  "transit-shelter:left|street-level->light-rail:outbound|grass": 3,
  "transit-shelter:left|street-level->light-rail:outbound|regular": 55,
  "transit-shelter:left|street-level->parking-lane:inbound|left": 44,
  "transit-shelter:left|street-level->parking-lane:inbound|right": 10,
  "transit-shelter:left|street-level->parking-lane:outbound|left": 6,
  "transit-shelter:left|street-level->parking-lane:outbound|right": 7,
  "transit-shelter:left|street-level->parklet:left": 3,
  "transit-shelter:left|street-level->parklet:right": 15,
  "transit-shelter:left|street-level->scooter:inbound|regular": 9,
  "transit-shelter:left|street-level->scooter:outbound|regular": 1,
  "transit-shelter:left|street-level->sidewalk-bench:center": 2,
  "transit-shelter:left|street-level->sidewalk-bench:left": 12,
  "transit-shelter:left|street-level->sidewalk-bench:right": 4,
  "transit-shelter:left|street-level->sidewalk-bike-rack:left|road": 4,
  "transit-shelter:left|street-level->sidewalk-bike-rack:left|sidewalk": 29,
  "transit-shelter:left|street-level->sidewalk-bike-rack:left|sidewalk-parallel": 50,
  "transit-shelter:left|street-level->sidewalk-bike-rack:right|road": 1,
  "transit-shelter:left|street-level->sidewalk-bike-rack:right|sidewalk": 4,
  "transit-shelter:left|street-level->sidewalk-bike-rack:right|sidewalk-parallel": 7,
  "transit-shelter:left|street-level->sidewalk-lamp:both|modern": 189,
  "transit-shelter:left|street-level->sidewalk-lamp:both|pride": 9,
  "transit-shelter:left|street-level->sidewalk-lamp:both|traditional": 142,
  "transit-shelter:left|street-level->sidewalk-lamp:left": 1,
  "transit-shelter:left|street-level->sidewalk-lamp:left|modern": 74,
  "transit-shelter:left|street-level->sidewalk-lamp:left|traditional": 35,
  "transit-shelter:left|street-level->sidewalk-lamp:modern": 2,
  "transit-shelter:left|street-level->sidewalk-lamp:pride": 1,
  "transit-shelter:left|street-level->sidewalk-lamp:pride|left": 1,
  "transit-shelter:left|street-level->sidewalk-lamp:right|modern": 31215,
  "transit-shelter:left|street-level->sidewalk-lamp:right|pride": 57,
  "transit-shelter:left|street-level->sidewalk-lamp:right|traditional": 348,
  "transit-shelter:left|street-level->sidewalk-lamp:traditional": 1,
  "transit-shelter:left|street-level->sidewalk-tree:big": 258,
  "transit-shelter:left|street-level->sidewalk-tree:palm-tree": 14,
  "transit-shelter:left|street-level->sidewalk-wayfinding:large": 46,
  "transit-shelter:left|street-level->sidewalk-wayfinding:medium": 22,
  "transit-shelter:left|street-level->sidewalk-wayfinding:small": 17,
  "transit-shelter:left|street-level->sidewalk:dense": 167,
  "transit-shelter:left|street-level->sidewalk:empty": 4,
  "transit-shelter:left|street-level->sidewalk:normal": 31,
  "transit-shelter:left|street-level->sidewalk:sparse": 3,
  "transit-shelter:left|street-level->streetcar:inbound|colored": 39,
  "transit-shelter:left|street-level->streetcar:inbound|grass": 8,
  "transit-shelter:left|street-level->streetcar:inbound|regular": 178,
  "transit-shelter:left|street-level->streetcar:outbound|colored": 15,
  "transit-shelter:left|street-level->streetcar:outbound|grass": 4,
  "transit-shelter:left|street-level->streetcar:outbound|regular": 84,
  "transit-shelter:left|street-level->transit-shelter:right|street-level": 2,
  "transit-shelter:left|street-level->turn-lane:inbound|left": 9,
  "transit-shelter:left|street-level->turn-lane:inbound|left-right-straight": 1,
  "transit-shelter:left|street-level->turn-lane:inbound|left-straight": 7,
  "transit-shelter:left|street-level->turn-lane:inbound|right": 10,
  "transit-shelter:left|street-level->turn-lane:inbound|right-straight": 15,
  "transit-shelter:left|street-level->turn-lane:inbound|shared": 2,
  "transit-shelter:left|street-level->turn-lane:inbound|straight": 4,
  "transit-shelter:left|street-level->turn-lane:outbound|left": 5,
  "transit-shelter:left|street-level->turn-lane:outbound|left-straight": 3,
  "transit-shelter:left|street-level->turn-lane:outbound|right": 1,
  "transit-shelter:left|street-level->turn-lane:outbound|right-straight": 1,
  "transit-shelter:left|street-level->turn-lane:outbound|straight": 4,
  "transit-shelter:left|street-level->utilities:left": 1,
  "transit-shelter:light-rail->divider:flowers": 1,
  "transit-shelter:light-rail->sidewalk-lamp:right|modern": 1,
  "transit-shelter:right->bus-lane:inbound|shared|typical": 1,
  "transit-shelter:right->divider:planter-box": 4,
  "transit-shelter:right->parking-lane:outbound|right": 1,
  "transit-shelter:right->sidewalk-lamp:left|modern": 1,
  "transit-shelter:right->sidewalk-lamp:right|modern": 1,
  "transit-shelter:right|light-rail->bike-lane:inbound|colored": 8,
  "transit-shelter:right|light-rail->bike-lane:inbound|regular": 3,
  "transit-shelter:right|light-rail->bike-lane:inbound|regular|road": 4,
  "transit-shelter:right|light-rail->bike-lane:outbound|colored": 26,
  "transit-shelter:right|light-rail->bike-lane:outbound|red|road": 1,
  "transit-shelter:right|light-rail->bike-lane:outbound|red|sidewalk": 4,
  "transit-shelter:right|light-rail->bike-lane:outbound|regular": 13,
  "transit-shelter:right|light-rail->bike-lane:outbound|regular|road": 4,
  "transit-shelter:right|light-rail->bike-lane:outbound|regular|sidewalk": 2,
  "transit-shelter:right|light-rail->bus-lane:inbound|colored": 1,
  "transit-shelter:right|light-rail->bus-lane:inbound|regular": 5,
  "transit-shelter:right|light-rail->bus-lane:inbound|regular|typical": 3,
  "transit-shelter:right|light-rail->bus-lane:outbound|colored": 6,
  "transit-shelter:right|light-rail->bus-lane:outbound|regular": 13,
  "transit-shelter:right|light-rail->bus-lane:outbound|regular|typical": 1,
  "transit-shelter:right|light-rail->divider:big-tree": 10,
  "transit-shelter:right|light-rail->divider:bollard": 2,
  "transit-shelter:right|light-rail->divider:bush": 33,
  "transit-shelter:right|light-rail->divider:flowers": 16,
  "transit-shelter:right|light-rail->divider:median": 1,
  "transit-shelter:right|light-rail->divider:palm-tree": 8,
  "transit-shelter:right|light-rail->divider:planting-strip": 4,
  "transit-shelter:right|light-rail->divider:striped-buffer": 2,
  "transit-shelter:right|light-rail->drive-lane:inbound|car": 34,
  "transit-shelter:right|light-rail->drive-lane:inbound|truck": 1,
  "transit-shelter:right|light-rail->drive-lane:outbound|car": 41,
  "transit-shelter:right|light-rail->drive-lane:outbound|sharrow": 6,
  "transit-shelter:right|light-rail->drive-lane:outbound|truck": 18,
  "transit-shelter:right|light-rail->flex-zone-curb:sparse|left": 3,
  "transit-shelter:right|light-rail->light-rail:inbound|colored": 6,
  "transit-shelter:right|light-rail->light-rail:inbound|regular": 22,
  "transit-shelter:right|light-rail->light-rail:outbound|colored": 16,
  "transit-shelter:right|light-rail->light-rail:outbound|grass": 1,
  "transit-shelter:right|light-rail->light-rail:outbound|regular": 39,
  "transit-shelter:right|light-rail->parking-lane:inbound|right": 2,
  "transit-shelter:right|light-rail->parking-lane:outbound|right": 3,
  "transit-shelter:right|light-rail->parklet:left": 1,
  "transit-shelter:right|light-rail->parklet:right": 10,
  "transit-shelter:right|light-rail->scooter:inbound|red": 2,
  "transit-shelter:right|light-rail->sidewalk-bench:left": 3,
  "transit-shelter:right|light-rail->sidewalk-bench:right": 1,
  "transit-shelter:right|light-rail->sidewalk-bike-rack:left|sidewalk": 2,
  "transit-shelter:right|light-rail->sidewalk-bike-rack:left|sidewalk-parallel": 6,
  "transit-shelter:right|light-rail->sidewalk-lamp:both|modern": 56,
  "transit-shelter:right|light-rail->sidewalk-lamp:both|pride": 3,
  "transit-shelter:right|light-rail->sidewalk-lamp:both|traditional": 18,
  "transit-shelter:right|light-rail->sidewalk-lamp:left|modern": 41,
  "transit-shelter:right|light-rail->sidewalk-lamp:left|pride": 1,
  "transit-shelter:right|light-rail->sidewalk-lamp:left|traditional": 9,
  "transit-shelter:right|light-rail->sidewalk-lamp:right|modern": 31,
  "transit-shelter:right|light-rail->sidewalk-lamp:right|pride": 1,
  "transit-shelter:right|light-rail->sidewalk-lamp:right|traditional": 9,
  "transit-shelter:right|light-rail->sidewalk-tree:big": 124,
  "transit-shelter:right|light-rail->sidewalk-tree:palm-tree": 12,
  "transit-shelter:right|light-rail->sidewalk-wayfinding:large": 10,
  "transit-shelter:right|light-rail->sidewalk-wayfinding:medium": 4,
  "transit-shelter:right|light-rail->sidewalk-wayfinding:small": 10,
  "transit-shelter:right|light-rail->sidewalk:dense": 52,
  "transit-shelter:right|light-rail->sidewalk:normal": 13,
  "transit-shelter:right|light-rail->sidewalk:sparse": 4,
  "transit-shelter:right|light-rail->streetcar:inbound|regular": 3,
  "transit-shelter:right|light-rail->streetcar:outbound|colored": 1,
  "transit-shelter:right|light-rail->streetcar:outbound|regular": 4,
  "transit-shelter:right|light-rail->transit-shelter:left|light-rail": 32,
  "transit-shelter:right|light-rail->transit-shelter:left|street-level": 1,
  "transit-shelter:right|light-rail->transit-shelter:right|light-rail": 1,
  "transit-shelter:right|light-rail->transit-shelter:right|street-level": 1,
  "transit-shelter:right|light-rail->turn-lane:inbound|left": 1,
  "transit-shelter:right|light-rail->turn-lane:inbound|right": 3,
  "transit-shelter:right|light-rail->turn-lane:inbound|right-straight": 2,
  "transit-shelter:right|light-rail->turn-lane:inbound|straight": 2,
  "transit-shelter:right|light-rail->turn-lane:outbound|left": 8,
  "transit-shelter:right|light-rail->turn-lane:outbound|left-straight": 6,
  "transit-shelter:right|light-rail->turn-lane:outbound|right": 2,
  "transit-shelter:right|light-rail->turn-lane:outbound|straight": 2,
  "transit-shelter:right|street-level->bike-lane:inbound|colored": 22,
  "transit-shelter:right|street-level->bike-lane:inbound|green|road": 4,
  "transit-shelter:right|street-level->bike-lane:inbound|red|road": 2,
  "transit-shelter:right|street-level->bike-lane:inbound|red|sidewalk": 1,
  "transit-shelter:right|street-level->bike-lane:inbound|regular": 19,
  "transit-shelter:right|street-level->bike-lane:inbound|regular|road": 26,
  "transit-shelter:right|street-level->bike-lane:inbound|regular|sidewalk": 2,
  "transit-shelter:right|street-level->bike-lane:outbound|colored": 80,
  "transit-shelter:right|street-level->bike-lane:outbound|green": 1,
  "transit-shelter:right|street-level->bike-lane:outbound|green|road": 19,
  "transit-shelter:right|street-level->bike-lane:outbound|green|sidewalk": 4,
  "transit-shelter:right|street-level->bike-lane:outbound|red|road": 1,
  "transit-shelter:right|street-level->bike-lane:outbound|red|sidewalk": 1,
  "transit-shelter:right|street-level->bike-lane:outbound|regular": 13,
  "transit-shelter:right|street-level->bike-lane:outbound|regular|road": 6,
  "transit-shelter:right|street-level->bike-lane:outbound|regular|sidewalk": 4,
  "transit-shelter:right|street-level->bikeshare:left|road": 3,
  "transit-shelter:right|street-level->bikeshare:right|sidewalk": 1,
  "transit-shelter:right|street-level->bus-lane:inbound|colored": 5,
  "transit-shelter:right|street-level->bus-lane:inbound|regular": 30,
  "transit-shelter:right|street-level->bus-lane:inbound|regular|typical": 14,
  "transit-shelter:right|street-level->bus-lane:inbound|shared|typical": 2,
  "transit-shelter:right|street-level->bus-lane:outbound|colored": 10,
  "transit-shelter:right|street-level->bus-lane:outbound|colored|typical": 1,
  "transit-shelter:right|street-level->bus-lane:outbound|regular": 19,
  "transit-shelter:right|street-level->bus-lane:outbound|regular|typical": 10,
  "transit-shelter:right|street-level->bus-lane:outbound|shared|typical": 6,
  "transit-shelter:right|street-level->divider:big-tree": 31,
  "transit-shelter:right|street-level->divider:bollard": 8,
  "transit-shelter:right|street-level->divider:bush": 8,
  "transit-shelter:right|street-level->divider:flowers": 59,
  "transit-shelter:right|street-level->divider:median": 14,
  "transit-shelter:right|street-level->divider:palm-tree": 8,
  "transit-shelter:right|street-level->divider:planter-box": 3,
  "transit-shelter:right|street-level->divider:planting-strip": 3,
  "transit-shelter:right|street-level->divider:striped-buffer": 3,
  "transit-shelter:right|street-level->drive-lane:inbound|car": 50,
  "transit-shelter:right|street-level->drive-lane:inbound|truck": 6,
  "transit-shelter:right|street-level->drive-lane:outbound|car": 74,
  "transit-shelter:right|street-level->drive-lane:outbound|sharrow": 1,
  "transit-shelter:right|street-level->drive-lane:outbound|truck": 35,
  "transit-shelter:right|street-level->flex-zone-curb:empty|left": 1,
  "transit-shelter:right|street-level->flex-zone-curb:sparse|left": 13,
  "transit-shelter:right|street-level->flex-zone-curb:sparse|right": 1,
  "transit-shelter:right|street-level->flex-zone:taxi|inbound|left": 1,
  "transit-shelter:right|street-level->flex-zone:taxi|outbound|right": 1,
  "transit-shelter:right|street-level->food-truck:left": 5,
  "transit-shelter:right|street-level->light-rail:inbound|colored": 1,
  "transit-shelter:right|street-level->light-rail:inbound|regular": 12,
  "transit-shelter:right|street-level->light-rail:outbound|colored": 2,
  "transit-shelter:right|street-level->light-rail:outbound|grass": 2,
  "transit-shelter:right|street-level->light-rail:outbound|regular": 8,
  "transit-shelter:right|street-level->parking-lane:inbound|left": 4,
  "transit-shelter:right|street-level->parking-lane:inbound|right": 5,
  "transit-shelter:right|street-level->parking-lane:outbound|left": 2,
  "transit-shelter:right|street-level->parking-lane:outbound|right": 3,
  "transit-shelter:right|street-level->parklet:left": 3,
  "transit-shelter:right|street-level->parklet:right": 7,
  "transit-shelter:right|street-level->scooter-drop-zone:right|sidewalk|empty": 3,
  "transit-shelter:right|street-level->scooter:inbound|green": 1,
  "transit-shelter:right|street-level->scooter:inbound|regular": 6,
  "transit-shelter:right|street-level->scooter:outbound|green": 1,
  "transit-shelter:right|street-level->scooter:outbound|regular": 1,
  "transit-shelter:right|street-level->sidewalk-bench:left": 4,
  "transit-shelter:right|street-level->sidewalk-bench:right": 7,
  "transit-shelter:right|street-level->sidewalk-bike-rack:left|road": 1,
  "transit-shelter:right|street-level->sidewalk-bike-rack:left|sidewalk": 3,
  "transit-shelter:right|street-level->sidewalk-bike-rack:left|sidewalk-parallel": 14,
  "transit-shelter:right|street-level->sidewalk-bike-rack:right|sidewalk": 3,
  "transit-shelter:right|street-level->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "transit-shelter:right|street-level->sidewalk-lamp:both|modern": 55,
  "transit-shelter:right|street-level->sidewalk-lamp:both|pride": 2,
  "transit-shelter:right|street-level->sidewalk-lamp:both|traditional": 53,
  "transit-shelter:right|street-level->sidewalk-lamp:left|modern": 148,
  "transit-shelter:right|street-level->sidewalk-lamp:left|pride": 7,
  "transit-shelter:right|street-level->sidewalk-lamp:left|traditional": 35,
  "transit-shelter:right|street-level->sidewalk-lamp:right|modern": 160,
  "transit-shelter:right|street-level->sidewalk-lamp:right|traditional": 25,
  "transit-shelter:right|street-level->sidewalk-tree:big": 1876,
  "transit-shelter:right|street-level->sidewalk-tree:palm-tree": 36,
  "transit-shelter:right|street-level->sidewalk-wayfinding:large": 18,
  "transit-shelter:right|street-level->sidewalk-wayfinding:medium": 27,
  "transit-shelter:right|street-level->sidewalk-wayfinding:small": 14,
  "transit-shelter:right|street-level->sidewalk:dense": 210,
  "transit-shelter:right|street-level->sidewalk:empty": 3,
  "transit-shelter:right|street-level->sidewalk:normal": 77,
  "transit-shelter:right|street-level->sidewalk:sparse": 11,
  "transit-shelter:right|street-level->streetcar:inbound|colored": 1,
  "transit-shelter:right|street-level->streetcar:inbound|regular": 10,
  "transit-shelter:right|street-level->streetcar:outbound|colored": 6,
  "transit-shelter:right|street-level->streetcar:outbound|regular": 15,
  "transit-shelter:right|street-level->transit-shelter:left|light-rail": 2,
  "transit-shelter:right|street-level->transit-shelter:left|street-level": 33,
  "transit-shelter:right|street-level->transit-shelter:right|street-level": 4,
  "transit-shelter:right|street-level->turn-lane:inbound|left": 4,
  "transit-shelter:right|street-level->turn-lane:inbound|left-straight": 3,
  "transit-shelter:right|street-level->turn-lane:inbound|right": 3,
  "transit-shelter:right|street-level->turn-lane:inbound|right-straight": 19,
  "transit-shelter:right|street-level->turn-lane:inbound|straight": 1,
  "transit-shelter:right|street-level->turn-lane:outbound|left": 19,
  "transit-shelter:right|street-level->turn-lane:outbound|left-straight": 5,
  "transit-shelter:right|street-level->turn-lane:outbound|straight": 11,
  "transit-shelter:street-level|right->sidewalk-lamp:right|modern": 1,
  "turn-lane:both->parking-lane:outbound|right": 1,
  "turn-lane:inbound->parking-lane:outbound|right": 1,
  "turn-lane:inbound|both->bus-lane:inbound|colored": 1,
  "turn-lane:inbound|both->bus-lane:inbound|regular": 3,
  "turn-lane:inbound|both->bus-lane:inbound|regular|typical": 2,
  "turn-lane:inbound|both->bus-lane:outbound|colored": 1,
  "turn-lane:inbound|both->divider:big-tree": 6,
  "turn-lane:inbound|both->divider:bush": 4,
  "turn-lane:inbound|both->divider:dome": 1,
  "turn-lane:inbound|both->divider:flowers": 73,
  "turn-lane:inbound|both->divider:median": 2,
  "turn-lane:inbound|both->divider:palm-tree": 8,
  "turn-lane:inbound|both->divider:planting-strip": 1,
  "turn-lane:inbound|both->divider:striped-buffer": 1,
  "turn-lane:inbound|both->drive-lane:inbound|car": 1,
  "turn-lane:inbound|both->drive-lane:inbound|truck": 2,
  "turn-lane:inbound|both->drive-lane:outbound|car": 6,
  "turn-lane:inbound|both->drive-lane:outbound|truck": 7,
  "turn-lane:inbound|both->light-rail:inbound|colored": 1,
  "turn-lane:inbound|both->parking-lane:inbound|right": 1,
  "turn-lane:inbound|both->parking-lane:outbound|right": 8,
  "turn-lane:inbound|both->parklet:right": 1,
  "turn-lane:inbound|both->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "turn-lane:inbound|both->sidewalk-lamp:both|modern": 1,
  "turn-lane:inbound|both->sidewalk-lamp:left|modern": 2,
  "turn-lane:inbound|both->sidewalk-lamp:left|traditional": 1,
  "turn-lane:inbound|both->sidewalk-lamp:right|modern": 1,
  "turn-lane:inbound|both->sidewalk-tree:big": 2,
  "turn-lane:inbound|both->streetcar:inbound|regular": 3,
  "turn-lane:inbound|both->turn-lane:inbound|right": 4,
  "turn-lane:inbound|both->turn-lane:inbound|shared": 1,
  "turn-lane:inbound|both->turn-lane:outbound|both": 1,
  "turn-lane:inbound|both->turn-lane:outbound|right": 1,
  "turn-lane:inbound|left->bike-lane:inbound|colored": 8,
  "turn-lane:inbound|left->bike-lane:inbound|green|road": 4,
  "turn-lane:inbound|left->bike-lane:inbound|regular": 16,
  "turn-lane:inbound|left->bike-lane:inbound|regular|road": 1,
  "turn-lane:inbound|left->bike-lane:outbound|colored": 7,
  "turn-lane:inbound|left->bike-lane:outbound|green|road": 2,
  "turn-lane:inbound|left->bike-lane:outbound|regular": 1,
  "turn-lane:inbound|left->bus-lane:inbound|colored": 1,
  "turn-lane:inbound|left->bus-lane:inbound|regular": 55,
  "turn-lane:inbound|left->bus-lane:inbound|regular|typical": 7,
  "turn-lane:inbound|left->bus-lane:outbound|regular": 2,
  "turn-lane:inbound|left->bus-lane:outbound|regular|typical": 1,
  "turn-lane:inbound|left->divider:big-tree": 3,
  "turn-lane:inbound|left->divider:bollard": 3,
  "turn-lane:inbound|left->divider:dome": 5,
  "turn-lane:inbound|left->divider:flowers": 36,
  "turn-lane:inbound|left->divider:median": 6,
  "turn-lane:inbound|left->divider:striped-buffer": 6,
  "turn-lane:inbound|left->drive-lane:inbound|car": 289,
  "turn-lane:inbound|left->drive-lane:inbound|sharrow": 1,
  "turn-lane:inbound|left->drive-lane:inbound|truck": 28,
  "turn-lane:inbound|left->drive-lane:outbound|car": 34,
  "turn-lane:inbound|left->drive-lane:outbound|truck": 19,
  "turn-lane:inbound|left->flex-zone-curb:sparse|left": 3,
  "turn-lane:inbound|left->light-rail:inbound|colored": 1,
  "turn-lane:inbound|left->light-rail:inbound|regular": 11,
  "turn-lane:inbound|left->parking-lane:inbound|left": 10,
  "turn-lane:inbound|left->parking-lane:inbound|right": 5,
  "turn-lane:inbound|left->parking-lane:outbound|left": 1,
  "turn-lane:inbound|left->parking-lane:outbound|right": 10,
  "turn-lane:inbound|left->parklet:right": 4,
  "turn-lane:inbound|left->scooter:inbound|green": 1,
  "turn-lane:inbound|left->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "turn-lane:inbound|left->sidewalk-lamp:both|modern": 4,
  "turn-lane:inbound|left->sidewalk-lamp:both|traditional": 2,
  "turn-lane:inbound|left->sidewalk-lamp:left|modern": 5,
  "turn-lane:inbound|left->sidewalk-lamp:left|traditional": 1,
  "turn-lane:inbound|left->sidewalk-tree:big": 11,
  "turn-lane:inbound|left->sidewalk-tree:palm-tree": 1,
  "turn-lane:inbound|left->sidewalk-wayfinding:large": 1,
  "turn-lane:inbound|left->sidewalk:dense": 4,
  "turn-lane:inbound|left->sidewalk:normal": 2,
  "turn-lane:inbound|left->streetcar:inbound|colored": 2,
  "turn-lane:inbound|left->streetcar:inbound|regular": 7,
  "turn-lane:inbound|left->streetcar:outbound|regular": 2,
  "turn-lane:inbound|left->transit-shelter:left|light-rail": 2,
  "turn-lane:inbound|left->transit-shelter:left|street-level": 6,
  "turn-lane:inbound|left->transit-shelter:right|street-level": 8,
  "turn-lane:inbound|left->turn-lane:inbound|both": 4,
  "turn-lane:inbound|left->turn-lane:inbound|left": 50,
  "turn-lane:inbound|left->turn-lane:inbound|left-straight": 19,
  "turn-lane:inbound|left->turn-lane:inbound|right": 9,
  "turn-lane:inbound|left->turn-lane:inbound|right-straight": 15,
  "turn-lane:inbound|left->turn-lane:inbound|shared": 3,
  "turn-lane:inbound|left->turn-lane:inbound|straight": 11,
  "turn-lane:inbound|left->turn-lane:outbound|left": 6,
  "turn-lane:inbound|left->turn-lane:outbound|right": 12,
  "turn-lane:inbound|left->turn-lane:outbound|right-straight": 1,
  "turn-lane:inbound|left->turn-lane:outbound|straight": 2,
  "turn-lane:inbound|left-right-straight->bike-lane:outbound|green|road": 1,
  "turn-lane:inbound|left-right-straight->bus-lane:inbound|regular|typical": 3,
  "turn-lane:inbound|left-right-straight->bus-lane:inbound|shared|typical": 1,
  "turn-lane:inbound|left-right-straight->divider:big-tree": 2,
  "turn-lane:inbound|left-right-straight->divider:bollard": 1,
  "turn-lane:inbound|left-right-straight->divider:bush": 1,
  "turn-lane:inbound|left-right-straight->divider:dome": 1,
  "turn-lane:inbound|left-right-straight->divider:flowers": 1,
  "turn-lane:inbound|left-right-straight->divider:striped-buffer": 1,
  "turn-lane:inbound|left-right-straight->drive-lane:inbound|car": 5,
  "turn-lane:inbound|left-right-straight->drive-lane:outbound|car": 3,
  "turn-lane:inbound|left-right-straight->light-rail:inbound|regular": 1,
  "turn-lane:inbound|left-right-straight->parking-lane:outbound|right": 3,
  "turn-lane:inbound|left-right-straight->sidewalk-lamp:both|modern": 1,
  "turn-lane:inbound|left-right-straight->sidewalk-lamp:left|modern": 2,
  "turn-lane:inbound|left-right-straight->sidewalk-lamp:left|traditional": 2,
  "turn-lane:inbound|left-right-straight->sidewalk-tree:big": 2,
  "turn-lane:inbound|left-right-straight->sidewalk:dense": 1,
  "turn-lane:inbound|left-right-straight->turn-lane:outbound|left-right-straight": 10,
  "turn-lane:inbound|left-right-straight->turn-lane:outbound|left-straight": 4,
  "turn-lane:inbound|left-right-straight->turn-lane:outbound|straight": 1,
  "turn-lane:inbound|left-straight->bike-lane:inbound|colored": 2,
  "turn-lane:inbound|left-straight->bike-lane:inbound|regular": 4,
  "turn-lane:inbound|left-straight->bike-lane:inbound|regular|road": 1,
  "turn-lane:inbound|left-straight->bike-lane:outbound|green|road": 2,
  "turn-lane:inbound|left-straight->bus-lane:inbound|colored": 7,
  "turn-lane:inbound|left-straight->bus-lane:inbound|colored|typical": 1,
  "turn-lane:inbound|left-straight->bus-lane:inbound|regular": 318,
  "turn-lane:inbound|left-straight->bus-lane:inbound|regular|typical": 4,
  "turn-lane:inbound|left-straight->bus-lane:inbound|shared|typical": 2,
  "turn-lane:inbound|left-straight->divider:big-tree": 16,
  "turn-lane:inbound|left-straight->divider:bollard": 1,
  "turn-lane:inbound|left-straight->divider:bush": 4,
  "turn-lane:inbound|left-straight->divider:dome": 3,
  "turn-lane:inbound|left-straight->divider:flowers": 29,
  "turn-lane:inbound|left-straight->divider:median": 5,
  "turn-lane:inbound|left-straight->divider:palm-tree": 8,
  "turn-lane:inbound|left-straight->divider:planter-box": 1,
  "turn-lane:inbound|left-straight->divider:planting-strip": 9,
  "turn-lane:inbound|left-straight->divider:striped-buffer": 5,
  "turn-lane:inbound|left-straight->drive-lane:inbound|car": 151,
  "turn-lane:inbound|left-straight->drive-lane:inbound|sharrow": 3,
  "turn-lane:inbound|left-straight->drive-lane:inbound|truck": 24,
  "turn-lane:inbound|left-straight->drive-lane:outbound|car": 14,
  "turn-lane:inbound|left-straight->drive-lane:outbound|truck": 5,
  "turn-lane:inbound|left-straight->flex-zone-curb:sparse|right": 2,
  "turn-lane:inbound|left-straight->light-rail:inbound|colored": 1,
  "turn-lane:inbound|left-straight->light-rail:inbound|regular": 7,
  "turn-lane:inbound|left-straight->parking-lane:inbound|left": 2,
  "turn-lane:inbound|left-straight->parking-lane:inbound|right": 20,
  "turn-lane:inbound|left-straight->parking-lane:outbound|left": 1,
  "turn-lane:inbound|left-straight->parking-lane:outbound|right": 24,
  "turn-lane:inbound|left-straight->sidewalk-bike-rack:left|sidewalk": 2,
  "turn-lane:inbound|left-straight->sidewalk-lamp:both|modern": 3,
  "turn-lane:inbound|left-straight->sidewalk-lamp:both|traditional": 3,
  "turn-lane:inbound|left-straight->sidewalk-lamp:left|modern": 11,
  "turn-lane:inbound|left-straight->sidewalk-lamp:left|traditional": 2,
  "turn-lane:inbound|left-straight->sidewalk-tree:big": 6,
  "turn-lane:inbound|left-straight->sidewalk:dense": 2,
  "turn-lane:inbound|left-straight->streetcar:inbound|regular": 2,
  "turn-lane:inbound|left-straight->transit-shelter:left|light-rail": 2,
  "turn-lane:inbound|left-straight->transit-shelter:left|street-level": 1,
  "turn-lane:inbound|left-straight->turn-lane:inbound|left": 6,
  "turn-lane:inbound|left-straight->turn-lane:inbound|right": 16,
  "turn-lane:inbound|left-straight->turn-lane:inbound|right-straight": 46,
  "turn-lane:inbound|left-straight->turn-lane:inbound|shared": 5,
  "turn-lane:inbound|left-straight->turn-lane:inbound|straight": 18,
  "turn-lane:inbound|left-straight->turn-lane:outbound|left-straight": 1,
  "turn-lane:inbound|left-straight->turn-lane:outbound|right-straight": 17,
  "turn-lane:inbound|left-straight->turn-lane:outbound|shared": 9,
  "turn-lane:inbound|left|left->drive-lane:outbound|truck": 2,
  "turn-lane:inbound|right->bike-lane:inbound|colored": 7,
  "turn-lane:inbound|right->bike-lane:inbound|green|road": 1,
  "turn-lane:inbound|right->bike-lane:inbound|regular": 15,
  "turn-lane:inbound|right->bike-lane:inbound|regular|road": 11,
  "turn-lane:inbound|right->bike-lane:outbound|colored": 5,
  "turn-lane:inbound|right->bike-lane:outbound|green|road": 4,
  "turn-lane:inbound|right->bike-lane:outbound|regular": 1,
  "turn-lane:inbound|right->bus-lane:inbound|colored": 1,
  "turn-lane:inbound|right->bus-lane:inbound|regular": 12,
  "turn-lane:inbound|right->bus-lane:inbound|regular|typical": 13,
  "turn-lane:inbound|right->bus-lane:outbound|regular": 3,
  "turn-lane:inbound|right->bus-lane:outbound|regular|typical": 1,
  "turn-lane:inbound|right->divider:big-tree": 54,
  "turn-lane:inbound|right->divider:bollard": 21,
  "turn-lane:inbound|right->divider:bush": 40,
  "turn-lane:inbound|right->divider:dome": 8,
  "turn-lane:inbound|right->divider:flowers": 347,
  "turn-lane:inbound|right->divider:median": 47,
  "turn-lane:inbound|right->divider:palm-tree": 15,
  "turn-lane:inbound|right->divider:planter-box": 18,
  "turn-lane:inbound|right->divider:planting-strip": 9,
  "turn-lane:inbound|right->divider:striped-buffer": 25,
  "turn-lane:inbound|right->drive-lane:inbound|car": 62,
  "turn-lane:inbound|right->drive-lane:inbound|truck": 8,
  "turn-lane:inbound|right->drive-lane:outbound|car": 87,
  "turn-lane:inbound|right->drive-lane:outbound|sharrow": 1,
  "turn-lane:inbound|right->drive-lane:outbound|truck": 36,
  "turn-lane:inbound|right->flex-zone-curb:sparse|left": 3,
  "turn-lane:inbound|right->flex-zone:taxi|inbound|left": 1,
  "turn-lane:inbound|right->flex-zone:taxi|outbound|left": 1,
  "turn-lane:inbound|right->food-truck:left": 3,
  "turn-lane:inbound|right->light-rail:inbound|colored": 1,
  "turn-lane:inbound|right->light-rail:inbound|regular": 11,
  "turn-lane:inbound|right->parking-lane:inbound|left": 2,
  "turn-lane:inbound|right->parking-lane:inbound|right": 19,
  "turn-lane:inbound|right->parking-lane:outbound|right": 7,
  "turn-lane:inbound|right->parklet:left": 2,
  "turn-lane:inbound|right->parklet:right": 8,
  "turn-lane:inbound|right->scooter-drop-zone:right|sidewalk|empty": 1,
  "turn-lane:inbound|right->scooter:inbound|regular": 1,
  "turn-lane:inbound|right->sidewalk-bench:left": 2,
  "turn-lane:inbound|right->sidewalk-bench:right": 1,
  "turn-lane:inbound|right->sidewalk-bike-rack:left|sidewalk": 1,
  "turn-lane:inbound|right->sidewalk-bike-rack:right|sidewalk": 1,
  "turn-lane:inbound|right->sidewalk-lamp:both|modern": 18,
  "turn-lane:inbound|right->sidewalk-lamp:both|traditional": 5,
  "turn-lane:inbound|right->sidewalk-lamp:left|modern": 59,
  "turn-lane:inbound|right->sidewalk-lamp:left|traditional": 11,
  "turn-lane:inbound|right->sidewalk-lamp:right|modern": 13,
  "turn-lane:inbound|right->sidewalk-lamp:right|traditional": 2,
  "turn-lane:inbound|right->sidewalk-tree:big": 61,
  "turn-lane:inbound|right->sidewalk-tree:palm-tree": 2,
  "turn-lane:inbound|right->sidewalk-wayfinding:large": 1,
  "turn-lane:inbound|right->sidewalk-wayfinding:medium": 2,
  "turn-lane:inbound|right->sidewalk:dense": 30,
  "turn-lane:inbound|right->sidewalk:normal": 3,
  "turn-lane:inbound|right->sidewalk:sparse": 1,
  "turn-lane:inbound|right->streetcar:inbound|regular": 9,
  "turn-lane:inbound|right->streetcar:outbound|regular": 1,
  "turn-lane:inbound|right->transit-shelter:left|light-rail": 4,
  "turn-lane:inbound|right->transit-shelter:left|street-level": 7,
  "turn-lane:inbound|right->transit-shelter:right|light-rail": 1,
  "turn-lane:inbound|right->transit-shelter:right|street-level": 2,
  "turn-lane:inbound|right->turn-lane:inbound|left": 4,
  "turn-lane:inbound|right->turn-lane:inbound|left-straight": 1,
  "turn-lane:inbound|right->turn-lane:inbound|right": 40,
  "turn-lane:inbound|right->turn-lane:inbound|shared": 1,
  "turn-lane:inbound|right->turn-lane:inbound|straight": 1,
  "turn-lane:inbound|right->turn-lane:outbound|left": 17,
  "turn-lane:inbound|right->turn-lane:outbound|left-straight": 3,
  "turn-lane:inbound|right->turn-lane:outbound|right-straight": 1,
  "turn-lane:inbound|right->turn-lane:outbound|straight": 3,
  "turn-lane:inbound|right-straight->bike-lane:inbound|colored": 22,
  "turn-lane:inbound|right-straight->bike-lane:inbound|regular": 19,
  "turn-lane:inbound|right-straight->bike-lane:inbound|regular|road": 1,
  "turn-lane:inbound|right-straight->bike-lane:outbound|colored": 37,
  "turn-lane:inbound|right-straight->bike-lane:outbound|regular": 2,
  "turn-lane:inbound|right-straight->bus-lane:inbound|colored": 2,
  "turn-lane:inbound|right-straight->bus-lane:inbound|regular": 73,
  "turn-lane:inbound|right-straight->bus-lane:outbound|colored": 1,
  "turn-lane:inbound|right-straight->bus-lane:outbound|regular": 4,
  "turn-lane:inbound|right-straight->divider:big-tree": 141,
  "turn-lane:inbound|right-straight->divider:bollard": 42,
  "turn-lane:inbound|right-straight->divider:bush": 63,
  "turn-lane:inbound|right-straight->divider:dome": 40,
  "turn-lane:inbound|right-straight->divider:flowers": 12269,
  "turn-lane:inbound|right-straight->divider:median": 58,
  "turn-lane:inbound|right-straight->divider:palm-tree": 143,
  "turn-lane:inbound|right-straight->divider:planter-box": 5,
  "turn-lane:inbound|right-straight->divider:planting-strip": 24,
  "turn-lane:inbound|right-straight->divider:striped-buffer": 47,
  "turn-lane:inbound|right-straight->drive-lane:inbound|car": 28,
  "turn-lane:inbound|right-straight->drive-lane:inbound|truck": 42,
  "turn-lane:inbound|right-straight->drive-lane:outbound|car": 46,
  "turn-lane:inbound|right-straight->drive-lane:outbound|sharrow": 1,
  "turn-lane:inbound|right-straight->drive-lane:outbound|truck": 349,
  "turn-lane:inbound|right-straight->food-truck:left": 2,
  "turn-lane:inbound|right-straight->food-truck:right": 1,
  "turn-lane:inbound|right-straight->light-rail:inbound|colored": 3,
  "turn-lane:inbound|right-straight->light-rail:inbound|regular": 42,
  "turn-lane:inbound|right-straight->light-rail:outbound|regular": 14,
  "turn-lane:inbound|right-straight->parking-lane:inbound|left": 5,
  "turn-lane:inbound|right-straight->parking-lane:inbound|right": 18,
  "turn-lane:inbound|right-straight->parking-lane:outbound|left": 4,
  "turn-lane:inbound|right-straight->parking-lane:outbound|right": 43,
  "turn-lane:inbound|right-straight->parking-lane:sideways|right": 3,
  "turn-lane:inbound|right-straight->parklet:left": 1,
  "turn-lane:inbound|right-straight->parklet:right": 12,
  "turn-lane:inbound|right-straight->sidewalk-bench:right": 2,
  "turn-lane:inbound|right-straight->sidewalk-bike-rack:left|sidewalk-parallel": 4,
  "turn-lane:inbound|right-straight->sidewalk-bike-rack:right|sidewalk": 1,
  "turn-lane:inbound|right-straight->sidewalk-lamp:both|modern": 28,
  "turn-lane:inbound|right-straight->sidewalk-lamp:both|traditional": 5,
  "turn-lane:inbound|right-straight->sidewalk-lamp:left|modern": 35,
  "turn-lane:inbound|right-straight->sidewalk-lamp:left|traditional": 4,
  "turn-lane:inbound|right-straight->sidewalk-lamp:right|modern": 2,
  "turn-lane:inbound|right-straight->sidewalk-tree:big": 78,
  "turn-lane:inbound|right-straight->sidewalk-tree:palm-tree": 13,
  "turn-lane:inbound|right-straight->sidewalk-wayfinding:large": 4,
  "turn-lane:inbound|right-straight->sidewalk-wayfinding:medium": 6,
  "turn-lane:inbound|right-straight->sidewalk:dense": 18,
  "turn-lane:inbound|right-straight->sidewalk:normal": 7,
  "turn-lane:inbound|right-straight->streetcar:inbound|regular": 24,
  "turn-lane:inbound|right-straight->streetcar:outbound|colored": 1,
  "turn-lane:inbound|right-straight->streetcar:outbound|regular": 15,
  "turn-lane:inbound|right-straight->transit-shelter:left|light-rail": 7,
  "turn-lane:inbound|right-straight->transit-shelter:left|street-level": 6,
  "turn-lane:inbound|right-straight->transit-shelter:right|light-rail": 1,
  "turn-lane:inbound|right-straight->transit-shelter:right|street-level": 6,
  "turn-lane:inbound|right-straight->turn-lane:inbound|both": 1,
  "turn-lane:inbound|right-straight->turn-lane:inbound|left": 2,
  "turn-lane:inbound|right-straight->turn-lane:inbound|left-straight": 7,
  "turn-lane:inbound|right-straight->turn-lane:inbound|right": 10,
  "turn-lane:inbound|right-straight->turn-lane:inbound|straight": 1,
  "turn-lane:inbound|right-straight->turn-lane:outbound|both": 2,
  "turn-lane:inbound|right-straight->turn-lane:outbound|left": 2,
  "turn-lane:inbound|right-straight->turn-lane:outbound|left-straight": 34,
  "turn-lane:inbound|right-straight->turn-lane:outbound|right-straight": 3,
  "turn-lane:inbound|shared->bikeshare:left|road": 1,
  "turn-lane:inbound|shared->bus-lane:inbound|regular": 4,
  "turn-lane:inbound|shared->bus-lane:outbound|regular": 3,
  "turn-lane:inbound|shared->bus-lane:outbound|regular|typical": 1,
  "turn-lane:inbound|shared->bus-lane:outbound|shared|typical": 1,
  "turn-lane:inbound|shared->divider:big-tree": 1,
  "turn-lane:inbound|shared->divider:dome": 1,
  "turn-lane:inbound|shared->divider:flowers": 30,
  "turn-lane:inbound|shared->divider:median": 1,
  "turn-lane:inbound|shared->divider:palm-tree": 1,
  "turn-lane:inbound|shared->divider:planting-strip": 1,
  "turn-lane:inbound|shared->drive-lane:inbound|car": 17,
  "turn-lane:inbound|shared->drive-lane:inbound|sharrow": 1,
  "turn-lane:inbound|shared->drive-lane:inbound|truck": 1,
  "turn-lane:inbound|shared->drive-lane:outbound|car": 194,
  "turn-lane:inbound|shared->drive-lane:outbound|sharrow": 8,
  "turn-lane:inbound|shared->drive-lane:outbound|truck": 66,
  "turn-lane:inbound|shared->parking-lane:inbound|right": 1,
  "turn-lane:inbound|shared->parking-lane:outbound|right": 5,
  "turn-lane:inbound|shared->sidewalk-bike-rack:left|sidewalk-parallel": 1,
  "turn-lane:inbound|shared->sidewalk-lamp:right|modern": 1,
  "turn-lane:inbound|shared->sidewalk-wayfinding:medium": 1,
  "turn-lane:inbound|shared->streetcar:inbound|regular": 2,
  "turn-lane:inbound|shared->streetcar:outbound|regular": 1,
  "turn-lane:inbound|shared->turn-lane:inbound|right-straight": 1,
  "turn-lane:inbound|shared->turn-lane:outbound|left": 1,
  "turn-lane:inbound|shared->turn-lane:outbound|right": 1,
  "turn-lane:inbound|shared->turn-lane:outbound|right-straight": 4,
  "turn-lane:inbound|shared->turn-lane:outbound|straight": 8,
  "turn-lane:inbound|straight->bike-lane:inbound|colored": 6,
  "turn-lane:inbound|straight->bike-lane:inbound|green|sidewalk": 1,
  "turn-lane:inbound|straight->bike-lane:inbound|regular": 4,
  "turn-lane:inbound|straight->bike-lane:inbound|regular|road": 1,
  "turn-lane:inbound|straight->bike-lane:outbound|colored": 5,
  "turn-lane:inbound|straight->bike-lane:outbound|green|road": 1,
  "turn-lane:inbound|straight->bus-lane:inbound|colored": 7,
  "turn-lane:inbound|straight->bus-lane:inbound|regular": 7,
  "turn-lane:inbound|straight->bus-lane:inbound|regular|typical": 4,
  "turn-lane:inbound|straight->bus-lane:outbound|regular|typical": 2,
  "turn-lane:inbound|straight->divider:big-tree": 36,
  "turn-lane:inbound|straight->divider:bollard": 5,
  "turn-lane:inbound|straight->divider:bush": 18,
  "turn-lane:inbound|straight->divider:dome": 12,
  "turn-lane:inbound|straight->divider:flowers": 93,
  "turn-lane:inbound|straight->divider:median": 16,
  "turn-lane:inbound|straight->divider:palm-tree": 19,
  "turn-lane:inbound|straight->divider:planter-box": 5,
  "turn-lane:inbound|straight->divider:planting-strip": 5,
  "turn-lane:inbound|straight->divider:striped-buffer": 14,
  "turn-lane:inbound|straight->drive-lane:inbound|car": 36,
  "turn-lane:inbound|straight->drive-lane:inbound|truck": 1,
  "turn-lane:inbound|straight->drive-lane:outbound|car": 40,
  "turn-lane:inbound|straight->drive-lane:outbound|sharrow": 1,
  "turn-lane:inbound|straight->drive-lane:outbound|truck": 39,
  "turn-lane:inbound|straight->flex-zone-curb:sparse|left": 1,
  "turn-lane:inbound|straight->light-rail:inbound|colored": 9,
  "turn-lane:inbound|straight->light-rail:inbound|regular": 10,
  "turn-lane:inbound|straight->light-rail:outbound|colored": 1,
  "turn-lane:inbound|straight->light-rail:outbound|grass": 1,
  "turn-lane:inbound|straight->light-rail:outbound|regular": 2,
  "turn-lane:inbound|straight->parking-lane:inbound|left": 6,
  "turn-lane:inbound|straight->parking-lane:inbound|right": 12,
  "turn-lane:inbound|straight->parking-lane:outbound|left": 3,
  "turn-lane:inbound|straight->parking-lane:outbound|right": 30,
  "turn-lane:inbound|straight->parking-lane:sideways|left": 1,
  "turn-lane:inbound|straight->parking-lane:sideways|right": 3,
  "turn-lane:inbound|straight->sidewalk-bench:center": 2,
  "turn-lane:inbound|straight->sidewalk-bench:left": 1,
  "turn-lane:inbound|straight->sidewalk-lamp:both|modern": 5,
  "turn-lane:inbound|straight->sidewalk-lamp:both|traditional": 4,
  "turn-lane:inbound|straight->sidewalk-lamp:left|modern": 17,
  "turn-lane:inbound|straight->sidewalk-lamp:left|traditional": 3,
  "turn-lane:inbound|straight->sidewalk-lamp:right|modern": 1,
  "turn-lane:inbound|straight->sidewalk-tree:big": 19,
  "turn-lane:inbound|straight->sidewalk-tree:palm-tree": 2,
  "turn-lane:inbound|straight->sidewalk-wayfinding:large": 1,
  "turn-lane:inbound|straight->sidewalk:dense": 1,
  "turn-lane:inbound|straight->sidewalk:empty": 1,
  "turn-lane:inbound|straight->sidewalk:sparse": 2,
  "turn-lane:inbound|straight->streetcar:inbound|regular": 34,
  "turn-lane:inbound|straight->transit-shelter:left|light-rail": 2,
  "turn-lane:inbound|straight->transit-shelter:left|street-level": 6,
  "turn-lane:inbound|straight->transit-shelter:right|street-level": 3,
  "turn-lane:inbound|straight->turn-lane:inbound|both": 1,
  "turn-lane:inbound|straight->turn-lane:inbound|left": 1,
  "turn-lane:inbound|straight->turn-lane:inbound|left-straight": 1,
  "turn-lane:inbound|straight->turn-lane:inbound|right": 23,
  "turn-lane:inbound|straight->turn-lane:inbound|right-straight": 15,
  "turn-lane:inbound|straight->turn-lane:inbound|shared": 5,
  "turn-lane:inbound|straight->turn-lane:inbound|straight": 45,
  "turn-lane:inbound|straight->turn-lane:outbound|left": 3,
  "turn-lane:inbound|straight->turn-lane:outbound|left-straight": 1,
  "turn-lane:inbound|straight->turn-lane:outbound|right": 4,
  "turn-lane:inbound|straight->turn-lane:outbound|right-straight": 1,
  "turn-lane:inbound|straight->turn-lane:outbound|shared": 2,
  "turn-lane:inbound|straight->turn-lane:outbound|straight": 33,
  "turn-lane:left->parking-lane:outbound|left": 2,
  "turn-lane:left-right-straight|inbound->parking-lane:outbound|right": 1,
  "turn-lane:left-right-straight|outbound->drive-lane:inbound|car": 1,
  "turn-lane:left-straight->drive-lane:outbound|car": 1,
  "turn-lane:left-straight->turn-lane:left-straight|left": 1,
  "turn-lane:left-straight|left->divider:planter-box": 1,
  "turn-lane:left-straight|outbound->divider:planter-box": 1,
  "turn-lane:left-straight|shared->divider:planter-box": 1,
  "turn-lane:left|inbound->parking-lane:outbound|right": 1,
  "turn-lane:left|outbound->bike-lane:outbound|green|road": 1,
  "turn-lane:left|outbound->parking-lane:inbound|left": 1,
  "turn-lane:left|outbound->parking-lane:outbound|right": 2,
  "turn-lane:left|outbound->sidewalk:normal": 1,
  "turn-lane:outbound->divider:planter-box": 2,
  "turn-lane:outbound->divider:planting-strip": 1,
  "turn-lane:outbound->parking-lane:inbound|right": 1,
  "turn-lane:outbound->turn-lane:outbound|straight": 1,
  "turn-lane:outbound|both->bike-lane:inbound|regular|road": 1,
  "turn-lane:outbound|both->bike-lane:outbound|colored": 8,
  "turn-lane:outbound|both->bike-lane:outbound|regular": 5,
  "turn-lane:outbound|both->bus-lane:inbound|regular": 1,
  "turn-lane:outbound|both->bus-lane:inbound|regular|typical": 1,
  "turn-lane:outbound|both->bus-lane:outbound|regular": 9,
  "turn-lane:outbound|both->bus-lane:outbound|regular|typical": 1,
  "turn-lane:outbound|both->divider:big-tree": 1,
  "turn-lane:outbound|both->divider:bush": 5,
  "turn-lane:outbound|both->divider:flowers": 4,
  "turn-lane:outbound|both->divider:median": 1,
  "turn-lane:outbound|both->divider:planter-box": 2,
  "turn-lane:outbound|both->divider:planting-strip": 1,
  "turn-lane:outbound|both->drive-lane:inbound|car": 1,
  "turn-lane:outbound|both->drive-lane:outbound|car": 6,
  "turn-lane:outbound|both->drive-lane:outbound|sharrow": 1,
  "turn-lane:outbound|both->drive-lane:outbound|truck": 34,
  "turn-lane:outbound|both->light-rail:outbound|colored": 1,
  "turn-lane:outbound|both->parking-lane:inbound|right": 1,
  "turn-lane:outbound|both->parking-lane:outbound|left": 4,
  "turn-lane:outbound|both->parking-lane:outbound|right": 44,
  "turn-lane:outbound|both->parklet:right": 1,
  "turn-lane:outbound|both->sidewalk-lamp:left|modern": 4,
  "turn-lane:outbound|both->sidewalk-lamp:left|traditional": 1,
  "turn-lane:outbound|both->sidewalk-lamp:right|modern": 2,
  "turn-lane:outbound|both->sidewalk-tree:big": 2,
  "turn-lane:outbound|both->sidewalk-tree:palm-tree": 2,
  "turn-lane:outbound|both->sidewalk:dense": 1,
  "turn-lane:outbound|both->turn-lane:outbound|left": 2,
  "turn-lane:outbound|left->bike-lane:inbound|colored": 3,
  "turn-lane:outbound|left->bike-lane:inbound|regular": 1,
  "turn-lane:outbound|left->bike-lane:inbound|regular|road": 1,
  "turn-lane:outbound|left->bike-lane:outbound|colored": 370,
  "turn-lane:outbound|left->bike-lane:outbound|green|road": 2,
  "turn-lane:outbound|left->bike-lane:outbound|regular": 8,
  "turn-lane:outbound|left->bus-lane:inbound|regular": 2,
  "turn-lane:outbound|left->bus-lane:outbound|colored": 6,
  "turn-lane:outbound|left->bus-lane:outbound|colored|typical": 2,
  "turn-lane:outbound|left->bus-lane:outbound|regular": 269,
  "turn-lane:outbound|left->bus-lane:outbound|regular|typical": 6,
  "turn-lane:outbound|left->divider:big-tree": 1,
  "turn-lane:outbound|left->divider:bollard": 3,
  "turn-lane:outbound|left->divider:bush": 2,
  "turn-lane:outbound|left->divider:dome": 3,
  "turn-lane:outbound|left->divider:flowers": 21,
  "turn-lane:outbound|left->divider:median": 13,
  "turn-lane:outbound|left->divider:palm-tree": 1,
  "turn-lane:outbound|left->divider:planter-box": 2,
  "turn-lane:outbound|left->divider:striped-buffer": 11,
  "turn-lane:outbound|left->drive-lane:inbound|car": 9,
  "turn-lane:outbound|left->drive-lane:inbound|truck": 7,
  "turn-lane:outbound|left->drive-lane:outbound|car": 385,
  "turn-lane:outbound|left->drive-lane:outbound|sharrow": 10,
  "turn-lane:outbound|left->drive-lane:outbound|truck": 2156,
  "turn-lane:outbound|left->flex-zone-curb:sparse|left": 1,
  "turn-lane:outbound|left->light-rail:outbound|colored": 1,
  "turn-lane:outbound|left->light-rail:outbound|regular": 38,
  "turn-lane:outbound|left->parking-lane:inbound|left": 8,
  "turn-lane:outbound|left->parking-lane:inbound|right": 12,
  "turn-lane:outbound|left->parking-lane:outbound|left": 3,
  "turn-lane:outbound|left->parking-lane:outbound|right": 45,
  "turn-lane:outbound|left->parklet:right": 4,
  "turn-lane:outbound|left->sidewalk-bike-rack:left|sidewalk": 2,
  "turn-lane:outbound|left->sidewalk-bike-rack:right|sidewalk-parallel": 1,
  "turn-lane:outbound|left->sidewalk-lamp:both|modern": 12,
  "turn-lane:outbound|left->sidewalk-lamp:left|modern": 70,
  "turn-lane:outbound|left->sidewalk-lamp:left|traditional": 5,
  "turn-lane:outbound|left->sidewalk-tree:big": 117,
  "turn-lane:outbound|left->sidewalk-tree:palm-tree": 1,
  "turn-lane:outbound|left->sidewalk-wayfinding:large": 2,
  "turn-lane:outbound|left->sidewalk-wayfinding:medium": 5,
  "turn-lane:outbound|left->sidewalk:dense": 3,
  "turn-lane:outbound|left->sidewalk:normal": 8,
  "turn-lane:outbound|left->sidewalk:sparse": 1,
  "turn-lane:outbound|left->streetcar:inbound|regular": 1,
  "turn-lane:outbound|left->streetcar:outbound|colored": 2,
  "turn-lane:outbound|left->streetcar:outbound|regular": 23,
  "turn-lane:outbound|left->transit-shelter:left|street-level": 1,
  "turn-lane:outbound|left->transit-shelter:right|street-level": 4,
  "turn-lane:outbound|left->turn-lane:inbound|left": 1,
  "turn-lane:outbound|left->turn-lane:outbound|both": 2,
  "turn-lane:outbound|left->turn-lane:outbound|left": 54,
  "turn-lane:outbound|left->turn-lane:outbound|left-straight": 11,
  "turn-lane:outbound|left->turn-lane:outbound|right": 10,
  "turn-lane:outbound|left->turn-lane:outbound|right-straight": 15,
  "turn-lane:outbound|left->turn-lane:outbound|straight": 16,
  "turn-lane:outbound|left-right-straight->bike-lane:inbound|green|road": 1,
  "turn-lane:outbound|left-right-straight->bike-lane:inbound|regular|road": 2,
  "turn-lane:outbound|left-right-straight->bike-lane:outbound|green|road": 8,
  "turn-lane:outbound|left-right-straight->bike-lane:outbound|regular|road": 2,
  "turn-lane:outbound|left-right-straight->bus-lane:inbound|regular|typical": 1,
  "turn-lane:outbound|left-right-straight->bus-lane:outbound|colored|typical": 1,
  "turn-lane:outbound|left-right-straight->bus-lane:outbound|regular|typical": 1,
  "turn-lane:outbound|left-right-straight->divider:bollard": 2,
  "turn-lane:outbound|left-right-straight->divider:bush": 6,
  "turn-lane:outbound|left-right-straight->divider:flowers": 5,
  "turn-lane:outbound|left-right-straight->divider:median": 1,
  "turn-lane:outbound|left-right-straight->divider:palm-tree": 3,
  "turn-lane:outbound|left-right-straight->divider:planter-box": 7,
  "turn-lane:outbound|left-right-straight->divider:planting-strip": 1,
  "turn-lane:outbound|left-right-straight->divider:striped-buffer": 2,
  "turn-lane:outbound|left-right-straight->drive-lane:inbound|car": 1,
  "turn-lane:outbound|left-right-straight->drive-lane:inbound|truck": 1,
  "turn-lane:outbound|left-right-straight->drive-lane:outbound|car": 6,
  "turn-lane:outbound|left-right-straight->light-rail:outbound|regular": 1,
  "turn-lane:outbound|left-right-straight->parking-lane:inbound|right": 2,
  "turn-lane:outbound|left-right-straight->parking-lane:outbound|left": 6,
  "turn-lane:outbound|left-right-straight->parking-lane:outbound|right": 41,
  "turn-lane:outbound|left-right-straight->parklet:right": 2,
  "turn-lane:outbound|left-right-straight->sidewalk-lamp:both|pride": 17,
  "turn-lane:outbound|left-right-straight->sidewalk-lamp:both|traditional": 1,
  "turn-lane:outbound|left-right-straight->sidewalk-lamp:left|modern": 6,
  "turn-lane:outbound|left-right-straight->sidewalk-lamp:left|traditional": 3,
  "turn-lane:outbound|left-right-straight->sidewalk-tree:big": 4,
  "turn-lane:outbound|left-right-straight->sidewalk:normal": 1,
  "turn-lane:outbound|left-right-straight->turn-lane:inbound|left-right-straight": 2,
  "turn-lane:outbound|left-straight->bike-lane:inbound|colored": 1,
  "turn-lane:outbound|left-straight->bike-lane:inbound|red|road": 1,
  "turn-lane:outbound|left-straight->bike-lane:inbound|regular|road": 6,
  "turn-lane:outbound|left-straight->bike-lane:outbound|colored": 30,
  "turn-lane:outbound|left-straight->bike-lane:outbound|green|road": 89,
  "turn-lane:outbound|left-straight->bike-lane:outbound|green|road|inbound": 1,
  "turn-lane:outbound|left-straight->bike-lane:outbound|green|sidewalk": 1,
  "turn-lane:outbound|left-straight->bike-lane:outbound|red|sidewalk": 1,
  "turn-lane:outbound|left-straight->bike-lane:outbound|regular": 4,
  "turn-lane:outbound|left-straight->bike-lane:outbound|regular|road": 5,
  "turn-lane:outbound|left-straight->bike-lane:outbound|regular|road|inbound": 1,
  "turn-lane:outbound|left-straight->bikeshare:left|road": 1,
  "turn-lane:outbound|left-straight->brt-lane:outbound|regular": 1,
  "turn-lane:outbound|left-straight->bus-lane:inbound|colored|typical": 1,
  "turn-lane:outbound|left-straight->bus-lane:inbound|regular": 1,
  "turn-lane:outbound|left-straight->bus-lane:inbound|regular|typical": 6,
  "turn-lane:outbound|left-straight->bus-lane:inbound|shared|typical": 25,
  "turn-lane:outbound|left-straight->bus-lane:outbound|colored": 2,
  "turn-lane:outbound|left-straight->bus-lane:outbound|colored|typical": 3,
  "turn-lane:outbound|left-straight->bus-lane:outbound|regular": 22,
  "turn-lane:outbound|left-straight->bus-lane:outbound|regular|typical": 28,
  "turn-lane:outbound|left-straight->bus-lane:outbound|shared|typical": 10,
  "turn-lane:outbound|left-straight->divider:big-tree": 13,
  "turn-lane:outbound|left-straight->divider:bollard": 8,
  "turn-lane:outbound|left-straight->divider:bush": 39,
  "turn-lane:outbound|left-straight->divider:dome": 5,
  "turn-lane:outbound|left-straight->divider:flowers": 19,
  "turn-lane:outbound|left-straight->divider:median": 10,
  "turn-lane:outbound|left-straight->divider:palm-tree": 7,
  "turn-lane:outbound|left-straight->divider:planter-box": 281,
  "turn-lane:outbound|left-straight->divider:planting-strip": 4,
  "turn-lane:outbound|left-straight->divider:striped-buffer": 8,
  "turn-lane:outbound|left-straight->drive-lane:inbound|car": 40,
  "turn-lane:outbound|left-straight->drive-lane:inbound|truck": 5,
  "turn-lane:outbound|left-straight->drive-lane:outbound|car": 267,
  "turn-lane:outbound|left-straight->drive-lane:outbound|sharrow": 7,
  "turn-lane:outbound|left-straight->drive-lane:outbound|truck": 115,
  "turn-lane:outbound|left-straight->flex-zone-curb:sparse|left": 9,
  "turn-lane:outbound|left-straight->flex-zone:taxi|inbound|left": 1,
  "turn-lane:outbound|left-straight->flex-zone:taxi|outbound|left": 2,
  "turn-lane:outbound|left-straight->flex-zone:taxi|outbound|right": 3,
  "turn-lane:outbound|left-straight->food-truck:left": 4,
  "turn-lane:outbound|left-straight->light-rail:inbound|regular": 2,
  "turn-lane:outbound|left-straight->light-rail:outbound|colored": 5,
  "turn-lane:outbound|left-straight->light-rail:outbound|regular": 14,
  "turn-lane:outbound|left-straight->parking-lane:angled-front-right|right": 1,
  "turn-lane:outbound|left-straight->parking-lane:angled-rear-left": 1,
  "turn-lane:outbound|left-straight->parking-lane:angled-rear-right|right": 3,
  "turn-lane:outbound|left-straight->parking-lane:inbound|left": 25,
  "turn-lane:outbound|left-straight->parking-lane:inbound|right": 52,
  "turn-lane:outbound|left-straight->parking-lane:outbound|left": 24,
  "turn-lane:outbound|left-straight->parking-lane:outbound|right": 13351,
  "turn-lane:outbound|left-straight->parking-lane:sideways|right": 2,
  "turn-lane:outbound|left-straight->parklet:right": 3,
  "turn-lane:outbound|left-straight->scooter-drop-zone:right|sidewalk|empty": 1,
  "turn-lane:outbound|left-straight->scooter:inbound|regular": 1,
  "turn-lane:outbound|left-straight->scooter:outbound|regular": 4,
  "turn-lane:outbound|left-straight->sidewalk-bike-rack:left|road": 1,
  "turn-lane:outbound|left-straight->sidewalk-bike-rack:left|sidewalk": 5,
  "turn-lane:outbound|left-straight->sidewalk-lamp:both|modern": 1,
  "turn-lane:outbound|left-straight->sidewalk-lamp:both|traditional": 3,
  "turn-lane:outbound|left-straight->sidewalk-lamp:left|modern": 60,
  "turn-lane:outbound|left-straight->sidewalk-lamp:left|pride": 1,
  "turn-lane:outbound|left-straight->sidewalk-lamp:left|traditional": 19,
  "turn-lane:outbound|left-straight->sidewalk-lamp:right|modern": 14,
  "turn-lane:outbound|left-straight->sidewalk-tree:big": 45,
  "turn-lane:outbound|left-straight->sidewalk-tree:palm-tree": 3,
  "turn-lane:outbound|left-straight->sidewalk:dense": 10,
  "turn-lane:outbound|left-straight->sidewalk:normal": 15,
  "turn-lane:outbound|left-straight->sidewalk:sparse": 4,
  "turn-lane:outbound|left-straight->streetcar:inbound|regular": 1,
  "turn-lane:outbound|left-straight->streetcar:outbound|colored": 4,
  "turn-lane:outbound|left-straight->streetcar:outbound|regular": 7,
  "turn-lane:outbound|left-straight->transit-shelter:left|street-level": 3,
  "turn-lane:outbound|left-straight->transit-shelter:right|street-level": 1,
  "turn-lane:outbound|left-straight->turn-lane:inbound|right": 1,
  "turn-lane:outbound|left-straight->turn-lane:inbound|right-straight": 3,
  "turn-lane:outbound|left-straight->turn-lane:outbound|left": 5,
  "turn-lane:outbound|left-straight->turn-lane:outbound|left-straight": 1,
  "turn-lane:outbound|left-straight->turn-lane:outbound|right": 17,
  "turn-lane:outbound|left-straight->turn-lane:outbound|right-straight": 53,
  "turn-lane:outbound|left-straight->turn-lane:outbound|straight": 41,
  "turn-lane:outbound|left|right->drive-lane:outbound|truck": 1,
  "turn-lane:outbound|left|straight->drive-lane:outbound|truck": 2,
  "turn-lane:outbound|right->bike-lane:inbound|regular": 1,
  "turn-lane:outbound|right->bike-lane:inbound|regular|road": 3,
  "turn-lane:outbound|right->bike-lane:inbound|regular|sidewalk": 2,
  "turn-lane:outbound|right->bike-lane:outbound|colored": 36,
  "turn-lane:outbound|right->bike-lane:outbound|green|road": 6,
  "turn-lane:outbound|right->bike-lane:outbound|green|sidewalk": 1,
  "turn-lane:outbound|right->bike-lane:outbound|red|road": 1,
  "turn-lane:outbound|right->bike-lane:outbound|regular": 7,
  "turn-lane:outbound|right->bike-lane:outbound|regular|road": 9,
  "turn-lane:outbound|right->bus-lane:inbound|shared|typical": 1,
  "turn-lane:outbound|right->bus-lane:outbound|colored": 3,
  "turn-lane:outbound|right->bus-lane:outbound|colored|typical": 1,
  "turn-lane:outbound|right->bus-lane:outbound|regular": 9,
  "turn-lane:outbound|right->bus-lane:outbound|regular|typical": 10,
  "turn-lane:outbound|right->bus-lane:outbound|shared|typical": 3,
  "turn-lane:outbound|right->divider:big-tree": 19,
  "turn-lane:outbound|right->divider:bollard": 10,
  "turn-lane:outbound|right->divider:bush": 10,
  "turn-lane:outbound|right->divider:dome": 2,
  "turn-lane:outbound|right->divider:flowers": 89,
  "turn-lane:outbound|right->divider:median": 12,
  "turn-lane:outbound|right->divider:palm-tree": 2,
  "turn-lane:outbound|right->divider:planter-box": 12,
  "turn-lane:outbound|right->divider:planting-strip": 2,
  "turn-lane:outbound|right->divider:striped-buffer": 7,
  "turn-lane:outbound|right->drive-lane:inbound|car": 7,
  "turn-lane:outbound|right->drive-lane:inbound|truck": 2,
  "turn-lane:outbound|right->drive-lane:outbound|car": 12,
  "turn-lane:outbound|right->drive-lane:outbound|sharrow": 4,
  "turn-lane:outbound|right->drive-lane:outbound|truck": 20,
  "turn-lane:outbound|right->flex-zone:rideshare|outbound|right": 1,
  "turn-lane:outbound|right->flex-zone:taxi|outbound|right": 2,
  "turn-lane:outbound|right->food-truck:right": 2,
  "turn-lane:outbound|right->light-rail:outbound|colored": 1,
  "turn-lane:outbound|right->light-rail:outbound|grass": 1,
  "turn-lane:outbound|right->light-rail:outbound|regular": 1,
  "turn-lane:outbound|right->parking-lane:inbound|left": 4,
  "turn-lane:outbound|right->parking-lane:inbound|right": 21,
  "turn-lane:outbound|right->parking-lane:outbound|left": 2,
  "turn-lane:outbound|right->parking-lane:outbound|right": 43,
  "turn-lane:outbound|right->parking-lane:sideways|right": 2,
  "turn-lane:outbound|right->parklet:right": 4,
  "turn-lane:outbound|right->sidewalk-bench:center": 1,
  "turn-lane:outbound|right->sidewalk-bench:left": 4,
  "turn-lane:outbound|right->sidewalk-bench:right": 4,
  "turn-lane:outbound|right->sidewalk-bike-rack:left|sidewalk": 1,
  "turn-lane:outbound|right->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "turn-lane:outbound|right->sidewalk-lamp:both|modern": 7,
  "turn-lane:outbound|right->sidewalk-lamp:both|traditional": 2,
  "turn-lane:outbound|right->sidewalk-lamp:left|modern": 82,
  "turn-lane:outbound|right->sidewalk-lamp:left|pride": 1,
  "turn-lane:outbound|right->sidewalk-lamp:left|traditional": 9,
  "turn-lane:outbound|right->sidewalk-lamp:right|modern": 2,
  "turn-lane:outbound|right->sidewalk-lamp:right|traditional": 1,
  "turn-lane:outbound|right->sidewalk-tree:big": 37,
  "turn-lane:outbound|right->sidewalk-tree:palm-tree": 4,
  "turn-lane:outbound|right->sidewalk-wayfinding:medium": 6,
  "turn-lane:outbound|right->sidewalk:dense": 7,
  "turn-lane:outbound|right->sidewalk:empty": 2,
  "turn-lane:outbound|right->sidewalk:normal": 19,
  "turn-lane:outbound|right->sidewalk:sparse": 1,
  "turn-lane:outbound|right->streetcar:inbound|regular": 1,
  "turn-lane:outbound|right->streetcar:outbound|regular": 1,
  "turn-lane:outbound|right->transit-shelter:right|street-level": 4,
  "turn-lane:outbound|right->turn-lane:inbound|left": 1,
  "turn-lane:outbound|right->turn-lane:inbound|left-straight": 1,
  "turn-lane:outbound|right->turn-lane:inbound|right": 1,
  "turn-lane:outbound|right->turn-lane:outbound|left": 3,
  "turn-lane:outbound|right->turn-lane:outbound|right": 60,
  "turn-lane:outbound|right->turn-lane:outbound|straight": 1,
  "turn-lane:outbound|right-straight->bike-lane:inbound|colored": 1,
  "turn-lane:outbound|right-straight->bike-lane:inbound|regular": 1,
  "turn-lane:outbound|right-straight->bike-lane:inbound|regular|road": 5,
  "turn-lane:outbound|right-straight->bike-lane:outbound|colored": 31,
  "turn-lane:outbound|right-straight->bike-lane:outbound|green|road": 23,
  "turn-lane:outbound|right-straight->bike-lane:outbound|red|road": 1,
  "turn-lane:outbound|right-straight->bike-lane:outbound|regular": 8,
  "turn-lane:outbound|right-straight->bike-lane:outbound|regular|road": 6,
  "turn-lane:outbound|right-straight->bikeshare:left|road": 1,
  "turn-lane:outbound|right-straight->bus-lane:inbound|regular|typical": 2,
  "turn-lane:outbound|right-straight->bus-lane:inbound|shared|typical": 4,
  "turn-lane:outbound|right-straight->bus-lane:outbound|colored": 4,
  "turn-lane:outbound|right-straight->bus-lane:outbound|colored|typical": 1,
  "turn-lane:outbound|right-straight->bus-lane:outbound|regular": 11,
  "turn-lane:outbound|right-straight->bus-lane:outbound|regular|typical": 3,
  "turn-lane:outbound|right-straight->bus-lane:outbound|shared|typical": 1,
  "turn-lane:outbound|right-straight->divider:big-tree": 16,
  "turn-lane:outbound|right-straight->divider:bollard": 10,
  "turn-lane:outbound|right-straight->divider:bush": 919,
  "turn-lane:outbound|right-straight->divider:dome": 5,
  "turn-lane:outbound|right-straight->divider:flowers": 38,
  "turn-lane:outbound|right-straight->divider:median": 15,
  "turn-lane:outbound|right-straight->divider:palm-tree": 16,
  "turn-lane:outbound|right-straight->divider:planter-box": 15,
  "turn-lane:outbound|right-straight->divider:planting-strip": 8,
  "turn-lane:outbound|right-straight->divider:striped-buffer": 15,
  "turn-lane:outbound|right-straight->drive-lane:inbound|car": 50,
  "turn-lane:outbound|right-straight->drive-lane:outbound|car": 4,
  "turn-lane:outbound|right-straight->drive-lane:outbound|truck": 27,
  "turn-lane:outbound|right-straight->flex-zone-curb:sparse|left": 2,
  "turn-lane:outbound|right-straight->flex-zone:rideshare|outbound|right": 17,
  "turn-lane:outbound|right-straight->flex-zone:taxi|outbound|left": 1,
  "turn-lane:outbound|right-straight->light-rail:inbound|regular": 2,
  "turn-lane:outbound|right-straight->light-rail:outbound|colored": 3,
  "turn-lane:outbound|right-straight->light-rail:outbound|regular": 15,
  "turn-lane:outbound|right-straight->parking-lane:angled-front-right|right": 1,
  "turn-lane:outbound|right-straight->parking-lane:angled-rear-right|right": 1,
  "turn-lane:outbound|right-straight->parking-lane:inbound|left": 1,
  "turn-lane:outbound|right-straight->parking-lane:inbound|right": 9,
  "turn-lane:outbound|right-straight->parking-lane:outbound|left": 15,
  "turn-lane:outbound|right-straight->parking-lane:outbound|right": 78,
  "turn-lane:outbound|right-straight->parking-lane:sideways|right": 2,
  "turn-lane:outbound|right-straight->parklet:left": 1,
  "turn-lane:outbound|right-straight->parklet:right": 2,
  "turn-lane:outbound|right-straight->scooter:outbound|green": 1,
  "turn-lane:outbound|right-straight->sidewalk-bike-rack:left|sidewalk": 1,
  "turn-lane:outbound|right-straight->sidewalk-bike-rack:left|sidewalk-parallel": 2,
  "turn-lane:outbound|right-straight->sidewalk-lamp:both|modern": 15,
  "turn-lane:outbound|right-straight->sidewalk-lamp:both|pride": 1,
  "turn-lane:outbound|right-straight->sidewalk-lamp:both|traditional": 3,
  "turn-lane:outbound|right-straight->sidewalk-lamp:left|modern": 47,
  "turn-lane:outbound|right-straight->sidewalk-lamp:left|traditional": 4,
  "turn-lane:outbound|right-straight->sidewalk-lamp:right|traditional": 1,
  "turn-lane:outbound|right-straight->sidewalk-tree:big": 34,
  "turn-lane:outbound|right-straight->sidewalk-tree:palm-tree": 2,
  "turn-lane:outbound|right-straight->sidewalk:dense": 11,
  "turn-lane:outbound|right-straight->sidewalk:normal": 9,
  "turn-lane:outbound|right-straight->sidewalk:sparse": 1,
  "turn-lane:outbound|right-straight->streetcar:inbound|regular": 3,
  "turn-lane:outbound|right-straight->streetcar:outbound|colored": 1,
  "turn-lane:outbound|right-straight->streetcar:outbound|regular": 4,
  "turn-lane:outbound|right-straight->transit-shelter:left|street-level": 3,
  "turn-lane:outbound|right-straight->transit-shelter:right|street-level": 6,
  "turn-lane:outbound|right-straight->turn-lane:inbound|left": 1,
  "turn-lane:outbound|right-straight->turn-lane:inbound|left-straight": 3,
  "turn-lane:outbound|right-straight->turn-lane:inbound|right-straight": 1,
  "turn-lane:outbound|right-straight->turn-lane:outbound|both": 1,
  "turn-lane:outbound|right-straight->turn-lane:outbound|left": 2,
  "turn-lane:outbound|right-straight->turn-lane:outbound|left-straight": 6,
  "turn-lane:outbound|right-straight->turn-lane:outbound|right": 25,
  "turn-lane:outbound|right-straight->turn-lane:outbound|right-straight": 4,
  "turn-lane:outbound|shared->bike-lane:outbound|colored": 1,
  "turn-lane:outbound|shared->bus-lane:outbound|regular": 3,
  "turn-lane:outbound|shared->divider:bollard": 1,
  "turn-lane:outbound|shared->divider:bush": 2,
  "turn-lane:outbound|shared->divider:flowers": 2,
  "turn-lane:outbound|shared->divider:median": 2,
  "turn-lane:outbound|shared->drive-lane:inbound|car": 4,
  "turn-lane:outbound|shared->drive-lane:outbound|car": 72,
  "turn-lane:outbound|shared->drive-lane:outbound|sharrow": 6,
  "turn-lane:outbound|shared->drive-lane:outbound|truck": 52,
  "turn-lane:outbound|shared->flex-zone:rideshare|inbound|right": 1,
  "turn-lane:outbound|shared->parking-lane:inbound|right": 1,
  "turn-lane:outbound|shared->parking-lane:outbound|left": 2,
  "turn-lane:outbound|shared->parking-lane:outbound|right": 5,
  "turn-lane:outbound|shared->sidewalk-lamp:both|traditional": 1,
  "turn-lane:outbound|shared->sidewalk-wayfinding:medium": 2,
  "turn-lane:outbound|shared->sidewalk:dense": 1,
  "turn-lane:outbound|shared->streetcar:outbound|colored": 1,
  "turn-lane:outbound|shared->streetcar:outbound|regular": 4,
  "turn-lane:outbound|shared->turn-lane:outbound|right": 1,
  "turn-lane:outbound|shared->turn-lane:outbound|right-straight": 9,
  "turn-lane:outbound|shared->turn-lane:outbound|straight": 3,
  "turn-lane:outbound|straight->bike-lane:inbound|green|sidewalk": 2,
  "turn-lane:outbound|straight->bike-lane:inbound|red|road": 1,
  "turn-lane:outbound|straight->bike-lane:inbound|regular": 2,
  "turn-lane:outbound|straight->bike-lane:inbound|regular|road": 23,
  "turn-lane:outbound|straight->bike-lane:outbound|colored": 50,
  "turn-lane:outbound|straight->bike-lane:outbound|green|road": 51,
  "turn-lane:outbound|straight->bike-lane:outbound|green|sidewalk": 1,
  "turn-lane:outbound|straight->bike-lane:outbound|red|road": 3,
  "turn-lane:outbound|straight->bike-lane:outbound|regular": 14,
  "turn-lane:outbound|straight->bike-lane:outbound|regular|road": 7,
  "turn-lane:outbound|straight->bike-lane:outbound|regular|sidewalk": 2,
  "turn-lane:outbound|straight->bikeshare:left|road": 2,
  "turn-lane:outbound|straight->bus-lane:inbound|regular|typical": 2,
  "turn-lane:outbound|straight->bus-lane:outbound|colored": 3,
  "turn-lane:outbound|straight->bus-lane:outbound|colored|typical": 5,
  "turn-lane:outbound|straight->bus-lane:outbound|regular": 23,
  "turn-lane:outbound|straight->bus-lane:outbound|regular|typical": 52,
  "turn-lane:outbound|straight->bus-lane:outbound|shared|typical": 4,
  "turn-lane:outbound|straight->divider:big-tree": 26,
  "turn-lane:outbound|straight->divider:bollard": 17,
  "turn-lane:outbound|straight->divider:bush": 37,
  "turn-lane:outbound|straight->divider:dome": 11,
  "turn-lane:outbound|straight->divider:flowers": 36,
  "turn-lane:outbound|straight->divider:median": 14,
  "turn-lane:outbound|straight->divider:palm-tree": 6,
  "turn-lane:outbound|straight->divider:planter-box": 59,
  "turn-lane:outbound|straight->divider:planting-strip": 10,
  "turn-lane:outbound|straight->divider:striped-buffer": 35,
  "turn-lane:outbound|straight->drive-lane:inbound|car": 48,
  "turn-lane:outbound|straight->drive-lane:inbound|sharrow": 2,
  "turn-lane:outbound|straight->drive-lane:inbound|truck": 11,
  "turn-lane:outbound|straight->drive-lane:outbound|car": 130,
  "turn-lane:outbound|straight->drive-lane:outbound|sharrow": 4,
  "turn-lane:outbound|straight->drive-lane:outbound|truck": 74,
  "turn-lane:outbound|straight->flex-zone-curb:empty|left": 1,
  "turn-lane:outbound|straight->flex-zone-curb:sparse|left": 4,
  "turn-lane:outbound|straight->flex-zone:rideshare|outbound|right": 3,
  "turn-lane:outbound|straight->flex-zone:taxi|outbound|right": 1,
  "turn-lane:outbound|straight->food-truck:left": 1,
  "turn-lane:outbound|straight->light-rail:inbound|regular": 2,
  "turn-lane:outbound|straight->light-rail:outbound|regular": 5,
  "turn-lane:outbound|straight->parking-lane:angled-rear-right|left": 1,
  "turn-lane:outbound|straight->parking-lane:angled-rear-right|right": 1,
  "turn-lane:outbound|straight->parking-lane:inbound|left": 8,
  "turn-lane:outbound|straight->parking-lane:inbound|right": 30,
  "turn-lane:outbound|straight->parking-lane:outbound": 1,
  "turn-lane:outbound|straight->parking-lane:outbound|left": 33,
  "turn-lane:outbound|straight->parking-lane:outbound|right": 295,
  "turn-lane:outbound|straight->parking-lane:sideways|left": 1,
  "turn-lane:outbound|straight->parking-lane:sideways|right": 2,
  "turn-lane:outbound|straight->parklet:right": 2,
  "turn-lane:outbound|straight->scooter:outbound|regular": 2,
  "turn-lane:outbound|straight->sidewalk-bench:center": 1,
  "turn-lane:outbound|straight->sidewalk-bench:right": 1,
  "turn-lane:outbound|straight->sidewalk-bike-rack:left|sidewalk": 2,
  "turn-lane:outbound|straight->sidewalk-lamp:both|modern": 11,
  "turn-lane:outbound|straight->sidewalk-lamp:both|traditional": 14,
  "turn-lane:outbound|straight->sidewalk-lamp:left|modern": 94,
  "turn-lane:outbound|straight->sidewalk-lamp:left|traditional": 35,
  "turn-lane:outbound|straight->sidewalk-tree:big": 72,
  "turn-lane:outbound|straight->sidewalk-tree:palm-tree": 5,
  "turn-lane:outbound|straight->sidewalk-wayfinding:medium": 1,
  "turn-lane:outbound|straight->sidewalk:dense": 14,
  "turn-lane:outbound|straight->sidewalk:normal": 26,
  "turn-lane:outbound|straight->sidewalk:sparse": 5,
  "turn-lane:outbound|straight->streetcar:inbound|grass": 1,
  "turn-lane:outbound|straight->streetcar:inbound|regular": 1,
  "turn-lane:outbound|straight->streetcar:outbound|colored": 1,
  "turn-lane:outbound|straight->streetcar:outbound|regular": 4,
  "turn-lane:outbound|straight->transit-shelter:left|street-level": 10,
  "turn-lane:outbound|straight->transit-shelter:right|street-level": 3,
  "turn-lane:outbound|straight->turn-lane:inbound|right": 1,
  "turn-lane:outbound|straight->turn-lane:inbound|straight": 1,
  "turn-lane:outbound|straight->turn-lane:outbound|left": 1,
  "turn-lane:outbound|straight->turn-lane:outbound|left-straight": 4,
  "turn-lane:outbound|straight->turn-lane:outbound|right": 25,
  "turn-lane:outbound|straight->turn-lane:outbound|right-straight": 55,
  "turn-lane:outbound|straight->turn-lane:outbound|straight": 129,
  "turn-lane:right-straight->sidewalk-lamp:left|modern": 1,
  "turn-lane:right-straight|outbound->divider:planter-box": 1,
  "turn-lane:right|outbound->parking-lane:outbound|right": 2,
  "turn-lane:right|right-straight->sidewalk-tree:big": 1,
  "turn-lane:shared->flex-zone:taxi|shared|left": 1,
  "turn-lane:shared->parking-lane:angled-rear-right": 1,
  "turn-lane:shared->parking-lane:outbound|right": 1,
  "turn-lane:shared|outbound->divider:planter-box": 1,
  "turn-lane:shared|outbound->parking-lane:outbound|right": 1,
  "turn-lane:straight->divider:median": 1,
  "turn-lane:straight->drive-lane:straight|car": 1,
  "turn-lane:straight->parking-lane:outbound|right": 1,
  "turn-lane:straight->sidewalk-lamp:left|modern": 1,
  "turn-lane:straight|inbound->drive-lane:right-straight|car": 1,
  "turn-lane:straight|left->turn-lane:outbound|straight": 1,
  "turn-lane:straight|outbound->divider:bush": 1,
  "turn-lane:straight|outbound->sidewalk-lamp:left|modern": 1,
  "utilities:left->bike-lane:inbound|green|road": 1,
  "utilities:left->divider:big-tree": 1,
  "utilities:left->drive-lane:outbound|car": 2,
  "utilities:left->outdoor-dining:occupied|road": 1,
  "utilities:left->parking-lane:inbound|left": 2,
  "utilities:left->sidewalk-bench:center": 5,
  "utilities:left->sidewalk-bench:left": 2,
  "utilities:left->sidewalk:empty": 2,
  "utilities:left->sidewalk:normal": 1,
  "utilities:left->sidewalk:sparse": 1,
  "utilities:left->transit-shelter:right|street-level": 1,
  "utilities:right->divider:flowers": 1,
  "utilities:right->drive-lane:inbound|truck": 1,
  "utilities:right->sidewalk-tree:big": 6,
  "utilities:right->sidewalk-tree:palm-tree": 1
}
