"use strict";
/**
 * Fetch utilities
 */
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../constants");
exports.search = (baseUrl, queryString) => {
    return fetchGet(`${baseUrl}${constants_1.SEARCH_ENDPOINT}`, queryString);
};
exports.autocomplete = (baseUrl, queryString) => {
    return throttledFetchGet(500, `${baseUrl}${constants_1.AUTOCOMPLETE_ENDPOINT}`, queryString);
};
// Fetch helpers
const checkResponse = (response) => {
    return response.ok
        ? response
        : Promise.reject(new Error('Error in fetch: ' + response.status));
};
const getJson = (response) => {
    return response.json();
};
const fetchGet = (url, queryString) => {
    const fetchUrl = queryString ? `${url}${queryString}` : url;
    return fetch(fetchUrl)
        .then(checkResponse)
        .then(getJson)
        .catch((error) => {
        throw new Error(`Error fetching data: ${error}`);
    });
};
const throttledFetchGet = (timeout, url, queryString) => {
    let wait = false; // Initially, we're not waiting
    if (!wait) {
        if (queryString) {
            return fetchGet(url, queryString);
        }
        else {
            return fetchGet(url);
        }
        wait = true; // Prevent future invocations
        setTimeout(function () {
            wait = false; // And allow future invocations
        }, timeout);
    }
};
