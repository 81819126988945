"use strict";
/**
 * Constants
 */
Object.defineProperty(exports, "__esModule", { value: true });
// Search types
exports.GET = "GET";
exports.POST = "POST";
// URLs
exports.SEARCH_ENDPOINT = "/v1/search?";
exports.AUTOCOMPLETE_ENDPOINT = "/v1/autocomplete?";
// Query string params
exports.QS_TEXT = "text";
exports.QS_API_KEY = "api_key";
exports.QS_FOCUS_LAT = "focus.point.lat";
exports.QS_FOCUS_LONG = "focus.point.lon";
exports.QS_RESULTS_LIMIT = "size";
exports.QS_BOUNDARY_COUNTRY = "boundary.country";
exports.QS_BOUNDARY_RECT_MIN_LAT = "boundary.rect.min_lat";
exports.QS_BOUNDARY_RECT_MAX_LAT = "boundary.rect.max_lat";
exports.QS_BOUNDARY_RECT_MIN_LON = "boundary.rect.min_lon";
exports.QS_BOUNDARY_RECT_MAX_LON = "boundary.rect.max_lon";
exports.QS_BOUNDARY_CIRCLE_LAT = "boundary.circle.lat";
exports.QS_BOUNDARY_CIRCLE_LON = "boundary.circle.lon";
exports.QS_BOUNDARY_CIRCLE_RADIUS = "boundary.circle.radius";
exports.QS_BOUNDARY_ADMIN_AREA = "boundary.gid";
exports.QS_DATA_SOURCES = "sources";
exports.QS_LAYERS = "layers";
// Data sources
exports.DATA_SOURCE_OPENSTREETMAP = "osm";
exports.DATA_SOURCE_OPENADDRESSES = "oa";
exports.DATA_SOURCE_WHOSONFIRST = "wof";
exports.DATA_SOURCE_GEONAMES = "gn";
// Layers
exports.PLACE_TYPE_ADDRESS = "address";
exports.PLACE_TYPE_VENUE = "venue";
exports.PLACE_TYPE_NEIGHBOURHOOD = "neighbourhood";
exports.PLACE_TYPE_LOCALITY = "locality";
exports.PLACE_TYPE_BOROUGH = "borough";
exports.PLACE_TYPE_LOCALADMIN = "localadmin";
exports.PLACE_TYPE_COUNTY = "county";
exports.PLACE_TYPE_MACROCOUNTY = "macrocounty";
exports.PLACE_TYPE_REGION = "region";
exports.PLACE_TYPE_MACROREGION = "macroregion";
exports.PLACE_TYPE_COUNTRY = "country";
exports.PLACE_TYPE_COARSE = "coarse";
// Mins and maxes
exports.MIN_LAT = "min_lat";
exports.MAX_LAT = "max_lat";
exports.MIN_LON = "min_lon";
exports.MAX_LON = "max_lon";
exports.LAT = "lat";
exports.LON = "lon";
exports.RADIUS = "radius";
