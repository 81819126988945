{
  "lanes": {
    "asphalt": {
      "elevation": 0,
      "variants": {
        "color": {
          "regular": {
            "graphics": {
              "repeat": "ground--asphalt"
            }
          },
          "red": {
            "graphics": {
              "repeat": "ground--asphalt-red"
            }
          },
          "green": {
            "graphics": {
              "repeat": "ground--asphalt-green"
            }
          },
          "gray": {
            "graphics": {
              "repeat": "ground--asphalt-gray"
            }
          }
        }
      }
    },
    "sidewalk": {
      "elevation": 1,
      "variants": {
        "color": {
          "regular": {
            "graphics": {
              "repeat": "ground--concrete"
            }
          },
          "black": {
            "graphics": {
              "repeat": "ground--asphalt"
            }
          },
          "red": {
            "graphics": {
              "repeat": "ground--asphalt-red"
            }
          },
          "green": {
            "graphics": {
              "repeat": "ground--asphalt-green"
            }
          },
          "gray": {
            "graphics": {
              "repeat": "ground--asphalt-gray"
            }
          }
        },
        "density": {
          "empty": {
            "graphics": {
              "repeat": "ground--concrete"
            }
          }
        }
      }
    },
    "raised-sidewalk": {
      "elevation": 2,
      "variants": {
        "type": {
          "default": {
            "graphics": {
              "repeat": "ground--concrete"
            }
          }
        }
      }
    },
    "drainage": {
      "elevation": -2,
      "variants": {
        "color": {
          "regular": {
            "graphics": {
              "repeat": "ground--concrete"
            }
          }
        }
      }
    }
  },
  "markings": {
    "markings--lane-right": {
      "graphics": {
        "right": "markings--lane-right"
      }
    },
    "markings--lane-left": {
      "graphics": {
        "left": "markings--lane-left"
      }
    },
    "markings--straight-inbound-light": {
      "graphics": {
        "center": "markings--straight-inbound-light"
      }
    },
    "markings--straight-outbound-light": {
      "graphics": {
        "center": "markings--straight-outbound-light"
      }
    },
    "markings--lane-left-half": {
      "graphics": {
        "left": "markings--lane-left-half"
      }
    },
    "markings--lane-right-half": {
      "graphics": {
        "right": "markings--lane-right-half"
      }
    },
    "markings--lane-horiz": {
      "graphics": {
        "repeat": "markings--lane-horiz"
      }
    },
    "markings--sharrow-inbound": {
      "graphics": {
        "center": "markings--sharrow-inbound"
      }
    },
    "markings--sharrow-outbound": {
      "graphics": {
        "center": "markings--sharrow-outbound"
      }
    },
    "markings--left-inbound": {
      "graphics": {
        "center": "markings--left-inbound"
      }
    },
    "markings--left-straight-inbound": {
      "graphics": {
        "center": "markings--left-straight-inbound"
      }
    },
    "markings--straight-inbound": {
      "graphics": {
        "center": "markings--straight-inbound"
      }
    },
    "markings--right-straight-inbound": {
      "graphics": {
        "center": "markings--right-straight-inbound"
      }
    },
    "markings--right-inbound": {
      "graphics": {
        "center": "markings--right-inbound"
      }
    },
    "markings--both-inbound": {
      "graphics": {
        "center": "markings--both-inbound"
      }
    },
    "markings--left-right-straight-inbound": {
      "graphics": {
        "center": "markings--left-right-straight-inbound"
      }
    },
    "markings--shared-inbound": {
      "graphics": {
        "center": "markings--shared-inbound"
      }
    },
    "markings--center-lane-left": {
      "graphics": {
        "left": "markings--center-lane-left"
      }
    },
    "markings--center-lane-right": {
      "graphics": {
        "right": "markings--center-lane-right"
      }
    },
    "markings--left-outbound": {
      "graphics": {
        "center": "markings--left-outbound"
      }
    },
    "markings--left-straight-outbound": {
      "graphics": {
        "center": "markings--left-straight-outbound"
      }
    },
    "markings--straight-outbound": {
      "graphics": {
        "center": "markings--straight-outbound"
      }
    },
    "markings--right-straight-outbound": {
      "graphics": {
        "center": "markings--right-straight-outbound"
      }
    },
    "markings--right-outbound": {
      "graphics": {
        "center": "markings--right-outbound"
      }
    },
    "markings--both-outbound": {
      "graphics": {
        "center": "markings--both-outbound"
      }
    },
    "markings--left-right-straight-outbound": {
      "graphics": {
        "center": "markings--left-right-straight-outbound"
      }
    },
    "markings--shared-outbound": {
      "graphics": {
        "center": "markings--shared-outbound"
      }
    },
    "markings--streetcar-track-01": {
      "graphics": {
        "center": "markings--streetcar-track-01"
      }
    },
    "markings--streetcar-track-02": {
      "graphics": {
        "center": "markings--streetcar-track-02"
      }
    },
    "markings--stripes-diagonal": {
      "graphics": {
        "repeat": "markings--stripes-diagonal"
      }
    },
    "markings--parking-left": {
      "graphics": {
        "right": "markings--parking-left"
      }
    },
    "markings--parking-right": {
      "graphics": {
        "left": "markings--parking-right"
      }
    },
    "markings--twoway-left": {
      "graphics": {
        "center": [
          {
            "id": "markings--center-lane"
          },
          {
            "id": "markings--straight-inbound-light",
            "offsetX": "-50%"
          },
          {
            "id": "markings--straight-outbound-light",
            "offsetX": "50%"
          }
        ]
      }
    },
    "markings--twoway-right": {
      "graphics": {
        "center": [
          {
            "id": "markings--center-lane"
          },
          {
            "id": "markings--straight-inbound-light",
            "offsetX": "50%"
          },
          {
            "id": "markings--straight-outbound-light",
            "offsetX": "-50%"
          }
        ]
      }
    }
  },
  "objects": {
    "pickup-sign": {
      "name": "Pickup sign",
      "nameKey": "pickup-sign",
      "owner": "FLEX",
      "zIndex": 30,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "right": "curb--pickup-sign-left"
            }
          },
          "right": {
            "graphics": {
              "left": "curb--pickup-sign-right"
            }
          }
        }
      }
    },
    "person-waiting": {
      "name": "Person waiting",
      "nameKey": "person-waiting",
      "zIndex": 30,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "center": "curb--person-waiting-01-left"
            }
          },
          "right": {
            "graphics": {
              "center": "curb--person-waiting-01-right"
            }
          }
        }
      }
    },
    "utility-pole": {
      "name": "Utility pole",
      "nameKey": "utility-pole",
      "owner": "NONE",
      "zIndex": 10,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "center": "utilities--utility-pole-left"
            }
          },
          "right": {
            "graphics": {
              "center": "utilities--utility-pole-right"
            }
          }
        }
      }
    },
    "tree": {
      "name": "Tree",
      "nameKey": "tree",
      "owner": "NATURE",
      "zIndex": 22,
      "variants": {
        "type": {
          "big": {
            "graphics": {
              "center": "trees--tree"
            }
          },
          "palm-tree": {
            "graphics": {
              "center": "trees--palm-tree"
            }
          }
        }
      }
    },
    "bike-rack": {
      "name": "Bike rack",
      "nameKey": "bike-rack",
      "owner": "BIKE",
      "zIndex": 23,
      "variants": {
        "direction|orientation": {
          "parallel": {
            "left": {
              "graphics": {
                "left": "bikes--bike-rack-parallel-left"
              }
            },
            "right": {
              "graphics": {
                "right": "bikes--bike-rack-parallel-right"
              }
            }
          },
          "perpendicular": {
            "left": {
              "graphics": {
                "left": "bikes--bike-rack-perpendicular-left"
              }
            },
            "right": {
              "graphics": {
                "right": "bikes--bike-rack-perpendicular-right"
              }
            }
          }
        }
      }
    },
    "bench": {
      "name": "Bench",
      "nameKey": "bench",
      "owner": "PEDESTRIAN",
      "zIndex": 24,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "left": "furniture--bench-left"
            }
          },
          "center": {
            "graphics": {
              "center": "furniture--bench-center"
            }
          },
          "right": {
            "graphics": {
              "right": "furniture--bench-right"
            }
          }
        }
      }
    },
    "outdoor-dining": {
      "name": "Outdoor dining",
      "nameKey": "outdoor-dining",
      "owner": "PEDESTRIAN",
      "zIndex": 20,
      "variants": {
        "occupants": {
          "occupied": {
            "graphics": {
              "center": "furniture--cafe-seating-occupied"
            }
          },
          "empty": {
            "graphics": {
              "center": "furniture--cafe-seating"
            }
          }
        }
      }
    },
    "wayfinding-sign": {
      "name": "Wayfinding sign",
      "nameKey": "wayfinding-sign",
      "owner": "PEDESTRIAN",
      "zIndex": 21,
      "variants": {
        "type": {
          "small": {
            "graphics": {
              "center": "wayfinding--nyc-wayfinding-pylon-small"
            }
          },
          "medium": {
            "graphics": {
              "center": "wayfinding--nyc-wayfinding-pylon-medium"
            }
          },
          "large": {
            "graphics": {
              "center": "wayfinding--nyc-wayfinding-pylon-large"
            }
          }
        }
      }
    },
    "lamp": {
      "name": "Lamp",
      "nameKey": "lamp",
      "owner": "PEDESTRIAN",
      "zIndex": 19,
      "variants": {
        "type|orientation": {
          "modern": {
            "right": {
              "graphics": {
                "right": "lamps--lamp-modern-right"
              }
            },
            "left": {
              "graphics": {
                "left": "lamps--lamp-modern-left"
              }
            },
            "both": {
              "graphics": {
                "center": "lamps--lamp-modern-both"
              }
            }
          },
          "traditional": {
            "right": {
              "graphics": {
                "right": "lamps--lamp-traditional-right"
              }
            },
            "left": {
              "graphics": {
                "left": "lamps--lamp-traditional-left"
              }
            },
            "both": {
              "graphics": {
                "center": "lamps--lamp-traditional-center"
              }
            }
          }
        }
      }
    },
    "pride-banner": {
      "name": "Pride banner",
      "nameKey": "pride-banner",
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "left": "lamps--pride-banner-left"
            }
          },
          "right": {
            "graphics": {
              "right": "lamps--pride-banner-right"
            }
          },
          "both": {
            "graphics": {
              "center": {
                "id": "lamps--pride-banner-left",
                "offsetX": 49
              }
            }
          }
        }
      }
    },
    "parklet": {
      "name": "Parklet",
      "nameKey": "parklet",
      "owner": "NATURE",
      "zIndex": 15,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "left": "parklet--yerba-buena-parklet-left-v02"
            }
          },
          "right": {
            "graphics": {
              "right": "parklet--yerba-buena-parklet-right-v02"
            }
          }
        }
      }
    },
    "street-vendor": {
      "name": "Street vendor",
      "nameKey": "street-vendor",
      "zIndex": 24,
      "variants": {
        "vendor-type": {
          "handcart": {
            "graphics": {
              "center": "vendors--vendor-handcart"
            }
          },
          "platform": {
            "graphics": {
              "center": "vendors--vendor-platform"
            }
          },
          "table": {
            "graphics": {
              "center": "vendors--vendor-table"
            }
          },
          "tarp": {
            "graphics": {
              "center": "vendors--vendor-tarp"
            }
          }
        }
      }
    },
    "bikeshare": {
      "name": "Bikeshare station",
      "nameKey": "bikeshare-station",
      "owner": "FLEX",
      "zIndex": 21,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "left": "bikes--bikeshare-left"
            }
          },
          "right": {
            "graphics": {
              "right": "bikes--bikeshare-right"
            }
          }
        }
      }
    },
    "planting-strip": {
      "name": "Planting strip",
      "nameKey": "planting-strip",
      "owner": "NATURE",
      "zIndex": 20,
      "variants": {
        "type": {
          "grass": {
            "graphics": {
              "repeat": "plants--grass"
            }
          },
          "bush": {
            "graphics": {
              "scatter": {
                "sprites": ["plants--bush"],
                "minSpacing": -3,
                "maxSpacing": 3,
                "padding": 2
              }
            }
          },
          "flowers": {
            "graphics": {
              "scatter": {
                "sprites": [
                  "plants--flowers-red",
                  {
                    "id": "plants--flowers-orange",
                    "width": 4,
                    "disallowFirst": true,
                    "weight": 20
                  },
                  {
                    "id": "plants--flowers-yellow",
                    "width": 4,
                    "disallowFirst": true
                  },
                  {
                    "id": "plants--flowers-white",
                    "width": 4,
                    "disallowFirst": true,
                    "weight": 20
                  },
                  {
                    "id": "plants--flowers-blue",
                    "width": 4,
                    "disallowFirst": true,
                    "weight": 10
                  }
                ],
                "minSpacing": -3,
                "maxSpacing": 3,
                "padding": 2
              }
            }
          }
        }
      }
    },
    "planter-box": {
      "name": "Planter box",
      "nameKey": "planter-box",
      "owner": "NATURE",
      "zIndex": 20,
      "variants": {
        "type": {
          "default": {
            "graphics": {
              "center": "dividers--planter-box"
            }
          }
        }
      }
    },
    "bollard": {
      "name": "Bollard",
      "nameKey": "bollard",
      "zIndex": 20,
      "variants": {
        "type": {
          "default": {
            "graphics": {
              "center": "dividers--bollard"
            }
          }
        }
      }
    },
    "dome": {
      "name": "Traffic exclusion dome",
      "nameKey": "dome",
      "zIndex": 20,
      "variants": {
        "type": {
          "default": {
            "graphics": {
              "center": "dividers--dome"
            }
          }
        }
      }
    },
    "transit-shelter": {
      "name": "Transit shelter",
      "nameKey": "transit-shelter",
      "owner": "TRANSIT",
      "zIndex": 20,
      "variants": {
        "type|orientation": {
          "transit-shelter-01": {
            "left": {
              "graphics": {
                "left": "transit--transit-shelter-01-left"
              }
            },
            "right": {
              "graphics": {
                "right": "transit--transit-shelter-01-right"
              }
            }
          },
          "transit-shelter-02": {
            "left": {
              "graphics": {
                "left": "transit--transit-shelter-02-left"
              }
            },
            "right": {
              "graphics": {
                "right": "transit--transit-shelter-02-right"
              }
            }
          }
        }
      }
    },
    "brt-station": {
      "name": "BRT station",
      "nameKey": "brt-station",
      "owner": "TRANSIT",
      "zIndex": 20,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "quirks": {
                "minWidth": 6.75
              },
              "repeat": {
                "id": "transit--brt-station-piece-center",
                "padding": 1.5
              },
              "left": "transit--brt-station-piece-left-edge",
              "right": "transit--brt-station-piece-right-platform",
              "scatter": {
                "pool": "people",
                "minSpacing": 0,
                "maxSpacing": 2.5,
                "originY": -36,
                "padding": 7
              }
            }
          },
          "right": {
            "graphics": {
              "quirks": {
                "minWidth": 6.75
              },
              "repeat": {
                "id": "transit--brt-station-piece-center",
                "padding": 1.5
              },
              "right": "transit--brt-station-piece-right-edge",
              "left": "transit--brt-station-piece-left-platform",
              "scatter": {
                "pool": "people",
                "minSpacing": 0,
                "maxSpacing": 2.5,
                "originY": -36,
                "padding": 7
              }
            }
          },
          "center": {
            "graphics": {
              "quirks": {
                "minWidth": 6.75
              },
              "repeat": {
                "id": "transit--brt-station-piece-center",
                "padding": 1.5
              },
              "left": "transit--brt-station-piece-left-platform",
              "right": "transit--brt-station-piece-right-platform",
              "scatter": {
                "pool": "people",
                "minSpacing": 0,
                "maxSpacing": 2.5,
                "originY": -36,
                "padding": 7
              }
            }
          }
        }
      }
    },
    "traffic-cone": {
      "name": "Traffic cone",
      "nameKey": "traffic-cone",
      "zIndex": 20,
      "variants": {
        "type": {
          "default": {
            "graphics": {
              "center": "construction--cone"
            }
          }
        }
      }
    },
    "jersey-barrier": {
      "name": "Jersey barrier",
      "nameKey": "jersey-barrier",
      "zIndex": 20,
      "variants": {
        "material": {
          "concrete": {
            "graphics": {
              "center": "construction--jersey-barrier-concrete"
            }
          },
          "plastic": {
            "graphics": {
              "center": "construction--jersey-barrier-plastic"
            }
          }
        }
      }
    },
    "barricade": {
      "name": "Barricade",
      "nameKey": "barricade",
      "zIndex": 20,
      "variants": {
        "type": {
          "default": {
            "graphics": {
              "center": "construction--barricade"
            }
          }
        }
      }
    },
    "open-drain": {
      "variants": {
        "drainage-sides": {
          "slanted": {
            "graphics": {
              "quirks": {
                "minWidth": 3.3333
              },
              "repeat": "utilities--drainage-channel-piece-center",
              "left": "utilities--drainage-channel-piece-left-slanted",
              "right": "utilities--drainage-channel-piece-right-slanted"
            }
          },
          "vertical": {
            "graphics": {
              "quirks": {
                "minWidth": 2.6666
              },
              "repeat": "utilities--drainage-channel-piece-center",
              "left": "utilities--drainage-channel-piece-left-vertical",
              "right": "utilities--drainage-channel-piece-right-vertical"
            }
          }
        }
      }
    }
  },
  "vehicles": {
    "pedestrian": {
      "name": "Pedestrian",
      "nameKey": "pedestrian",
      "owner": "PEDESTRIAN",
      "variants": {
        "density": {
          "normal": {
            "graphics": {
              "scatter": {
                "pool": "people",
                "minSpacing": 0,
                "maxSpacing": 5,
                "padding": 2
              }
            }
          },
          "dense": {
            "graphics": {
              "scatter": {
                "pool": "people",
                "minSpacing": 0,
                "maxSpacing": 1.5,
                "padding": 2
              }
            }
          },
          "sparse": {
            "graphics": {
              "scatter": {
                "pool": "people",
                "minSpacing": 3.5,
                "maxSpacing": 11.8333,
                "padding": 2
              }
            }
          }
        }
      }
    },
    "scooter": {
      "name": "Electric scooter",
      "nameKey": "scooter",
      "owner": "BIKE",
      "zIndex": 16,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": ["scooters--scooter-inbound"]
            }
          },
          "outbound": {
            "graphics": {
              "center": ["scooters--scooter-outbound"]
            }
          }
        },
        "riders|orientation": {
          "empty": {
            "left": {
              "graphics": {
                "left": "scooters--scooter-left-docked"
              }
            },
            "right": {
              "graphics": {
                "right": "scooters--scooter-right-docked"
              }
            }
          },
          "sparse": {
            "left": {
              "graphics": {
                "left": "scooters--scooter-left-rider"
              }
            },
            "right": {
              "graphics": {
                "right": "scooters--scooter-right-rider"
              }
            }
          }
        }
      }
    },
    "taxi": {
      "name": "Taxi",
      "nameKey": "taxi",
      "owner": "FLEX",
      "zIndex": 15,
      "variants": {
        "direction|orientation": {
          "inbound": {
            "left": {
              "graphics": {
                "center": "vehicles--taxi-inbound-door-left"
              }
            },
            "right": {
              "graphics": {
                "center": "vehicles--taxi-inbound-door-right"
              }
            }
          },
          "outbound": {
            "left": {
              "graphics": {
                "center": "vehicles--taxi-outbound-door-left"
              }
            },
            "right": {
              "graphics": {
                "center": "vehicles--taxi-outbound-door-right"
              }
            }
          }
        }
      }
    },
    "rideshare": {
      "name": "Rideshare",
      "nameKey": "rideshare",
      "owner": "FLEX",
      "zIndex": 15,
      "variants": {
        "direction|orientation": {
          "inbound": {
            "left": {
              "graphics": {
                "center": "vehicles--rideshare-inbound-door-left"
              }
            },
            "right": {
              "graphics": {
                "center": "vehicles--rideshare-inbound-door-right"
              }
            }
          },
          "outbound": {
            "left": {
              "graphics": {
                "center": "vehicles--rideshare-outbound-door-left"
              }
            },
            "right": {
              "graphics": {
                "center": "vehicles--rideshare-outbound-door-right"
              }
            }
          }
        }
      }
    },
    "train": {
      "name": "“Inception” train",
      "nameKey": "inception-train",
      "owner": "TRANSIT",
      "zIndex": 10,
      "variants": {
        "type": {
          "default": {
            "graphics": {
              "center": "secret--inception-train"
            }
          }
        }
      }
    },
    "bike": {
      "name": "Bike",
      "nameKey": "bike",
      "owner": "BIKE",
      "zIndex": 16,
      "variants": {
        "type|direction": {
          "biker-01": {
            "inbound": {
              "graphics": {
                "center": "bikes--biker-01-inbound"
              }
            },
            "outbound": {
              "graphics": {
                "center": "bikes--biker-01-outbound"
              }
            }
          },
          "biker-02": {
            "inbound": {
              "graphics": {
                "center": "bikes--biker-02-inbound"
              }
            },
            "outbound": {
              "graphics": {
                "center": "bikes--biker-02-outbound"
              }
            }
          }
        }
      }
    },
    "car": {
      "name": "Car",
      "nameKey": "car",
      "owner": "CAR",
      "zIndex": 14,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "vehicles--car-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "vehicles--car-outbound"
            }
          }
        },
        "direction|turn-orientation": {
          "inbound": {
            "right": {
              "graphics": {
                "center": "vehicles--car-inbound-turn-signal-right"
              }
            },
            "left": {
              "graphics": {
                "center": "vehicles--car-inbound-turn-signal-left"
              }
            }
          },
          "outbound": {
            "right": {
              "graphics": {
                "center": "vehicles--car-outbound-turn-signal-right"
              }
            },
            "left": {
              "graphics": {
                "center": "vehicles--car-outbound-turn-signal-left"
              }
            }
          }
        },
        "direction|orientation": {
          "inbound": {
            "left": {
              "graphics": {
                "left": "vehicles--car-inbound"
              }
            },
            "right": {
              "graphics": {
                "right": "vehicles--car-inbound"
              }
            }
          },
          "outbound": {
            "left": {
              "graphics": {
                "left": "vehicles--car-outbound"
              }
            },
            "right": {
              "graphics": {
                "right": "vehicles--car-outbound"
              }
            }
          },
          "sideways": {
            "left": {
              "graphics": {
                "left": "vehicles--car-sideways-left"
              }
            },
            "right": {
              "graphics": {
                "right": "vehicles--car-sideways-right"
              }
            }
          },
          "angled-front-left": {
            "left": {
              "graphics": {
                "left": "vehicles--car-angled-front-left"
              }
            },
            "right": {
              "graphics": {
                "right": "vehicles--car-angled-front-left"
              }
            }
          },
          "angled-front-right": {
            "left": {
              "graphics": {
                "left": "vehicles--car-angled-front-right"
              }
            },
            "right": {
              "graphics": {
                "right": "vehicles--car-angled-front-right"
              }
            }
          },
          "angled-rear-left": {
            "left": {
              "graphics": {
                "left": "vehicles--car-angled-rear-left"
              }
            },
            "right": {
              "graphics": {
                "right": "vehicles--car-angled-rear-left"
              }
            }
          },
          "angled-rear-right": {
            "left": {
              "graphics": {
                "left": "vehicles--car-angled-rear-right"
              }
            },
            "right": {
              "graphics": {
                "right": "vehicles--car-angled-rear-right"
              }
            }
          }
        }
      }
    },
    "microvan": {
      "name": "Microvan",
      "nameKey": "microvan",
      "owner": "CAR",
      "zIndex": 14,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "vehicles--microvan-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "vehicles--microvan-outbound"
            }
          }
        }
      }
    },
    "av": {
      "name": "Autonomous vehicle",
      "nameKey": "av",
      "owner": "CAR",
      "zIndex": 14,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "vehicles--av-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "vehicles--av-outbound"
            }
          }
        }
      }
    },
    "motorcycle-sidecar": {
      "name": "Motorcycle with sidecar",
      "nameKey": "motorcycle-sidecar",
      "owner": "CAR",
      "zIndex": 14,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "vehicles--krz-motorbike-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "vehicles--krz-motorbike-outbound"
            }
          }
        }
      }
    },
    "truck": {
      "name": "Truck",
      "nameKey": "truck",
      "owner": "CAR",
      "zIndex": 14,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "vehicles--truck-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "vehicles--truck-outbound"
            }
          }
        }
      }
    },
    "food-truck": {
      "name": "Food truck",
      "nameKey": "foodtruck",
      "owner": "FLEX",
      "zIndex": 20,
      "variants": {
        "orientation": {
          "left": {
            "graphics": {
              "center": "vehicles--foodtruck-left"
            }
          },
          "right": {
            "graphics": {
              "center": "vehicles--foodtruck-right"
            }
          }
        }
      }
    },
    "streetcar": {
      "name": "Streetcar",
      "nameKey": "streetcar",
      "owner": "TRANSIT",
      "zIndex": 15,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "transit--streetcar-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "transit--streetcar-outbound"
            }
          }
        }
      }
    },
    "light-rail": {
      "name": "Light rail",
      "nameKey": "light-rail",
      "owner": "TRANSIT",
      "zIndex": 15,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "transit--light-rail-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "transit--light-rail-outbound"
            }
          }
        }
      }
    },
    "bus": {
      "name": "Bus",
      "nameKey": "bus",
      "owner": "TRANSIT",
      "zIndex": 15,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "transit--bus-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "transit--bus-outbound"
            }
          }
        }
      }
    },
    "bus-alt": {
      "name": "Bus",
      "nameKey": "bus",
      "owner": "TRANSIT",
      "zIndex": 15,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "transit--bus-inbound-alt"
            }
          },
          "outbound": {
            "graphics": {
              "center": "transit--bus-outbound-alt"
            }
          }
        }
      }
    },
    "brt-bus": {
      "name": "BRT",
      "nameKey": "brt-bus",
      "owner": "TRANSIT",
      "zIndex": 15,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "transit--brt-bus-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "transit--brt-bus-outbound"
            }
          }
        }
      }
    },
    "double-decker-bus": {
      "name": "Double decker bus",
      "nameKey": "double-decker",
      "owner": "TRANSIT",
      "zIndex": 15,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "transit--double-decker-bus-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "transit--double-decker-bus-outbound"
            }
          }
        }
      }
    },
    "av-shuttle": {
      "name": "Autonomous shuttle",
      "nameKey": "av-shuttle",
      "owner": "TRANSIT",
      "zIndex": 15,
      "variants": {
        "direction": {
          "inbound": {
            "graphics": {
              "center": "transit--av-shuttle-inbound"
            }
          },
          "outbound": {
            "graphics": {
              "center": "transit--av-shuttle-outbound"
            }
          }
        }
      }
    },
    "magic-carpet": {
      "name": "Magic carpet",
      "nameKey": "magic-carpet",
      "zIndex": 14,
      "variants": {
        "magic-carpet-occupants": {
          "aladdin": {
            "graphics": {
              "center": {
                "id": "vehicles--magic-carpet-aladdin",
                "originY": -325
              }
            }
          },
          "jasmine": {
            "graphics": {
              "center": {
                "id": "vehicles--magic-carpet-jasmine",
                "originY": -325
              }
            }
          },
          "aladdin-jasmine": {
            "graphics": {
              "center": {
                "id": "vehicles--magic-carpet-aladdin-jasmine",
                "originY": -325
              }
            }
          },
          "jasmine-aladdin": {
            "graphics": {
              "center": {
                "id": "vehicles--magic-carpet-jasmine-aladdin",
                "originY": -325
              }
            }
          }
        }
      }
    }
  },
  "effects": {
    "biker-stress": {
      "name": "Biker stress",
      "nameKey": "biker-stress",
      "variants": {
        "type": {
          "sweat-drop": {
            "graphics": {
              "center": "effects--sweat-drop"
            }
          }
        }
      }
    }
  }
}
