(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory(require('react'), require('prop-types'), require('downshift')) :
  typeof define === 'function' && define.amd ? define(['react', 'prop-types', 'downshift'], factory) :
  (global = global || self, global.DownshiftPelias = factory(global.React, global.PropTypes, global.Downshift));
}(this, function (React, PropTypes, Downshift) { 'use strict';

  React = React && React.hasOwnProperty('default') ? React['default'] : React;
  PropTypes = PropTypes && PropTypes.hasOwnProperty('default') ? PropTypes['default'] : PropTypes;
  Downshift = Downshift && Downshift.hasOwnProperty('default') ? Downshift['default'] : Downshift;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _extends() {
    _extends = Object.assign || function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];

        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }

      return target;
    };

    return _extends.apply(this, arguments);
  }

  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      var ownKeys = Object.keys(source);

      if (typeof Object.getOwnPropertySymbols === 'function') {
        ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
          return Object.getOwnPropertyDescriptor(source, sym).enumerable;
        }));
      }

      ownKeys.forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    }

    return target;
  }

  function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;

    for (i = 0; i < sourceKeys.length; i++) {
      key = sourceKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      target[key] = source[key];
    }

    return target;
  }

  function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};

    var target = _objectWithoutPropertiesLoose(source, excluded);

    var key, i;

    if (Object.getOwnPropertySymbols) {
      var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

      for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
        target[key] = source[key];
      }
    }

    return target;
  }

  class DownshiftPelias extends React.Component {
    constructor(props) {
      super(props);

      _defineProperty(this, "handleInputValueChange", (inputValue, stateAndHelpers) => {
        // Runs `handleInputValueChange` if provided by parent
        if (typeof this.props.handleInputValueChange === 'function') {
          this.props.handleInputValueChange(inputValue, stateAndHelpers);
        }

        this.doAutocomplete(inputValue);
      });

      _defineProperty(this, "doAutocomplete", inputValue => {
        const timestamp = Date.now();
        this.lastInputTimestamp = timestamp;

        if (!inputValue) {
          this.setState({
            pending: false,
            results: null
          });
          return;
        }

        this.setState({
          pending: true
        });
        this.props.pelias.autocomplete.setSearchTerm(inputValue).execute().then(response => {
          // Only display the results if the timestamp associated
          // with this request is still current
          if (timestamp === this.lastInputTimestamp) {
            this.setState({
              pending: false,
              results: response
            });
          }
        }).catch(error => {
          console.log(error);

          if (timestamp === this.lastInputTimestamp) {
            this.setState({
              pending: false,
              error: true
            });
          }
        });
      });

      _defineProperty(this, "doSearch", inputValue => {
        const timestamp = Date.now();
        this.lastInputTimestamp = timestamp;

        if (!inputValue) {
          this.setState({
            pending: false,
            results: null
          });
          return;
        }

        this.setState({
          pending: true
        });
        this.props.pelias.search.setSearchTerm(inputValue).execute().then(response => {
          // Only display the results if the timestamp associated
          // with this request is still current
          if (timestamp === this.lastInputTimestamp) {
            this.setState({
              pending: false,
              results: response
            });
          }
        }).catch(error => {
          console.log(error);

          if (timestamp === this.lastInputTimestamp) {
            this.setState({
              pending: false,
              error: true
            });
          }
        });
      });

      _defineProperty(this, "defaultItemToString", item => item ? item.properties.label : '');

      this.state = {
        results: null,
        pending: false,
        error: false // For internal use only to track when inputs are being made,
        // so we know when to throw away async responses that become stale

      };
      this.lastInputTimestamp = Date.now();
    }

    render() {
      return React.createElement(Downshift, _extends({
        onChange: this.handleChange,
        onInputValueChange: this.handleInputValueChange,
        itemToString: this.props.itemToString || this.defaultItemToString
      }, this.props), (_ref) => {
        let _getInputProps = _ref.getInputProps,
            highlightedIndex = _ref.highlightedIndex,
            inputValue = _ref.inputValue,
            args = _objectWithoutProperties(_ref, ["getInputProps", "highlightedIndex", "inputValue"]);

        return this.props.children(_objectSpread({}, args, {
          // Put back the stuff we destructured
          highlightedIndex,
          inputValue,
          // Modify the `onKeyDown` prop of input props so that it handles the "Enter"
          // key being pressed, by default.
          getInputProps: args => _getInputProps(_objectSpread({}, args, {
            onKeyDown: event => {
              // Run `onKeyDown` from parent <input> element, if provided
              if (args && typeof args.onKeyDown === 'function') {
                args.onKeyDown(event);
              } // If `enter` key is pressed and nothing in the menu is highlighted,
              // then perform a search by calling `doSearch`. This retrieves
              // results Pelias's search query endpoint.


              if (event.key === 'Enter' && highlightedIndex === null) {
                // Bail if default Downshift behavior is prevented
                if (event.nativeEvent.preventDownshiftDefault === true) {
                  return;
                }

                this.doSearch(inputValue);
              }
            }
          })),
          doSearch: () => {
            this.doSearch(inputValue);
          },
          results: this.state.results,
          pending: this.state.pending,
          error: this.state.error
        }));
      });
    }

  }

  _defineProperty(DownshiftPelias, "propTypes", {
    children: PropTypes.func.isRequired,
    pelias: PropTypes.shape({
      search: PropTypes.shape({
        setSearchTerm: PropTypes.func.isRequired,
        execute: PropTypes.func.isRequired
      }).isRequired,
      autocomplete: PropTypes.object.isRequired
    }).isRequired,
    itemToString: PropTypes.func
  });

  return DownshiftPelias;

}));
