"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * A fluent interface for forward geocoding in Pelias
 */
const Constants = __importStar(require("../constants"));
const fetch_1 = require("../util/fetch/fetch");
const parameterSet = __importStar(require("../util/parameter-set/parameter-set"));
// Auto-instantiate in case caller forgets 'new' so as not to pollute the global namespace
// TODO: take a formed search object so the use doesn't have to use the fluent setters
class Search {
    constructor(config) {
        this.setSearchTerm = parameterSet.setSearchTerm.bind(this, '_searchObject');
        this.setFocusPoint = parameterSet.setFocusPoint.bind(this, '_searchObject');
        this.setResultsLimit = parameterSet.setResultsLimit.bind(this, '_searchObject');
        this.setBoundaryCountry = parameterSet.setBoundaryCountry.bind(this, '_searchObject');
        this.setBoundaryRectangle = parameterSet.setBoundaryRectangle.bind(this, '_searchObject');
        this.setBoundaryCircle = parameterSet.setBoundaryCircle.bind(this, '_searchObject');
        this.setBoundaryAdminArea = parameterSet.setBoundaryAdminArea.bind(this, '_searchObject');
        this.setDataSources = parameterSet.setDataSources.bind(this, '_searchObject');
        this.setLayers = parameterSet.setLayers.bind(this, '_searchObject');
        this.execute = () => {
            const query = buildSearchQueryString(this._searchObject);
            return fetch_1.search(this._baseUrl, query).then((response) => {
                return (response);
            });
        };
        this._searchObject = {
            searchTerm: undefined,
            apiKey: config.apiKey || undefined
        };
        if (!('peliasUrl' in config)) {
            throw new Error("peliasUrl must be specified in the constructor");
        }
        this._baseUrl = config.peliasUrl;
    }
}
// Search takes a GET request with a variety of query string params
const buildSearchQueryString = (searchObject) => {
    const paramsArray = [];
    if (searchObject.searchTerm) {
        paramsArray.push([Constants.QS_TEXT, searchObject.searchTerm]);
    }
    if (searchObject.apiKey) {
        paramsArray.push([Constants.QS_API_KEY, searchObject.apiKey]);
    }
    if (searchObject.focusPoint) {
        paramsArray.push([Constants.QS_FOCUS_LAT, searchObject.focusPoint.lat]);
        paramsArray.push([Constants.QS_FOCUS_LONG, searchObject.focusPoint.lon]);
    }
    if (searchObject.resultsLimit) {
        paramsArray.push([Constants.QS_RESULTS_LIMIT, searchObject.resultsLimit]);
    }
    if (searchObject.boundaryCountry) {
        paramsArray.push([Constants.QS_BOUNDARY_COUNTRY, searchObject.boundaryCountry]);
    }
    if (searchObject.boundaryRectangle) {
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MIN_LAT, searchObject.boundaryRectangle.min_lat]);
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MAX_LAT, searchObject.boundaryRectangle.max_lat]);
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MIN_LON, searchObject.boundaryRectangle.min_lon]);
        paramsArray.push([Constants.QS_BOUNDARY_RECT_MAX_LON, searchObject.boundaryRectangle.max_lon]);
    }
    if (searchObject.boundaryCircle) {
        paramsArray.push([Constants.QS_BOUNDARY_CIRCLE_LAT, searchObject.boundaryCircle.lat]);
        paramsArray.push([Constants.QS_BOUNDARY_CIRCLE_LON, searchObject.boundaryCircle.lon]);
        paramsArray.push([Constants.QS_BOUNDARY_CIRCLE_RADIUS, searchObject.boundaryCircle.radius]);
    }
    if (searchObject.boundaryAdminArea) {
        paramsArray.push([Constants.QS_BOUNDARY_ADMIN_AREA, searchObject.boundaryAdminArea]);
    }
    if (searchObject.dataSources) {
        paramsArray.push([Constants.QS_DATA_SOURCES, searchObject.dataSources.join(",")]);
    }
    if (searchObject.layers) {
        paramsArray.push([Constants.QS_LAYERS, searchObject.layers.join(",")]);
    }
    const searchParams = paramsArray.map((keyValPair) => {
        return `${encodeURIComponent(keyValPair[0])}=${encodeURIComponent(keyValPair[1])}`;
    }).join('&');
    return searchParams;
};
exports.default = Search;
