{
  "sidewalk": {
    "name": "Sidewalk",
    "nameKey": "sidewalk",
    "owner": "PEDESTRIAN",
    "zIndex": 30,
    "defaultWidth": 6,
    "defaultElevation": 1,
    "enableElevation": true,
    "variants": ["sidewalk-density"],
    "details": {
      "dense": {
        "elevation": 1,
        "minWidth": 6,
        "graphics": {
          "repeat": "ground--concrete"
        }
      },
      "normal": {
        "elevation": 1,
        "minWidth": 6,
        "graphics": {
          "repeat": "ground--concrete"
        }
      },
      "sparse": {
        "elevation": 1,
        "minWidth": 6,
        "graphics": {
          "repeat": "ground--concrete"
        }
      },
      "empty": {
        "elevation": 1,
        "minWidth": 6,
        "graphics": {
          "repeat": "ground--concrete"
        }
      }
    }
  },
  "sidewalk-tree": {
    "name": "Sidewalk tree",
    "nameKey": "sidewalk-tree",
    "owner": "NATURE",
    "zIndex": 22,
    "defaultWidth": 4,
    "variants": ["tree-type"],
    "details": {
      "big": {
        "elevation": 1,
        "graphics": {
          "center": "trees--tree",
          "repeat": "ground--concrete"
        }
      },
      "palm-tree": {
        "elevation": 1,
        "graphics": {
          "center": "trees--palm-tree",
          "repeat": "ground--concrete"
        }
      }
    }
  },
  "sidewalk-bike-rack": {
    "name": "Bike rack",
    "nameKey": "bike-rack",
    "owner": "BIKE",
    "zIndex": 23,
    "defaultWidth": 5,
    "variants": ["orientation", "bike-rack-elevation"],
    "defaultVariant": "left|sidewalk",
    "details": {
      "left|sidewalk-parallel": {
        "elevation": 1,
        "graphics": {
          "left": "bikes--bike-rack-parallel-left",
          "repeat": "ground--concrete"
        }
      },
      "right|sidewalk-parallel": {
        "elevation": 1,
        "graphics": {
          "right": "bikes--bike-rack-parallel-right",
          "repeat": "ground--concrete"
        }
      },
      "left|sidewalk": {
        "elevation": 1,
        "graphics": {
          "left": "bikes--bike-rack-perpendicular-left",
          "repeat": "ground--concrete"
        }
      },
      "right|sidewalk": {
        "elevation": 1,
        "graphics": {
          "right": "bikes--bike-rack-perpendicular-right",
          "repeat": "ground--concrete"
        }
      },
      "left|road": {
        "elevation": 0,
        "graphics": {
          "left": "bikes--bike-rack-perpendicular-left",
          "repeat": "ground--asphalt"
        }
      },
      "right|road": {
        "elevation": 0,
        "graphics": {
          "right": "bikes--bike-rack-perpendicular-right",
          "repeat": "ground--asphalt"
        }
      }
    }
  },
  "sidewalk-bench": {
    "name": "Bench",
    "nameKey": "bench",
    "owner": "PEDESTRIAN",
    "zIndex": 24,
    "defaultWidth": 4,
    "variants": ["bench-orientation"],
    "details": {
      "left": {
        "elevation": 1,
        "graphics": {
          "left": "furniture--bench-left",
          "repeat": "ground--concrete"
        }
      },
      "center": {
        "elevation": 1,
        "graphics": {
          "center": "furniture--bench-center",
          "repeat": "ground--concrete"
        }
      },
      "right": {
        "elevation": 1,
        "graphics": {
          "right": "furniture--bench-right",
          "repeat": "ground--concrete"
        }
      }
    }
  },
  "sidewalk-wayfinding": {
    "name": "Wayfinding sign",
    "nameKey": "wayfinding-sign",
    "owner": "PEDESTRIAN",
    "zIndex": 21,
    "defaultWidth": 4,
    "variants": ["wayfinding-type"],
    "description": {
      "key": "wayfinding-sign",
      "image": "wayfinding-02.jpg"
    },
    "details": {
      "large": {
        "elevation": 1,
        "graphics": {
          "center": "wayfinding--nyc-wayfinding-pylon-large",
          "repeat": "ground--concrete"
        }
      },
      "medium": {
        "elevation": 1,
        "graphics": {
          "center": "wayfinding--nyc-wayfinding-pylon-medium",
          "repeat": "ground--concrete"
        }
      },
      "small": {
        "elevation": 1,
        "graphics": {
          "center": "wayfinding--nyc-wayfinding-pylon-small",
          "repeat": "ground--concrete"
        }
      }
    }
  },
  "sidewalk-lamp": {
    "name": "Lamp",
    "nameKey": "sidewalk-lamp",
    "owner": "PEDESTRIAN",
    "zIndex": 19,
    "defaultWidth": 4,
    "variants": ["lamp-orientation", "lamp-type"],
    "defaultVariant": "both|traditional",
    "details": {
      "right|modern": {
        "elevation": 1,
        "graphics": {
          "right": "lamps--lamp-modern-right",
          "repeat": "ground--concrete"
        }
      },
      "both|modern": {
        "elevation": 1,
        "graphics": {
          "center": "lamps--lamp-modern-both",
          "repeat": "ground--concrete"
        }
      },
      "left|modern": {
        "elevation": 1,
        "graphics": {
          "left": "lamps--lamp-modern-left",
          "repeat": "ground--concrete"
        }
      },
      "right|traditional": {
        "elevation": 1,
        "graphics": {
          "right": "lamps--lamp-traditional-right",
          "repeat": "ground--concrete"
        }
      },
      "both|traditional": {
        "elevation": 1,
        "graphics": {
          "center": "lamps--lamp-traditional-center",
          "repeat": "ground--concrete"
        }
      },
      "left|traditional": {
        "elevation": 1,
        "graphics": {
          "left": "lamps--lamp-traditional-left",
          "repeat": "ground--concrete"
        }
      },
      "right|pride": {
        "elevation": 1,
        "graphics": {
          "right": ["lamps--lamp-modern-right", "lamps--pride-banner-right"],
          "repeat": "ground--concrete"
        }
      },
      "both|pride": {
        "elevation": 1,
        "graphics": {
          "center": [
            "lamps--lamp-modern-both",
            {
              "id": "lamps--pride-banner-left",
              "offsetX": 36
            }
          ],
          "repeat": "ground--concrete"
        }
      },
      "left|pride": {
        "elevation": 1,
        "graphics": {
          "left": ["lamps--lamp-modern-left", "lamps--pride-banner-left"],
          "repeat": "ground--concrete"
        }
      }
    }
  },
  "utilities": {
    "name": "Utility pole",
    "nameKey": "utility-pole",
    "owner": "NONE",
    "zIndex": 10,
    "defaultWidth": 4,
    "variants": ["orientation"],
    "enableWithFlag": "SEGMENT_UTILITIES",
    "details": {
      "left": {
        "elevation": 1,
        "graphics": {
          "center": "utilities--utility-pole-left",
          "repeat": "ground--concrete"
        }
      },
      "right": {
        "elevation": 1,
        "graphics": {
          "center": "utilities--utility-pole-right",
          "repeat": "ground--concrete"
        }
      }
    }
  },
  "parklet": {
    "name": "Parklet",
    "nameKey": "parklet",
    "owner": "NATURE",
    "zIndex": 15,
    "defaultWidth": 8,
    "variants": ["orientation"],
    "description": {
      "key": "parklet",
      "image": "parklets-01.jpg"
    },
    "details": {
      "left": {
        "elevation": 0,
        "minWidth": 8,
        "graphics": {
          "left": "parklet--yerba-buena-parklet-left-v02",
          "repeat": "ground--asphalt"
        }
      },
      "right": {
        "elevation": 0,
        "minWidth": 8,
        "graphics": {
          "right": "parklet--yerba-buena-parklet-right-v02",
          "repeat": "ground--asphalt"
        }
      }
    }
  },
  "divider": {
    "name": "Buffer",
    "nameKey": "divider",
    "owner": "NATURE",
    "zIndex": 20,
    "defaultWidth": 2,
    "variants": ["divider-type"],
    "defaultVariant": "planting-strip",
    "details": {
      "planting-strip": {
        "elevation": 1,
        "name": "Planting strip",
        "nameKey": "planting-strip",
        "graphics": {
          "repeat": ["plants--grass", "ground--concrete"]
        }
      },
      "planter-box": {
        "name": "Planter box",
        "nameKey": "planter-box",
        "elevation": 0,
        "graphics": {
          "center": "dividers--planter-box",
          "repeat": ["ground--asphalt", "markings--stripes-diagonal"],
          "left": "markings--lane-left",
          "right": "markings--lane-right"
        }
      },
      "median": {
        "name": "Median",
        "nameKey": "median",
        "elevation": 1,
        "graphics": {
          "repeat": "ground--concrete"
        }
      },
      "striped-buffer": {
        "name": "Buffer",
        "nameKey": "divider",
        "elevation": 0,
        "graphics": {
          "repeat": ["ground--asphalt", "markings--stripes-diagonal"],
          "left": "markings--lane-left",
          "right": "markings--lane-right"
        }
      },
      "bush": {
        "name": "Planting strip",
        "nameKey": "planting-strip",
        "elevation": 1,
        "graphics": {
          "center": "plants--bush",
          "repeat": ["plants--grass", "ground--concrete"]
        }
      },
      "flowers": {
        "name": "Planting strip",
        "nameKey": "planting-strip",
        "elevation": 1,
        "graphics": {
          "center": "plants--flowers",
          "repeat": ["plants--grass", "ground--concrete"]
        }
      },
      "big-tree": {
        "name": "Planting strip",
        "nameKey": "planting-strip",
        "elevation": 1,
        "graphics": {
          "center": "trees--tree",
          "repeat": ["plants--grass", "ground--concrete"]
        }
      },
      "palm-tree": {
        "name": "Planting strip",
        "nameKey": "planting-strip",
        "elevation": 1,
        "graphics": {
          "center": "trees--palm-tree",
          "repeat": ["plants--grass", "ground--concrete"]
        }
      },
      "bollard": {
        "name": "Bollard",
        "nameKey": "bollard",
        "elevation": 0,
        "graphics": {
          "center": "dividers--bollard",
          "repeat": ["ground--asphalt", "markings--stripes-diagonal"],
          "left": "markings--lane-left",
          "right": "markings--lane-right"
        }
      },
      "dome": {
        "name": "Traffic exclusion dome",
        "nameKey": "dome",
        "elevation": 0,
        "graphics": {
          "center": "dividers--dome",
          "repeat": ["ground--asphalt", "markings--stripes-diagonal"],
          "left": "markings--lane-left",
          "right": "markings--lane-right"
        }
      }
    }
  },
  "scooter": {
    "name": "Electric scooter",
    "nameKey": "scooter",
    "owner": "BIKE",
    "zIndex": 16,
    "defaultWidth": 5,
    "enableWithFlag": "SEGMENT_SCOOTERS",
    "variants": ["direction", "bike-asphalt"],
    "details": {
      "inbound|regular": {
        "elevation": 0,
        "minWidth": 4,
        "graphics": {
          "center": [
            "scooters--scooter-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|regular": {
        "elevation": 0,
        "minWidth": 4,
        "graphics": {
          "center": [
            "scooters--scooter-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|green": {
        "elevation": 0,
        "minWidth": 4,
        "graphics": {
          "center": [
            "scooters--scooter-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-green"
        }
      },
      "outbound|green": {
        "elevation": 0,
        "minWidth": 4,
        "graphics": {
          "center": [
            "scooters--scooter-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-green"
        }
      },
      "inbound|red": {
        "elevation": 0,
        "minWidth": 4,
        "graphics": {
          "center": [
            "scooters--scooter-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "outbound|red": {
        "elevation": 0,
        "minWidth": 4,
        "graphics": {
          "center": [
            "scooters--scooter-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      }
    }
  },
  "scooter-drop-zone": {
    "name": "Scooter drop zone",
    "nameKey": "scooter-drop-zone",
    "owner": "FLEX",
    "zIndex": 21,
    "defaultWidth": 5,
    "enableWithFlag": "SEGMENT_SCOOTERS",
    "variants": ["orientation", "scooter-elevation", "scooter-riders"],
    "defaultVariant": "right|sidewalk|empty",
    "details": {
      "left|sidewalk|empty": {
        "elevation": 1,
        "graphics": {
          "left": ["scooters--scooter-left-docked", "markings--lane-left-half"],
          "repeat": ["ground--concrete", "markings--lane-horiz"],
          "right": "markings--lane-right-half"
        }
      },
      "right|sidewalk|empty": {
        "elevation": 1,
        "graphics": {
          "right": [
            "scooters--scooter-right-docked",
            "markings--lane-right-half"
          ],
          "repeat": ["ground--concrete", "markings--lane-horiz"],
          "left": "markings--lane-left-half"
        }
      },
      "left|sidewalk|sparse": {
        "elevation": 1,
        "graphics": {
          "left": ["scooters--scooter-left-rider", "markings--lane-left-half"],
          "repeat": ["ground--concrete", "markings--lane-horiz"],
          "right": "markings--lane-right-half"
        }
      },
      "right|sidewalk|sparse": {
        "elevation": 1,
        "graphics": {
          "right": [
            "scooters--scooter-right-rider",
            "markings--lane-right-half"
          ],
          "repeat": ["ground--concrete", "markings--lane-horiz"],
          "left": "markings--lane-left-half"
        }
      },
      "left|road|empty": {
        "elevation": 0,
        "graphics": {
          "left": ["scooters--scooter-left-docked", "markings--lane-left-half"],
          "repeat": ["ground--asphalt", "markings--lane-horiz"],
          "right": "markings--lane-right-half"
        }
      },
      "right|road|empty": {
        "elevation": 0,
        "graphics": {
          "right": [
            "scooters--scooter-right-docked",
            "markings--lane-right-half"
          ],
          "repeat": ["ground--asphalt", "markings--lane-horiz"],
          "left": "markings--lane-left-half"
        }
      },
      "left|road|sparse": {
        "elevation": 0,
        "graphics": {
          "left": ["scooters--scooter-left-rider", "markings--lane-left-half"],
          "repeat": ["ground--asphalt", "markings--lane-horiz"],
          "right": "markings--lane-right-half"
        }
      },
      "right|road|sparse": {
        "elevation": 0,
        "graphics": {
          "right": [
            "scooters--scooter-right-rider",
            "markings--lane-right-half"
          ],
          "repeat": ["ground--asphalt", "markings--lane-horiz"],
          "left": "markings--lane-left-half"
        }
      }
    }
  },
  "bike-lane": {
    "name": "Bike lane",
    "nameKey": "bike-lane",
    "owner": "BIKE",
    "zIndex": 16,
    "defaultWidth": 6,
    "variants": ["direction", "bike-asphalt", "elevation"],
    "description": {
      "key": "bike-lane",
      "image": "bike-lane-02.jpg"
    },
    "details": {
      "inbound|regular|sidewalk": {
        "elevation": 1,
        "minWidth": 5,
        "graphics": {
          "center": [
            "bikes--biker-01-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|regular|sidewalk": {
        "elevation": 1,
        "minWidth": 5,
        "graphics": {
          "center": [
            "bikes--biker-01-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|green|sidewalk": {
        "elevation": 1,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-green"
        }
      },
      "outbound|green|sidewalk": {
        "elevation": 1,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-green"
        }
      },
      "inbound|red|sidewalk": {
        "elevation": 1,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "outbound|red|sidewalk": {
        "elevation": 1,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "inbound|regular|road": {
        "elevation": 0,
        "minWidth": 5,
        "graphics": {
          "center": [
            "bikes--biker-01-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|regular|road": {
        "elevation": 0,
        "minWidth": 5,
        "graphics": {
          "center": [
            "bikes--biker-01-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|green|road": {
        "elevation": 0,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-green"
        }
      },
      "outbound|green|road": {
        "elevation": 0,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-green"
        }
      },
      "inbound|red|road": {
        "elevation": 0,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "outbound|red|road": {
        "elevation": 0,
        "minWidth": 5,
        "description": {
          "key": "colored-bike-lane",
          "image": "bike-lane-colored-01.jpg"
        },
        "graphics": {
          "center": [
            "bikes--biker-01-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      }
    }
  },
  "bikeshare": {
    "name": "Bikeshare station",
    "nameKey": "bikeshare-station",
    "owner": "FLEX",
    "zIndex": 21,
    "defaultWidth": 7,
    "variants": ["orientation"],
    "details": {
      "left|road": {
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "left": ["bikes--bikeshare-left", "markings--lane-left"],
          "repeat": "ground--asphalt",
          "right": "markings--lane-right"
        }
      },
      "right|road": {
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "right": ["bikes--bikeshare-right", "markings--lane-right"],
          "repeat": "ground--asphalt",
          "left": "markings--lane-left"
        }
      },
      "left|sidewalk": {
        "elevation": 1,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "left": ["bikes--bikeshare-left", "markings--lane-left"],
          "repeat": "ground--asphalt",
          "right": "markings--lane-right"
        }
      },
      "right|sidewalk": {
        "elevation": 1,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "right": ["bikes--bikeshare-right", "markings--lane-right"],
          "repeat": "ground--asphalt",
          "left": "markings--lane-left"
        }
      }
    }
  },
  "food-truck": {
    "name": "Food truck",
    "nameKey": "foodtruck",
    "owner": "FLEX",
    "zIndex": 20,
    "defaultWidth": 10,
    "variants": ["orientation"],
    "details": {
      "left": {
        "elevation": 0,
        "minWidth": 10,
        "graphics": {
          "center": "vehicles--foodtruck-left",
          "repeat": "ground--asphalt"
        }
      },
      "right": {
        "elevation": 0,
        "minWidth": 10,
        "graphics": {
          "center": "vehicles--foodtruck-right",
          "repeat": "ground--asphalt"
        }
      }
    }
  },
  "flex-zone": {
    "name": "Flex zone",
    "nameKey": "flex-zone",
    "owner": "FLEX",
    "zIndex": 15,
    "defaultWidth": 7,
    "variants": ["flex-type", "direction", "orientation"],
    "details": {
      "taxi|inbound|left": {
        "name": "Taxi loading zone",
        "nameKey": "taxi-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--taxi-inbound-door-left",
          "repeat": "ground--asphalt",
          "right": "markings--lane-right"
        }
      },
      "taxi|inbound|right": {
        "name": "Taxi loading zone",
        "nameKey": "taxi-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--taxi-inbound-door-right",
          "repeat": "ground--asphalt",
          "left": "markings--lane-left"
        }
      },
      "taxi|outbound|left": {
        "name": "Taxi loading zone",
        "nameKey": "taxi-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--taxi-outbound-door-left",
          "repeat": "ground--asphalt",
          "right": "markings--lane-right"
        }
      },
      "taxi|outbound|right": {
        "name": "Taxi loading zone",
        "nameKey": "taxi-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--taxi-outbound-door-right",
          "repeat": "ground--asphalt",
          "left": "markings--lane-left"
        }
      },
      "rideshare|inbound|left": {
        "name": "Rideshare loading zone",
        "nameKey": "rideshare-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--rideshare-inbound-door-left",
          "repeat": "ground--asphalt",
          "right": "markings--lane-right"
        }
      },
      "rideshare|inbound|right": {
        "name": "Rideshare loading zone",
        "nameKey": "rideshare-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--rideshare-inbound-door-right",
          "repeat": "ground--asphalt",
          "left": "markings--lane-left"
        }
      },
      "rideshare|outbound|left": {
        "name": "Rideshare loading zone",
        "nameKey": "rideshare-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--rideshare-outbound-door-left",
          "repeat": "ground--asphalt",
          "right": "markings--lane-right"
        }
      },
      "rideshare|outbound|right": {
        "name": "Rideshare loading zone",
        "nameKey": "rideshare-pick-up",
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "center": "vehicles--rideshare-outbound-door-right",
          "repeat": "ground--asphalt",
          "left": "markings--lane-left"
        }
      }
    }
  },
  "flex-zone-curb": {
    "name": "Waiting area",
    "nameKey": "flex-zone-curb",
    "owner": "FLEX",
    "zIndex": 30,
    "defaultWidth": 4,
    "variants": ["waiting-area", "orientation"],
    "details": {
      "sparse|left": {
        "elevation": 1,
        "graphics": {
          "left": "curb--pickup-sign-right",
          "center": "curb--person-waiting-01-right",
          "repeat": "ground--concrete"
        }
      },
      "sparse|right": {
        "elevation": 1,
        "graphics": {
          "right": "curb--pickup-sign-left",
          "center": "curb--person-waiting-01-left",
          "repeat": "ground--concrete"
        }
      },
      "empty|left": {
        "elevation": 1,
        "graphics": {
          "left": "curb--pickup-sign-right",
          "repeat": "ground--concrete"
        }
      },
      "empty|right": {
        "elevation": 1,
        "graphics": {
          "right": "curb--pickup-sign-left",
          "repeat": "ground--concrete"
        }
      }
    }
  },
  "parking-lane": {
    "name": "Parking lane",
    "nameKey": "parking-lane",
    "owner": "CAR",
    "zIndex": 13,
    "defaultWidth": 7,
    "variants": ["parking-lane-direction", "parking-lane-orientation"],
    "details": {
      "inbound|left": {
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "left": {
            "id": "vehicles--car-inbound",
            "offsetX": -80
          },
          "repeat": "ground--asphalt",
          "right": "markings--parking-left"
        }
      },
      "inbound|right": {
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "right": {
            "id": "vehicles--car-inbound",
            "offsetX": -80
          },
          "repeat": "ground--asphalt",
          "left": "markings--parking-right"
        }
      },
      "outbound|left": {
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "left": {
            "id": "vehicles--car-outbound",
            "offsetX": -80
          },
          "repeat": "ground--asphalt",
          "right": "markings--parking-left"
        }
      },
      "outbound|right": {
        "elevation": 0,
        "minWidth": 7,
        "maxWidth": 10,
        "graphics": {
          "right": {
            "id": "vehicles--car-outbound",
            "offsetX": -80
          },
          "repeat": "ground--asphalt",
          "left": "markings--parking-right"
        }
      },
      "sideways|left": {
        "elevation": 0,
        "name": "Perpendicular parking",
        "nameKey": "perpendicular-parking",
        "minWidth": 14,
        "maxWidth": 20,
        "graphics": {
          "left": "vehicles--car-sideways-left",
          "repeat": "ground--asphalt"
        }
      },
      "sideways|right": {
        "elevation": 0,
        "name": "Perpendicular parking",
        "nameKey": "perpendicular-parking",
        "minWidth": 14,
        "maxWidth": 20,
        "graphics": {
          "right": "vehicles--car-sideways-right",
          "repeat": "ground--asphalt"
        }
      },
      "angled-front-left|left": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "left": "vehicles--car-angled-front-left",
          "repeat": "ground--asphalt"
        }
      },
      "angled-front-right|left": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "left": "vehicles--car-angled-front-right",
          "repeat": "ground--asphalt"
        }
      },
      "angled-rear-left|left": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "left": "vehicles--car-angled-rear-left",
          "repeat": "ground--asphalt"
        }
      },
      "angled-rear-right|left": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "left": "vehicles--car-angled-rear-right",
          "repeat": "ground--asphalt"
        }
      },
      "angled-front-left|right": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "right": "vehicles--car-angled-front-left",
          "repeat": "ground--asphalt"
        }
      },
      "angled-front-right|right": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "right": "vehicles--car-angled-front-right",
          "repeat": "ground--asphalt"
        }
      },
      "angled-rear-left|right": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "right": "vehicles--car-angled-rear-left",
          "repeat": "ground--asphalt"
        }
      },
      "angled-rear-right|right": {
        "elevation": 0,
        "name": "Angled parking",
        "nameKey": "angled-parking",
        "minWidth": 14,
        "maxWidth": 18,
        "graphics": {
          "right": "vehicles--car-angled-rear-right",
          "repeat": "ground--asphalt"
        }
      }
    }
  },
  "drive-lane": {
    "name": "Drive lane",
    "nameKey": "drive-lane",
    "owner": "CAR",
    "zIndex": 14,
    "defaultWidth": 10,
    "variants": ["direction", "car-type"],
    "details": {
      "inbound|car": {
        "elevation": 0,
        "minWidth": 8,
        "maxWidth": 11.9,
        "graphics": {
          "center": [
            "vehicles--car-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|car": {
        "elevation": 0,
        "minWidth": 8,
        "maxWidth": 11.9,
        "graphics": {
          "center": [
            "vehicles--car-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|sharrow": {
        "name": "Sharrow",
        "nameKey": "sharrow",
        "minWidth": 12,
        "maxWidth": 14,
        "defaultWidth": 14,
        "description": {
          "key": "sharrow",
          "image": "sharrow-01.jpg"
        },
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-inbound",
            "bikes--biker-02-inbound",
            "markings--sharrow-inbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|sharrow": {
        "name": "Sharrow",
        "nameKey": "sharrow",
        "minWidth": 12,
        "maxWidth": 14,
        "defaultWidth": 14,
        "description": {
          "key": "sharrow",
          "image": "sharrow-01.jpg"
        },
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-outbound",
            "bikes--biker-02-outbound",
            "markings--sharrow-outbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|truck": {
        "minWidth": 8,
        "maxWidth": 11.9,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--truck-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|truck": {
        "minWidth": 8,
        "maxWidth": 11.9,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--truck-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      }
    }
  },
  "turn-lane": {
    "name": "Turn lane",
    "nameKey": "turn-lane",
    "owner": "CAR",
    "zIndex": 13,
    "defaultWidth": 10,
    "variants": ["direction", "turn-lane-orientation"],
    "details": {
      "inbound|left": {
        "elevation": 0,
        "minWidth": 9,
        "maxWidth": 12,
        "graphics": {
          "center": [
            "vehicles--car-inbound-turn-signal-right",
            "markings--left-inbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|left-straight": {
        "elevation": 0,
        "minWidth": 9,
        "maxWidth": 12,
        "graphics": {
          "center": [
            "vehicles--car-inbound-turn-signal-right",
            "markings--left-straight-inbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|straight": {
        "name": "No turn lane",
        "nameKey": "turn-lane-straight",
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": ["vehicles--car-inbound", "markings--straight-inbound"],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|right-straight": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-inbound-turn-signal-left",
            "markings--right-straight-inbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|right": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-inbound-turn-signal-left",
            "markings--right-inbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|both": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-inbound-turn-signal-right",
            "markings--both-inbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|shared": {
        "name": "Center turn lane",
        "nameKey": "turn-lane-center",
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": "markings--shared-inbound",
          "repeat": "ground--asphalt",
          "left": "markings--center-lane-left",
          "right": "markings--center-lane-right"
        }
      },
      "outbound|left": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-outbound-turn-signal-left",
            "markings--left-outbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|left-straight": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-outbound-turn-signal-left",
            "markings--left-straight-outbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|straight": {
        "name": "No turn lane",
        "nameKey": "turn-lane-straight",
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": ["vehicles--car-outbound", "markings--straight-outbound"],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|right-straight": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-outbound-turn-signal-right",
            "markings--right-straight-outbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|right": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-outbound-turn-signal-right",
            "markings--right-outbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|both": {
        "minWidth": 9,
        "maxWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": [
            "vehicles--car-outbound-turn-signal-left",
            "markings--both-outbound"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|shared": {
        "name": "Center turn lane",
        "nameKey": "turn-lane-center",
        "minWidth": 10,
        "maxWidth": 16,
        "defaultWidth": 12,
        "elevation": 0,
        "graphics": {
          "center": "markings--shared-outbound",
          "repeat": "ground--asphalt",
          "left": "markings--center-lane-left",
          "right": "markings--center-lane-right"
        }
      }
    }
  },
  "bus-lane": {
    "name": "Bus lane",
    "nameKey": "bus-lane",
    "owner": "TRANSIT",
    "zIndex": 15,
    "defaultWidth": 12,
    "variants": ["direction", "bus-asphalt"],
    "details": {
      "inbound|regular": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 13,
        "graphics": {
          "center": [
            "transit--bus-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|regular": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 13,
        "graphics": {
          "center": [
            "transit--bus-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|colored": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 13,
        "graphics": {
          "center": [
            "transit--bus-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "outbound|colored": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 13,
        "graphics": {
          "center": [
            "transit--bus-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "inbound|shared": {
        "elevation": 0,
        "name": "Shared bus/bike lane",
        "nameKey": "bus-lane-shared",
        "minWidth": 12,
        "maxWidth": 14,
        "defaultWidth": 14,
        "graphics": {
          "center": [
            "transit--bus-inbound",
            {
              "id": "bikes--biker-02-inbound",
              "offsetX": 30
            },
            "markings--sharrow-inbound"
          ],
          "repeat": "ground--asphalt",
          "left": "markings--lane-left",
          "right": "markings--lane-right"
        }
      },
      "outbound|shared": {
        "elevation": 0,
        "name": "Shared bus/bike lane",
        "nameKey": "bus-lane-shared",
        "minWidth": 12,
        "maxWidth": 14,
        "defaultWidth": 14,
        "graphics": {
          "center": [
            "transit--bus-outbound",
            {
              "id": "bikes--biker-02-outbound",
              "offsetX": -30
            },
            "markings--sharrow-outbound"
          ],
          "repeat": "ground--asphalt",
          "left": "markings--lane-left",
          "right": "markings--lane-right"
        }
      }
    }
  },
  "streetcar": {
    "name": "Streetcar",
    "nameKey": "streetcar",
    "owner": "TRANSIT",
    "zIndex": 15,
    "defaultWidth": 12,
    "variants": ["direction", "public-transit-asphalt"],
    "details": {
      "inbound|regular": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "markings--streetcar-track-01",
            "transit--streetcar-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "outbound|regular": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "markings--streetcar-track-01",
            "transit--streetcar-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt"
        }
      },
      "inbound|colored": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "markings--streetcar-track-02",
            "transit--streetcar-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "outbound|colored": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "markings--streetcar-track-02",
            "transit--streetcar-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "inbound|grass": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "markings--streetcar-track-02",
            "transit--streetcar-inbound",
            "markings--straight-inbound-light"
          ],
          "repeat": ["plants--grass", "ground--asphalt-green"]
        }
      },
      "outbound|grass": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "markings--streetcar-track-02",
            "transit--streetcar-outbound",
            "markings--straight-outbound-light"
          ],
          "repeat": ["plants--grass", "ground--asphalt-green"]
        }
      }
    }
  },
  "light-rail": {
    "name": "Light rail",
    "nameKey": "light-rail",
    "owner": "TRANSIT",
    "zIndex": 15,
    "defaultWidth": 12,
    "variants": ["direction", "public-transit-asphalt"],
    "details": {
      "inbound|regular": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "transit--light-rail-inbound",
            "markings--streetcar-track-02",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-gray"
        }
      },
      "outbound|regular": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "transit--light-rail-outbound",
            "markings--streetcar-track-02",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-gray"
        }
      },
      "inbound|colored": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "transit--light-rail-inbound",
            "markings--streetcar-track-02",
            "markings--straight-inbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "outbound|colored": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "transit--light-rail-outbound",
            "markings--streetcar-track-02",
            "markings--straight-outbound-light"
          ],
          "repeat": "ground--asphalt-red"
        }
      },
      "inbound|grass": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "transit--light-rail-inbound",
            "markings--streetcar-track-02",
            "markings--straight-inbound-light"
          ],
          "repeat": ["plants--grass", "ground--asphalt-green"]
        }
      },
      "outbound|grass": {
        "elevation": 0,
        "minWidth": 10,
        "maxWidth": 14,
        "graphics": {
          "center": [
            "transit--light-rail-outbound",
            "markings--streetcar-track-02",
            "markings--straight-outbound-light"
          ],
          "repeat": ["plants--grass", "ground--asphalt-green"]
        }
      }
    }
  },
  "transit-shelter": {
    "name": "Transit shelter",
    "nameKey": "transit-shelter",
    "owner": "TRANSIT",
    "zIndex": 20,
    "defaultWidth": 9,
    "variants": ["orientation", "transit-shelter-elevation"],
    "defaultVariant": "right|light-rail",
    "details": {
      "left|street-level": {
        "elevation": 1,
        "minWidth": 9,
        "graphics": {
          "left": "transit--transit-shelter-01-left",
          "repeat": "ground--concrete"
        }
      },
      "right|street-level": {
        "elevation": 1,
        "minWidth": 9,
        "graphics": {
          "right": "transit--transit-shelter-01-right",
          "repeat": "ground--concrete"
        }
      },
      "left|light-rail": {
        "minWidth": 8,
        "description": {
          "key": "transit-shelter-elevated",
          "image": "transit-station-elevated.jpg"
        },
        "elevation": 2,
        "graphics": {
          "left": "transit--transit-shelter-02-left",
          "repeat": "ground--concrete-raised"
        }
      },
      "right|light-rail": {
        "minWidth": 8,
        "description": {
          "key": "transit-shelter-elevated",
          "image": "transit-station-elevated.jpg"
        },
        "elevation": 2,
        "graphics": {
          "right": "transit--transit-shelter-02-right",
          "repeat": "ground--concrete-raised"
        }
      }
    }
  },
  "train": {
    "name": "“Inception” train",
    "nameKey": "inception-train",
    "owner": "TRANSIT",
    "zIndex": 10,
    "defaultWidth": 14,
    "variants": [""],
    "enableWithFlag": "SEGMENT_INCEPTION_TRAIN",
    "description": {
      "key": "inception-train",
      "image": "train.jpg"
    },
    "details": {
      "": {
        "elevation": 0,
        "minWidth": 14,
        "graphics": {
          "center": "secret--inception-train",
          "repeat": "ground--asphalt"
        }
      }
    }
  }
}
