{
  "sidewalk-tree:big": 20921,
  "sidewalk:normal": 15567,
  "sidewalk-wayfinding:medium": 4834,
  "sidewalk-lamp:left|modern": 1544,
  "sidewalk:dense": 1422,
  "drive-lane:outbound|car": 742,
  "drive-lane:inbound|car": 606,
  "sidewalk-bench:right": 455,
  "sidewalk-lamp:left|traditional": 434,
  "divider:flowers": 394,
  "parking-lane:outbound|right": 383,
  "bike-lane:outbound|colored": 369,
  "sidewalk-lamp:both|traditional": 361,
  "sidewalk-bench:left": 360,
  "sidewalk-tree:palm-tree": 308,
  "transit-shelter:right|street-level": 285,
  "bike-lane:inbound|regular|road": 270,
  "bike-lane:outbound|green|road": 267,
  "parking-lane:inbound|right": 249,
  "divider:big-tree": 249,
  "bike-lane:outbound|regular|road": 248,
  "sidewalk-wayfinding:small": 243,
  "sidewalk-wayfinding:large": 195,
  "bike-lane:outbound|regular": 188,
  "sidewalk-lamp:both|modern": 184,
  "sidewalk:sparse": 171,
  "sidewalk-bike-rack:left|sidewalk-parallel": 139,
  "parklet:right": 138,
  "bike-lane:inbound|regular": 130,
  "drive-lane:outbound|truck": 117,
  "sidewalk-bike-rack:left|sidewalk": 112,
  "divider:planting-strip": 104,
  "divider:bush": 100,
  "sidewalk-bench:center": 99,
  "parklet:left": 98,
  "light-rail:inbound|regular": 98,
  "divider:median": 95,
  "divider:planter-box": 92,
  "divider:striped-buffer": 90,
  "sidewalk-lamp:right|traditional": 87,
  "streetcar:inbound|regular": 68,
  "turn-lane:outbound|right": 66,
  "divider:bollard": 66,
  "bike-lane:inbound|colored": 65,
  "turn-lane:outbound|straight": 63,
  "flex-zone-curb:sparse|left": 61,
  "light-rail:outbound|regular": 59,
  "bus-lane:outbound|regular": 57,
  "transit-shelter:left|street-level": 55,
  "bus-lane:inbound|regular": 54,
  "bike-lane:outbound|regular|sidewalk": 54,
  "transit-shelter:right|light-rail": 51,
  "sidewalk:empty": 46,
  "bus-lane:inbound|regular|typical": 45,
  "bike-lane:outbound|red|road": 45,
  "turn-lane:outbound|left-straight": 42,
  "bus-lane:outbound|regular|typical": 42,
  "bike-lane:outbound|red|sidewalk": 41,
  "bike-lane:outbound|green|sidewalk": 39,
  "turn-lane:outbound|right-straight": 37,
  "bike-lane:inbound|green|road": 37,
  "sidewalk-lamp:right|modern": 36,
  "sidewalk-bike-rack:right|sidewalk-parallel": 36,
  "scooter:inbound|regular": 35,
  "turn-lane:inbound|right": 33,
  "divider:dome": 32,
  "streetcar:outbound|regular": 32,
  "parking-lane:inbound|left": 31,
  "drive-lane:outbound|sharrow": 30,
  "bikeshare:left|road": 27,
  "parking-lane:outbound|left": 23,
  "sidewalk-bike-rack:right|sidewalk": 23,
  "drive-lane:inbound|truck": 22,
  "bus-lane:inbound|shared|typical": 22,
  "parking-lane:sideways|right": 21,
  "bike-lane:inbound|regular|sidewalk": 21,
  "bike-lane:inbound|green|sidewalk": 20,
  "turn-lane:inbound|straight": 18,
  "food-truck:left": 18,
  "turn-lane:outbound|left": 17,
  "divider:palm-tree": 17,
  "sidewalk-lamp:left|pride": 14,
  "scooter-drop-zone:right|sidewalk|empty": 14,
  "bus-lane:outbound|shared|typical": 11,
  "transit-shelter:left|light-rail": 9,
  "bus-lane:inbound|colored": 9,
  "drive-lane:inbound|sharrow": 9,
  "light-rail:outbound|colored": 9,
  "turn-lane:inbound|right-straight": 9,
  "food-truck:right": 9,
  "bus-lane:outbound|colored|typical": 9,
  "bike-lane:inbound|red|road": 9,
  "scooter:outbound|regular": 9,
  "bike-lane:inbound|red|sidewalk": 8,
  "bus-lane:outbound|colored": 7,
  "sidewalk-lamp:both|pride": 7,
  "light-rail:inbound|colored": 7,
  "flex-zone:taxi|inbound|left": 7,
  "turn-lane:inbound|left": 6,
  "light-rail:outbound|grass": 6,
  "flex-zone:taxi|outbound|left": 6,
  "light-rail:inbound|grass": 5,
  "bikeshare:left|sidewalk": 5,
  "streetcar:outbound|colored": 4,
  "scooter:outbound|green": 4,
  "bikeshare:right|road": 4,
  "streetcar:outbound|grass": 4,
  "turn-lane:outbound|shared": 3,
  "streetcar:inbound|colored": 3,
  "streetcar:inbound|grass": 3,
  "flex-zone-curb:sparse|right": 3,
  "flex-zone-curb:empty|left": 3,
  "flex-zone:rideshare|inbound|left": 3,
  "parking-lane:sideways|left": 2,
  "flex-zone:taxi|outbound|right": 2,
  "flex-zone:taxi|inbound|right": 2,
  "turn-lane:inbound|shared": 2,
  "flex-zone:rideshare|outbound|right": 2,
  "drive-lane:car": 2,
  "bike-lane:outbound|green|road|inbound": 2,
  "bus-lane:inbound|colored|typical": 2,
  "drive-lane:inbound": 1,
  "scooter:outbound|red": 1,
  "parking-lane:angled-rear-right|right": 1,
  "turn-lane:left|outbound": 1,
  "bike-lane:green|outbound|sidewalk": 1,
  "bikeshare:right|sidewalk": 1,
  "turn-lane:outbound|left-right-straight": 1,
  "turn-lane:outbound|both": 1,
  "parking-lane:right": 1,
  "turn-lane:inbound|left-straight": 1,
  "scooter-drop-zone:left|sidewalk|sparse": 1,
  "parking-lane:inbound": 1,
  "parking-lane:angled-front-right|right": 1,
  "bike-lane:road|red|outbound": 1,
  "bike-lane:inbound": 1,
  "temporary:jersey-barrier-concrete": 1,
  "flex-zone:rideshare|inbound|right": 1,
  "sidewalk-bike-rack:right|road": 1,
  "bike-lane:outbound": 1,
  "scooter:inbound|green": 1
}
