{
  "display": false,
  "lede": "We stand with the Black community and against police brutality.",
  "link": "https://blacklivesmatters.carrd.co/",
  "linkText": "Learn about ways you can help.",
  "_lede": "We’ve got swag!",
  "_text": "And so can you. Streetmix t-shirts and hoodies are now available!",
  "_link": "https://cottonbureau.com/people/streetmix",
  "_linkText": "Take me to the store",
  "_localStorageKey": "notification-store-dismissed"
}
