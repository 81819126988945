{
  "sidewalk-tree": {
    "big": 94158,
    "palm-tree": 2343
  },
  "sidewalk-lamp": {
    "right|modern": 40681,
    "left|modern": 40192,
    "both|traditional": 3413,
    "right|traditional": 2787,
    "both|modern": 2723,
    "left|traditional": 2302,
    "right|pride": 157,
    "left|pride": 123,
    "both|pride": 115,
    "modern": 4,
    "pride": 3,
    "pride|left": 2,
    "modern|left": 2,
    "left": 1,
    "both": 1,
    "pride|both": 1,
    "traditional|left": 1,
    "traditional": 1,
    "modern|both": 1
  },
  "sidewalk": {
    "dense": 49883,
    "normal": 19696,
    "sparse": 1402,
    "empty": 364
  },
  "divider": {
    "flowers": 23517,
    "bush": 17399,
    "planter-box": 15835,
    "big-tree": 3507,
    "median": 2929,
    "striped-buffer": 1806,
    "planting-strip": 1707,
    "bollard": 1600,
    "palm-tree": 1224,
    "dome": 871
  },
  "drive-lane": {
    "inbound|car": 35533,
    "outbound|truck": 19122,
    "outbound|car": 11727,
    "inbound|truck": 1247,
    "outbound|sharrow": 346,
    "inbound|sharrow": 341,
    "car": 10,
    "outbound": 9,
    "truck": 6,
    "inbound": 5,
    "right-straight|car": 4,
    "straight|car": 2,
    "car|outbound": 2,
    "car|car": 2,
    "truck|outbound": 1,
    "sharrow": 1,
    "truck|inbound": 1,
    "truck|truck": 1,
    "inbound|pedestrian": 1,
    "outbound|": 1,
    "inbound|": 1
  },
  "bike-lane": {
    "outbound|colored": 22658,
    "outbound|green|road": 16785,
    "inbound|regular": 3630,
    "inbound|colored": 2798,
    "inbound|regular|road": 2758,
    "outbound|regular": 1644,
    "outbound|regular|road": 1047,
    "inbound|green|road": 781,
    "outbound|green|sidewalk": 290,
    "inbound|green|sidewalk": 286,
    "inbound|red|road": 274,
    "outbound|red|road": 269,
    "inbound|regular|sidewalk": 243,
    "outbound|regular|sidewalk": 194,
    "inbound|red|sidewalk": 132,
    "outbound|red|sidewalk": 122,
    "outbound|green|road|inbound": 28,
    "outbound|green": 12,
    "outbound|regular|road|inbound": 10,
    "outbound|regular|sidewalk|inbound": 8,
    "outbound|red|sidewalk|inbound": 8,
    "outbound|red|road|inbound": 6,
    "outbound": 4,
    "inbound": 3,
    "outbound|green|sidewalk|inbound": 3,
    "road|inbound": 2,
    "inbound|green": 1,
    "outbound|red": 1,
    "sidewalk": 1,
    "green|outbound|sidewalk": 1,
    "red": 1,
    "red||sidewalk": 1,
    "road|outbound|regular": 1,
    "sidewalk|green|inbound": 1,
    "inbound|road|green": 1,
    "regular|road": 1,
    "red|inbound|sidewalk": 1,
    "green|sidewalk": 1,
    "road|red|outbound": 1,
    "green": 1,
    "regular|inbound|sidewalk": 1,
    "green|outbound": 1,
    "red|road|outbound": 1,
    "outbound||": 1
  },
  "turn-lane": {
    "outbound|left-straight": 14894,
    "inbound|right-straight": 13936,
    "outbound|left": 3773,
    "outbound|straight": 1677,
    "outbound|right-straight": 1583,
    "inbound|right": 1220,
    "inbound|left-straight": 828,
    "inbound|left": 773,
    "inbound|straight": 696,
    "outbound|right": 652,
    "inbound|shared": 359,
    "outbound|shared": 176,
    "inbound|both": 148,
    "outbound|both": 147,
    "outbound|left-right-straight": 137,
    "inbound|left-right-straight": 47,
    "outbound": 5,
    "left|outbound": 5,
    "straight": 4,
    "shared": 3,
    "left": 2,
    "inbound|left|left": 2,
    "outbound|left|straight": 2,
    "left-straight": 2,
    "right|outbound": 2,
    "shared|outbound": 2,
    "straight|outbound": 2,
    "both": 1,
    "inbound": 1,
    "outbound|left|right": 1,
    "right-straight": 1,
    "left-straight|left": 1,
    "straight|left": 1,
    "right-straight|outbound": 1,
    "left-right-straight|outbound": 1,
    "right|right-straight": 1,
    "left|inbound": 1,
    "left-right-straight|inbound": 1,
    "left-straight|outbound": 1,
    "left-straight|shared": 1,
    "straight|inbound": 1
  },
  "transit-shelter": {
    "left|street-level": 34920,
    "right|street-level": 3618,
    "left|light-rail": 985,
    "right|light-rail": 854,
    "right": 8,
    "light-rail": 2,
    "left": 1,
    "street-level|right": 1
  },
  "bus-lane": {
    "inbound|regular": 18976,
    "inbound|shared|typical": 14628,
    "inbound|regular|typical": 1479,
    "outbound|regular": 1459,
    "outbound|regular|typical": 881,
    "inbound|colored": 741,
    "outbound|colored": 338,
    "inbound|colored|typical": 300,
    "outbound|shared|typical": 214,
    "outbound|colored|typical": 188,
    "inbound": 3,
    "colored": 3,
    "outbound": 2,
    "regular": 2,
    "shared|inbound": 2,
    "colored|inbound|typical": 1,
    "inbound|typical": 1,
    "regular|typical": 1,
    "shared|typical|inbound": 1,
    "colored|inbound": 1,
    "inbound||": 1,
    "colored||typical": 1
  },
  "parking-lane": {
    "outbound|right": 30645,
    "inbound|left": 2793,
    "outbound|left": 2137,
    "inbound|right": 1747,
    "sideways|right": 114,
    "sideways|left": 93,
    "angled-front-right|right": 25,
    "angled-front-left|left": 24,
    "angled-rear-right|right": 23,
    "angled-rear-left|right": 9,
    "angled-front-left|right": 8,
    "angled-rear-left|left": 7,
    "angled-rear-right|left": 7,
    "angled-front-right|left": 4,
    "outbound": 3,
    "angled-rear-left": 2,
    "right": 1,
    "angled-rear-right": 1,
    "inbound": 1,
    "angled-front-right": 1
  },
  "sidewalk-wayfinding": {
    "medium": 20537,
    "large": 1304,
    "small": 881
  },
  "light-rail": {
    "inbound|regular": 2610,
    "outbound|regular": 1727,
    "inbound|colored": 494,
    "outbound|colored": 420,
    "inbound|grass": 132,
    "outbound|grass": 104,
    "outbound": 1,
    "colored|inbound": 1
  },
  "sidewalk-bench": {
    "left": 2952,
    "right": 1345,
    "center": 462
  },
  "sidewalk-bike-rack": {
    "left|sidewalk": 1557,
    "left|sidewalk-parallel": 1004,
    "right|sidewalk-parallel": 713,
    "right|sidewalk": 390,
    "left|road": 68,
    "right|road": 44
  },
  "streetcar": {
    "inbound|regular": 1730,
    "outbound|regular": 1220,
    "inbound|colored": 236,
    "outbound|colored": 220,
    "inbound|grass": 82,
    "outbound|grass": 60,
    "outbound": 1
  },
  "parklet": {
    "left": 1189,
    "right": 941
  },
  "scooter": {
    "inbound|regular": 411,
    "outbound|regular": 94,
    "inbound|green": 31,
    "outbound|green": 29,
    "outbound|red": 14,
    "inbound|red": 13
  },
  "flex-zone-curb": {
    "sparse|left": 360,
    "sparse|right": 143,
    "empty|right": 16,
    "empty|left": 11
  },
  "flex-zone": {
    "taxi|inbound|left": 215,
    "taxi|outbound|right": 79,
    "taxi|outbound|left": 37,
    "rideshare|inbound|left": 33,
    "rideshare|outbound|right": 29,
    "taxi|inbound|right": 27,
    "rideshare|outbound|left": 5,
    "rideshare|inbound|right": 4,
    "taxi|shared|left": 1
  },
  "bikeshare": {
    "left|road": 230,
    "left|sidewalk": 31,
    "right|road": 15,
    "right|sidewalk": 11,
    "sidewalk": 1
  },
  "food-truck": {
    "left": 207,
    "right": 40
  },
  "scooter-drop-zone": {
    "right|sidewalk|empty": 158,
    "left|sidewalk|empty": 9,
    "right|sidewalk|sparse": 5,
    "left|sidewalk|sparse": 4,
    "left|road|empty": 3,
    "right|road|empty": 2,
    "right|road|sparse": 1
  },
  "utilities": {
    "left": 19,
    "right": 9
  },
  "outdoor-dining": {
    "occupied|sidewalk": 17,
    "occupied|road": 6
  },
  "temporary": {
    "traffic-cone": 9,
    "jersey-barrier-concrete": 5,
    "barricade": 2
  },
  "brt-station": {
    "center": 3,
    "left": 2,
    "right": 1
  },
  "brt-lane": {
    "inbound|red": 2,
    "inbound|regular": 1,
    "outbound|regular": 1
  },
  "street-vendor": {
    "table|concrete": 2,
    "tarp|concrete": 1
  },
  "train": {
    "": 1
  }
}
