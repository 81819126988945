"use strict";
/**
 * Parameters to be set for Pelias searches. Uses 'this' so each search module can import identical functions
 * and apply to the internal search object.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const validate_1 = require("../validate/validate");
exports.setSearchTerm = function (objectKey, searchTerm) {
    if (!validate_1.isValidString(searchTerm)) {
        throw new Error('Search term should be a nonempty string');
    }
    this[objectKey].searchTerm = searchTerm;
    return this;
};
// Set a locale to search near - require both lat and long
exports.setFocusPoint = function (objectKey, point) {
    if (!validate_1.isNumeric(point.lat) || !validate_1.isNumeric(point.lon)) {
        throw new Error('Lat and long values should be numeric floating-point coordinates');
    }
    this[objectKey].focusPoint = point;
    return this;
};
// Set a locale to search near - require both lat and long
exports.setResultsLimit = function (objectKey, limit) {
    if (!Number.isInteger(limit)) {
        throw new Error('Limit should be an integer');
    }
    this[objectKey].resultsLimit = limit;
    return this;
};
// Restrict search to a boundary country
exports.setBoundaryCountry = function (objectKey, boundaryCountry) {
    if (!validate_1.isValidString(boundaryCountry)) {
        throw new Error('Boundary country should be a nonempty string');
    }
    this[objectKey].boundaryCountry = boundaryCountry;
    return this;
};
// Restrict search to a boundary rectangle
exports.setBoundaryRectangle = function (objectKey, boundaryRectangle) {
    if (!validate_1.isValidBoundaryRectangle(boundaryRectangle)) {
        throw new Error('Boundary rectangle should be an object with keys min_lat, max_lat, min_lon, max_lon. Values should be floating-point coordinates');
    }
    this[objectKey].boundaryRectangle = boundaryRectangle;
    return this;
};
// Restrict search to a boundary circle
exports.setBoundaryCircle = function (objectKey, boundaryCircle) {
    if (!validate_1.isValidBoundaryCircle(boundaryCircle)) {
        throw new Error('Boundary circle should be an object with keys lat, lon, and radius. Lat and lon should be floating-point coordinates, radius may be floating point or integer');
    }
    this[objectKey].boundaryCircle = boundaryCircle;
    return this;
};
// Restrict search to a boundary admin area
exports.setBoundaryAdminArea = function (objectKey, boundaryAdminArea) {
    if (!validate_1.isValidString(boundaryAdminArea)) {
        throw new Error('Boundary admin area should be a nonempty string');
    }
    this[objectKey].boundaryAdminArea = boundaryAdminArea;
    return this;
};
// Restrict search to a specific data source
exports.setDataSources = function (objectKey, dataSources) {
    if (!validate_1.isValidDataSources(dataSources)) {
        throw new Error('Data sources should be an array with one or more of: oa, osm, wof, gn');
    }
    this[objectKey].dataSources = dataSources;
    return this;
};
// Restrict search to a specific layer
exports.setLayers = function (objectKey, layers) {
    if (!validate_1.isValidLayers(layers)) {
        throw new Error('Data sources should be an array with one or more of: venue, address, street, neighbourhood, borough, localadmin, locality, county, macrocounty, region, macroregion, country, coarse');
    }
    this[objectKey].layers = layers;
    return this;
};
