[
  {
    "id": "people-01",
    "width": 2,
    "name": "Nora"
  },
  {
    "id": "people-02",
    "width": 2
  },
  {
    "id": "people-03",
    "width": 2
  },
  {
    "id": "people-04",
    "width": 2
  },
  {
    "id": "people-05",
    "width": 2
  },
  {
    "id": "people-06",
    "width": 2
  },
  {
    "id": "people-07",
    "width": 2
  },
  {
    "id": "people-08",
    "width": 2
  },
  {
    "id": "people-09",
    "width": 2
  },
  {
    "id": "people-10",
    "width": 2
  },
  {
    "id": "people-11",
    "width": 2
  },
  {
    "id": "people-12",
    "width": 2
  },
  {
    "id": "people-13",
    "width": 2
  },
  {
    "id": "people-14",
    "width": 3
  },
  {
    "id": "people-15",
    "width": 2
  },
  {
    "id": "people-16",
    "width": 3,
    "name": "Lou"
  },
  {
    "id": "people-17",
    "width": 3
  },
  {
    "id": "people-18",
    "width": 3
  },
  {
    "id": "people-19",
    "width": 3,
    "disallowFirst": true
  },
  {
    "id": "people-20",
    "width": 3,
    "disallowFirst": true
  },
  {
    "id": "people-21",
    "width": 1
  },
  {
    "id": "people-22",
    "width": 1
  },
  {
    "id": "people-23",
    "width": 3,
    "name": "Katie"
  },
  {
    "id": "people-24",
    "width": 4
  },
  {
    "id": "people-25",
    "width": 2
  },
  {
    "id": "people-26",
    "width": 3
  },
  {
    "id": "people-27",
    "width": 2
  },
  {
    "id": "people-28",
    "width": 3
  },
  {
    "id": "people-29",
    "width": 4
  },
  {
    "id": "people-30",
    "width": 3
  },
  {
    "id": "people-31",
    "width": 2
  },
  {
    "id": "johnny-01",
    "width": 3,
    "name": "Johnny",
    "disallowFirst": true,
    "weight": 10
  },
  {
    "id": "johnny-02",
    "width": 3,
    "name": "Johnny",
    "disallowFirst": true,
    "weight": 5
  },
  {
    "id": "junebug-01",
    "width": 3,
    "name": "Junebug",
    "disallowFirst": true,
    "weight": 10
  },
  {
    "id": "junebug-02",
    "width": 3,
    "name": "Junebug",
    "disallowFirst": true,
    "weight": 5
  },
  {
    "id": "bernie",
    "width": 3,
    "name": "Bernie",
    "weight": 1
  }
]
