{
  "sidewalk:dense": 42637,
  "sidewalk-tree:big": 2391,
  "sidewalk:normal": 2324,
  "sidewalk-bench:left": 1093,
  "sidewalk:sparse": 852,
  "sidewalk-lamp:right|modern": 579,
  "drive-lane:inbound|car": 395,
  "sidewalk-lamp:right|traditional": 381,
  "divider:flowers": 368,
  "transit-shelter:left|street-level": 265,
  "divider:planting-strip": 234,
  "sidewalk:empty": 209,
  "parklet:left": 178,
  "sidewalk-tree:palm-tree": 141,
  "sidewalk-bike-rack:left|sidewalk": 132,
  "bike-lane:inbound|regular|road": 119,
  "parking-lane:inbound|left": 118,
  "divider:bush": 118,
  "bike-lane:inbound|regular": 109,
  "sidewalk-wayfinding:large": 103,
  "sidewalk-bike-rack:right|sidewalk-parallel": 103,
  "divider:median": 102,
  "divider:big-tree": 98,
  "sidewalk-bike-rack:left|sidewalk-parallel": 86,
  "sidewalk-bench:right": 73,
  "drive-lane:outbound|car": 69,
  "light-rail:inbound|regular": 68,
  "sidewalk-lamp:both|traditional": 66,
  "parklet:right": 62,
  "turn-lane:inbound|left": 57,
  "bus-lane:inbound|regular": 57,
  "streetcar:inbound|regular": 46,
  "sidewalk-bike-rack:right|sidewalk": 45,
  "bike-lane:inbound|colored": 41,
  "bike-lane:inbound|green|road": 34,
  "bike-lane:inbound|regular|sidewalk": 33,
  "sidewalk-bench:center": 32,
  "turn-lane:inbound|right": 31,
  "transit-shelter:left|light-rail": 28,
  "bus-lane:inbound|shared|typical": 28,
  "transit-shelter:right|street-level": 27,
  "sidewalk-wayfinding:small": 27,
  "drive-lane:inbound|truck": 27,
  "flex-zone-curb:sparse|left": 26,
  "divider:bollard": 25,
  "sidewalk-lamp:both|modern": 24,
  "divider:striped-buffer": 23,
  "bike-lane:inbound|green|sidewalk": 23,
  "parking-lane:inbound|right": 21,
  "bikeshare:left|road": 21,
  "bike-lane:outbound|green|road": 20,
  "parking-lane:outbound|right": 19,
  "bus-lane:inbound|regular|typical": 18,
  "scooter:inbound|regular": 18,
  "divider:palm-tree": 17,
  "sidewalk-wayfinding:medium": 17,
  "flex-zone-curb:sparse|right": 17,
  "sidewalk-lamp:left|modern": 15,
  "scooter-drop-zone:right|sidewalk|empty": 15,
  "parking-lane:sideways|left": 14,
  "bike-lane:outbound|colored": 14,
  "parking-lane:outbound|left": 14,
  "bike-lane:outbound|regular": 13,
  "food-truck:left": 13,
  "drive-lane:outbound|truck": 12,
  "bike-lane:inbound|red|road": 12,
  "sidewalk-lamp:left|traditional": 10,
  "flex-zone:taxi|inbound|left": 10,
  "turn-lane:outbound|straight": 10,
  "light-rail:inbound|colored": 8,
  "turn-lane:inbound|left-straight": 8,
  "turn-lane:outbound|left-straight": 8,
  "streetcar:outbound|regular": 7,
  "parking-lane:sideways|right": 7,
  "drive-lane:inbound|sharrow": 6,
  "turn-lane:inbound|straight": 6,
  "divider:planter-box": 6,
  "bike-lane:outbound|regular|sidewalk": 6,
  "sidewalk-lamp:right|pride": 6,
  "food-truck:right": 6,
  "bus-lane:outbound|colored": 5,
  "light-rail:outbound|regular": 5,
  "bus-lane:inbound|colored": 5,
  "bus-lane:outbound|regular": 5,
  "sidewalk-bike-rack:left|road": 5,
  "turn-lane:inbound|right-straight": 5,
  "turn-lane:outbound|left": 4,
  "turn-lane:outbound|right": 4,
  "flex-zone:rideshare|inbound|left": 4,
  "divider:dome": 4,
  "bus-lane:outbound|regular|typical": 4,
  "bike-lane:outbound|regular|road": 4,
  "outdoor-dining:occupied|sidewalk": 4,
  "sidewalk-bike-rack:right|road": 3,
  "parking-lane:angled-front-left|left": 3,
  "bus-lane:inbound|colored|typical": 3,
  "light-rail:outbound|colored": 2,
  "streetcar:inbound|colored": 2,
  "bus-lane:outbound|shared|typical": 2,
  "light-rail:inbound|grass": 2,
  "drive-lane:outbound|sharrow": 2,
  "turn-lane:outbound|right-straight": 2,
  "bike-lane:inbound|red|sidewalk": 2,
  "scooter:outbound|regular": 1,
  "bike-lane:outbound|green|sidewalk": 1,
  "utilities:left": 1,
  "bike-lane:sidewalk": 1,
  "sidewalk-lamp:both|pride": 1,
  "bikeshare:right|road": 1,
  "flex-zone-curb:empty|left": 1,
  "drive-lane:outbound": 1,
  "bike-lane:road|outbound|regular": 1,
  "turn-lane:inbound|left-right-straight": 1,
  "bus-lane:outbound|colored|typical": 1,
  "parking-lane:angled-rear-left|left": 1,
  "parking-lane:angled-front-left|right": 1,
  "flex-zone:rideshare|outbound|left": 1,
  "scooter-drop-zone:left|sidewalk|sparse": 1,
  "scooter-drop-zone:right|road|empty": 1,
  "transit-shelter:right|light-rail": 1,
  "scooter:inbound|green": 1,
  "bikeshare:sidewalk": 1,
  "scooter:inbound|red": 1,
  "bikeshare:right|sidewalk": 1,
  "flex-zone:taxi|outbound|left": 1,
  "sidewalk-lamp:pride": 1,
  "bus-lane:regular": 1,
  "sidewalk-lamp:pride|both": 1,
  "streetcar:inbound|grass": 1,
  "scooter-drop-zone:right|sidewalk|sparse": 1
}
